import {Modal} from "react-bootstrap";
import React from "react";
import NsModalHeader from "../../components/modal/NsModalHeader";

function ModalMsg({show, handleClose, handlePagamento}) {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <NsModalHeader title={"Pagamento tramite Bonifico Bancario"}/>
            <Modal.Body>
                <p>Effettua un bonifico alle seguenti coordinate bancarie:</p>
                <h6><strong>IBAN:</strong> IT68 Y030 6909 6061 0000 0117 245</h6>
                <h6><strong>PRESSO:</strong> BANCA INTESASANPAOLO SPA</h6>
                <h6><strong>Codice BIC:</strong> BCITITMM</h6>
                <p className={"mt-3"}>Se proseguirai con questa modalità di pagamento, riceverai una E-mail con riassunte le informazioni
                    sulle coordinate bancarie e quota da pagare.</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className={"btn btn-danger btn-lg"} onClick={() => {
                    handlePagamento('BON');
                }}>
                    <i className="fas fa-credit-card"></i> Procedi al pagamento
                </button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalMsg;