import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ButtonBar from "../../components/ButtonBar";
import Card from "../../components/Card";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {getTimestampFormattato} from "../../utility/common";

export default function HomeSocioInAttesaApprov({usr}) {

    const [infoSocio, setInfoSocio] = useState('');

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getDatiRichiedente() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/" + usr.id_utente).then((response) => {
            setInfoSocio(response.data);

            endChiamata()
        });
    }

    useEffect(() => {
        getDatiRichiedente();
    }, [usr]);

    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Card
                        title={"Profilo"}
                        icon={"fas fa-user"}
                    >
                        <div>
                            <h5><strong>{usr.cognome || '-'} {usr.nome || '-'}</strong></h5>
                            <p><i className="fas fa-envelope"/> <strong>{usr.email || '-'}</strong></p>
                            <p><i className="fas fa-phone"/> <strong>{usr.tel_casa || '-'}</strong></p>
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    <i className="fas fa-home"/> Indirizzo:
                                </div>
                                <div className={"col-md-8 text-end"}>
                                    <strong>{usr.indirizzo + ' ' + usr.numero_civico + ' - ' + usr.localita + ' (' + usr.provincia + ')'}</strong>
                                </div>
                                <div className={"col-md-4"}>
                                    <i className="fas fa-mail-bulk"/> Indirizzo spedizione:
                                </div>
                                <div className={"col-md-8 text-end"}>
                                    <strong>{usr.indirizzo_sped + ' ' + usr.numero_civico_sped + ' - ' + usr.localita_sped + ' (' + usr.provincia_sped + ')'}</strong>
                                </div>
                                <ButtonBar align={"text-center"}>
                                    <Link to={"/upd-profilo/" + usr.id_utente} className={"btn btn-principal"}>
                                        <i className={"fas fa-pen"}/> Aggiorna profilo
                                    </Link>
                                </ButtonBar>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className={"col-md-8 ps-4"}>
                    <div className={"text-center mt-4"}>
                        <h2>La tua richiesta di adesione è in fase di approvazione dal nostro Staff.</h2>
                        <h4>Per favore verifica che i dati inseriti siano corretti.</h4>
                        <h4>Ti invieremo un'e-mail quanto prima, nel frattempo non esitare a contattarci per qualsiasi
                            esigenza.</h4>
                    </div>
                    {
                        infoSocio && infoSocio.note && infoSocio.note.length > 0 &&
                        <div className={"mt-4"}>
                            <Card
                                title={"Note della Segreteria"}
                                icon={"fas fa-sticky-note"}
                            >
                                {
                                    infoSocio.note.map(nota =>
                                        <div className={"mt-3 mb-3 row"}>
                                            <div className={"col-md-3"}>
                                                {getTimestampFormattato(nota.data_insert)}
                                            </div>
                                            <div className={"col-md-9"}>
                                                {nota.note}
                                            </div>
                                        </div>
                                    )
                                }
                            </Card>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}
