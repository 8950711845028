import {Modal} from "react-bootstrap";
import React, {useContext} from "react";
import NsModalHeader from "../../components/modal/NsModalHeader";
import {RuoliContext} from "../../context/RuoliContext";

function ModalLegendaStatoCert({show, handleClose}) {

    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Legenda"}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <ul>
                            <li>
                                <strong>Lista Chiusa</strong>: Lista chiusa per alcuni veicoli FIAT e Abarth dai 10 ai 19 anni.
                                Iscrivendoti puoi godere di agevolazioni assicurative. Da verificare con la propria compagnìa.
                            </li>
                            <li>
                                <strong>Albo Storico</strong>: Albo storico per veicoli di almeno 20 anni. Iscrivendoti
                                puoi godere di agevolazioni assicurative. Da verificare con la propria compagnìa.
                            </li>
                            <li>
                                <strong>CRS</strong>: Certifica di Rilevanza Storica e Collezionistica per veicoli di
                                interesse storico e collezionistico già circolanti di almeno 20 anni e dotati di
                                attestato di revisione riportato sulla carta di circolazione. Iscrivendoti puoi godere
                                di agevolazioni ed esenzioni fiscali ed assicurative.
                            </li>
                            <li>
                                <strong>Certificato di omologazione</strong>: Certificato di omologazione che attestano
                                le caratteristiche di originalità del veicolo e il suo buon stato di conservazione.
                            </li>
                        </ul>
                        <p>Stati:</p>
                        {
                            (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) ?
                                <ul className={"ns-list-no-bullet"}>
                                    <li>
                                    <span><i className={"fab fa-firstdraft rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip" title={"Bozza"}/> Bozza</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-spinner rfi-icon-table me-2 ns-span--giallo"}
                                             data-toggle="tooltip"
                                             title={"In Approvazione"}/> <strong>In Approvazione</strong></span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-ban rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip"
                                             title={"Rifiutato"}/> <strong>Rifiutato</strong></span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-check rfi-icon-table me-2 ns-span--verde"}
                                             data-toggle="tooltip"
                                             title={"Approvato"}/> <strong>Approvato</strong></span>
                                    </li>
                                </ul>
                                :
                                <ul className={"ns-list-no-bullet"}>
                                    <li>
                                    <span><i className={"fab fa-firstdraft rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip" title={"Bozza"}/> Bozza</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-paper-plane rfi-icon-table me-2 ns-span--verde"}
                                             data-toggle="tooltip" title={"Richiesta effettuata"}/> Richiesta di approvazione effettuata</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-spinner rfi-icon-table me-2 ns-span--giallo"}
                                             data-toggle="tooltip" title={"In verifica"}/> In verifica da parte di un Commissario</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-check rfi-icon-table me-2 ns-span--giallo"}
                                             data-toggle="tooltip" title={"Approvato da Commissario"}/> Approvato dal Commissario</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-ban rfi-icon-table me-2 ns-span--giallo"}
                                             data-toggle="tooltip" title={"Rifiutato da Commissario"}/> Rifiutato dal Commissario</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-check-double rfi-icon-table me-2 ns-span--verde"}
                                             data-toggle="tooltip" title={"Approvato"}/> Approvato</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-ban rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip" title={"Rifiutato"}/> Rifiutato</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-check-double rfi-icon-table me-2 ns-span--blu"}
                                             data-toggle="tooltip" title={"Firmato da presidente"}/> Firmato dal Presidente</span>
                                    </li>
                                    <li>
                                    <span><i className={"fas fa-ban rfi-icon-table me-2 ns-span--blu"}
                                             data-toggle="tooltip" title={"Rifiutato"}/> Rigettato dal Presidente</span>
                                    </li>
                                </ul>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default ModalLegendaStatoCert;