import {Form, Formik} from "formik";
import axios from "axios";
import { useParams} from "react-router-dom";
import ModalMsg from "../../components/ModalMsg";
import React, {useContext, useRef, useState} from "react";
import {getUtenteFromToken} from "../../utility/common";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import Password from "../../components/Password";
import * as Yup from "yup";
import {TranslateContext} from "../../context/TranslateContext";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";

function AttivaAccount() {
    const {token} = useParams()

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const formikRef = useRef();

    const closeModal = () => setShow(false);

    const SignupSchema = Yup.object().shape({
        password: Yup.string()
            .required(traduci('Password obbligatoria.')),
        password2: Yup.string()
            .required(traduci('La Conferma Password deve essere valorizzata.')),
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <div className="rfi-login rfi-recupera-pwd">
                <Card
                    icon={'fas fa-key'}
                    title={'Completa la registrazione scegliendo la password e cliccando sul bottone Attiva'}
                    subtitle={'Una volta attivato l\'account, potrai accedere ai nostri servizi'}>
                    <Formik
                        initialValues={{
                            password: '',
                            password2: ''
                        }}
                        innerRef={formikRef}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            if (values.password === values.password2) {
                                let utente = getUtenteFromToken(token);
                                if (utente && utente.id_utente && utente.id_utente !== '') {
                                    startChiamata()
                                    axios
                                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + utente.id_utente + "/password/",
                                            values, {
                                                headers: {
                                                    jwt: token
                                                }
                                            })
                                        .then((response) => {
                                            setModalMsg(response.data.description);
                                            setShow(true);
                                            endChiamata()
                                        });
                                }
                            } else {
                                setModalMsg('La Password e la Conferma password devono avere lo stesso valore');
                                setShow(true);
                            }
                        }}
                    >
                        {({values, errors, touched, submitCount}) => (
                            <Form className="row g-3">
                                <div className="col-12">
                                    <label htmlFor="password" className="form-label">Password*</label>
                                    <Password name={"password"} values={values} formikRef={formikRef} showControlli={true}/>
                                    <LabelErroreCampo errors={errors} touched={touched} name={"password"}
                                                      submitCount={submitCount}/>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="password2" className="form-label">Conferma password*</label>
                                    <Password name={"password2"} values={values} formikRef={formikRef} />
                                    <LabelErroreCampo errors={errors} touched={touched} name={"password2"}
                                                      submitCount={submitCount}/>
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" name="conferma" id="conferma"
                                            className="btn btn-dark"
                                            disabled={values.password === '' || values.password2 === '' || values.password !== values.password2}>
                                        <i className="fas fa-check" /> Conferma
                                    </button>
                                </div>
                                <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </div>
        </div>
    )
}

export default AttivaAccount;
