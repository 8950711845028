import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import Pager from "../../components/Pager";
import NsDataTable from "../../components/tabelle/NsDataTable";
import NsAccordion from "../../components/accordion/NsAccordion";
import {
    getDataFormattata,
    getIconeStatoSocio, getTimestampFormattato,
} from "../../utility/common";
import Translate from "../../components/Translate";
import DettaglioSociDaApprovare from "./DettaglioSociDaApprovare";
import {RichiedentiContext} from "../../context/RichiedentiContext";
import ModalLegendaStato from "./ModalLegendaStato";

function ListNuoviSoci() {

    const [filtro, setFiltro] = useState('');
    const [provincia, setProvincia] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [showLegendaStato, setShowLegendaStato] = useState(false);
    const [province, setProvince] = useState([]);
    const [soci, setSoci] = useState([]);
    const [totSoci, setTotSoci] = useState(0);
    const [tipiRegistro, setTipiRegistro] = useState([]);
    const [registro, setRegistro] = useState(null);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();
    const [statoRichiesta, setStatoRichiesta] = useState('1');

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {aggiornaContatore} = useContext(RichiedentiContext);

    function getFiltroRichiedenti() {
        let f = '?pag=' + pagina;
        if (filtro && filtro !== '') {
            f += '&filtro=' + filtro;
        }
        if (registro && registro !== '') {
            f += '&registro=' + registro;
        }
        if (provincia && provincia !== '') {
            f += '&provincia=' + provincia;
        }
        if (statoRichiesta && statoRichiesta !== '') {
            f += '&statoRichiesta=' + statoRichiesta;
        }
        return f;
    }

    function getRichiedenti() {
        let f = getFiltroRichiedenti();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/richiedente/" + f).then((response) => {
            setSoci(response.data.data);
            setTotPagine(response.data.pages);
            setTotSoci(response.data.tot);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getTipiRegistro() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-registro/").then((response) => {
            setTipiRegistro(response.data);
            endChiamata()
        });
    }

    function getProvince() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/provincia/").then((response) => {
            setProvince(response.data.data);
            endChiamata()
        });
    }

    useEffect(() => {
        getRichiedenti();

        if (loadingData === true) {
            getTipiRegistro()
            getProvince()
        }
    }, [pagina, statoRichiesta, provincia, registro]);

    const cambioPagina = (nuovaPagina) => {
        setPagina(nuovaPagina)
    }

    const columns = [
        {
            name: 'Registrazione',
            selector: row => getTimestampFormattato(row.registrazione),
            sortable: true,
        },
        {
            name: 'Adesione',
            selector: row => row.cod_adesione,
            sortable: true,
        },
        {
            name: 'Cognome',
            selector: row => row.cognome,
            sortable: true,
        },
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Club',
            selector: row => row.club,
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Data di nascita',
            selector: row => getDataFormattata(row.data_nascita),
            sortable: true,
        },
        {
            name: 'Regione',
            selector: row => row.regione,
            sortable: true,
        },
        {
            name: 'Provincia',
            selector: row => row.provincia,
            sortable: true,
        },
        {
            name: 'Località',
            selector: row => row.localita,
            sortable: true,
        },
        {
            name: 'Stato',
            selector: row => {
                return getIconeStatoSocio(row)
            },
            sortable: true,
        }
    ];

    const getDettaglioSocio = (el) => {
        return (
            <DettaglioSociDaApprovare
                socio={el}
                updFun={() => {
                    getRichiedenti();
                    aggiornaContatore();
                }}/>
        )
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-folder-plus'}
                title={'Nuove registrazioni'}>
                <ModalLegendaStato
                    show={showLegendaStato}
                    handleClose={() => {
                        setShowLegendaStato(false);
                    }}
                />
                <NsAccordion
                    titolo={'Filtri'}
                    label={'fas fa-filter'}

                >
                    <form>
                        <div className={'row'}>
                            <div className={'col-md-2'}>
                                <input
                                    type={"text"}
                                    id={"filtro"}
                                    placeholder={"Nome/Cognome"}
                                    className="form-control"
                                    onChange={(event) => {
                                        setFiltro(event.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <select
                                    id={"tipiRegistro"}
                                    placeholder={"Tipo adesione"}
                                    className="form-control form-select"
                                    defaultValue={""}
                                    onChange={(event) => {
                                        setRegistro(event.target.value)
                                    }}
                                >
                                    <option value={""}>Tutti i registri</option>
                                    {
                                        tipiRegistro && tipiRegistro.map((reg) => {
                                            return <option value={reg.numero_primo}
                                                           key={reg.numero_primo}>{reg.descrizione}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className={'col-md-2'}>
                                <select
                                    id="provincia"
                                    name="provincia"
                                    className="form-select"
                                    defaultValue={""}
                                    onChange={(event) => {
                                        setProvincia(event.target.value);
                                    }}>
                                    <option value={""}>Tutte le province</option>
                                    {
                                        province ? province.map((op, index) => {
                                            return <option key={index}
                                                           value={op.sigla_provincia}>{op.descrizione}</option>
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className={'col-md-2'}>
                                <select
                                    id="stato_richiesta"
                                    name="stato_richiesta"
                                    className="form-select"
                                    defaultValue={"1"}
                                    onChange={(event) => {
                                        setStatoRichiesta(event.target.value);
                                    }}>
                                    <option value={"0"}>Tutte le richieste</option>
                                    <option value={"1"} selected={true}>Da approvare</option>
                                </select>
                            </div>
                            <div className={'col-md-2'}>
                                <button className={"btn btn-dark"} onClick={getRichiedenti}>
                                    <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                                </button>
                            </div>
                        </div>
                    </form>
                </NsAccordion>
                <p>
                    Legenda Stato:&nbsp;&nbsp;
                    <i className="fas fa-info-circle rfi-cursor--pointer"
                       onClick={() => {
                           setShowLegendaStato(true)
                       }}/>

                </p>
                {
                    loadingData ? (
                        <p><Translate>Caricamento in corso...</Translate></p>
                    ) : (
                        <NsDataTable
                            data={soci}
                            totRighe={totSoci}
                            columns={columns}
                            addPagination={false}
                            addFiltri={false}
                            addDetail={true}
                            getDettaglio={getDettaglioSocio}
                        >
                        </NsDataTable>
                    )}
                {
                    totPagine ?
                        (
                            <Pager
                                pagina={pagina}
                                totale={totPagine}
                                handlerCambioPagina={cambioPagina}
                            >
                            </Pager>
                        ) : ''
                }
            </Card>
        </CardContainer>
    );
}

export default ListNuoviSoci;
