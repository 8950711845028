import Translate from './Translate'
import React from "react";

function LabelErroreCampo({errors, touched, submitCount, name}) {

    return (errors && errors[name] && touched && touched[name] && submitCount > 0 ? (
        <div className="ns-div-err-form" data-field={name}><Translate>{errors[name]}</Translate></div>
    ) : null);
}

export default LabelErroreCampo;