import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import Pager from "../../components/Pager";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {convertiDateInDataPerDb, formattaImporto, getTimestampFormattato} from "../../utility/common";
import {Form, Formik} from "formik";
import Translate from "../../components/Translate";
import NsAccordion from "../../components/accordion/NsAccordion";
import NsDatePicker from "../../components/NsDatePicker";
import {RuoliContext} from "../../context/RuoliContext";
import ModalMsg from "../../components/ModalMsg";

function Listtransazioni() {

    const [filtri, setFiltri] = useState({
        nominativo: '',
        num_tessera2: '',
        mail2: '',
        importo: '',
        rinnovo: '',
        idmarca: '',
        idstato: '',
        data_da: '',
        data_a: '',
    });

    const [loadingData, setLoadingData] = useState(true);
    const [transazioni, setTransazioni] = useState([]);
    const [totTransazioni, setTotTransazioni] = useState(0);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloAdmin, isRuoloSegreteria} = useContext(RuoliContext);

    function getTransazioni() {
        let f = '?pag=' + pagina;
        if (filtri.nominativo && filtri.nominativo !== '') {
            f += '&nominativo=' + filtri.nominativo;
        }
        if (filtri.num_tessera2 && filtri.num_tessera2 !== '') {
            f += '&num_tessera2=' + filtri.num_tessera2;
        }
        if (filtri.importo && filtri.importo !== '') {
            f += '&importo=' + filtri.importo;
        }
        if (filtri.rinnovo && filtri.rinnovo !== '') {
            f += '&rinnovo=' + filtri.rinnovo;
        }
        if (filtri.idmarca && filtri.idmarca !== '') {
            f += '&idmarca=' + filtri.idmarca;
        }
        if (filtri.idstato && filtri.idstato !== '') {
            f += '&idstato=' + filtri.idstato;
        }
        if (filtri.data_da && filtri.data_da !== '') {
            f += '&data_da=' + convertiDateInDataPerDb(filtri.data_da);
        }
        if (filtri.data_a && filtri.data_a !== '') {
            f += '&data_a=' + convertiDateInDataPerDb(filtri.data_a);
        }

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/transazione/" + f).then((response) => {
            setTransazioni(response.data.data);
            setTotPagine(response.data.pages);
            setTotTransazioni(response.data.tot);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        getTransazioni();
    }, [pagina]);

    return (
        <CardContainer>
            <Card
                icon={'fas fa-credit-card'}
                title={'Lista transazioni'}
                getBottoniDx={() => {
                }}>
                <ModalMsg
                    show={show}
                    handleClose={() => setShow(false)}
                    testo={modalMsg}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <Fragment>
                            <Formik
                                onSubmit={getTransazioni}
                                initialValues={{}}>
                                <Form>
                                    <div className={'row pb-2'}>
                                        <div className={'col-md-3'}>
                                            <input
                                                type={"text"}
                                                id={"nominativo"}
                                                placeholder={"Nome/Cognome/E-mail"}
                                                className="form-control"
                                                value={filtri.nominativo}
                                                onChange={(ev) => {
                                                    setFiltri(prevState => ({
                                                        ...prevState,
                                                        ["nominativo"]: ev.target.value
                                                    }));
                                                }}/>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <select
                                                id={"idmarca"}
                                                className="form-control form-select"
                                                value={filtri.idmarca}
                                                onChange={(ev) => {
                                                    setFiltri(prevState => ({
                                                        ...prevState,
                                                        ["idmarca"]: ev.target.value
                                                    }));
                                                }}>
                                                <option value={''}>Tutti i registri</option>
                                                <option value={'1'}>RFI</option>
                                                <option value={'2'}>Abarth</option>
                                                <option value={'3'}>RFI & Abarth</option>
                                            </select>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <button type={"submit"} className={"btn btn-dark"}>
                                                <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                                            </button>
                                            <button type="button" className={"btn btn-dark"} onClick={() => {
                                                setFiltri({
                                                    nominativo: '',
                                                    num_tessera2: '',
                                                    importo: '',
                                                    rinnovo: '',
                                                    idmarca: '',
                                                    idstato: '',
                                                    data_da: '',
                                                    data_a: '',
                                                })
                                            }}>
                                                <i className="fas fa-trash"></i> <Translate>Azzera filtri</Translate>
                                            </button>
                                        </div>
                                    </div>
                                    <NsAccordion
                                        titolo={'Filtri'}
                                        label={'fas fa-filter'}
                                        defaultKey={""}
                                    >
                                        <div className={'row'}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"num_tessera2"}>Numero Tessera:</label>
                                                <input
                                                    id={"num_tessera2"}
                                                    className="form-control"
                                                    value={filtri.num_tessera2}
                                                    onChange={(ev) => {
                                                        setFiltri(prevState => ({
                                                            ...prevState,
                                                            ["num_tessera2"]: ev.target.value
                                                        }));
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"importo"}>Importo:</label>
                                                <input
                                                    id={"importo"}
                                                    className="form-control rfi-number-no-arrows"
                                                    type={"number"}
                                                    step="0.01"
                                                    value={filtri.importo}
                                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                    onChange={(ev) => {
                                                        setFiltri(prevState => ({
                                                            ...prevState,
                                                            ["importo"]: ev.target.value
                                                        }));
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"rinnovo"}>Rinnovi:</label>
                                                <select
                                                    id={"rinnovo"}
                                                    className="form-control form-select"
                                                    value={filtri.rinnovo}
                                                    onChange={(ev) => {
                                                        setFiltri(prevState => ({
                                                            ...prevState,
                                                            ["rinnovo"]: ev.target.value
                                                        }));
                                                    }}>
                                                    <option value={''}>Tutte le transazioni</option>
                                                    <option value={'1'}>Solo rinnovi</option>
                                                    <option value={'0'}>Escludi rinnovi</option>
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"idstato"}>Stato:</label>
                                                <select
                                                    id={"idstato"}
                                                    className="form-control form-select"
                                                    value={filtri.idstato}
                                                    onChange={(ev) => {
                                                        setFiltri(prevState => ({
                                                            ...prevState,
                                                            ["idstato"]: ev.target.value
                                                        }));
                                                    }}>
                                                    <option value={''}>Tutti gli stati</option>
                                                    <option value={'0'}>In richiesta</option>
                                                    <option value={'1'}>Pagamento effettuato</option>
                                                    <option value={'2'}>Pagamento negato</option>
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_da"}>Dal:</label>
                                                <NsDatePicker
                                                    nome={"data_da"}
                                                    dataSelezionata={filtri.data_da}
                                                    maxDate={filtri.data_a}
                                                    setSelectedDate={(date, event) => {
                                                        setFiltri(prevState => ({
                                                            ...prevState,
                                                            ["data_da"]: date
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_a"}>Al:</label>
                                                <NsDatePicker
                                                    nome={"data_a"}
                                                    dataSelezionata={filtri.data_a}
                                                    minDate={filtri.data_da}
                                                    setSelectedDate={(date, event) => {
                                                        setFiltri(prevState => ({
                                                            ...prevState,
                                                            ["data_a"]: date
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </NsAccordion>
                                </Form>
                            </Formik>
                            <NsDataTable
                                data={transazioni}
                                totRighe={totTransazioni}
                                columns={[
                                    {
                                        name: 'ID',
                                        selector: row => row.idtransazione,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Data transazione',
                                        selector: row => getTimestampFormattato(row.data_transazione),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Nome',
                                        selector: row => row.nome2,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Cognome',
                                        selector: row => row.cognome2,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Motivo',
                                        selector: row => row.tipo_socio,
                                        sortable: true,
                                    },
                                    {
                                        name: 'E-mail',
                                        selector: row => row.mail2,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Importo',
                                        selector: row => formattaImporto(row.importo) + ' €',
                                        sortable: true,
                                    },
                                    {
                                        name: 'Rinnovo',
                                        selector: row => row.rinnovo == '1' ? 'Sì' : 'No',
                                        sortable: true,
                                    },
                                    {
                                        name: 'Adesione',
                                        selector: row => row.idmarca == '1' ? 'RFI' : (row.idmarca == '2' ? 'Abarth' : 'RFI & Abarth'),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Stato',
                                        selector: row => {
                                            if (row.idrisultato != '0') {
                                                return <i className={"fas fa-times rfi-icon-table me-2 ns-span--rosso"}
                                                          data-toggle="tooltip" title={"KO"}/>
                                            } else if (row.idstato == '0') {
                                                return <i
                                                    className={"fas fa-spinner rfi-icon-table me-2 ns-span--giallo"}
                                                    data-toggle="tooltip" title={"In richiesta"}/>
                                            } else if (row.idstato == '1') {
                                                return <i className={"fas fa-check rfi-icon-table me-2 ns-span--verde"}
                                                          data-toggle="tooltip" title={"Ok"}/>
                                            }
                                        },
                                        sortable: true,
                                    },
                                    {
                                        name: '',
                                        selector: row => <Fragment>
                                            {
                                                (isRuoloAdmin() || isRuoloSegreteria()) && row.idstato == '0' && row.idrisultato == '0' &&
                                                <div className="btn-group" role="group"
                                                     aria-label="Button group with nested dropdown">
                                                    <div className="btn-group" role="group">
                                                        <button id="btnGroupDrop1" type="button"
                                                                className="btn btn-dark dropdown-toggle"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa-solid fa-bars"></i>
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">

                                                            <li>
                                                                <button className="dropdown-item"
                                                                        data-toggle="tooltip" title="Conferma pagamento"
                                                                        onClick={() => {
                                                                            startChiamata()
                                                                            axios
                                                                                .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + row.idutente + "/pagamento/conferma/" + row.idtransazione,
                                                                                    {})
                                                                                .then((response) => {
                                                                                    endChiamata();
                                                                                    setModalMsg(response.data.description);
                                                                                    setShow(true);
                                                                                    getTransazioni();
                                                                                });
                                                                        }}>
                                                                    <i className="fas fa-check"/> Conferma pagamento
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>,
                                        sortable: false,
                                        right: true,
                                    }
                                ]}
                                addPagination={false}
                                addFiltri={false}
                                addDetail={true}
                                getDettaglio={(el) => <div className={"row"}>
                                    <div className={"col-md-2 col-sm-6"}>
                                        Telefono: <strong>{el.telefono2 || '-'}</strong>
                                    </div>
                                    <div className={"col-md-4 col-sm-6"}>
                                        N° tessera: <strong>{el.num_tessera2 || '-'}</strong>
                                    </div>
                                    <div className={"col-md-4 col-sm-6"}>
                                        Risultato: <strong>{el.risultato || '-'}</strong>
                                    </div>
                                </div>
                                }
                            >
                            </NsDataTable>
                        </Fragment>
                    )}
                {
                    totPagine ?
                        (
                            <Pager
                                pagina={pagina}
                                totale={totPagine}
                                handlerCambioPagina={(nuovaPagina) => {
                                    setPagina(nuovaPagina)
                                }}
                            >
                            </Pager>
                        ) : ''
                }
            </Card>
        </CardContainer>
    );
}

export default Listtransazioni;
