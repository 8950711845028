import React, {Fragment} from "react";
import {isNaN} from "formik";
import {Link} from "react-router-dom";

export const mesiIt = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
export const mesiBreviIt = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
export const giorniIt = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica']
export const giorniBreviIt = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom']

export const STATO_IN_RICHIESTA = 0;
export const STATO_IN_RICHIESTA_VALIDO = 1;
export const STATO_CARTA_ID_UPL = 2;
export const STATO_APPROVATO = 3; // Approvato dalla Segreteria
export const STATO_COMPLETO = 4;
export const STATO_CANCELLATO = 5;

export const TIPO_MODULO_REGISTRAZIONE = 1;
export const TIPO_MODULO_RINNOVO = 2;
export const TIPO_MODULO_CERTIFICATO_VEICOLO = 3;

export const TEMPLATE_CERT_DICH_SOST_ATTO_NOTORIETA = 1;
export const TEMPLATE_CERT_OFFICINA = 2;

export const TIPO_REQ_VEICOLO_ALL = 0;
export const TIPO_REQ_VEICOLO_OMOLOGAZIONE = 1;
export const TIPO_REQ_VEICOLO_CRS = 2;
export const TIPO_REQ_VEICOLO_ALBO = 3;
export const TIPO_REQ_VEICOLO_LISTA_CHIUSA = 4;
export const TIPO_REQ_VEICOLO_CRS_REIM = 5;
export const TIPO_REQ_VEICOLO_CRS_REIMM_ALBO = 6;
export const TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE = 7;

export const TIPO_FOTO_V_3_4_ANTERIORE = '3_4_anteriore';
export const TIPO_FOTO_V_3_4_POSTERIORE = '3_4_posteriore';
export const TIPO_FOTO_V_MOTORE_LATO_DX = 'motore_lato_dx';
export const TIPO_FOTO_V_MOTORE_LATO_SX = 'motore_lato_sx';
export const TIPO_FOTO_V_RUOTA_ANT = 'ruota_ant';
export const TIPO_FOTO_V_RUOTA_POST = 'ruota_post';
export const TIPO_FOTO_V_INTERNI_ANT = 'interni_ant';
export const TIPO_FOTO_V_INTERNI_POST = 'interni_post';
export const TIPO_FOTO_V_PLANCIA = 'plancia';
export const TIPO_FOTO_V_PUNZONATURA_MOTORE = 'punzonatura_motore';
export const TIPO_FOTO_V_PUNZONATURA_TELAIO = 'punzonatura_telaio';
export const TIPO_FOTO_V_TARGHETTA = 'targhetta';
export const TIPO_FOTO_V_BAGAGLIAIO = 'bagagliaio';
export const TIPO_FOTO_V_PONTE = 'ponte';
export const TIPO_FOTO_V_ALTRO = 'altra_foto_v';
export const TIPO_FOTO_V_3_4_ANTERIORE_MOTO = '3_4_anteriore_m';
export const TIPO_FOTO_V_3_4_POSTERIORE_MOTO = '3_4_posteriore_m';
export const TIPO_FOTO_V_CRUSCOTTO_MOTO = 'cruscotto_m';
export const TIPO_FOTO_V_SELLA_MOTO = 'sella_m';
export const TIPO_FOTO_V_RUOTA_ANT_MOTO = 'ruota_ant_m';
export const TIPO_FOTO_V_RUOTA_POST_MOTO = 'ruota_post_m';
export const TIPO_FOTO_V_PUNZONATURA_MOTORE_MOTO = 'punzonatura_motore_m';
export const TIPO_FOTO_V_PUNZONATURA_TELAIO_MOTO = 'punzonatura_telaio_m';
export const TIPO_FOTO_V_TARGHETTA_MOTO = 'targhetta_m';
export const TIPO_FOTO_C_CORPO = 'corpo_vettura';
export const TIPO_FOTO_C_PARAFANGHI = 'parafanghi';
export const TIPO_FOTO_C_FILETTATURA = 'filettatura';
export const TIPO_FOTO_C_RUOTE = 'ruote';
export const TIPO_FOTO_C_SEDILI_ANTERIORI = 'mat_sedili_ant';
export const TIPO_FOTO_C_SEDILI_POSTERIORI = 'mat_sedili_post';
export const TIPO_FOTO_C_PANNELLI_ANTERIORI = 'mat_pannelli_ant';
export const TIPO_FOTO_C_PANNELLI_POSTERIORI = 'mat_pannelli_post';

export const ORDINE_SOCI_RFI_ASC = 1;
export const ORDINE_SOCI_ABARTH_ASC = 2;
export const ORDINE_SOCI_TIPO_SOCIO = 3;
export const ORDINE_SOCI_COGNOME_ASC = 4;
export const ORDINE_SOCI_CAP_ASC = 5;

export const ORDINE_VEICOLI_RFI_ASC = 1;
export const ORDINE_VEICOLI_ABARTH_ASC = 2;
export const ORDINE_VEICOLI_TIPO_SOCIO = 3;
export const ORDINE_VEICOLI_COGNOME_ASC = 4;
export const ORDINE_VEICOLI_MARCA_ASC = 5;

export const STATO_CERTIFICATO_DA_GESTIRE = -1;
export const STATO_CERTIFICATO_BOZZA = 0;
export const STATO_CERTIFICATO_IN_APPROVAZIONE = 1;
export const STATO_IN_VERIFICA_COMMISSARIO = 2;
export const STATO_CERTIFICATO_APPROVATO_DA_COMMISSARIO = 3;
export const STATO_CERTIFICATO_APPROVATO_DA_STAFF = 4;
export const STATO_CERTIFICATO_RIFIUTATO = 5;
export const STATO_CERTIFICATO_RIFIUTATO_DA_COMMISSARIO = 6;
export const STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE = 7;
export const STATO_CERTIFICATO_RIGETTATO_DA_PRESIDENTE = 8;
export const STATO_CERTIFICATO_SOCIO_IN_ATTESA = 20;

export const STATO_RELAZIONE_BOZZA = '0';
export const STATO_RELAZIONE_APPROVATA = '1';
export const STATO_RELAZIONE_RIFIUTATA = '2';

export const STATO_TENUTA_VEICOLO_OTTIMO = '1';
export const STATO_TENUTA_VEICOLO_BUONO = '2';
export const STATO_TENUTA_VEICOLO_SUFFICIENTE = '3';
export const STATO_TENUTA_VEICOLO_MEDIOCRE = '4';
export const STATO_TENUTA_VEICOLO_INSUFFICIENTE = '5';
export const STATO_TENUTA_VEICOLO_CONSERVATO = '6';

export const TIPO_STAMPA_MOV_CAUSALE = '1';
export const TIPO_STAMPA_MOV_DATA = '2';
export const TIPO_STAMPA_MOV_CONTO = '3';
export const TIPO_STAMPA_MOV_NOMINATIVO = '4';
export const TIPO_STAMPA_USR_LETTERA = '5';
export const TIPO_STAMPA_USR_TESSERA = '6';
export const TIPO_STAMPA_USR_BOLLI = '7';
export const TIPO_STAMPA_USR_ATTESTATI = '8';

export const CRUSCOTTO_SOCI_PER_TIPO_SOCIO = '1';
export const CRUSCOTTO_SOCI_QUOTE = '2';
export const CRUSCOTTO_SOCI_RINNOVARE = '3';
export const CRUSCOTTO_RINNOVI_MESE = '4';
export const CRUSCOTTO_VEICOLI_SOCI_BOLLO = '5';
export const CRUSCOTTO_VEICOLI_SOCI_BOLLO_MESE = '6';
export const CRUSCOTTO_VEICOLI_ANNO = '7';
export const CRUSCOTTO_VEICOLI_ANNO_BOLLO = '8';
export const CRUSCOTTO_VEICOLI_MARCA = '9';

export const STAMPA_FG_CONFERMA_RINNOVO = '1';
export const STAMPA_FG_BOLLI = '2';
export const STAMPA_FG_ETICHETTE = '3';
export const STAMPA_FG_BADGE = '4';
export const STAMPA_FG_REGISTRAZIONE_VEICOLI = '5';

export function getUtenteFromToken(token) {
    var base64Url = token.split('.')[1];
    if (base64Url) {
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var usr = JSON.parse(jsonPayload);
        return usr && usr.user;
    } else {
        return null;
    }
}

export function getTimestampFormattato(data) {
    if (!data || data === '') {
        return '';
    }
    return data.substring(8, 10) + '/' + data.substring(5, 7) + '/' + data.substring(0, 4) + (data.length > 10 ? data.substring(10, 16) : '');
}

export function getDataFormattata(data) {
    if (!data || data === '') {
        return '';
    }
    return data.substring(8, 10) + '/' + data.substring(5, 7) + '/' + data.substring(0, 4);
}

export function getDataPerDb(data) {
    if (!data) {
        return null;
    }
    return data.substring(6, 10) + '-' + data.substring(3, 5) + '-' + data.substring(0, 2);
}

function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
}

export function convertiDateInDataPerDb(data) {
    if (data) {
        data = new Date(data)
    }

    return data && !isNaN(data) ? [
        data.getFullYear(),
        (data.getMonth() + 1).toString().padStart(2, '0'),
        data.getDate().toString().padStart(2, '0')
    ].join('-') : null;
}

export function convertiTimestampInDataPerDb(data) {
    let d = convertiDateInDataPerDb(data)
    if (d) {
        if (data) {
            data = new Date(data)
        }

        d += ' ' + [
            data.getHours().toString().padStart(2, '0'),
            data.getMinutes().toString().padStart(2, '0'),
            data.getSeconds().toString().padStart(2, '0')
        ].join(':')
    }
    return d;
}

export function getLunedi(data) {
    var d = new Date(data.getTime());
    var day = d.getDay()
    var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export function getDomenica(d) {
    var lunedi = getLunedi(d);
    var diff = lunedi.getDate() + 6;
    return new Date(d.setDate(diff));
}

export function aggiungiGiorno(data) {
    var d = new Date(data.getTime());
    var diff = d.getDate() + 1;
    return new Date(d.setDate(diff));
}

export function sottraiGiorno(data) {
    return sottraiGiorni(data, 1);
}

export function sottraiGiorni(data, giorni) {
    var d = new Date(data.getTime());
    var diff = d.getDate() - giorni;
    return new Date(d.setDate(diff));
}

export function aggiungiMese(data) {
    var d = new Date(data.getTime());
    return new Date(d.setMonth(d.getMonth() + 1));
}

export function aggiungiAnni(data, numAnni) {
    let date = new Date(data);
    var d = new Date(date.getTime());
    return new Date(d.setFullYear(d.getFullYear() + numAnni));
}

export function sottraiMese(data) {
    var d = new Date(data.getTime());
    return new Date(d.setMonth(d.getMonth() - 1));
}

export function getDataJsFormattata(d) {
    return [
        d.getDate().toString().padStart(2, '0'),
        (d.getMonth() + 1).toString().padStart(2, '0'),
        d.getFullYear()
    ].join('/')
}

export function getTimeDataJs(d) {
    let date = new Date(d);
    date.setHours(0, 0, 0, 0);
    return date.getTime()
}

export function getMese(d) {
    let date = new Date(d);
    date.setDate(1)
    let mese = date.getMonth();
    return mesiIt[mese] + ' ' + date.getFullYear()
}

export function getAnno(d) {
    let date = new Date(d);
    date.setDate(1)
    return date.getFullYear()
}

export function getMeseAbbreviato(d) {
    let date = new Date(d);
    date.setDate(1)
    let mese = date.getMonth();
    return mesiBreviIt[mese]
}

export function getGiorno(d) {
    let date = new Date(d);
    let giorno = date.getDay();
    return giorniIt[giorno]
}

export function getStrRgba(strColore) {
    if (!strColore || strColore === '') {
        strColore = '{"r":255,"g":255,"b":255,"a":1}';
    }
    let colore = JSON.parse(strColore);
    return "rgba("
        + colore.r + ", "
        + colore.g + ", "
        + colore.b + ", "
        + colore.a + ")";
}

export function getUtenteLoggato() {
    let usr;

    let token = localStorage.getItem('rfi-login');
    if (token) {
        let user = getUtenteFromToken(token);
        if (user) {
            usr = user;
        }
    }
    return usr;
}

export function isUnder30(dataNascita) {
    let dateN = new Date(dataNascita);
    dateN.setDate(1)
    let dNascitaPiu30 = aggiungiAnni(dataNascita, 31)
    dNascitaPiu30.setHours(0, 0, 0, 0);

    let date = new Date();
    date.setHours(0, 0, 0, 0);

    return date.getTime() < dNascitaPiu30.getTime();
}

export function formattaImporto(val) {
    return isNaN(val) ? val : (Math.round(val * 100) / 100).toFixed(2).replaceAll('.', ',');
}

export function formattaInteroConCommas(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0';
}

export function formattaIntero(val) {
    let num = '';

    if (val && val !== '') {
        num = isNaN(val) ? val : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return num;
}

export function formattaInteroDocVeicolo(val) {
    let num = formattaIntero(val);
    if (num === '') {
        num = '/';
    }
    return num;
}

export function getRigaInfoSocio(el, showInfoRegistrazione, showAltreInfo) {
    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-4 col-sm-6"}>
                    Cognome: <strong>{el.cognome || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Nome: <strong>{el.nome || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Tipo socio: <strong>{el.tipo_socio || '-'}</strong>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    N° tessera RFI: <strong>{el.numero_tessera_rfi || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    N° tessera Abarth: <strong>{el.numero_tessera_abarth || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Numero tessera ASI: <strong>{el.numero_tessera_asi || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Scadenza: <strong>{el.scadenza || '-'}</strong>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Data di nascita: <strong>{getDataFormattata(el.data_nascita)}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Luogo di nascita: <strong>{el.luogo_nascita || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Anno iscrizione: <strong>{el.anno_iscrizione || '-'}</strong>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Codice fiscale: <strong>{el.codice_fiscale || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    E-mail: <strong>{el.email || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Club: <strong>{el.club || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Agenzia: <strong>{el.agenzia || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Azienda: <strong>{el.azienda || '-'}</strong>
                </div>

                <div className={"col-md-12"}>
                    <hr/>
                    <h6>Residenza</h6>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Nazione: <strong>{el.nazione || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Regione: <strong>{el.regione || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Provincia: <strong>{el.provincia || '-'}</strong>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Localià: <strong>{el.localita || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    CAP: <strong>{el.cap || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Indirizzo: <strong>{el.indirizzo || '-'}</strong> N° <strong>{el.numero_civico || '-'}</strong>
                </div>

                <div className={"col-md-12"}>
                    <hr/>
                    <h6>Indirizzo di spedizione</h6>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Nazione: <strong>{el.nazione_sped || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Regione: <strong>{el.regione_sped || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Provincia: <strong>{el.provincia_sped || '-'}</strong>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Localià: <strong>{el.localita_sped || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    CAP: <strong>{el.cap_sped || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Indirizzo: <strong>{el.indirizzo_sped || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    N° civico: <strong>{el.numero_civico_sped || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Nominativo: <strong>{el.nominativo_sped || '-'}</strong>
                </div>

                <div className={"col-md-12"}>
                    <hr/>
                    <h6>Contatti</h6>
                </div>

                <div className={"col-md-4 col-sm-6"}>
                    Telefono casa: <strong>{el.tel_casa || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Telefono lavoro: <strong>{el.tel_lavoro || '-'}</strong>
                </div>
                <div className={"col-md-4 col-sm-6"}>
                    Fax: <strong>{el.fax || '-'}</strong>
                </div>

                {
                    showAltreInfo && showAltreInfo === true ?
                        <Fragment>
                            <div className={"col-md-12"}>
                                <hr/>
                            </div>

                            <div className={"col-md-4 col-sm-6"}>
                                Newsletter: <strong>{el.newsletter == '1' ? 'Sì' : 'No'}</strong>
                            </div>
                            <div className={"col-md-4 col-sm-6"}>
                                Da ratificare: <strong>{el.da_ratificare == '1' ? 'Sì' : 'No'}</strong>
                            </div>
                            <div className={"col-md-4 col-sm-6"}>
                                Delegazione: <strong>{el.delegazione || '-'}</strong>
                            </div>
                            <div className={"col-md-4 col-sm-6"}>
                                Numero veicoli: <strong>{el.num_veicoli || '-'}</strong>
                            </div>
                            <div className={"col-md-4 col-sm-6"}>
                                Tipo Club: <strong>{el.club || '-'}</strong>
                            </div>
                            <div className={"col-md-4 col-sm-6"}>
                                Id utente: <strong>{el.id_utente || '-'}</strong>
                            </div>

                            <div className={"col-md-8"}>
                                Note persona: <strong>{el.note || '-'}</strong>
                            </div>
                            <div className={"col-md-4 col-sm-6"}>
                                Comunicazioni via E-Mail: <strong>{el.comunicazioni_email == '1' ? 'Sì' : 'No'}</strong>
                            </div>
                        </Fragment>
                        : ''
                }
            </div>
            {
                showInfoRegistrazione && showInfoRegistrazione === true ?
                    <div className={"row mt-2"}>
                        <div className={"col-md-8"}>
                            Motivi registrazione:
                            {
                                el.motiviRegistrazione && el.motiviRegistrazione.length > 0 ?
                                    <ul>
                                        {
                                            el.motiviRegistrazione.map((motivo) => <li
                                                    key={motivo.id_motivo_registrazione}>
                                                    <strong>
                                                        {
                                                            motivo.descrizione + (motivo.note !== '' ? ' - ' + motivo.note : '')
                                                        }
                                                    </strong>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    : '-'
                            }
                        </div>
                        <div className={"col-md-4 col-sm-6"}>
                            Informazioni sulle auto da iscrivere: <strong>{el.info_auto || '-'}</strong>
                        </div>
                    </div>
                    : ''
            }
        </Fragment>
    )
}

export function getRigaInfoVeicolo(el, isSocio) {
    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    Registro: <strong>{el.registro || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Numero registrazione: <strong>{(el.numero_registrazione || '-') + (el.suffisso || '')}</strong>
                </div>
                <div className={"col-md-4"}>
                    Data registrazione: <strong>{getDataFormattata(el.data_registrazione)}</strong>
                </div>

                <div className={"col-md-4"}>
                    Bollo RFI: <strong>{el.bollo_rfi == '1' ? 'Sì' : 'No'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Club: <strong>{el.club || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Omologato: <strong>{el.omologato == '1' ? 'Sì' : 'No'}</strong>
                </div>

                <div className={"col-md-4"}>
                    Marca: <strong>{el.marca || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Modello: <strong>{el.tipo || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Anno: <strong>{el.anno || '-'}</strong>
                </div>

                <div className={"col-md-4"}>
                    Targa: <strong>{el.targa || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Telaio: <strong>{el.numero_telaio || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Tipo motore: <strong>{el.tipo_motore || '-'}</strong>
                </div>

                <div className={"col-md-4"}>
                    Marca carrozzeria: <strong>{el.marca_carrozzeria || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Tipo carrozzeria: <strong>{el.tipo_carrozzeria || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Note carrozzeria: <strong>{el.note_carrozzeria || '-'}</strong>
                </div>

                <div className={"col-md-4"}>
                    Colore: <strong>{el.marca_carrozzeria || '-'}</strong>
                </div>
                {
                    isSocio === true ?
                        <div className={"col-md-4"}>
                            Proprietario: <strong>{el.cognome || ''} {el.nome || ''}</strong>
                        </div>
                        : <div className={"col-md-4"}>
                            Proprietario:&nbsp;
                            <Link to={"/info-socio/" + el.id_utente}>
                                <strong>{el.cognome || ''} {el.nome || ''}</strong>
                            </Link>
                        </div>
                }

                <div className={"col-md-4"}>
                    Numero tessera RFI: <strong>{el.numero_tessera_rfi || '-'}</strong>
                </div>
                <div className={"col-md-4"}>
                    Numero tessera Abarth: <strong>{el.numero_tessera_abarth || '-'}</strong>
                </div>
            </div>
        </Fragment>
    )
}

export function getIconeStatoSocio(usr) {
    return <Fragment>
        <i className={"fas fa-envelope rfi-icon-table me-2 ns-span" + (usr.stato == STATO_IN_RICHIESTA ? '--rosso' : '--verde')}
           data-toggle="tooltip"
           title={"E-mail " + (usr.stato == STATO_IN_RICHIESTA ? 'non verificata' : 'verificata')}/>
        <i className={"fas fa-id-card rfi-icon-table me-2 ns-span" + (usr.stato == STATO_IN_RICHIESTA || usr.stato == STATO_IN_RICHIESTA_VALIDO ? '--rosso' : '--verde')}
           data-toggle="tooltip"
           title={"Documento d'identità " + (usr.stato == STATO_IN_RICHIESTA || usr.stato == STATO_IN_RICHIESTA_VALIDO ? 'NON presente' : 'presente')}/>
        <i className={"fas fa-check rfi-icon-table me-2 ns-span" + (usr.stato == STATO_APPROVATO || usr.stato == STATO_COMPLETO ? '--verde' : '--rosso')}
           data-toggle="tooltip"
           title={(usr.stato == STATO_APPROVATO || usr.stato == STATO_COMPLETO ? 'Approvato' : 'Non approvato') + " dalla Segreteria"}/>
        <i className={"fas fa-dollar-sign rfi-icon-table ns-span" + (usr.scadenza && usr.scadenza != '' ? '--verde' : '--rosso')}
           data-toggle="tooltip"
           title={"Pagamento " + (usr.scadenza && usr.scadenza != '' ? 'effettuato' : 'NON effettuato')}/>
        <i className={"fas fa-comment rfi-icon-table ns-span" + (usr.note_segreteria && usr.note_segreteria.length > 0 ? '--verde' : '--rosso')}
           data-toggle="tooltip"
           title={"Note " + (usr.note_segreteria && usr.note_segreteria.length > 0 ? 'presenti' : 'NON presenti')}/>
    </Fragment>
}

export function getIconaStatoCertificato(certificato) {
    if (certificato.stato == STATO_CERTIFICATO_BOZZA) {
        return <i className={"fab fa-firstdraft rfi-icon-table me-2 ns-span--rosso"}
                  data-toggle="tooltip" title={"Bozza"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_IN_APPROVAZIONE) {
        return <i className={"fas fa-paper-plane rfi-icon-table me-2 ns-span--verde"}
                  data-toggle="tooltip" title={"Richiesta effettuata"}/>
    } else if (certificato.stato == STATO_IN_VERIFICA_COMMISSARIO) {
        return <i className={"fas fa-spinner rfi-icon-table me-2 ns-span--giallo"}
                  data-toggle="tooltip" title={"In verifica"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_APPROVATO_DA_COMMISSARIO) {
        return <i className={"fas fa-check rfi-icon-table me-2 ns-span--giallo"}
                  data-toggle="tooltip" title={"Approvato da Commissario"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_APPROVATO_DA_STAFF) {
        return <i className={"fas fa-check-double rfi-icon-table me-2 ns-span--verde"}
                  data-toggle="tooltip" title={"Approvato"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_RIFIUTATO) {
        return <i className={"fas fa-ban rfi-icon-table me-2 ns-span--rosso"}
                  data-toggle="tooltip" title={"Rifiutato"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_RIFIUTATO_DA_COMMISSARIO) {
        return <i className={"fas fa-ban rfi-icon-table me-2 ns-span--giallo"}
                  data-toggle="tooltip" title={"Rifiutato da Commissario"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_RIGETTATO_DA_PRESIDENTE) {
        return <i className={"fas fa-ban rfi-icon-table me-2 ns-span--blu"}
                  data-toggle="tooltip" title={"Rifiutato da Presidente"}/>
    } else if (certificato.stato == STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE) {
        return <i className={"fas fa-check-double rfi-icon-table me-2 ns-span--blu"}
                  data-toggle="tooltip" title={"Approvato"}/>
    }
}

export function getStatoCertificato(certificato) {
    if (certificato.stato == STATO_CERTIFICATO_BOZZA) {
        return <Fragment>
            <span><i className={"fab fa-firstdraft rfi-icon-table me-2 ns-span--rosso"}
                   data-toggle="tooltip" title={"Bozza"}/> <strong>Bozza</strong></span>
        </Fragment>
    } else if (certificato.stato == STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE) {
        return <Fragment>
            <span><i className={"fas fa-check rfi-icon-table me-2 ns-span--verde"}
                   data-toggle="tooltip" title={"Approvato"}/> <strong>Approvato</strong></span>
        </Fragment>
    } else if (certificato.stato == STATO_CERTIFICATO_RIFIUTATO) {
        return <Fragment>
            <span><i className={"fas fa-ban rfi-icon-table me-2 ns-span--rosso"}
                   data-toggle="tooltip" title={"Rifiutato"}/> <strong>Rifiutato</strong></span>
        </Fragment>
    } else {
        return <Fragment>
            <span><i className={"fas fa-spinner rfi-icon-table me-2 ns-span--giallo"}
                   data-toggle="tooltip" title={"In Approvazione"}/> <strong>In Approvazione</strong></span>
        </Fragment>
    }
}

export function getIconaStatoRelazione(relazione) {
    if (relazione.stato == STATO_RELAZIONE_BOZZA) {
        return <i className={"fab fa-firstdraft rfi-icon-table me-2 ns-span--rosso"}
                  data-toggle="tooltip" title={"Bozza"}/>
    } else if (relazione.stato == STATO_RELAZIONE_APPROVATA) {
        return <i className={"fas fa-check rfi-icon-table me-2 ns-span--verde"}
                  data-toggle="tooltip" title={"Approvato"}/>
    } else if (relazione.stato == STATO_RELAZIONE_RIFIUTATA) {
        return <i className={"fas fa-ban rfi-icon-table me-2 ns-span--rosso"}
                  data-toggle="tooltip" title={"Rifiutato"}/>
    }
}

export function getStatoMantenimentoVeicolo(stato) {
    if (stato == STATO_TENUTA_VEICOLO_OTTIMO) {
        return 'Ottimo';
    } else if (stato == STATO_TENUTA_VEICOLO_BUONO) {
        return 'Buono';
    } else if (stato == STATO_TENUTA_VEICOLO_SUFFICIENTE) {
        return 'Sufficiente';
    } else if (stato == STATO_TENUTA_VEICOLO_MEDIOCRE) {
        return 'Mediocre';
    } else if (stato == STATO_TENUTA_VEICOLO_INSUFFICIENTE) {
        return 'Insufficiente';
    } else if (stato == STATO_TENUTA_VEICOLO_CONSERVATO) {
        return 'Conservato';
    } else {
        return '-';
    }
}

export function getAnni(startYear, anniInFuturo = 0) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1920;
    while (startYear <= (currentYear + anniInFuturo)) {
        years.push(startYear++);
    }
    return years;
}

export function handleMoneyChange(e) {
    if (e.key !== "Backspace") {
        if (e.target.value.includes('.')) {
            if (e.target.value.split('.')[1].length >= 2) {
                var num = parseFloat(e.target.value);
                e.target.value = num.toFixed(2);
                e.preventDefault();
            }
        }
    }
}

export function getBoolStampaCertificato(val) {
    return val ? (val == '1' ? 'sì' : '/') : '/'
}

export function getStrStampaCertificato(val) {
    return val && val !== '' ? val : '/'
}

/**
 * Funzione che serve a gestire campi un tempo booleani ("Sì", "No") ed ora di tipo stringhe
 * @param val
 * @returns {string}
 */
export function getStrStampaCertificatoOldBool(val) {
    let resp = getStrStampaCertificato(val);
    if (val && (val == 0 || val == 1)) {
        resp = getBoolStampaCertificato(val);
    }
    return resp
}

export function checkDocumentiScaduti(docs) {
    let scaduto = false;
    docs && docs.forEach((doc) => {
        if (isDocumentoScaduto(doc)) {
            scaduto = true;
        }
    });
    return scaduto;
}

export function isDocumentoScaduto(doc) {
    return (doc.scadenza && new Date(doc.scadenza).getTime() < new Date().getTime());
}

export function getLabelValidita(quota) {
    if (quota.attiva == '0') {
        return <span className={"ns-span--giallo"}>Da approvare</span>
    } else if (quota.anno_validita < getAnno(new Date())) {
        return <span className={"ns-span--rosso"}>Scaduta</span>
    } else if (quota.anno_validita == getAnno(new Date())) {
        return <span className={"ns-span--verde"}>In corso</span>
    } else if (quota.anno_validita > getAnno(new Date())) {
        return <span className={"ns-span--verde"}>Rinnovato</span>
    } else {
        return ''
    }
}

export function getLabelScadenza(anno) {
    if (anno < getAnno(new Date())) {
        return <span className={"ns-span--rosso"}>{anno}</span>
    } else if (anno >= getAnno(new Date())) {
        return <span className={"ns-span--verde"}>{anno}</span>
    } else {
        return ''
    }
}

export function downloadDoc(resp) {
    var a = document.createElement("a"); //Create <a>
    a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
    a.download = resp.nome_file; //File name Here
    a.click(); //Downloaded file
}