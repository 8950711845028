import React from "react";

const Separatore = ({children}) => {

    return (
        <div className="row g-3 mt-4 mb-2 ns-riga-separatore">
            <div className={"col-md-12 ns-riga-separatore-col"}>
                <h4>{children}</h4>
            </div>
        </div>
    )
}

export default Separatore;