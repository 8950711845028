import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import {TranslateContext} from "../../context/TranslateContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import AutocompleteText from "../../components/AutocompleteText";

function ModificaNota() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [marche, setMarche] = useState([]);
    const [modelli, setModelli] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getNota() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/nota-commissario/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_nota_commissario", val.id_nota_commissario);
                    formikRef.current.setFieldValue("marca", val.marca);
                    formikRef.current.setFieldValue("tipo", val.tipo);
                    formikRef.current.setFieldValue("descrizione", val.descrizione);
                    formikRef.current.setFieldValue("note", val.note);
                }
                endChiamata()
            });
    }

    function getMarche(marca) {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/marca/?marca=" + marca).then((response) => {
            setMarche(response.data.data)
        });
    }

    function getModelli(modelli) {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/modello/?modello=" + modelli).then((response) => {
            setModelli(response.data.data)
        });
    }

    useEffect(() => {
        if (loadingData) {
            if (id && id !== '') {
                getNota();
            }
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        marca: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        tipo: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        descrizione: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        note: Yup.string()
            .max(255, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
    });

    return (
        <Card
            icon={'fas fa-tags'}
            title={id ? 'Modifica Nota' : 'Inserisci Nota'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_nota_commissario: '',
                    marca: '',
                    tipo: '',
                    descrizione: '',
                    note: '',
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/nota-commissario/" + id,
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/nota-commissario/",
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="descrizione" className="form-label">Descrizione*:</label>
                            <Field
                                name="descrizione"
                                className="form-control"
                                readOnly={id}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"descrizione"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="marca" className="form-label">Marca*:</label>
                            <AutocompleteText
                                name={"marca"}
                                formikRef={formikRef}
                                formikValues={values}
                                filterFunction={getMarche}
                                filteredValues={marche}
                                toUpper={true}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"marca"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="tipo" className="form-label">Modello*:</label>
                            <AutocompleteText
                                name={"tipo"}
                                formikRef={formikRef}
                                formikValues={values}
                                filterFunction={getModelli}
                                filteredValues={modelli}
                                toUpper={true}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"tipo"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="note" className="form-label">Note*:</label>
                            <Field
                                as={"textarea"}
                                name="note"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"note"}
                                              submitCount={submitCount}/>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaNota;