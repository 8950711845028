import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import {TranslateContext} from "../../context/TranslateContext";
import Translate from "../../components/Translate";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import UplFotoSocio from "./UplFotoSocio";
import {checkDocumentiScaduti, isDocumentoScaduto, STATO_COMPLETO} from "../../utility/common";
import {RuoliContext} from "../../context/RuoliContext";
import AutocompleteText from "../../components/AutocompleteText";

function ModificaProfiloSocio() {
    const {id} = useParams()

    const [user, setUser] = useState();
    const [province, setProvince] = useState([]);
    const [comuniResidenza, setComuniResidenza] = useState([]);
    const [comuniSped, setComuniSped] = useState([]);
    const [nazioni, setNazioni] = useState([]);
    const [tipiDocumento, setTipiDocumento] = useState([]);
    const [nazione, setNazione] = useState('I');
    const [nazioneSped, setNazioneSped] = useState('I');

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloAdmin, isRuoloSegreteria} = useContext(RuoliContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    const token = localStorage.getItem('rfi-login');

    function getUtente() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);
                setUser(val)
                setNazione(val.nazione)

                if (formikRef.current) {
                    formikRef.current.setFieldValue("email", val.email || '')
                    formikRef.current.setFieldValue("indirizzo", val.indirizzo || '')
                    formikRef.current.setFieldValue("cap", val.cap || '')
                    formikRef.current.setFieldValue("numero_civico", val.numero_civico || '')
                    formikRef.current.setFieldValue("nazione", val.nazione || '')
                    formikRef.current.setFieldValue("localita", val.localita || '')
                    formikRef.current.setFieldValue("provincia", val.provincia || '')
                    formikRef.current.setFieldValue("provincia_nascita", val.provincia_nascita || '')
                    formikRef.current.setFieldValue("tel_casa", val.tel_casa || '')
                    formikRef.current.setFieldValue("tel_lavoro", val.tel_lavoro || '')
                    formikRef.current.setFieldValue("fax", val.fax || '')
                    formikRef.current.setFieldValue("comunicazioni_email", val.comunicazioni_email || '')
                    formikRef.current.setFieldValue("nazione_sped", val.nazione_sped || '')
                    formikRef.current.setFieldValue("provincia_sped", val.provincia_sped || '')
                    formikRef.current.setFieldValue("localita_sped", val.localita_sped || '')
                    formikRef.current.setFieldValue("indirizzo_sped", val.indirizzo_sped || '')
                    formikRef.current.setFieldValue("numero_civico_sped", val.numero_civico_sped || '')
                    formikRef.current.setFieldValue("cap_sped", val.cap_sped || '')
                    formikRef.current.setFieldValue("nominativo_sped", val.nominativo_sped || '')
                    formikRef.current.setFieldValue("azienda", val.azienda || '')

                    if (val.provincia && val.provincia !== '') {
                        searchComuniResidenza(val.provincia, '', true);
                    }
                    if (val.provincia_sped && val.provincia_sped !== '') {
                        searchComuniSpedizione(val.provincia_sped, '', true);
                    }
                }
                endChiamata()
            });
    }

    const getProvince = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/provincia/").then((response) => {
            setProvince(response.data.data);

            if (user) {
                formikRef.current.setFieldValue("provincia", user.provincia)
                formikRef.current.setFieldValue("provincia_nascita", user.provincia_nascita)
            }
            endChiamata()
        });
    }

    const searchComuniResidenza = (provincia, comune, initVal) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
            setComuniResidenza(response.data.data)
            if (user && initVal) {
                formikRef.current.setFieldValue("localita", user.localita);
            }
        });
    }

    const searchComuniSpedizione = (provincia, comune, initVal) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
            setComuniSped(response.data.data);
            if (user && initVal) {
                formikRef.current.setFieldValue("localita_sped", user.localita_sped);
            }
        });
    }

    function getCap(comune, arrayComuni) {
        let cap = '';

        if (comune !== '') {
            let comuni = arrayComuni.filter(c => c.nome === comune);
            if (comuni && comuni.length > 0) {
                cap = comuni.map(c => c.cap).reduce(c => c) || '';
            }
        }
        return cap;
    }

    const getNazioni = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/nazione/").then((response) => {
            setNazioni(response.data.data);
            endChiamata()
        });
    }

    const getTipiDocumentoUtente = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata()
        });
    }

    useEffect(() => {

        if (loadingData) {
            getNazioni();
            getProvince();
            getTipiDocumentoUtente();
        }
        if (id && id !== '') {
            getUtente();
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Indirizzo E-mail non valido')
            .required(traduci('Questo campo è obbligatorio')),
        localita: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        localita_sped: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        indirizzo: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        indirizzo_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        tel_casa: Yup.string()
            .min(9, traduci('Attenzione! Inserisci almeno 9 caratteri numerici'))
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        cap: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        cap_sped: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        numero_civico: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        numero_civico_sped: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        nazione: Yup.string()
            .max(3, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        nazione_sped: Yup.string()
            .max(3, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        tel_lavoro: Yup.string()
            .min(9, traduci('Attenzione! Inserisci almeno 9 caratteri numerici'))
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        fax: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        azienda: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri')),
        nominativo_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
    });

    return (
        <Card
            icon={'fas fa-user'}
            title={id ? 'Modifica Socio' : 'Inserisci Socio'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_utente: '',
                    indirizzo: '',
                    cap: '',
                    numero_civico: '',
                    nazione: 'I',
                    localita: '',
                    provincia: '',
                    tel_casa: '',
                    tel_lavoro: '',
                    fax: '',
                    email: '',
                    comunicazioni_email: '1',
                    nazione_sped: 'I',
                    provincia_sped: '',
                    localita_sped: '',
                    indirizzo_sped: '',
                    cap_sped: '',
                    nominativo_sped: '',
                    numero_civico_sped: '',
                    azienda: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id,
                            values)
                        .then((response) => {
                            if (response.data.result === 0) {
                                endChiamata()
                                history.goBack();
                            } else {
                                setModalMsg(response.data.description);
                                setShow(true);
                                endChiamata()
                            }
                        });
                }}
            >
                {({values, errors, touched, submitCount}) => (
                    <Form>
                        <h4 className={"pt-2 pb-4"}>Dati Residenza</h4>
                        <div className="row g-3">
                            <div className="col-md-2">
                                <label htmlFor="nazione"
                                       className="form-label"><Translate>Nazione</Translate>:</label>
                                <Field
                                    as={"select"}
                                    name="nazione"
                                    className="form-select"
                                    value={values['nazione']}
                                    onChange={(event) => {
                                        formikRef.current.setFieldValue("nazione", event.target.value)
                                        setNazione(event.target.value);
                                    }}>
                                    <option value={""}><Translate>Sel. nazione</Translate></option>
                                    {
                                        nazioni ? nazioni.map((op, index) => {
                                            return <option key={index}
                                                           value={op.codice}>{op.descrizione}</option>
                                        }) : ''
                                    }
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"nazione"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                nazione && nazione === 'I' ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="provincia" className="form-label">Provincia di
                                                residenza:</label>
                                            <select
                                                id="provincia"
                                                name="provincia"
                                                className="form-select"
                                                value={values["provincia"]}
                                                onChange={(event) => {
                                                    formikRef.current.setFieldValue("provincia", event.target.value)
                                                    searchComuniResidenza(event.target.value);
                                                }}>
                                                <option value={""}>Sel. provincia</option>
                                                {
                                                    province ? province.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.sigla_provincia}>{op.descrizione}</option>
                                                    }) : ''
                                                }
                                            </select>
                                            <LabelErroreCampo errors={errors}
                                                              touched={touched} name={"provincia"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="localita" className="form-label"><Translate>Residente
                                                a</Translate>:</label>
                                            <AutocompleteText
                                                name={"localita"}
                                                formikRef={formikRef}
                                                formikValues={values}
                                                filterFunction={(comune) => {
                                                    searchComuniResidenza(formikRef.current.values['provincia'], comune);
                                                }}
                                                filteredValues={comuniResidenza.map(c => c.nome)}
                                                handlerChange={(event) => {
                                                    let comune = event.target.value;
                                                    let cap = getCap(comune, comuniResidenza);
                                                    formikRef.current.setFieldValue('cap', cap)
                                                }}
                                                handlerSelect={(comune) => {
                                                    let cap = getCap(comune, comuniResidenza);
                                                    formikRef.current.setFieldValue('cap', cap)
                                                }}
                                                toUpper={true}
                                            />
                                            <LabelErroreCampo errors={errors}
                                                              touched={touched} name={"localita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    : <Fragment>
                                        <div className="col-md-4">
                                            <label htmlFor="localita" className="form-label"><Translate>Residente
                                                a</Translate>:</label>
                                            <Field name="localita" className="form-control"/>
                                            <LabelErroreCampo errors={errors}
                                                              touched={touched} name={"localita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                            }
                            <div className="col-md-3">
                                <label htmlFor="indirizzo"
                                       className="form-label"><Translate>Indirizzo e numero
                                    civico</Translate>:</label>
                                <Field name="indirizzo" className="form-control"
                                       placeholder={"Via Roma 15"}/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"indirizzo"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-1">
                                <label htmlFor="numero_civico" className="form-label">N° civico:</label>
                                <Field name="numero_civico" className="form-control"/>
                                <LabelErroreCampo errors={errors}
                                                  touched={touched} name={"numero_civico"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-1">
                                <label htmlFor="cap" className="form-label">CAP:</label>
                                <Field
                                    name="cap"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"cap"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Dati Indirizzo Spedizione</h4>
                        <div className="row g-3">
                            <div className="col-md-2">
                                <label htmlFor="nazione"
                                       className="form-label"><Translate>Nazione</Translate>:</label>
                                <Field
                                    as={"select"}
                                    name="nazione_sped"
                                    className="form-select"
                                    value={values['nazione_sped']}
                                    onChange={(event) => {
                                        formikRef.current.setFieldValue("nazione_sped", event.target.value)
                                        setNazioneSped(event.target.value);
                                    }}
                                >
                                    <option value={""}><Translate>Sel. nazione</Translate></option>
                                    {
                                        nazioni ? nazioni.map((op, index) => {
                                            return <option key={index}
                                                           value={op.codice}>{op.descrizione}</option>
                                        }) : ''
                                    }
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"nazione_sped"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                nazioneSped && nazioneSped === 'I' ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="provincia_sped"
                                                   className="form-label"><Translate>Provincia
                                                spedizione</Translate>:</label>
                                            <select
                                                id="provincia_sped"
                                                name="provincia_sped"
                                                className="form-select"
                                                value={values['provincia_sped']}
                                                onChange={(event) => {
                                                    formikRef.current.setFieldValue("provincia_sped", event.target.value)
                                                    searchComuniSpedizione(event.target.value);
                                                }}>
                                                <option value={""}><Translate>Sel. provincia</Translate>
                                                </option>
                                                {
                                                    province ? province.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.sigla_provincia}>{op.descrizione}</option>
                                                    }) : ''
                                                }
                                            </select>
                                            <LabelErroreCampo errors={errors} touched={touched} name={"provincia_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="localita"
                                                   className="form-label"><Translate>Comune</Translate>:</label>
                                            <AutocompleteText
                                                name={"localita_sped"}
                                                formikRef={formikRef}
                                                formikValues={values}
                                                filterFunction={(comune) => {
                                                    searchComuniSpedizione(formikRef.current.values['provincia_sped'], comune);
                                                }}
                                                filteredValues={comuniSped.map(c => c.nome)}
                                                toUpper={true}
                                                handlerChange={(event) => {
                                                    let comune = event.target.value;
                                                    let cap = getCap(comune, comuniSped);
                                                    formikRef.current.setFieldValue('cap_sped', cap)
                                                }}
                                                handlerSelect={(comune) => {
                                                    let cap = getCap(comune, comuniSped);
                                                    formikRef.current.setFieldValue('cap_sped', cap)
                                                }}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] === '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                            <LabelErroreCampo errors={errors}
                                                              touched={touched} name={"localita_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    : <Fragment>
                                        <div className="col-md-4">
                                            <label htmlFor="localita_sped"
                                                   className="form-label"><Translate>Comune</Translate>:</label>
                                            <Field
                                                name="localita_sped"
                                                className="form-control"
                                            />
                                            <LabelErroreCampo errors={errors}
                                                              touched={touched} name={"localita_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                            }
                            <div className="col-md-3">
                                <label htmlFor="indirizzo_sped"
                                       className="form-label"><Translate>Indirizzo e numero
                                    civico</Translate>:</label>
                                <Field
                                    name="indirizzo_sped"
                                    className="form-control"
                                    placeholder={"Via Roma 15"}
                                />
                                <LabelErroreCampo errors={errors}
                                                  touched={touched} name={"indirizzo_sped"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-1">
                                <label htmlFor="numero_civico_sped" className="form-label">N°
                                    civico:</label>
                                <Field name="numero_civico_sped" className="form-control"/>
                                <LabelErroreCampo errors={errors}
                                                  touched={touched} name={"numero_civico_sped"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-1">
                                <label htmlFor="cap_sped" className="form-label">CAP:</label>
                                <Field
                                    name="cap_sped"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"cap_sped"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="nominativo_sped" className="form-label">Nominativo:</label>
                                <Field name="nominativo_sped" className="form-control"/>
                                <LabelErroreCampo errors={errors} touched={touched}
                                                  name={"nominativo_sped"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Contatti</h4>
                        <div className="row g-3">
                            <div className="col-md-3">
                                <label htmlFor="email" className="form-label">E-mail*:</label>
                                <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"email"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="tel_casa" className="form-label">Tel. casa:</label>
                                <Field
                                    name="tel_casa"
                                    className="form-control"
                                    value={values['tel_casa']}
                                    onChange={event => {
                                        let value = event.target.value;
                                        if (/^[ -/+0-9]+$/.test(value)) {
                                            formikRef.current.setFieldValue('tel_casa',value);
                                        }
                                    }}
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"tel_casa"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="tel_lavoro" className="form-label">Tel. lavoro:</label>
                                <Field
                                    name="tel_lavoro"
                                    className="form-control"
                                    value={values['tel_lavoro']}
                                    onChange={event => {
                                        let value = event.target.value;
                                        if (/^[ -/+0-9]+$/.test(value)) {
                                            formikRef.current.setFieldValue('tel_lavoro',value);
                                        }
                                    }}
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"tel_lavoro"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="fax" className="form-label">Fax:</label>
                                <Field
                                    name="fax"
                                    className="form-control"
                                    value={values['fax']}
                                    onChange={event => {
                                        let value = event.target.value;
                                        if (/^[ -/+0-9]+$/.test(value)) {
                                            formikRef.current.setFieldValue('fax',value);
                                        }
                                    }}
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"fax"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-6">
                                <div className="rfi-va--center rfi-display--flex1 rfi-va--center">
                                    <label htmlFor={"comunicazioni_email"} className="form-label">
                                        <Field
                                            type="checkbox"
                                            name={"comunicazioni_email"}
                                            className="form-check-input"
                                            checked={values['comunicazioni_email'] == '1'}
                                            onChange={e => {
                                                formikRef.current.setFieldValue('comunicazioni_email', e.target.checked ? '1' : '0')
                                            }}
                                        />
                                        &nbsp;
                                        Desidero ricevere le comunicazioni via E-Mail
                                    </label>
                                    <img src={"assets/img/eco-friendly.png"} className={"rfi-img-eco"}/>
                                </div>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Altro</h4>
                        <div className="row g-3">
                            <div className="col-md-2">
                                <label htmlFor="azienda" className="form-label">Azienda:</label>
                                <Field name="azienda" className="form-control"/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"azienda"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        {
                            user && user.id_utente !== '' ?
                                <Fragment>
                                    <hr className={"mt-4"}/>
                                    <br/>
                                    <h4 className={"pt-2 pb-4"}>Documenti d'Identità</h4>
                                    {
                                        checkDocumentiScaduti(user.documenti) &&
                                        <h5>Sono presenti dei documenti scaduti. Eliminali e carica una nuova foto del
                                            documento valido</h5>
                                    }
                                    <div className="row g-3">
                                        {
                                            tipiDocumento && tipiDocumento.map((doc, idx) => {
                                                return (
                                                    <div className={"col-md-4 mt-4"} key={idx}>
                                                        <UplFotoSocio
                                                            formikRef={formikRef}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            socio={user}
                                                            documento={doc}
                                                            checkIsEliminabile={(doc) => {
                                                                return isRuoloAdmin() || isRuoloSegreteria() || isDocumentoScaduto(doc) || user.stato != STATO_COMPLETO
                                                            }}
                                                            token={token}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </Fragment>
                                : ''
                        }

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaProfiloSocio;