import {Modal} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import 'react-medium-image-zoom/dist/styles.css'
import ContainerFotoCaricata from "../../components/ContainerFotoCaricata";
import NsModalHeader from "../../components/modal/NsModalHeader";
import ContainerFoto from "../../components/ContainerFoto";
import {getDataFormattata} from "../../utility/common";

function ModalCartaIdentita({show, handleClose, user}) {

    const [dati, setDati] = useState('');

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getDatiRichiedente() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/" + user.id_utente).then((response) => {
            setDati(response.data);

            endChiamata()
        });
    }

    useEffect(() => {
        if (user) {
            getDatiRichiedente()
        } else {
            setDati(null)
        }
    }, [user]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Documenti d'identità"}/>
            <Modal.Body>
                {
                    dati && dati.documenti && dati.documenti.length > 0 ?
                        <div className={"row text-center"}>
                            {
                                dati.documenti.map((doc) =>
                                    <div className={"col-md-12 rfi-container-border--sm m-2 text-center"} key={doc.id_documento_utente}>
                                        <p>{doc ? doc.nome_file_originale || '' : ''}</p>
                                        <ContainerFoto
                                            label={doc.label}
                                            content_type={doc.content_type}
                                            nome={doc.nome_file_originale}
                                            endpointContent={process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + doc.id_utente + '/documento/' + doc.id_documento_utente}
                                        />
                                        {
                                            doc.tipo_descr !== '' && doc.scadenza && doc.scadenza !== '' ?
                                                <Fragment>
                                                    <p>
                                                        <strong>{doc.tipo_descr}</strong><br/>scad.: {getDataFormattata(doc.scadenza)}
                                                    </p>
                                                </Fragment>
                                                : ''
                                        }
                                    </div>
                                )
                            }
                        </div>
                        : ''
                }
            </Modal.Body>
        </Modal>
    )

}

export default ModalCartaIdentita;