import React, {Fragment, useState} from "react";
import ModalMsg from "./ModalMsg";

export default function NsFilePond({
                                       id,
                                       children,
                                       multiple = true,
                                       handleFiles,
                                       acceptedFileTypes = ["image/x-png", "image/png", "image/gif", "image/jpeg", "image/bmp", "image/svg+xml"
                                           , "image/heic"]
                                   }) {

    const [dragActive, setDragActive] = React.useState(false);
    const [show, setShow] = useState(false);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let file = e.dataTransfer.files[0];
            if (!acceptedFileTypes || acceptedFileTypes.includes(file.type)) {
                handleFiles(file);
            } else {
                setShow(true);
            }
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            if (!acceptedFileTypes || acceptedFileTypes.includes(file.type)) {
                handleFiles(file);
            } else {
                setShow(true);
            }
        }
    };

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={"Non è possibile inserire il file del tipo selezionato"}/>
            <div className="ns-form-file-upload" onDragEnter={handleDrag}>
                <div className={"ns-file-upload-container"}>
                    <input type="file" id={"input-file-upload-" + id} className={"ns-input-file-upload"}
                           multiple={multiple}
                           onChange={handleChange}
                           accept={acceptedFileTypes}
                    />
                    <label htmlFor={"input-file-upload-" + id}
                           className={"ns-label-file-upload rfi-cursor--pointer " + (dragActive ? "ns-label-file-upload--drag-active" : "")}>
                        <div>
                            {
                                children
                            }
                        </div>
                        {dragActive &&
                            <div className="ns-drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                                 onDragOver={handleDrag} onDrop={handleDrop}></div>}
                    </label>
                </div>
            </div>
        </Fragment>
    );
}