import React, {Fragment, useContext, useEffect, useState} from "react";
import {LoaderContext} from "../../context/LoaderContext";
import {AuthContext} from "../../context/AuthContext";
import axios from "axios";
import {RuoliContext} from "../../context/RuoliContext";
import {
    STATO_APPROVATO, STATO_CARTA_ID_UPL,
    STATO_COMPLETO, STATO_IN_RICHIESTA_VALIDO, TIPO_MODULO_REGISTRAZIONE,
} from "../../utility/common";
import HomeAdminAndStaff from "./HomeAdminAndStaff";
import HomeSocioCompleto from "./HomeSocioCompleto";
import ModuloPagamentoQuota from "../pagamenti/ModuloPagamentoQuota";
import HomeSocioInAttesaApprov from "./HomeSocioInAttesaApprov";
import HomeSocioMailVerificata from "./HomeSocioMailVerificata";
import HomeCommissario from "./HomeCommissario";
import HomeSocioApprovato from "./HomeSocioApprovato";
import HomeClub from "./HomeClub";

function Home() {

    const [utente, setUtente] = useState();

    const {user} = useContext(AuthContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {
        isRuoloAdmin,
        isRuoloSegreteria,
        isRuoloCommissario,
        isRuoloSocio,
        isRuoloPresidente,
        isRuoloClub,
        isRuoloAgenzia
    } = useContext(RuoliContext);

    const getUtente = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + user.id_utente).then((response) => {
            let u = response.data.data;
            setUtente(u);
            endChiamata()
        });
    }

    useEffect(() => {
        getUtente()
    }, []);

    return (
        <div className="rfi-home">
            {
                utente && (isRuoloAdmin() || isRuoloSegreteria()) ?
                    <HomeAdminAndStaff usr={utente}/>
                    : ''
            }
            {
                utente && (isRuoloClub() || isRuoloAgenzia()) &&
                <HomeClub usr={utente}/>
            }
            {
                utente && (isRuoloCommissario() || isRuoloPresidente()) &&
                <HomeCommissario usr={utente}/>
            }
            {
                isRuoloSocio() && utente && utente.stato == STATO_APPROVATO &&
                <Fragment>
                    <HomeSocioApprovato usr={utente}/>
                </Fragment>
            }
            {
                isRuoloSocio() && utente && utente.stato == STATO_IN_RICHIESTA_VALIDO ?
                    <Fragment>
                        <HomeSocioMailVerificata
                            usr={utente}
                            handlerConferma={(usr) => setUtente(usr)}
                        />
                    </Fragment>
                    : ''
            }
            {
                isRuoloSocio() && utente && utente.stato == STATO_CARTA_ID_UPL ?
                    <Fragment>
                        <HomeSocioInAttesaApprov usr={utente}/>
                    </Fragment>
                    : ''
            }
            {
                isRuoloSocio() && utente && utente.stato == STATO_COMPLETO ?
                    <HomeSocioCompleto usr={utente}/>
                    : ''
            }
        </div>
    )
}

export default Home;
