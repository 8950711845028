import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import {convertiTimestampInDataPerDb, isUnder30} from "../../utility/common";
import ModalMsg from "../../components/ModalMsg";
import {Link} from "react-router-dom";
import {TranslateContext} from "../../context/TranslateContext";
import Translate from "../../components/Translate";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import NsDatePicker from "../../components/NsDatePicker";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import ModalGdpr from "./ModalGdpr";
import CalcolaCodiceFiscale from "../../components/codice_fiscale/CalcolaCodiceFiscale";
import AutocompleteText from "../../components/AutocompleteText";
import NsField from "../../components/NsField";

function RegistrazioneUtente({setNextStep, setUser, tipo}) {

    const [motiviRegistrazione, setMotiviRegistrazione] = useState([]);
    const [province, setProvince] = useState([]);
    const [nazioni, setNazioni] = useState([]);
    const [comuniNascita, setComuniNascita] = useState([]);
    const [comuniResidenza, setComuniResidenza] = useState([]);
    const [comuniSped, setComuniSped] = useState([]);
    const [tipiVersamento, setTipiVersamento] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [nazione, setNazione] = useState('I');
    const [nazioneNascita, setNazioneNascita] = useState('I');
    const [nazioneSped, setNazioneSped] = useState('I');
    const [spedUgualeResidenza, setSpedUgualeResidenza] = useState(true);
    const [gdprOk, setGdprOk] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);
    const formikRef = useRef();

    useEffect(() => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/motivi-registrazione/").then((response) => {
            setMotiviRegistrazione(response.data);
            endChiamata()
        });

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/provincia/").then((response) => {
            setProvince(response.data.data);
            endChiamata()
        });

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/nazione/").then((response) => {
            setNazioni(response.data.data);
            formikRef.current.setFieldValue("nazione", 'I')
            endChiamata()
        });

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/rfi/quota/tipi-versamento/`).then((response) => {
            setTipiVersamento(response.data)
            endChiamata()
        });

    }, []);

    const SignupSchema = Yup.object().shape({
        cognome: Yup.string()
            .max(30, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        nome: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        data_nascita: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        nazione: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        luogo_nascita: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        localita: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        localita_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')).nullable(),
        indirizzo: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        indirizzo_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')).nullable(),
        cap: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        numero_civico: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        email: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        tel_casa: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri'))
            .min(9, traduci('Attenzione! Inserisci almeno 9 caratteri numerici'))
            .required(traduci('Questo campo è obbligatorio')),
        altro: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        tipo_adesione: Yup.string()
            .required(traduci('Selezionare almeno un tipo adesione')),
        genere: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        codice_fiscale: Yup.string()
            .max(16, traduci('Attenzione! Hai inserito troppi caratteri'))
            .min(16, traduci('Dato errato! Per favore ricontrolla')),
        nominativo_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
    });

    const searchComuniNascita = (provincia, comune) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        if (provincia && provincia !== '') {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
                setComuniNascita(response.data.data);
            });
        }
    }

    const searchComuniResidenza = (provincia, comune) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        if (provincia && provincia !== '') {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
                setComuniResidenza(response.data.data);
            });
        }
    }

    const searchComuniSpedizione = (provincia, comune) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        if (provincia && provincia !== '') {
            axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
                setComuniSped(response.data.data);
            });
        }
    }

    function initCodiceFiscale(comune) {
        let values = formikRef.current.values;
        let luogo = comune && comune !== '' ? comune : values['luogo_nascita'];

        if (values['nome'] !== '' && values['cognome'] !== ''
            && values['genere'] !== '' && luogo !== ''
            && values['provincia_nascita'] && values['provincia_nascita'] !== '') {

            let dataN = new Date(values['data_nascita'])

            let params = {
                name: values['nome'],
                surname: values['cognome'],
                gender: values['genere'],
                day: dataN.getDate().toString().padStart(2, '0'),
                month: (dataN.getMonth() + 1).toString().padStart(2, '0'),
                year: dataN.getFullYear(),
                birthplace: luogo,
                birthplace_provincia: values['provincia_nascita']
            }

            try {
                let cf = CalcolaCodiceFiscale.compute(params)

                formikRef.current.setFieldValue("codice_fiscale", cf)
            } catch (e) {
                // errore trascurato. Mi limito a non compilare il campo
            }
        }
    }

    function getCampiNoteMotivoReg(motivo, values, name, errors, touched, submitCount) {
        if (motivo.codice === 'ALTROABA') {
            return <div className={"col-md-6 text-start"}>
                <Field name="altro_aba" className="form-control"
                       placeholder={"Altre motivazioni"}
                       required={values[name] === '1'}
                       validate={(value) => {
                           if (value.length > 200) {
                               return 'Attenzione! Hai inserito troppi caratteri'
                           }
                       }}
                />
                <LabelErroreCampo errors={errors} touched={touched} name={"altro_aba"}
                                  submitCount={submitCount}/>
            </div>
        } else if (motivo.codice === 'SOCIO_ASI_ABA') {
            return <Fragment>
                <div className={"col-md-3 text-start"}>
                    <Field name="socio_aba_asi" className="form-control"
                           placeholder={"Numero tessera ASI"}
                           required={values[name] === '1'}
                           validate={(value) => {
                               if (value.length > 6) {
                                   return 'Attenzione! Hai inserito troppi caratteri'
                               }
                           }}
                    />
                    <LabelErroreCampo errors={errors} touched={touched} name={"socio_aba_asi"}
                                      submitCount={submitCount}/>
                </div>
                <div className={"col-md-3 text-start"}>
                    <Field
                        name="numero_tessera_rfi"
                        className="form-control rfi-number-no-arrows"
                        type={"number"}
                        onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                        placeholder={"Numero tessera RFI"}
                        required={values[name] === '1'}
                    />
                    <LabelErroreCampo errors={errors} touched={touched} name={"numero_tessera_rfi"}
                                      submitCount={submitCount}/>
                </div>
            </Fragment>
        } else {
            return <div className={"col-md-6 text-start"}/>
        }
    }

    function setTipoAdesionePerCheck() {
        let motiviRegSel = Object.keys(formikRef.current.values)
            .filter(val => val.startsWith('mrrfi_') || val.startsWith('mraba_'))
        let tipoAdd = ''
        if (motiviRegSel) {
            motiviRegSel.forEach(val => {
                if (formikRef.current.values[val] !== '' && formikRef.current.values[val] !== '0') {
                    tipoAdd = '1'
                }
            })
        }

        formikRef.current.setFieldValue('tipo_adesione', tipoAdd)
    }

    function getCap(comune, arrayComuni) {
        let cap = '';

        if (comune !== '') {
            let comuni = arrayComuni.filter(c => c.nome === comune);
            if (comuni && comuni.length > 0) {
                cap = comuni.map(c => c.cap).reduce(c => c) || '';
            }
        }
        return cap;
    }

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <ModalGdpr
                show={showPrivacy}
                handleClose={() => setShowPrivacy(false)}
                handlerGdprOk={() => {
                    setGdprOk(true);
                    setShowPrivacy(false);
                }}/>
            <Formik
                initialValues={{
                    cognome: '',
                    nome: '',
                    data_nascita: '',
                    provincia_nascita: '',
                    luogo_nascita: '',
                    provincia: '',
                    nazione_nascita: 'I',
                    nazione: 'I',
                    localita: '',
                    indirizzo: '',
                    cap: '',
                    numero_civico: '',
                    email: '',
                    tel_casa: '',
                    altro: '',
                    numero_tessera_asi: '',
                    altro_aba: '',
                    socio_aba_asi: '',
                    numero_tessera_rfi: '',
                    codice_fiscale: '',
                    tipo_adesione: '',
                    comunicazioni_email: '1',
                    nazione_sped: 'I',
                    provincia_sped: '',
                    localita_sped: '',
                    indirizzo_sped: '',
                    cap_sped: '',
                    numero_civico_sped: '',
                    sped_eq_res: spedUgualeResidenza === true ? '1' : '0',
                    genere: '',
                    nominativo_sped: '',
                    info_auto: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let newUsr = values;
                    newUsr['data_nascita'] = convertiTimestampInDataPerDb(newUsr['data_nascita'])
                    newUsr['tipo_modulo'] = tipo

                    /*
                     * Se ho richiesto l'utilizzo della residenza come indirizzo di spedizione, per sicurezza,
                     * copio ulteriormente i valori. Questo perché attualmente la copia avviene solo sull'evento di
                     * selezione e deselezione della checkbox, quindi potrebbe non essere richiamata
                     */
                    if (values['sped_eq_res'] === '1') {
                        newUsr['nazione_sped'] = newUsr['nazione']
                        newUsr['provincia_sped'] = newUsr['provincia']
                        newUsr['localita_sped'] = newUsr['localita']
                        newUsr['indirizzo_sped'] = newUsr['indirizzo']
                        newUsr['cap_sped'] = newUsr['cap']
                        newUsr['numero_civico_sped'] = newUsr['numero_civico']
                    }

                    // Concateno il query param reg=1 per gestire solo questo servizio in withe list
                    startChiamata()
                    axios
                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/",
                            values)
                        .then((response) => {
                            if (response.data.result === 0) {
                                setUser(response.data.data)

                                // Passo al prossimo step. Da fare dopo il salvataggio ed invio della mail
                                setNextStep(1)
                            } else {
                                setModalMsg(response.data.description);
                                setShow(true);
                            }
                            endChiamata()
                        });
                }}
            >
                {({errors, touched, values, submitCount}) => (
                    <Form>
                        <h4 className={"pt-2 pb-4"}>Dati Anagrafici</h4>
                        <div className="row g-3">
                            <div className="col-md-5">
                                <NsField
                                    label="Cognome"
                                    name="cognome"
                                    className="form-control"
                                    placeholder={"Rossi"}
                                    onBlur={initCodiceFiscale}
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-5">
                                <NsField
                                    label="Nome"
                                    name="nome"
                                    className="form-control"
                                    placeholder={"Mario"}
                                    onBlur={initCodiceFiscale}
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="genere" className="form-label">Genere:</label>
                                <Field
                                    name="genere"
                                    as="select"
                                    className="form-select"
                                    onBlur={initCodiceFiscale}
                                >
                                    <option value={""}></option>
                                    <option value={"M"}>M</option>
                                    <option value={"F"}>F</option>
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"genere"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="data_nascita" className="form-label"><Translate>Data di
                                    nascita</Translate>:</label>
                                <NsDatePicker
                                    maxDate={new Date()}
                                    nome={"data_nascita"}
                                    dataSelezionata={values["data_nascita"]}
                                    setSelectedDate={(date, event) => {
                                        formikRef.current.setFieldValue("data_nascita", date)
                                    }}
                                    onBlur={initCodiceFiscale}
                                />
                                <LabelErroreCampo errors={errors} touched={touched}
                                                  name={"data_nascita"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="nazione_nascita" className="form-label"><Translate>Nazione di
                                    nascita</Translate>:</label>
                                <Field
                                    as={"select"}
                                    name="nazione_nascita"
                                    className="form-select"
                                    onChange={(event) => {
                                        formikRef.current.setFieldValue('nazione_nascita', event.target.value)
                                        setNazioneNascita(event.target.value);
                                    }}>
                                    <option value={""}><Translate>Sel. nazione</Translate></option>
                                    {
                                        nazioni ? nazioni.map((op, index) => {
                                            return <option key={index}
                                                           value={op.codice}>{op.descrizione.toUpperCase()}</option>
                                        }) : ''
                                    }
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"nazione_nascita"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                nazioneNascita === 'I' ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="provincia_nascita" className="form-label"><Translate>Provincia
                                                di
                                                nascita</Translate>:</label>
                                            <select
                                                id="provincia_nascita"
                                                name="provincia_nascita"
                                                className="form-select"
                                                onChange={(event) => {
                                                    formikRef.current.setFieldValue('provincia_nascita', event.target.value)
                                                    searchComuniNascita(event.target.value);
                                                }}
                                                onBlur={initCodiceFiscale}>
                                                <option value={""}>Sel. provincia</option>
                                                {
                                                    province ? province.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.sigla_provincia}>{op.descrizione.toUpperCase()}</option>
                                                    }) : ''
                                                }
                                            </select>
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"provincia_nascita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="luogo_nascita" className="form-label"><Translate>Luogo di
                                                nascita</Translate>:</label>
                                            <AutocompleteText
                                                name={"luogo_nascita"}
                                                formikRef={formikRef}
                                                formikValues={values}
                                                filterFunction={(comune) => {
                                                    searchComuniNascita(formikRef.current.values['provincia_nascita'], comune);
                                                }}
                                                filteredValues={comuniNascita.map(c => c.nome)}
                                                handlerBlur={(ev) => {
                                                    initCodiceFiscale(ev.target.value);
                                                }}
                                                handlerSelect={(val) => {
                                                    initCodiceFiscale(val);
                                                }}
                                                toUpper={true}
                                            />
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"luogo_nascita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div className="col-md-5">
                                            <NsField
                                                label="Luogo di nascita"
                                                name="luogo_nascita"
                                                className="form-control"
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                    </Fragment>
                            }
                            <div className="col-md-3">
                                <NsField
                                    label="Codice Fiscale"
                                    name="codice_fiscale"
                                    className="form-control"
                                    placeholder={"RSSMRA81H13F205W"}
                                    validate={(value) => {
                                        if (values['nazione'] == 'I' && value === '') {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Dati Residenza</h4>
                        <div className="row g-3">
                            <div className="col-md-2">
                                <label htmlFor="nazione" className="form-label"><Translate>Nazione</Translate>:</label>
                                <Field
                                    as={"select"}
                                    name="nazione"
                                    className="form-select"
                                    onChange={(event) => {
                                        formikRef.current.setFieldValue('nazione', event.target.value)
                                        setNazione(event.target.value);
                                    }}>
                                    <option value={""}><Translate>Sel. nazione</Translate></option>
                                    {
                                        nazioni ? nazioni.map((op, index) => {
                                            return <option key={index}
                                                           value={op.codice}>{op.descrizione.toUpperCase()}</option>
                                        }) : ''
                                    }
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"nazione"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                nazione && nazione === 'I' ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="provincia" className="form-label"><Translate>Provincia
                                                residenza</Translate>:</label>
                                            <select
                                                id="provincia"
                                                name="provincia"
                                                className="form-select"
                                                onChange={(event) => {
                                                    formikRef.current.setFieldValue('provincia', event.target.value)
                                                    searchComuniResidenza(event.target.value);
                                                }}>
                                                <option value={""}><Translate>Sel. provincia</Translate></option>
                                                {
                                                    province ? province.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.sigla_provincia}>{op.descrizione.toUpperCase()}</option>
                                                    }) : ''
                                                }
                                            </select>
                                            <LabelErroreCampo errors={errors} name={"provincia"}
                                                              touched={touched}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="localita" className="form-label"><Translate>Residente
                                                a</Translate>:</label>
                                            <AutocompleteText
                                                name={"localita"}
                                                formikRef={formikRef}
                                                formikValues={values}
                                                filterFunction={(comune) => {
                                                    searchComuniResidenza(formikRef.current.values['provincia'], comune);
                                                }}
                                                filteredValues={comuniResidenza.map(c => c.nome)}
                                                handlerChange={(event) => {
                                                    let comune = event.target.value;
                                                    let cap = getCap(comune, comuniResidenza);
                                                    formikRef.current.setFieldValue('cap', cap)
                                                }}
                                                handlerSelect={(comune) => {
                                                    let cap = getCap(comune, comuniResidenza);
                                                    formikRef.current.setFieldValue('cap', cap)
                                                }}
                                                toUpper={true}
                                            />
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"localita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    : <Fragment>
                                        <div className="col-md-5">
                                            <NsField
                                                label="Residente a"
                                                name="localita"
                                                className="form-control"
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                    </Fragment>
                            }
                            <div className="col-md-3">
                                <NsField
                                    label="Indirizzo"
                                    name="indirizzo"
                                    className="form-control"
                                    placeholder={"Via Roma"}
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-1">
                                <NsField
                                    label="N° civico"
                                    name="numero_civico"
                                    className="form-control"
                                    placeholder={"15"}
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-1">
                                <label htmlFor="cap" className="form-label">CAP:</label>
                                <Field
                                    name="cap"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"cap"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Dati Indirizzo Spedizione</h4>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <div className="rfi-va--center rfi-display--flex">
                                    <label htmlFor={"sped_eq_res"} className="form-label">
                                        <Field type="checkbox"
                                               name={"sped_eq_res"}
                                               className="form-check-input"
                                               onChange={e => {
                                                   formikRef.current.setFieldValue('sped_eq_res', e.target.checked ? '1' : '0')
                                                   setSpedUgualeResidenza(e.target.checked)

                                                   if (e.target.checked) {
                                                       formikRef.current.setFieldValue('nazione_sped', values['nazione'])
                                                       formikRef.current.setFieldValue('provincia_sped', values['provincia'])
                                                       formikRef.current.setFieldValue('localita_sped', values['localita'])
                                                       formikRef.current.setFieldValue('indirizzo_sped', values['indirizzo'])
                                                       formikRef.current.setFieldValue('cap_sped', values['cap'])
                                                       formikRef.current.setFieldValue('numero_civico_sped', values['numero_civico'])
                                                   } else {
                                                       formikRef.current.setFieldValue('nazione_sped', 'I')
                                                       formikRef.current.setFieldValue('provincia_sped', '')
                                                       formikRef.current.setFieldValue('localita_sped', '')
                                                       formikRef.current.setFieldValue('indirizzo_sped', '')
                                                       formikRef.current.setFieldValue('cap_sped', '')
                                                       formikRef.current.setFieldValue('numero_civico_sped', '')
                                                   }
                                               }}
                                               checked={values['sped_eq_res'] === '1'}
                                        />
                                        &nbsp;
                                        Utilizzare per le spedizioni l'indirizzo di residenza?
                                    </label>
                                </div>
                            </div>

                            {
                                spedUgualeResidenza === false ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="nazione"
                                                   className="form-label"><Translate>Nazione</Translate>:</label>
                                            <Field
                                                as={"select"}
                                                name="nazione_sped"
                                                className="form-select"
                                                value={values['nazione_sped']}
                                                onChange={(event) => {
                                                    values['nazione_sped'] = event.target.value
                                                    setNazioneSped(event.target.value);
                                                }}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] === '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            >
                                                <option value={""}><Translate>Sel. nazione</Translate></option>
                                                {
                                                    nazioni ? nazioni.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.codice}>{op.descrizione.toUpperCase()}</option>
                                                    }) : ''
                                                }
                                            </Field>
                                            <LabelErroreCampo errors={errors} name={"nazione_sped"}
                                                              touched={touched}
                                                              submitCount={submitCount}/>
                                        </div>
                                        {
                                            nazioneSped && nazioneSped === 'I' ?
                                                <Fragment>
                                                    <div className="col-md-2">
                                                        <label htmlFor="provincia_sped"
                                                               className="form-label"><Translate>Provincia
                                                            spedizione</Translate>:</label>
                                                        <select
                                                            id="provincia_sped"
                                                            name="provincia_sped"
                                                            className="form-select"
                                                            value={values['provincia_sped']}
                                                            onChange={(event) => {
                                                                values['provincia_sped'] = event.target.value
                                                                searchComuniSpedizione(event.target.value);
                                                            }}>
                                                            <option value={""}><Translate>Sel. provincia</Translate>
                                                            </option>
                                                            {
                                                                province ? province.map((op, index) => {
                                                                    return <option key={index}
                                                                                   value={op.sigla_provincia}>{op.descrizione.toUpperCase()}</option>
                                                                }) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="localita"
                                                               className="form-label"><Translate>Comune</Translate>:</label>
                                                        <AutocompleteText
                                                            name={"localita_sped"}
                                                            formikRef={formikRef}
                                                            formikValues={values}
                                                            filterFunction={(comune) => {
                                                                searchComuniSpedizione(formikRef.current.values['provincia_sped'], comune);
                                                            }}
                                                            filteredValues={comuniSped.map(c => c.nome)}
                                                            toUpper={true}
                                                            handlerChange={(event) => {
                                                                let comune = event.target.value;
                                                                let cap = getCap(comune, comuniSped);
                                                                formikRef.current.setFieldValue('cap_sped', cap)
                                                            }}
                                                            handlerSelect={(comune) => {
                                                                let cap = getCap(comune, comuniSped);
                                                                formikRef.current.setFieldValue('cap_sped', cap)
                                                            }}
                                                            validate={(value) => {
                                                                if (values['sped_eq_res'] === '0' && value === '') {
                                                                    return 'Questo campo è obbligatorio'
                                                                }
                                                            }}
                                                        />
                                                        <LabelErroreCampo errors={errors}
                                                                          touched={touched}
                                                                          name={"localita_sped"}
                                                                          submitCount={submitCount}/>
                                                    </div>
                                                </Fragment>
                                                : <Fragment>
                                                    <div className="col-md-5">
                                                        <NsField
                                                            label="Comune"
                                                            name="localita_sped"
                                                            className="form-control"
                                                            validate={(value) => {
                                                                if (values['sped_eq_res'] === '0' && value === '') {
                                                                    return 'Questo campo è obbligatorio'
                                                                }
                                                            }}
                                                            formikRef={formikRef}
                                                            submitCount={submitCount}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            toUpperCase={true}
                                                        />
                                                    </div>
                                                </Fragment>
                                        }
                                        <div className="col-md-3">
                                            <NsField
                                                label="Indirizzo"
                                                name="indirizzo_sped"
                                                className="form-control"
                                                placeholder={"Via Roma"}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] === '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <NsField
                                                label="N° civico"
                                                name="numero_civico_sped"
                                                className="form-control"
                                                placeholder={"15"}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] === '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <label htmlFor="cap_sped" className="form-label">CAP:</label>
                                            <Field
                                                name="cap_sped"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] === '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"cap_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="nominativo_sped" className="form-label">Nominativo:</label>
                                            <Field name="nominativo_sped" className="form-control"/>
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"nominativo_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    : ''
                            }
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Contatti</h4>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label htmlFor="email" className="form-label"><Translate>Indirizzo
                                    E-mail</Translate>:</label>
                                <Field name="email" className="form-control" type={"email"}
                                       placeholder={"mario.rossi@gmail.com"}/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"email"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="tel_casa" className="form-label"><Translate>Numero di
                                    telefono</Translate>:</label>
                                <Field
                                    name="tel_casa"
                                    className="form-control"
                                    placeholder={"3491234567"}
                                    value={values['tel_casa']}
                                    onChange={event => {
                                        let value = event.target.value;
                                        if (/^[ -/+0-9]+$/.test(value)) {
                                            formikRef.current.setFieldValue('tel_casa', value);
                                        }
                                    }}
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"tel_casa"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-12">
                                <div className="rfi-va--center rfi-display--flex1 rfi-va--center">
                                    <label htmlFor={"comunicazioni_email"} className="form-label">
                                        <Field
                                            type="checkbox"
                                            name={"comunicazioni_email"}
                                            className="form-check-input"
                                            checked={values['comunicazioni_email'] == '1'}
                                            onChange={e => {
                                                formikRef.current.setFieldValue('comunicazioni_email', e.target.checked ? '1' : '0')
                                            }}
                                        />
                                        &nbsp;
                                        Desidero ricevere le comunicazioni via E-Mail (la tessera ed i certificati
                                        arriveranno comunque per posta)
                                    </label>
                                    <img src={"assets/img/eco-friendly.png"} className={"rfi-img-eco"}/>
                                </div>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        {
                            // Modulistica RFI

                            tipo && (tipo == 1 || tipo == 3) ?
                                <Fragment>
                                    <div className={"row pt-4"}>
                                        <div className={"col-md-12 text-center"}>
                                            <p><strong><Translate>Compilando i precedenti campi si richiede di diventare
                                                socio/a del REGISTRO FIAT ITALIANO a decorrere dal presente anno
                                                dichiarando:</Translate></strong></p>
                                        </div>
                                    </div>
                                    <div className={"row pt-2"}>
                                        {
                                            motiviRegistrazione ? motiviRegistrazione.filter((motivo) => motivo.tipo == 1)
                                                .map(motivo => {
                                                    var name = "mrrfi_" + motivo.id_motivo_registrazione;

                                                    return (<Fragment key={motivo.id_motivo_registrazione}>
                                                        <div className={"col-md-6 text-start"}>
                                                            <label htmlFor={name} className="form-label">
                                                                <Field type="checkbox"
                                                                       name={name}
                                                                       className="form-check-input"
                                                                       onChange={e => {
                                                                           formikRef.current.setFieldValue(name, e.target.checked ? '1' : '0')
                                                                               .then(() => {
                                                                                   setTipoAdesionePerCheck();
                                                                               })
                                                                       }}
                                                                       checked={values[name] == '1'}/>
                                                                &nbsp;
                                                                {motivo.descrizione}
                                                            </label>
                                                        </div>
                                                        <div className={"col-md-6 text-start"}>
                                                            {
                                                                motivo.codice === 'ALTRO' ?
                                                                    <Field name="altro" className="form-control m-1"
                                                                           placeholder={"Altre motivazioni"}
                                                                           required={values['altro'] === '1'}/>
                                                                    : ''
                                                            }
                                                            {
                                                                motivo.codice === 'SOCIO_ASI' ?
                                                                    <Fragment>
                                                                        <Field
                                                                            name="numero_tessera_asi"
                                                                            className="form-control m-1"
                                                                            placeholder={"Numero tessera ASI"}
                                                                            required={values['numero_tessera_asi'] === '1'}
                                                                            validate={(value) => {
                                                                                if (value.length > 6) {
                                                                                    return 'Attenzione! Hai inserito troppi caratteri'
                                                                                }
                                                                            }}
                                                                        />
                                                                        <LabelErroreCampo errors={errors}
                                                                                          touched={touched}
                                                                                          name={"numero_tessera_asi"}
                                                                                          submitCount={submitCount}/>
                                                                    </Fragment>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </Fragment>)
                                                }) : ''
                                        }
                                        <LabelErroreCampo errors={errors} touched={touched}
                                                          name={"tipo_adesione"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className={"row pt-4"}>
                                        <div className={"col-md-12"}>
                                            <label htmlFor="info_auto" className="form-label"><Translate>Indica marca,
                                                modello e anno
                                                delle auto da iscrivere</Translate>:</label>
                                            <Field
                                                as={"textarea"}
                                                name="info_auto"
                                                className="form-control"
                                                type={"info_auto"}
                                                placeholder={"Fiat 1100 del 1955, Fiat 500 C del 1951"}/>
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"info_auto"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </div>

                                    <div className={"row pt-4"}>
                                        <div className={"col-md-12 text-start"}>
                                            <p><Translate>Se la mia domanda sarà accolta, provvederò a
                                                rimetterVi le quote stabilite e cioè:</Translate></p>
                                            <ul>
                                                {
                                                    tipiVersamento ?
                                                        tipiVersamento.filter(tipo => {
                                                            let under30 = isUnder30(values['data_nascita']) === true ? '1' : '0';
                                                            return tipo.abarth == '0'
                                                                && (tipo.under_30 == under30 || tipo.under_30 == '2')
                                                                && tipo.asi == '0'
                                                        }).map(tipo => <li key={tipo.id_tipo_versamento}>
                                                            <span className="content"
                                                                  dangerouslySetInnerHTML={{__html: tipo.descrizione_html}}></span>
                                                            {
                                                                tipo.info && tipo.info !== '' &&
                                                                <span
                                                                    className={"rfi-font--12"}>&nbsp;({tipo.info})</span>
                                                            }
                                                        </li>)
                                                        : ''
                                                }
                                                <li>
                                                    Per chi desidera la tessera ASI, integrare il versamento con:
                                                    <ul>
                                                        {
                                                            tipiVersamento ?
                                                                tipiVersamento.filter(tipo => {
                                                                    let under30 = isUnder30(values['data_nascita']) === true ? '1' : '0';
                                                                    return tipo.abarth == '0'
                                                                        && (tipo.under_30 == under30 || tipo.under_30 == '2')
                                                                        && tipo.asi == '1'
                                                                }).map(tipo => <li key={tipo.id_tipo_versamento}>
                                                            <span className="content"
                                                                  dangerouslySetInnerHTML={{__html: tipo.descrizione_html}}></span>
                                                                    {
                                                                        tipo.info && tipo.info !== '' &&
                                                                        <span
                                                                            className={"rfi-font--12"}>&nbsp;({tipo.info})</span>
                                                                    }
                                                                </li>)
                                                                : ''
                                                        }
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Fragment>
                                : ''
                        }
                        {
                            // Modulistica Abarth

                            tipo && (tipo == 2 || tipo == 3) ?
                                <Fragment>
                                    <div className={"row pt-4"}>
                                        <div className={"col-md-12 text-center"}>
                                            <p><strong><Translate>Compilando i precedenti campi si richiede di diventare
                                                socio del REGISTRO ABARTH a decorrere dal presente anno
                                                dichiarando:</Translate></strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={"row pt-2"}>
                                        {
                                            motiviRegistrazione ? motiviRegistrazione.filter((motivo) => motivo.tipo == 2)
                                                .map(motivo => {
                                                    var name = "mraba_" + motivo.id_motivo_registrazione;

                                                    return (<Fragment key={motivo.id_motivo_registrazione}>
                                                        <div className={"col-md-6 text-start"}>
                                                            <label htmlFor={name} className="form-label">
                                                                <Field type="checkbox"
                                                                       name={name}
                                                                       className="form-check-input"
                                                                       onChange={e => {
                                                                           formikRef.current.setFieldValue(name, e.target.checked ? '1' : '0')
                                                                               .then(() => {
                                                                                   setTipoAdesionePerCheck();
                                                                               })
                                                                       }}
                                                                       checked={values[name] == '1'}/>
                                                                &nbsp;
                                                                {motivo.descrizione}
                                                            </label>
                                                        </div>
                                                        {getCampiNoteMotivoReg(motivo, values, name, errors, touched, submitCount)}
                                                    </Fragment>)
                                                }) : ''
                                        }
                                        <LabelErroreCampo errors={errors} touched={touched}
                                                          name={"tipo_adesione"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className={"row pt-4"}>
                                        <div className={"col-md-12"}>
                                            <label htmlFor="info_auto" className="form-label"><Translate>Indica marca,
                                                modello e anno
                                                delle auto da iscrivere</Translate>:</label>
                                            <Field
                                                as={"textarea"}
                                                name="info_auto"
                                                className="form-control"
                                                type={"info_auto"}
                                                placeholder={"Fiat 1100 del 1955, Fiat 500 C del 1951"}/>
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"info_auto"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </div>

                                    <div className={"row pt-4"}>
                                        <div className={"col-md-12 text-start"}>
                                            <p><Translate>Se la mia domanda sarà accolta, provvederò a
                                                rimetterVi le quote stabilite e cioè:</Translate></p>
                                            <ul>
                                                {
                                                    tipiVersamento ?
                                                        tipiVersamento.filter(tipo => {
                                                            let under30 = isUnder30(values['data_nascita']) === true ? '1' : '0';
                                                            return tipo.abarth == '1'
                                                                && (tipo.under_30 == under30 || tipo.under_30 == '2')
                                                                && tipo.asi == '0'
                                                        }).map(tipo => <li key={tipo.id_tipo_versamento}>
                                                            <span className="content"
                                                                  dangerouslySetInnerHTML={{__html: tipo.descrizione_html}}></span>
                                                            {
                                                                tipo.info && tipo.info !== '' &&
                                                                <span
                                                                    className={"rfi-font--12"}>&nbsp;({tipo.info})</span>
                                                            }
                                                        </li>)
                                                        : ''
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Fragment>
                                : ''
                        }

                        <hr className={"mt-4"}/>
                        <div className={"row"}>
                            <div className={"col-md-12 text-center"}>
                                <p>
                                    <button
                                        type={"button"}
                                        className={"btn btn-link btn-sm"}
                                        onClick={() => {
                                            setShowPrivacy(true);
                                        }}
                                    >
                                        <strong>Attenzione! Prima di proseguire devi leggere ed accettare Codizioni
                                            generali, Termini d'uso ed Informativa sulla Privacy. Clicca qui per
                                            procedere</strong>
                                    </button>
                                </p>
                                {
                                    gdprOk &&
                                    <span className={"fas fa-check-square ns-span--verde rfi-font--40"}></span>
                                }
                            </div>
                        </div>

                        <div className={"row pt-4"}>
                            <div className={"col-md-12 text-center"}>
                                <Link to={"/"} className="btn btn-card">
                                    <i className="fas fa-chevron-left"/> <Translate>Indietro</Translate>
                                </Link>
                                <button type="button" id="salva" name="salva"
                                        className="btn btn-principal"
                                        onClick={() => {
                                            if (gdprOk === true && formikRef.current) {
                                                formikRef.current.handleSubmit();
                                            } else {
                                                setShowPrivacy(true);
                                            }
                                        }}>
                                    <i className="fas fa-paper-plane"/> <Translate>Invia richiesta</Translate>
                                </button>
                            </div>
                        </div>
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default RegistrazioneUtente;
