import React, {Fragment} from "react";
import {
    getTimestampFormattato,
} from "../../utility/common";
import NsDataTable from "../../components/tabelle/NsDataTable";
import Separatore from "../../components/Spearatore";

function infoStoricoCertificato({certificato}) {

    let righeStorico = [];
    const labelCampiVeicolo = {
        anno: "Anno di costruzione",
        canc: "Cancellato",
        note: "Note",
        tara: "Tara (Kg)",
        tipo: "Modello",
        marca: "Marca",
        massa: "Massa complessiva (peso totale) Kg",
        targa: "Targa",
        targa_prec: "Targa precedente nazionale (se esiste)",
        tempi: "Tempi motore",
        cavalli: "Cavalli (cv)",
        id_club: "Club",
        potenza: "Potenza massima (kw)",
        cilindri: "Numero cilindri",
        velocita: "Velocità massima del veicolo (km/h)",
        bollo_rfi: "Bollo RFI",
        id_colore: "Colore carrozzeria",
        larghezza: "Larghezza massima (mm)",
        lunghezza: "Lunghezza massima (mm)",
        massa_rim: "Massa rimorchiabile (Kg)",
        num_porte: "Numero porte",
        omologato: "Omologato",
        omologazione: "Omologazione",
        cilindrata: "Cilindrata",
        tipo_ruote: "Tipo ruote",
        id_registro: "Registro",
        retrovisori: "Retrovisori",
        tipo_cambio: "Tipo di cambio",
        tipo_motore: "Tipo motore",
        tipo_telaio: "Tipo telaio",
        targa_estera: "Targa estera",
        targa_estera_prec: "Targa precedente estera (se esiste)",
        alimentazione: "Alimentazione",
        id_tipo_marca: "Tipo di veicolo",
        num_posti_ant: "Posti anteriori",
        num_posti_tot: "Posti totali",
        numero_motore: "Numero motore",
        numero_telaio: "Numero telaio",
        tipo_frizione: "Frizione",
        tipo_sosp_ant: "Sospensioni anteriori",
        tipo_trazione: "Tipo di trazione",
        disp_sil_legge: "Dispositivo silenziatore conforme alla legge",
        freno_soccorso: "Freno di soccorso",
        tipo_freni_ant: "Tipo di freno anteriore",
        tipo_sosp_post: "Sospensioni posteriori",
        tipo_freni_post: "Tipo di freno posteriore",
        altri_pneumatici: "Altri pneumatici consentiti",
        note_carrozzeria: "Specificazioni carrozzeria",
        posizione_motore: "Posizione motore",
        posizione_telaio: "Posizione telaio",
        marca_carrozzeria: "Marca carrozzeria",
        tipo_trasmissione: "Tipo di trasmissione",
        data_registrazione: "Data registrazione",
        impianto_elettrico: "Impianto elettrico",
        tipo_scatola_guida: "Tipo scatola guida",
        disp_sil_org_traino: "Dispositivo silenziatore organi di traino presente",
        id_tipo_carrozzeria: "Tipo carrozzeria",
        tipo_raffreddamento: "Tipo raffreddamento",
        numero_registrazione: "Numero registrazione",
        disp_illuminazione_ok: "Dispositivi di segnalazione visiva, illuminazione e sonora nel rispetto delle norme e convalidati in sede di revisione",
        misura_pneumatici_ant: "Misura pneumatici anteriori",
        misura_pneumatici_post: "Misura pneumatici posteriori",
        tipo_freno_stazionamento: "Freno di stazionamento"
    };

    if (certificato && certificato.storico) {
        for (let i = 0; i < certificato.storico.length; i++) {
            let current = certificato.storico[i];
            let next = ((i + 1) < certificato.storico.length) ? certificato.storico[i + 1] : null;

            if (next) {
                let arrMod = [];

                let veicolo = JSON.parse(current.dati_veicolo);
                let veicoloPrev = JSON.parse(next.dati_veicolo);

                Object.keys(veicolo).forEach(key => {
                    if (veicolo[key] === true) {
                        veicolo[key] = 'Sì';
                    }
                    if (veicolo[key] === false) {
                        veicolo[key] = 'No';
                    }
                    if (veicoloPrev[key] === true) {
                        veicoloPrev[key] = 'Sì';
                    }
                    if (veicoloPrev[key] === false) {
                        veicoloPrev[key] = 'No';
                    }

                    if (veicoloPrev && labelCampiVeicolo[key] && veicolo[key] != veicoloPrev[key]) {
                        arrMod.push(<li><strong>{labelCampiVeicolo[key]}</strong> : da "<strong>{veicoloPrev[key]}</strong>" a
                            "<strong>{veicolo[key]}</strong>"</li>);
                    }
                });

                righeStorico.push({
                    data_modifica: getTimestampFormattato(current.data_modifica),
                    id_utente_modifica: current.id_utente_modifica,
                    utente_modifica: current.utente_modifica,
                    modifiche: (arrMod.length > 0 ?
                        <ul>
                            {
                                arrMod.map((el, idx) => <span key={idx}>{el}</span>)
                            }
                        </ul>
                        : 'Nessuna modifica')
                });
            } else {
                righeStorico.push({
                    data_modifica: getTimestampFormattato(current.data_modifica),
                    id_utente_modifica: current.id_utente_modifica,
                    utente_modifica: current.utente_modifica,
                    modifiche: <strong>Primo salvataggio</strong>
                });
            }
        }
    }

    return (
        <Fragment>
            <Separatore>
                Storico modifiche del veicolo
            </Separatore>
            <NsDataTable
                data={righeStorico}
                columns={[
                    {
                        name: 'Data modifica',
                        selector: row => row.data_modifica,
                        sortable: true,
                    },
                    {
                        name: 'Utente',
                        selector: row => (row.utente_modifica || ''),
                        sortable: true,
                    },
                    {
                        name: 'Modifiche',
                        selector: row => row.modifiche,
                        sortable: true,
                    }
                ]}
                addPagination={false}
                addFiltri={false}
            >
            </NsDataTable>
        </Fragment>
    );
}

export default infoStoricoCertificato;