import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {getAnno, getDataFormattata, getLabelValidita} from "../../utility/common";
import {RuoliContext} from "../../context/RuoliContext";

function ListQuote() {


    const [loadingData, setLoadingData] = useState(true);
    const [quote, setQuote] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloSocio} = useContext(RuoliContext);

    function getQuote() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?quoteVeicoli=1").then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        if (loadingData === true) {
            getQuote();
        }
    }, []);

    return (
        <CardContainer>
            <Card
                icon={'fas fa-credit-card'}
                title={'Quote'}
                getBottoniDx={() => {
                }}>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={quote}
                            columns={[
                                {
                                    name: 'Data',
                                    selector: row => getDataFormattata(row.data_pagamento),
                                    sortable: true,
                                },
                                {
                                    name: 'Oggetto',
                                    selector: row => row.tipo_versamento,
                                    sortable: true,
                                },
                                {
                                    name: 'Importo €',
                                    selector: row => row.importo,
                                    sortable: true,
                                },
                                {
                                    name: 'Anno validità',
                                    selector: row =>
                                        row.anno_validita ?
                                            <Fragment>
                                                {row.anno_validita}&nbsp;
                                                {
                                                    getLabelValidita(row)
                                                }
                                            </Fragment>
                                            : '',
                                    sortable: true,
                                },
                                {
                                    name: 'Modalità di pagamento',
                                    selector: row => row.modalita,
                                    sortable: true,
                                },
                                {
                                    name: 'Note',
                                    selector: row => row.note,
                                    sortable: true,
                                    visible: () => !isRuoloSocio()
                                }
                            ]}
                            addPagination={false}
                            addFiltri={false}
                        >
                        </NsDataTable>
                    )
                }
            </Card>
        </CardContainer>
    );
}

export default ListQuote;
