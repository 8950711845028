import React, {Fragment} from "react";
import {
    formattaInteroDocVeicolo,
    getBoolStampaCertificato,
    getDataFormattata,
    getStrStampaCertificato, getStrStampaCertificatoOldBool
} from "../../utility/common";
import Separatore from "../../components/Spearatore";

function infoVeicoloCrs({veicolo, certificato}) {

    return (
        <Fragment>
            <Separatore>
                Informazioni del veicolo
            </Separatore>
            <div className={"row"}>
                <div className={"col-md-10 text-end mb-3"}>
                    n° registrazione<br/>
                    {
                        certificato ?
                            <h4>
                                <strong>{getStrStampaCertificato(certificato.numero_registrazione) + certificato.suffisso}</strong>
                            </h4>
                            :
                            <h4>
                                <strong>{getStrStampaCertificato(veicolo.numero_registrazione) + veicolo.suffisso}</strong>
                            </h4>
                    }
                </div>
                <div className={"col-md-2 text-end mb-3"}>
                    data registrazione<br/>
                    <h4><strong>{veicolo.data_registrazione ? getDataFormattata(veicolo.data_registrazione) : '-'}</strong></h4>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione I: dati possessore del veicolo (1)</h5>
                    </div>
                    <div className={"col-md-12"}>
                        Cognome: <strong>{getStrStampaCertificato(veicolo.cognome)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Nome: <strong>{getStrStampaCertificato(veicolo.nome)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Luogo e data di
                        nascita: <strong>{getStrStampaCertificato(veicolo.luogo_nascita) + ' - ' + getDataFormattata(veicolo.data_nascita)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Indirizzo: <strong>{veicolo.indirizzo + ' ' + (veicolo.numero_civico || '') + ', ' + veicolo.localita + ' (' + veicolo.provincia + ')'}</strong>
                    </div>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione II: dati immatricolazione (se disponibili)</h5>
                    </div>
                    <div className={"col-md-12"}>
                        Anno 1° immatricolazione: <strong>{getStrStampaCertificato(veicolo.anno_prima_immatricolazione)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Targa precedente nazionale: <strong>{
                            getStrStampaCertificato(veicolo.targa) + (veicolo.targa_prec && veicolo.targa_prec !== '' ? (' già ' + getStrStampaCertificato(veicolo.targa_prec)) : '')
                        }</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Targa precedente estera: <strong>{getStrStampaCertificato(veicolo.targa_estera_prec)}</strong>
                    </div>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione III: dati generali</h5>
                    </div>
                    <div className={"col-md-12"}>
                        Anno di costruzione: <strong>{getStrStampaCertificato(veicolo.anno)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Fabbrica e tipo: <strong>{getStrStampaCertificato(veicolo.marca) + ' ' + getStrStampaCertificato(veicolo.tipo)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Omologazione (ove
                        ricorra): <strong>{getStrStampaCertificato(veicolo.omologazione)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Carrozzeria: <strong>{getStrStampaCertificato(veicolo.tipo_carrozzeria)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Tipo di veicolo: <strong>{getStrStampaCertificato(veicolo.tipo_veicolo)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Categoria (art. 47, C.d.S.): <strong>{getStrStampaCertificato(veicolo.categoria)}</strong>
                    </div>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione IV: dati identificativi</h5>
                    </div>
                    <div className={"col-md-12"}>
                        Numero telaio e relativa
                        posizione: <strong>{getStrStampaCertificato(veicolo.numero_telaio) + ' ' + getStrStampaCertificato(veicolo.posizione_telaio)}</strong>
                    </div>
                    <div className={"col-md-12"}>
                        Targhetta identificativa originaria: <strong>{getStrStampaCertificato(veicolo.targhetta_identificativa)}</strong>
                    </div>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione V: dati tecnici</h5>
                    </div>
                    <div className={"row border-top--1"}>
                        <div className={"col-md-6 border-right--1"}>
                            <strong>Motore:</strong>
                            <ul className={"dash"}>
                                <li>Tipo: <strong>{getStrStampaCertificato(veicolo.tipo_motore)}</strong></li>
                                <li>Posizione: <strong>{getStrStampaCertificato(veicolo.posizione_motore)}</strong></li>
                                <li>Numero: <strong>{getStrStampaCertificato(veicolo.numero_motore)}</strong></li>
                                <li>Tempi e
                                    combustione: <strong>{getStrStampaCertificato(veicolo.tempi) + ' tempi - ' + getStrStampaCertificato(veicolo.alimentazione)}</strong>
                                </li>
                                <li>Numero cilindri: <strong>{getStrStampaCertificato(veicolo.cilindri)}</strong></li>
                                <li>Cilindrata totale (cc): <strong>{formattaInteroDocVeicolo(veicolo.cilindrata)}</strong></li>
                                <li>Potenza massima (kw o
                                    CV): <strong>{formattaInteroDocVeicolo(veicolo.potenza)
                                        + (veicolo.um_potenza ? (veicolo.um_potenza == '1' ? ' CV' : ' KW') : '')}</strong> a <strong>{formattaInteroDocVeicolo(veicolo.potenza_giri_motore)}</strong> g/m
                                </li>
                                <li>Velocità massima (km/h): <strong>{formattaInteroDocVeicolo(veicolo.velocita)}</strong></li>
                            </ul>
                        </div>
                        <div className={"col-md-6"}>
                            <strong>Trasmissione:</strong>
                            <ul className={"dash"}>
                                <li>Tipo di trazione: <strong>{getStrStampaCertificato(veicolo.tipo_trazione)}</strong></li>
                                <li>Numero assi motore: <strong>{getStrStampaCertificato(veicolo.numero_assi)}</strong></li>
                                <li>Tipo cambio: <strong>{getStrStampaCertificato(veicolo.tipo_cambio)}</strong></li>
                                <li>Numero rapporti: <strong>{getStrStampaCertificato(veicolo.numero_rapporti)}</strong></li>
                                <li>Rapporto totale: <strong>{getStrStampaCertificato(veicolo.rapporto_tot)}</strong></li>
                                <li>Frizione: <strong>{getStrStampaCertificato(veicolo.tipo_frizione)}</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"row border-top--1"}>
                        <div className={"col-md-6 border-right--1"}>
                            <strong>Freni:</strong>
                            <ul className={"dash"}>
                                <li>Freno di servizio: <strong>{getStrStampaCertificato(veicolo.freno_servizio)}</strong></li>
                                <li>Freno di
                                    soccorso: <strong>{getStrStampaCertificatoOldBool(veicolo.freno_soccorso)}</strong>
                                </li>
                                <li>Freno di stazionamento: <strong>{getStrStampaCertificato(veicolo.tipo_freno_stazionamento)}</strong></li>
                                <li>Freno motore: <strong>{getStrStampaCertificato(veicolo.freno_motore)}</strong></li>
                                <li>Rallentatore: <strong>{getStrStampaCertificato(veicolo.rallentatore)}</strong></li>
                            </ul>
                        </div>
                        <div className={"col-md-6"}>
                            <strong>Massa e Dimensioni:</strong>
                            <ul className={"dash"}>
                                <li>Massa complessiva (peso totale)
                                    Kg: <strong>{formattaInteroDocVeicolo(veicolo.massa)}</strong></li>
                                <li>Tara Kg: <strong>{formattaInteroDocVeicolo(veicolo.tara)}</strong></li>
                                <li>Massa rimorchiabile Kg: <strong>{formattaInteroDocVeicolo(veicolo.massa_rim)}</strong></li>
                                <li>Massa massima 1°
                                    asse: <strong>{veicolo.massa_max_1asse ? formattaInteroDocVeicolo(veicolo.massa_max_1asse) : 'Solo per autocarri'}</strong>
                                </li>
                                <li>Massa massima 2°
                                    asse: <strong>{veicolo.massa_max_2asse ? formattaInteroDocVeicolo(veicolo.massa_max_2asse) : 'Solo per autocarri'}</strong>
                                </li>
                                <li>Massa massima enn.mo
                                    asse: <strong>{veicolo.massa_max_n_asse ? formattaInteroDocVeicolo(veicolo.massa_max_n_asse) : 'Solo per autocarri'}</strong>
                                </li>
                                <li>Lunghezza massima mm: <strong>{formattaInteroDocVeicolo(veicolo.lunghezza)}</strong></li>
                                <li>Larghezza massima mm: <strong>{formattaInteroDocVeicolo(veicolo.larghezza)}</strong></li>
                                <li>Altezza massima mm: <strong>{formattaInteroDocVeicolo(veicolo.altezza)}</strong></li>
                                <li>Numero assi: <strong>{formattaInteroDocVeicolo(veicolo.numero_assi)}</strong></li>
                                <li>Interasse(i) mm: <strong>{formattaInteroDocVeicolo(veicolo.interasse)}</strong></li>
                                <li>Sbalzo anteriore mm: <strong>{formattaInteroDocVeicolo(veicolo.sbalzo_ant)}</strong></li>
                                <li>Sbalzo posteriore mm: <strong>{formattaInteroDocVeicolo(veicolo.sbalzo_post)}</strong></li>
                                <li>Carreggiata anteriore
                                    mm: <strong>{formattaInteroDocVeicolo(veicolo.carreggiata_asse_ant)}</strong></li>
                                <li>Carreggiata posteriore
                                    mm: <strong>{formattaInteroDocVeicolo(veicolo.carreggiata_asse_post)}</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"row border-top--1"}>
                        <div className={"col-md-6 border-right--1"}>
                            <strong>Pneumatici e sospensioni:</strong> (specificare per ogni asse)
                            <ul className={"dash"}>
                                <li>Misure pneumatici ant. e
                                    post.: <strong>{formattaInteroDocVeicolo(veicolo.misura_pneumatici_ant) + ' - ' + formattaInteroDocVeicolo(veicolo.misura_pneumatici_post)
                                        + (veicolo.altri_pneumatici && veicolo.altri_pneumatici !== '' ? (' oppure ' + formattaInteroDocVeicolo(veicolo.altri_pneumatici)) : '')}</strong>
                                </li>
                                <li>Sospensioni anteriori: <strong>{getStrStampaCertificato(veicolo.tipo_sosp_ant)}</strong></li>
                                <li>Sospensioni posteriori: <strong>{getStrStampaCertificato(veicolo.tipo_sosp_post)}</strong></li>
                            </ul>
                        </div>
                        <div className={"col-md-6"}>
                            <strong>Carrozzeria e Numero posti:</strong>
                            <ul className={"dash"}>
                                <li>Numero porte: <strong>{formattaInteroDocVeicolo(veicolo.num_porte)}</strong></li>
                                <li>Posti anteriori: <strong>{formattaInteroDocVeicolo(veicolo.num_posti_ant)}</strong></li>
                                <li>Posti totali: <strong>{formattaInteroDocVeicolo(veicolo.num_posti_tot)}</strong></li>
                                <li>Posti di guida: <strong>{getStrStampaCertificato(veicolo.posto_guida)}</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"row border-top--1"}>
                        <div className={"col-md-6 border-right--1"}>
                            <strong>Dispositivi di segnalazione visiva e di illuminazione:</strong>
                            <ul className={"dash"}>
                                <li>Proiettore
                                    abbagliante: <strong>{getBoolStampaCertificato(veicolo.proiett_abbagliante)}</strong>
                                </li>
                                <li>Proiettore
                                    anabbagliante: <strong>{getBoolStampaCertificato(veicolo.proiett_anabagliante)}</strong>
                                </li>
                                <li>Proiettore fendinebbia
                                    anteriore: <strong>{getBoolStampaCertificato(veicolo.fendinebbia_ant)}</strong></li>
                                <li>Luce di posizione
                                    anteriore: <strong>{getBoolStampaCertificato(veicolo.luce_pos_ant)}</strong></li>
                                <li>Luce di posizione
                                    laterale: <strong>{getBoolStampaCertificato(veicolo.luce_pos_lat)}</strong></li>
                                <li>Indicatore di direzione
                                    anteriore: <strong>{getBoolStampaCertificato(veicolo.ind_dir_ant)}</strong></li>
                                <li>Indicatore di direzione
                                    laterale: <strong>{getBoolStampaCertificato(veicolo.ind_dir_lat)}</strong></li>
                                <li>Indicatore di direzione
                                    posteriore: <strong>{getBoolStampaCertificato(veicolo.ind_dir_post)}</strong></li>
                                <li>Luce di posizione
                                    posteriore: <strong>{getBoolStampaCertificato(veicolo.luce_pos_post)}</strong></li>
                                <li>Luce di arresto: <strong>{getBoolStampaCertificato(veicolo.luce_arresto)}</strong>
                                </li>
                                <li>Catadiottro
                                    posteriore: <strong>{getBoolStampaCertificato(veicolo.catadiottro_post)}</strong>
                                </li>
                                <li>Proiettore per la
                                    retromarcia: <strong>{getBoolStampaCertificato(veicolo.proiettore_retromarcia)}</strong>
                                </li>
                                <li>Proiettore fendinebbia
                                    posteriore: <strong>{getBoolStampaCertificato(veicolo.fendinebbia_post)}</strong>
                                </li>
                                <li>Luce di ingombro
                                    anteriore: <strong>{getBoolStampaCertificato(veicolo.luce_ingombro_ant)}</strong>
                                </li>
                                <li>Luce di ingombro
                                    posteriore: <strong>{getBoolStampaCertificato(veicolo.luce_ingombro_post)}</strong>
                                </li>
                                <li>Catadiottro
                                    laterale: <strong>{getBoolStampaCertificato(veicolo.catadiottro_lat)}</strong></li>
                                <li>Dispositivo di illuminazione
                                    targa: <strong>{getBoolStampaCertificato(veicolo.disp_illum_targa)}</strong></li>
                                <li>Regolamentari: <strong>{getBoolStampaCertificato(veicolo.disp_illuminazione_ok)}</strong>
                                </li>
                            </ul>
                        </div>
                        <div className={"col-md-6"}>
                            <strong>Vetri e retrovisori:</strong>
                            <ul className={"dash"}>
                                <li>Parabrezza: <strong>{getStrStampaCertificato(veicolo.parabrezza)}</strong></li>
                                <li>Vetri laterali: <strong>{getStrStampaCertificato(veicolo.vetri_lat)}</strong></li>
                                <li>Lunotto posteriore: <strong>{getStrStampaCertificato(veicolo.lunotto_post)}</strong></li>
                                <li>Retrovisore
                                    interno: <strong>{getBoolStampaCertificato(veicolo.retrovisore_interno)}</strong>
                                </li>
                                <li>Retrovisore lato
                                    sinistro: <strong>{getBoolStampaCertificato(veicolo.retrovisore_sx)}</strong></li>
                                <li>Retrovisore lato
                                    destro: <strong>{getBoolStampaCertificato(veicolo.retrovisore_dx)}</strong></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"row border-top--1"}>
                        <div className={"col-md-6 border-right--1"}>
                            <strong>Dispositivo
                                silenziatore: {getBoolStampaCertificato(veicolo.disp_sil_legge)}</strong>
                        </div>
                        <div className={"col-md-6"}>
                            <strong>Organi di
                                traino: {getBoolStampaCertificato(veicolo.disp_sil_org_traino)}</strong>
                        </div>
                    </div>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione VI: parti del veicolo sostituite non conformi all'originale</h5>
                    </div>
                    <div className={"col-md-12"}>
                        <strong>{veicolo.parti_sostituite ? veicolo.parti_sostituite : 'Nessuna'}</strong>
                    </div>
                </div>
                <div className={"rfi-sezione-documento"}>
                    <div className={"col-md-12 mb-2"}>
                        <h5>Sezione VII: dichiarazioni acquisite (art. 4 commi 2 e 3 del Decreto)</h5>
                    </div>
                    <div className={"col-md-12"}>
                        <p>
                            Per il veicolo/complesso di veicoli è stata acquisita:<br/>
                            1) la dichiarazione di recupero e/o ripristino e/o manutenzione e/o verifica di cui all’art.
                            4, comma 2 del Decreto, rilasciata dalla/e seguente/i officina/e:
                        </p>
                        <ul>
                            <li>Denominazione: <strong>{getStrStampaCertificato(veicolo.officina)}</strong></li>
                            <li>Sede: <strong>{getStrStampaCertificato(veicolo.sede_officina)}</strong></li>
                            <li>Con IVA: <strong>{getStrStampaCertificato(veicolo.iva_officina)}</strong></li>
                        </ul>
                        <p>
                            2) la dichiarazione di corretta conservazione del veicolo di cui all’art. 4 comma 3 del
                            Decreto:<br/>
                            <strong>{getStrStampaCertificato(veicolo.stato_veicolo_imm)}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default infoVeicoloCrs;