import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import {Field} from "formik";
import 'react-medium-image-zoom/dist/styles.css'
import LabelErroreCampo from "../../components/LabelErroreCampo";
import ContainerFotoCaricata from "../../components/ContainerFotoCaricata";
import ModalConfirm from "../../components/ModalConfirm";
import NsFilePond from "../../components/NsFilePond";
import {ClipLoader} from "react-spinners";

function UplFotoVeicolo({
                            veicolo,
                            label,
                            tipo,
                            immagineDefault,
                            obbligatoria,
                            errors,
                            touched,
                            submitCount,
                            formikRef
                        }) {

    const [foto, setFoto] = useState(veicolo.foto?.filter(foto => foto.tipo === tipo))
    const [show, setShow] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [infoDoc, setInfoDoc] = useState();
    const token = localStorage.getItem('rfi-login');

    const url = process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + veicolo.id_veicolo + '/foto/';

    useEffect(() => {
        if (foto && foto.length > 0) {
            formikRef.current.setFieldValue("foto_" + tipo, '1')

            setLoadSpinner(true);
            axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + veicolo.id_veicolo + '/documento/' + foto[0].id_documento_veicolo)
                .then((response) => {
                    setLoadSpinner(false);
                    setInfoDoc(response.data.data)
                });

        } else {
            formikRef.current.setFieldValue("foto_" + tipo, '')
        }
    }, [foto]);

    function getContainerFotoCaricata() {

        return <ContainerFotoCaricata
            label={label}
            content_type={infoDoc.content_type}
            contenuto={infoDoc.contenuto}
            isEliminabile={true}
            nome={infoDoc.nome_file_originale}
            eliminaFoto={() => {
                setShow(true)
            }}
        />;
    }

    function getFilePondUploadFoto() {
        return <NsFilePond
            multiple={false}
            id={tipo}
            acceptedFileTypes={["image/x-png", "image/png", "image/gif", "image/jpeg", "image/bmp", "image/svg+xml"
                , "image/heic", "application/pdf", "application/pdf", "application/msword"
                , "application/vnd.openxmlformats-", "officedocument.wordprocessingml.document"]}
            handleFiles={(file) => {
                setLoadSpinner(true);

                const formData = new FormData();
                formData.append("files", file);

                axios
                    .post(url + ('?tipo=' + tipo), formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'jwt': token
                        },
                    })
                    .then((response) => {
                        setFoto([response.data])
                        setLoadSpinner(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoadSpinner(false);
                    });
            }}
        >
            <div>
                <p>{label}</p>
            </div>
            <div className="ns-foto--gray">
                {
                    loadSpinner === true ?
                        <ClipLoader
                            color={'#355d98'}
                            loading={loadSpinner}
                            size={80}/>
                        :
                        <img src={immagineDefault}/>
                }
            </div>
        </NsFilePond>
    }

    return (
        <Fragment>
            <ModalConfirm
                show={show}
                handleClose={() => setShow(false)}
                handleOk={() => {
                    setShow(false);
                    if (foto && foto.length > 0) {
                        axios.delete(url + foto[0].id_documento_veicolo + '?tipo=' + tipo).then((response) => {
                            setFoto([])
                            formikRef.current.setFieldValue("foto_" + tipo, '')
                        });
                    }
                }}
                testo={"Sicuro di voler eliminare la foto?"}/>
            <Field name={"foto_" + tipo} type={"hidden"}
                   validate={(value) => {
                       if ((!value || value === '') && obbligatoria) {
                           return 'Inserire foto'
                       }
                   }}/>
            <LabelErroreCampo errors={errors}
                              touched={touched}
                              name={"foto_" + tipo}
                              submitCount={submitCount}/>
            {
                foto && foto.length > 0 && infoDoc ?
                    getContainerFotoCaricata()
                    : getFilePondUploadFoto()
            }
        </Fragment>
    )
}

export default UplFotoVeicolo;
