import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../../components/ModalConfirm";
import NsDataTable from "../../../components/tabelle/NsDataTable";
import {
    convertiDateInDataPerDb,
    formattaImporto,
    getTimestampFormattato,
} from "../../../utility/common";
import Pager from "../../../components/Pager";
import {Form, Formik} from "formik";
import Translate from "../../../components/Translate";
import NsDatePicker from "../../../components/NsDatePicker";
import NsAccordion from "../../../components/accordion/NsAccordion";
import {FiltersContext} from "../../../context/FiltersContext";

function ListMovimenti() {

    const [loadingData, setLoadingData] = useState(true);
    const [movimenti, setMovimenti] = useState([]);
    const [totMovimenti, setTotMovimenti] = useState(0);
    const [tipiMovimento, setTipiMovimento] = useState([]);
    const [conti, setConti] = useState([]);
    const [causali, setCausali] = useState([]);
    const [movimentoSel, setMovimentoSel] = useState(null);
    const [show, setShow] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {filtroMovimenti, initFiltroMovimenti, updateFiltroMovimenti} = useContext(FiltersContext);

    function getFiltriMovimenti() {
        let f = '?pag=' + pagina;
        if (filtroMovimenti.anno && filtroMovimenti.anno !== '') {
            f += '&anno=' + filtroMovimenti.anno;
        }
        if (filtroMovimenti.mese && filtroMovimenti.mese !== '') {
            f += '&mese=' + filtroMovimenti.mese;
        }
        if (filtroMovimenti.abarth && filtroMovimenti.abarth !== '') {
            f += '&abarth=' + filtroMovimenti.abarth;
        }
        if (filtroMovimenti.nominativo && filtroMovimenti.nominativo !== '') {
            f += '&nominativo=' + filtroMovimenti.nominativo;
        }
        if (filtroMovimenti.rif_fattura && filtroMovimenti.rif_fattura !== '') {
            f += '&rif_fattura=' + filtroMovimenti.rif_fattura;
        }
        if (filtroMovimenti.note && filtroMovimenti.note !== '') {
            f += '&note=' + filtroMovimenti.note;
        }
        if (filtroMovimenti.id_tipo_movimento && filtroMovimenti.id_tipo_movimento !== '') {
            f += '&id_tipo_movimento=' + filtroMovimenti.id_tipo_movimento;
        }
        if (filtroMovimenti.id_conto && filtroMovimenti.id_conto !== '') {
            f += '&id_conto=' + filtroMovimenti.id_conto;
        }
        if (filtroMovimenti.id_causale_movimento && filtroMovimenti.id_causale_movimento !== '') {
            f += '&id_causale_movimento=' + filtroMovimenti.id_causale_movimento;
        }
        if (filtroMovimenti.importo && filtroMovimenti.importo !== '') {
            f += '&importo=' + filtroMovimenti.importo;
        }
        if (filtroMovimenti.data_da && filtroMovimenti.data_da !== '') {
            f += '&data_da=' + convertiDateInDataPerDb(filtroMovimenti.data_da);
        }
        if (filtroMovimenti.data_a && filtroMovimenti.data_a !== '') {
            f += '&data_a=' + convertiDateInDataPerDb(filtroMovimenti.data_a);
        }
        return f;
    }

    function getMovimenti() {
        let f = getFiltriMovimenti();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/" + f).then((response) => {
            setMovimenti(response.data.data);
            setTotPagine(response.data.pages);
            setTotMovimenti(response.data.tot);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getTipiMovimento() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/tipo-movimento/").then((response) => {
            setTipiMovimento(response.data.data);
            endChiamata()
        });
    }

    function getConti() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/conto/").then((response) => {
            setConti(response.data.data);
            endChiamata()
        });
    }

    function getCausali() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/causale-movimento/")
            .then((response) => {
                setCausali(response.data.data);
                endChiamata()
            });
    }

    useEffect(() => {
        if (loadingData === true) {
            getTipiMovimento();
            getConti();
            getCausali();
        }

        getMovimenti()
    }, [pagina]);

    const openModalDel = (utente) => {
        setMovimentoSel(utente)
        setShow(true);
    }

    function getDownloadCsv() {
        let f = getFiltriMovimenti();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/csv/" + f).then((response) => {
            endChiamata()
            let resp = response.data.data;
            if (resp) {
                var a = document.createElement("a");
                a.href = "data:text/csv;base64," + resp;
                a.download = 'elenco-movimenti-' + convertiDateInDataPerDb(new Date()) + '.csv';
                a.click(); //Downloaded file
            }
        });
    }

    return (
        <CardContainer>
            <Card
                icon={'far fa-credit-card'}
                title={'Elenco Movimenti'}
                getBottoniDx={() => <Fragment>
                    <Link to="/upd-movimento" className={"btn btn-card"}>
                        <i className="fas fa-plus"/> Aggiungi
                    </Link>
                    <Link to="/stampa-movimenti" className={"btn btn-card"}>
                        <i className="fas fa-print"/> Stampe
                    </Link>
                </Fragment>
                }>
                <ModalConfirm
                    show={show}
                    handleClose={() => setShow(false)}
                    handleOk={() => {
                        setShow(false);
                        startChiamata()
                        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/" + movimentoSel.id_movimento + '?pag=' + pagina).then((response) => {
                            setMovimenti(response.data.data);
                            setTotPagine(response.data.pages);
                            setTotMovimenti(response.data.tot);
                            endChiamata()
                        });
                    }}
                    testo={"Sicuro di voler eliminare il movimento: " + (movimentoSel
                        && (movimentoSel.id_movimento)) + "?"}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <Fragment>
                            <Formik
                                onSubmit={getMovimenti}
                                initialValues={{}}>
                                <Form>
                                    <div className={'row pb-2'}>
                                        <div className={'col-md-1'}>
                                            <input
                                                type={"text"}
                                                id={"anno"}
                                                placeholder={"Anno"}
                                                className="form-control"
                                                value={filtroMovimenti.anno}
                                                onChange={(ev) => {
                                                    updateFiltroMovimenti("anno", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-1'}>
                                            <input
                                                type={"text"}
                                                id={"mese"}
                                                placeholder={"Mese"}
                                                className="form-control"
                                                value={filtroMovimenti.mese}
                                                onChange={(ev) => {
                                                    updateFiltroMovimenti("mese", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <select
                                                id={"abarth"}
                                                className="form-control form-select"
                                                value={filtroMovimenti.abarth}
                                                onChange={(ev) => {
                                                    updateFiltroMovimenti("abarth", ev.target.value);
                                                }}>
                                                <option value={''}>Tutti i registri</option>
                                                <option value={'0'}>RFI</option>
                                                <option value={'1'}>Abarth</option>
                                            </select>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <button type={"submit"} className={"btn btn-dark"}>
                                                <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                                            </button>
                                            <button type="button" className={"btn btn-dark"} onClick={() => {
                                                initFiltroMovimenti();
                                            }}>
                                                <i className="fas fa-trash"></i> <Translate>Azzera filtri</Translate>
                                            </button>
                                            <button className={"btn btn-card"}
                                                    type={"button"}
                                                    onClick={getDownloadCsv}
                                                    data-toggle="tooltip" title="Download CSV">
                                                <i className="fas fa-download"/> <span
                                                className={'cmms-display-mobile'}>Download CSV</span>
                                            </button>
                                        </div>
                                    </div>
                                    <NsAccordion
                                        titolo={'Filtri'}
                                        label={'fas fa-filter'}
                                        defaultKey={""}
                                    >
                                        <div className={'row'}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"nominativo"}>Nominativo:</label>
                                                <input
                                                    type={"text"}
                                                    id={"nominativo"}
                                                    className="form-control"
                                                    value={filtroMovimenti.nominativo}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("nominativo", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"rif_fattura"}>Rif. fattura:</label>
                                                <input
                                                    type={"text"}
                                                    id={"rif_fattura"}
                                                    className="form-control"
                                                    value={filtroMovimenti.rif_fattura}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("rif_fattura", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"note"}>Note:</label>
                                                <input
                                                    type={"text"}
                                                    id={"note"}
                                                    className="form-control"
                                                    value={filtroMovimenti.note}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("note", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"id_tipo_movimento"}>Tipo movimento:</label>
                                                <select
                                                    id={"id_tipo_movimento"}
                                                    className="form-control form-select"
                                                    value={filtroMovimenti.id_tipo_movimento}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("id_tipo_movimento", ev.target.value);
                                                    }}>
                                                    <option value={""}>Tutti i tipi</option>
                                                    {
                                                        tipiMovimento && tipiMovimento.map(tm => <option
                                                            value={tm.id_tipo_movimento}
                                                            key={tm.id_tipo_movimento}>{tm.descrizione}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"id_conto"}>Conto:</label>
                                                <select
                                                    id={"id_conto"}
                                                    className="form-control form-select"
                                                    value={filtroMovimenti.id_conto}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("id_conto", ev.target.value);
                                                    }}>
                                                    <option value={""}>Tutti i conti</option>
                                                    {
                                                        conti && conti.map(c => <option
                                                            value={c.id_conto} key={c.id_conto}>{c.descrizione}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"id_causale_movimento"}>Causali:</label>
                                                <select
                                                    id={"id_causale_movimento"}
                                                    className="form-control form-select"
                                                    value={filtroMovimenti.id_causale_movimento}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("id_causale_movimento", ev.target.value);
                                                    }}>
                                                    <option value={""}>Tutte le causali</option>
                                                    {
                                                        causali && causali.map(cau => <option
                                                            value={cau.id_causale_movimento}
                                                            key={cau.id_causale_movimento}>{'[' + cau.id_causale_movimento + '] - ' + cau.descrizione}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"importo"}>Importo:</label>
                                                <input
                                                    id={"importo"}
                                                    className="form-control rfi-number-no-arrows"
                                                    type={"number"}
                                                    step="0.01"
                                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                    value={filtroMovimenti.importo}
                                                    onChange={(ev) => {
                                                        updateFiltroMovimenti("importo", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_da"}>Dal:</label>
                                                <NsDatePicker
                                                    nome={"data_da"}
                                                    dataSelezionata={filtroMovimenti.data_da}
                                                    maxDate={filtroMovimenti.data_a}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroMovimenti("data_a", date);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_a"}>Al:</label>
                                                <NsDatePicker
                                                    nome={"data_a"}
                                                    dataSelezionata={filtroMovimenti.data_a}
                                                    minDate={filtroMovimenti.data_da}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroMovimenti("data_da", date);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </NsAccordion>
                                </Form>
                            </Formik>
                            <NsDataTable
                                data={movimenti}
                                totRighe={totMovimenti}
                                columns={[
                                    {
                                        name: 'ID',
                                        selector: row => row.id_movimento,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Data',
                                        selector: row => getTimestampFormattato(row.data_movimento),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Tipo',
                                        selector: row => <span
                                            className={row.id_tipo_movimento === 'VERSAMENTO' ? 'ns-span--verde' : 'ns-span--rosso'}>{row.tipo_movimento}</span>,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Importo',
                                        selector: row => '€ ' + formattaImporto(row.importo),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Conto',
                                        selector: row => row.conto,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Causale',
                                        selector: row => row.causale_movimento && row.causale_movimento !== '' ? row.causale_movimento : row.id_causale_movimento,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Riferimento persona',
                                        selector: row => row.nominativo,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Riferimento fattura',
                                        selector: row => row.rif_fattura,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Note',
                                        selector: row => row.note,
                                        sortable: true,
                                    },
                                    {
                                        name: 'RFI / Abarth',
                                        selector: row => row.abarth == '1' ?
                                            <span className={"rfi-etichetta rfi-etichetta--arancione"}>Abarth</span>
                                            : <span className={"rfi-etichetta rfi-etichetta--blu"}>RFI</span>,
                                        sortable: true,
                                    },
                                    {
                                        name: '',
                                        selector: row => <Fragment>
                                            <div className="btn-group" role="group"
                                                 aria-label="Button group with nested dropdown">
                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button"
                                                            className="btn btn-dark dropdown-toggle"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa-solid fa-bars"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <li>
                                                            <Link to={"/upd-movimento/" + row.id_movimento}
                                                                  className="dropdown-item"
                                                                  data-toggle="tooltip" title="Modifica">
                                                                <i className="fas fa-pen"/> Modifica
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Elimina"
                                                                    onClick={() => {
                                                                        openModalDel(row)
                                                                    }}>
                                                                <i className="fas fa-trash"/> Elimina
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Fragment>,
                                        sortable: false,
                                        right: true,
                                    }
                                ]}
                                addPagination={false}
                                addFiltri={false}
                            >
                            </NsDataTable>
                            {
                                totPagine ?
                                    (
                                        <Pager
                                            pagina={pagina}
                                            totale={totPagine}
                                            handlerCambioPagina={(nuovaPagina) => {
                                                setPagina(nuovaPagina)
                                            }}
                                        >
                                        </Pager>
                                    ) : ''
                            }
                        </Fragment>
                    )}
            </Card>
        </CardContainer>
    );
}

export default ListMovimenti;
