import React, {Fragment, useEffect} from "react";
import '../../../node_modules/filepond/dist/filepond.min.css'
import "../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../node_modules/filepond-plugin-image-overlay/dist/filepond-plugin-image-overlay.css";
import 'react-medium-image-zoom/dist/styles.css'
import ContainerFoto from "../../components/ContainerFoto";

function GalleryVeicolo({veicolo}) {

    useEffect(() => {
    }, []);

    return (
        <Fragment>
            <div className="row g-3">
                {
                    veicolo.foto.length === 0 &&
                    <h5>Nessuna foto caricata</h5>
                }
                {
                    veicolo.foto.filter(f => parseInt(f.foto) === 1 ).map((f, idx) =>
                        <div className={"col-md-6"} key={idx}>
                            <ContainerFoto
                                label={f.label}
                                content_type={f.content_type}
                                nome={f.nome_file_originale}
                                endpointContent={process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + veicolo.id_veicolo + '/documento/' + f.id_documento_veicolo}
                            />
                        </div>
                    )
                }
            </div>
        </Fragment>
    )
}

export default GalleryVeicolo;
