import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import {Field, Form, Formik} from "formik";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import axios from "axios";

function DichiarazioneRespCrs() {
    const {idUsr, idCertificato} = useParams()

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [certificato, setCertificato] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const history = useHistory();
    const formikRef = useRef();

    function getCertificato() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato).then((response) => {
            setCertificato(response.data.data);

            if (formikRef.current) {
                formikRef.current.setFieldValue("resp_crs", response.data.data.resp_crs || '');
            }

            endChiamata();
        });
    }

    useEffect(() => {
        if (idCertificato && idCertificato !== '') {
            getCertificato()
        }
    }, [idCertificato]);

    return (
        <Card
            icon={'fas fa-check'}
            title={"Dichiarazione di assunzione di responsabilità relativa alla richiesta di emissione del CRSeC"}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button
                        type="button"
                        id="salva"
                        name="salva"
                        className="btn btn-card"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.handleSubmit()
                            }
                        }}>
                        <i className="fas fa-save"/> Salva e procedi
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <div className={"row mb-4"}>
                <div className={"col-md-12"}>
                    <p>
                        Il sottoscritto,
                    </p>
                    <ul>
                        <li>nella sua qualità di legittimo proprietario / possessore del veicolo i cui dati tecnici
                            sono elencati e specificati e le cui fotografie sono allegate alla presente richiesta, a
                            noma dell'art. 47, comma 4, del DPR 445 del 28 dicembre 2000,
                        </li>
                        <li>è consapevole che i dati, le fotografie e gli allegati forniti nella presente richiesta
                            sono
                            destinati ad essere riversati in atti della Pubblica Amministrazione;
                        </li>
                        <li>della responsabilità penale che gli incombe in caso di dichiarazioni mendaci ed in caso
                            di
                            inveritiera rappresentazione fotografica della cosa oggetto della richiesta,
                            responsabilità
                            richiamata dallo stesso DPR 445/2000;
                        </li>
                        <li>dichiara che il veicolo, cui la presente richiesta si riferisce, possiede le
                            caratteristiche
                            originali di fabbricazione così come sono descritte e documentate;
                        </li>
                        <li>che le fotografie allegate alla presente istanza si riferiscono, tutte, al veicolo del
                            quale sono
                            stati contestualmente forniti i dati tecnici e gli allegati e che esse ne rispecchiano
                            fedelmente lo stato fisico alla data della richiesta,
                        </li>
                        <li>e che i dati tecnici e storici del veicolo, nonché gli allegati, così come sono stati
                            forniti a
                            questo Registro Fiat Italiano ne descrivono in modo fedele tanto le condizioni materiali
                            quanto
                            lo stato giuridico per quanto attiene la legittimità della sua proprietà e del suo
                            possesso.
                        </li>
                    </ul>
                </div>
                <Formik
                    initialValues={{
                        resp_crs: '0'
                    }}
                    innerRef={formikRef}
                    onSubmit={values => {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato + '/resp-crs/',
                                values)
                            .then((response) => {
                                endChiamata()
                                if (response.data.result !== 0) {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                } else {
                                    history.push('/doc-aggiuntivi-cert/' + idUsr + '/' + idCertificato);
                                }
                            });
                    }}
                >
                    {({errors, touched, values, submitCount}) => (
                        <Form>
                            <div className="col-md-12 mt-4">
                                <div className="rfi-va--center rfi-display--flex">
                                    <Field
                                        type="checkbox"
                                        name={"resp_crs"}
                                        className="form-check-input"
                                        checked={values['resp_crs'] == '1'}
                                        onChange={e => {
                                            formikRef.current.setFieldValue('resp_crs', e.target.checked ? '1' : '0')
                                        }}
                                        validate={(value) => {
                                            if (value != '1') {
                                                return 'È necessario accettare la Dichiarazione di assunzione di responsabilità'
                                            }
                                        }}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    <label htmlFor={"resp_crs"} className="form-label">Accetto</label>
                                </div>
                                <LabelErroreCampo errors={errors} touched={touched} name={"resp_crs"}
                                                  submitCount={submitCount}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Card>
    );
}

export default DichiarazioneRespCrs;