import React, {useContext, useEffect} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {AuthContext} from "../context/AuthContext";
import {FiltersContext} from "../context/FiltersContext";

const PrivateRoute = ({component: Component, ...rest}) => {

    const authContext = useContext(AuthContext);
    const {routesToKeepSoci, routesToKeepVeicoli, routesToKeepCertificati, routesToKeepMovimenti, initFiltroSoci,
        initFiltroVeicoli, initFiltroCertificati, initFiltroMovimenti} = useContext(FiltersContext);
    const isLoggedIn = authContext.isLogged();

    var hash = document.location.hash || ''
    if (hash) {
        hash = hash.replaceAll('#', '')
    }

    useEffect(() => {
        let sociOk = false;
        let veicoliOk = false;
        let certificatiOk = false;
        let movimentiOk = false;

        routesToKeepSoci.forEach(route => {
            if (hash.indexOf(route) !== - 1) {
                sociOk = true;
            }
        });
        if (sociOk === false) {
            initFiltroSoci();
        }

        routesToKeepVeicoli.forEach(route => {
            if (hash.indexOf(route) !== - 1) {
                veicoliOk = true;
            }
        });
        if (veicoliOk === false) {
            initFiltroVeicoli();
        }

        routesToKeepCertificati.forEach(route => {
            if (hash.indexOf(route) !== - 1) {
                certificatiOk = true;
            }
        });
        if (certificatiOk === false) {
            initFiltroCertificati();
        }

        routesToKeepMovimenti.forEach(route => {
            if (hash.indexOf(route) !== - 1) {
                movimentiOk = true;
            }
        });
        if (movimentiOk === false) {
            initFiltroMovimenti();
        }
    }, [hash]);

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/login', state: {
                            from: props.location,
                            hash: hash
                        }
                    }}/>
                )
            }
        />
    )
}

export default PrivateRoute