import React, {Fragment, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import ModalDocVeicoloCert from "./ModalDocVeicoloCert";
import TabellaDocAggiuntiviCert from "./TabellaDocAggiuntiviCert";
import ModalMsg from "../../components/ModalMsg";
import {
    TEMPLATE_CERT_DICH_SOST_ATTO_NOTORIETA,
    TEMPLATE_CERT_OFFICINA,
    TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE, TIPO_REQ_VEICOLO_CRS
} from "../../utility/common";
import {RuoliContext} from "../../context/RuoliContext";

function DocAggiuntiviCert() {
    const {idUsr, idCertificato} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [showModalDocumenti, setShowModalDocumenti] = useState(false);
    const [certificato, setCertificato] = useState();
    const [tipiDocumento, setTipiDocumento] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [veicolo, setVeicolo] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    const history = useHistory();

    function getCertificato() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato).then((response) => {
            if (response.data.data) {
                setCertificato(response.data.data);
                getVeicolo(response.data.data.id_veicolo);
            }
            setLoadingData(false);
            endChiamata();
        });
    }

    function getVeicolo(idVeicolo) {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + idVeicolo).then((response) => {
            setVeicolo(response.data.data);
            endChiamata();
        });
    }

    function getTipiDocumentoVeicolo() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata();
        });
    }

    useEffect(() => {
        if (loadingData) {
            getTipiDocumentoVeicolo();

            if (idCertificato && idCertificato !== '') {
                getCertificato()
            }
        }
    }, [idCertificato]);

    const checkDocumentiObbligatori = () => {
        let okAll = true;

        let docObbl = tipiDocumento.filter(doc => {
            let obbligatorio = doc.obbligatorio == '1';

            if (veicolo && doc.condizione && doc.condizione === 'dichiarazione_officina') {
                obbligatorio = obbligatorio || veicolo.dichiarazione_officina == '1';
            }
            return obbligatorio;
        });
        if (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) {
            docObbl.forEach(doc => {
                let ok = false;

                if (certificato && certificato.documenti && certificato.documenti.length > 0) {
                    certificato.documenti.forEach(docCert => {
                        if (docCert.tipo === doc.codice) {
                            ok = true;
                        }
                    })
                    okAll = okAll && ok;
                } else {
                    okAll = false;
                }
            })
        }

        if (okAll === true) {
            /*
              * Per i CRS per i veicoli/motoveicoli circolanti o registrazione all’albo + richiesta CRS veicoli
              * circolanti deve essere obbligatoria la copia della revisione (doc_revisione) o, in assenza, la dichiarazione di officina (dic_int_storico).
             */
            if ((isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) && certificato && (certificato.id_tipo_certificato == TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE
                    || certificato.id_tipo_certificato == TIPO_REQ_VEICOLO_CRS)) {
                let docRichiesti = certificato.documenti.filter(doc => doc.tipo === 'doc_revisione' || doc.tipo === 'dic_int_storico');
                if (docRichiesti.length === 0) {
                    setModalMsg("È necessario aggiungere la copia della revisione o, in assenza, la dichiarazione di officina (dichiarazione di verifica del veicolo di interesse storico e collezionistico)");
                    setShow(true);
                } else {
                    history.push("/quota-cert/" + idCertificato);
                }
            } else {
                history.push("/quota-cert/" + idCertificato);
            }
        } else {
            let descrDocObbl = docObbl.map(doc => doc.descrizione).join(', ');
            setModalMsg("È necessario aggiungere i seguenti documenti: " + descrDocObbl);
            setShow(true);
        }
    }

    function scaricaDichiarazioneSostAttoNot() {
        scaricaModulo(TEMPLATE_CERT_DICH_SOST_ATTO_NOTORIETA);
    }

    function scaricaDichiarazioneOfficina() {
        scaricaModulo(TEMPLATE_CERT_OFFICINA);
    }

    function scaricaModulo(tipo) {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/modulo/" + tipo).then((response) => {
            let resp = response.data;
            if (resp) {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
                a.download = resp.nome_file_originale; //File name Here
                a.click(); //Downloaded file
            }
            endChiamata()
        });
    }

    return (
        <Card
            icon={'fas fa-id-card'}
            title={"Documenti aggiuntivi"}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.push("/upd-certificato/" + idUsr + '/' + idCertificato);
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            setShowModalDocumenti(true);
                        }}
                    >
                        <i className="fas fa-plus-circle"/> Aggiungi
                    </button>
                    <button
                        type="button"
                        id="salva"
                        name="salva"
                        className="btn btn-card"
                        onClick={checkDocumentiObbligatori}>
                        Procedi <i className="fas fa-angle-right"/>
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}
            />
            <ModalDocVeicoloCert
                veicolo={veicolo}
                show={showModalDocumenti}
                handleClose={() => {
                    setShowModalDocumenti(false);
                    getCertificato();
                }}
                tipiDocumento={tipiDocumento}
                submitOk={getCertificato}
            />
            {
                loadingData ? (
                    <p>Caricamento in corso...</p>
                ) : (<div className={"row mb-4"}>
                        <div className={"col-md-12 mt-4"}>
                            <h6>Si devono allegare le copie di tutti i documenti di cui si è in possesso, attestanti la
                                proprietà del veicolo, nonché la copia delle pagine della carta di circolazione
                                riportanti i dati tecnici e l'avvenuta revisione.</h6>
                            <h6>Nel caso la certificazione sia richiesta da parte del proprietario non intestatario, è
                                necessario
                                caricare la Dichiarazione Sostitutiva dell'Atto di Notorietà che puoi scaricare
                                qui: <button type={"button"}
                                             className={"btn btn-dark btn-sm"}
                                             onClick={scaricaDichiarazioneSostAttoNot}>
                                    <i className="fas fa-download"/>
                                </button>.
                            </h6>
                            <h6>Se ti viene richiesto il caricamento della dichiarazione dell'officina (dichiarazione di
                                verifica del veicolo di interesse storico e collezionistico. Da stampare e caricare una
                                volta firmata e timbrata), puoi scaricare il documento qui: <button
                                    type={"button"}
                                    className={"btn btn-dark btn-sm"}
                                    onClick={scaricaDichiarazioneOfficina}>
                                    <i className="fas fa-download"/>
                                </button>.
                            </h6>
                            <h6>Carica documenti compilati e firmati qua:</h6>
                        </div>
                        <div className={"col-md-12 mt-4"}>
                            <button type={"button"}
                                    className={"btn btn-dark btn-lg"}
                                    onClick={() => {
                                        setShowModalDocumenti(true);
                                    }}
                            >
                                <i className="fas fa-plus-circle"/> Aggiungi
                            </button>
                        </div>
                        <div className={"col-md-12 mt-4"}>
                            {
                                certificato ?
                                    <TabellaDocAggiuntiviCert certificato={certificato}/>
                                    : ''
                            }
                        </div>
                    </div>
                )
            }
        </Card>
    );
}

export default DocAggiuntiviCert;