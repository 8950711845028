import React, {useEffect} from "react";

function NsFormikScrollToError({submitCount, errors}) {


    useEffect(() => {
        if (errors) {
            let errori = Object.entries(errors);
            if (errori.length > 0) {
                let el = document.querySelector('.ns-div-err-form');

                if (el && el.dataset.field && el.dataset.field !== '') {
                    el = document.getElementsByName(el.dataset.field)[0];
                    if (el) {
                        el.focus();
                        el.parentElement.parentElement.scrollIntoView();
                    }
                }
            }
        }
    }, [submitCount]);

    return <div/>;
}

export default NsFormikScrollToError