import React, {Fragment, useContext, useRef, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import FormModificaVeicolo from "./FormModificaVeicolo";
import {TIPO_REQ_VEICOLO_LISTA_CHIUSA} from "../../utility/common";
import ButtonBar from "../../components/ButtonBar";

function ModificaVeicolo() {
    const {idUsr, idVeicolo} = useParams()

    const [idVeic, setIdVeic] = useState(idVeicolo);

    const history = useHistory();
    const formikRef = useRef();

    const onSubmitVeicolo = (veicoloNew) => {
        setIdVeic(veicoloNew.id_veicolo);
    };

    return (
        <Card
            icon={'fas fa-car'}
            title={idVeicolo ? 'Modifica Veicolo' : 'Inserisci Veicolo'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }>
            <FormModificaVeicolo
                idUsr={idUsr}
                idVeicolo={idVeic}
                formikRef={formikRef}
                tipoRichiesta={TIPO_REQ_VEICOLO_LISTA_CHIUSA}
                goBack={false}
                showCampiRegistro={false}
                showMsgSalvataggioOk={true}
                onSubmitVeicolo={onSubmitVeicolo}
            />
            <ButtonBar>
                <button type="button" id="salva" name="salva"
                        className="btn btn-card"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.handleSubmit()
                            }
                        }}>
                    <i className="fas fa-save"/> Salva
                </button>
            </ButtonBar>
        </Card>
    );
}

export default ModificaVeicolo;