import {Modal} from "react-bootstrap";
import React, {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {getRigaInfoSocio} from "../../utility/common";
import NsDataTable from "../../components/tabelle/NsDataTable";
import NsModalHeader from "../../components/modal/NsModalHeader";
import ModalConfirm from "../../components/ModalConfirm";
import ModalMsg from "../../components/ModalMsg";

function ModalSociSimili({show, handleClose, user}) {

    const [dati, setDati] = useState('');
    const [showUnionConfirm, setShowUnionConfirm] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [socioSel, setSocioSel] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getSociSimili() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/richiedente/simile/?id_utente=" + user.idSimili).then((response) => {
            setDati(response.data.data);

            endChiamata()
        });
    }

    useEffect(() => {
        if (user) {
            getSociSimili()
        }
    }, [user]);

    return (
        <Fragment>
            <ModalConfirm
                show={showUnionConfirm}
                handleClose={() => setShowUnionConfirm(false)}
                handleOk={() => {
                    setShowUnionConfirm(false);

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + socioSel.id_utente + '?unisciSocio=1',
                            user)
                        .then((response) => {
                            endChiamata();

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShowMessage(true);
                            } else {
                                handleClose(true);
                            }
                        });
                }}
                testo={"Tutti presenti nel nuovo socio andranno a sovrascrivere quelli del socio selezionato. Sicuro di voler unire il nuovo socio con quello selezionato?"}/>
            <ModalMsg
                show={showMessage}
                handleClose={() => setShowMessage(false)}
                testo={modalMsg}/>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size={"xl"}
            >
                <NsModalHeader title={"Soci con dati simili"}/>
                <Modal.Body>
                    {
                        dati && dati.length > 0 &&
                            <NsDataTable
                                data={dati}
                                columns={[
                                    {
                                        name: 'N° tessera RFI',
                                        selector: row => row.numero_tessera_rfi,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Scadenza',
                                        selector: row => row.scadenza,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Tipo socio',
                                        selector: row => row.tipo_socio,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Cognome',
                                        selector: row => row.cognome,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Nome',
                                        selector: row => row.nome,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Provincia',
                                        selector: row => row.provincia,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Località',
                                        selector: row => row.localita,
                                        sortable: true,
                                    },
                                    {
                                        name: '',
                                        selector: row => <Fragment>
                                            <button
                                                className="btn btn-dark"
                                                data-toggle="tooltip"
                                                title="Unisci"
                                                onClick={() => {
                                                    setSocioSel(row);
                                                    setShowUnionConfirm(true);
                                                }}>
                                                <i className="fas fa-link"/> Unisci
                                            </button>
                                        </Fragment>,
                                        sortable: false,
                                        right: true,
                                    }
                                ]}
                                addPagination={false}
                                addFiltri={false}
                                addDetail={true}
                                getDettaglio={getRigaInfoSocio}
                            />
                    }
                </Modal.Body>
            </Modal>
        </Fragment>
    )

}

export default ModalSociSimili;