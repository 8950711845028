import Translate from './Translate'
import React, {Fragment} from "react";

function Steps({steps, current}) {

    return (<div className={"row"}>
        <div className={"col-md-12 ns-steps"}>
            {
                steps ? steps.map((step, idx) => {
                    let classeStep = '';
                    let classeDescr = '';

                    if (current === idx) {
                        classeStep = 'ns-step-idx--active'
                        classeDescr = 'ns-step-name--active'
                    } else if (current > idx) {
                        classeStep = 'ns-step-idx--before'
                        classeDescr = 'ns-step-name--before'
                    }

                    return <div key={idx} className={"ns-step"}>
                        <div className={"ns-step-idx " + classeStep}>
                            <span>{step.idx}</span>
                        </div>
                        <span className={"ns-step-name " + classeDescr}><Translate>{step.name}</Translate></span>
                        {
                            idx < (steps.length - 1) ?
                                <span className={"ns-step-arrow"}><i className="fas fa-chevron-right"/></span>
                                : ''
                        }
                    </div>
                }) : ''
            }
        </div>
    </div>);
}

export default Steps;