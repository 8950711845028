import {Button, Modal} from "react-bootstrap";
import React, {useContext, useState} from "react";
import {TranslateContext} from "../../context/TranslateContext";
import Translate from "../../components/Translate";
import NsModalHeader from "../../components/modal/NsModalHeader";

function ModalNoteConfirm({show, handleClose, handleOk, titolo}) {

    const [nota, setNota] = useState('');

    const {traduci} = useContext(TranslateContext)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <NsModalHeader title={titolo || traduci('ATTENZIONE')}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <label htmlFor="note" className="form-label">Note:</label>
                        <textarea
                            id={"note"}
                            name={"note"}
                            className={"form-control"}
                            value={nota}
                            onChange={(event) => {
                                      setNota(event.target.value)
                                  }}>{nota}</textarea>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <Translate>Annulla</Translate>
                </Button>
                <Button variant="primary" onClick={() => {
                    handleOk(nota)
                }}>
                    <Translate>Conferma</Translate>
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalNoteConfirm;