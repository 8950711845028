import React, {Fragment, useEffect} from "react";
import ListCertificati from "../certificati/ListCertificati";

export default function HomeCommissario() {

    // const {numCertificati} = useContext(CertificatiContext);

    useEffect(() => {
    }, []);

    return (
        <Fragment>
            <div className={"row"}>
                {/*<div className={"col-md-12"}>
                    <Card
                        title={"Nuovi dati"}
                        icon={"fas fa-user-plus"}
                    >
                        <Link to={"/list-certificati"}>
                            <div className={"row rfi-row-link"}>
                                <div className={"col-md-8"}>
                                    <h4><i className={"fas fa-car"}/> Certificati da gestire</h4>
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <h3>{numCertificati}</h3>
                                </div>
                            </div>
                        </Link>
                    </Card>
                </div>*/}
                <div className={"col-md-12"}>
                    <ListCertificati/>
                </div>
            </div>
        </Fragment>
    )
}
