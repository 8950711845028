import {TranslateProvider} from "./context/TranslateContext";
import {LoaderProvider} from "./context/LoaderContext";
import {MobileProvider} from "./context/MobileContext";
import {AuthProvider} from "./context/AuthContext";
import {RuoliProvider} from "./context/RuoliContext";
import {RichiedentiProvider} from "./context/RichiedentiContext";
import {CertificatiProvider} from "./context/CertificatiContext";
import {FiltersProvider} from "./context/FiltersContext";

function ProvidersComponent({children}) {
    return (
        <TranslateProvider value={"it"}>
            <LoaderProvider>
                <MobileProvider>
                    <AuthProvider>
                        <RuoliProvider>
                            <RichiedentiProvider>
                                <CertificatiProvider>
                                    <FiltersProvider>
                                        {children}
                                    </FiltersProvider>
                                </CertificatiProvider>
                            </RichiedentiProvider>
                        </RuoliProvider>
                    </AuthProvider>
                </MobileProvider>
            </LoaderProvider>
        </TranslateProvider>
    )
}

export default ProvidersComponent;