import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {getUtenteFromToken} from "../utility/common";
import {AuthContext} from "./AuthContext";

export const RuoliContext = React.createContext(0);

export const RuoliProvider = ({value, children}) => {

    const [ruoli, setRuoli] = useState()
    const [idRuolo, setIdRuolo] = useState()

    const {isLogged} = useContext(AuthContext);

    useEffect(() => {

        async function getRuoli() {
            await axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/ruolo/")
                .then((response) => {
                    setRuoli(response.data.data)
                });
        }

        if (isLogged()) {
            getRuoli()

            const token = localStorage.getItem('rfi-login');
            if (token) {
                const user = getUtenteFromToken(token)
                if (user) {
                    setIdRuolo(user.id_ruolo)
                }
            }
        }
    }, [isLogged]);

    const isRuoloAdmin = () => {
        if (idRuolo && ruoli) {
            let ruolo = ruoli.filter((item) => item.codice === 'ADMIN')
                .reduce((item) => item)

            return ruolo && ruolo.id_ruolo === idRuolo;
        } else {
            return false;
        }
    }

    const isRuoloSegreteria = () => {
        if (idRuolo && ruoli) {
            let ruolo = ruoli.filter((item) => item.codice === 'STAFF')
                .reduce((item) => item)
            return ruolo && ruolo.id_ruolo === idRuolo;
        } else {
            return false;
        }
    }

    const isRuoloCommissario = () => {
        if (idRuolo && ruoli) {
            let ruoloComm = ruoli.filter((item) => item.codice === 'COMM')
                .reduce((item) => item)
            let ruoloCons = ruoli.filter((item) => item.codice === 'CONS')
                .reduce((item) => item)
            return (ruoloComm && ruoloComm.id_ruolo === idRuolo)
                || (ruoloCons && ruoloCons.id_ruolo === idRuolo);
        } else {
            return false;
        }
    }

    const isRuoloSocio = () => {
        if (idRuolo && ruoli) {
            let ruolo = ruoli.filter((item) => item.codice === 'SOCIO')
                .reduce((item) => item)
            return ruolo && ruolo.id_ruolo === idRuolo;
        } else {
            return false;
        }
    }

    const isRuoloClub = () => {
        if (idRuolo && ruoli) {
            let ruolo = ruoli.filter((item) => item.codice === 'CLUB')
                .reduce((item) => item)
            return ruolo && ruolo.id_ruolo === idRuolo;
        } else {
            return false;
        }
    }

    const isRuoloAgenzia = () => {
        if (idRuolo && ruoli) {
            let ruolo = ruoli.filter((item) => item.codice === 'AGE')
                .reduce((item) => item)
            return ruolo && ruolo.id_ruolo === idRuolo;
        } else {
            return false;
        }
    }

    const isRuoloPresidente = () => {
        if (idRuolo && ruoli) {
            let filter = ruoli.filter((item) => item.codice === 'PRES');
            let ruolo = filter && filter.length > 0 ? filter.reduce((item) => item) : null;
            return ruolo && ruolo.id_ruolo === idRuolo;
        } else {
            return false;
        }
    }

    const getIdRuoloClub = () => {
        if (ruoli) {
            let r = ruoli.filter((item) => item.codice === 'CLUB');
            if (r && r.length > 0) {
                return r[0].id_ruolo;
            }
        }
        return null;
    }

    const getIdRuoloAgenzia = () => {
        if (ruoli) {
            let r = ruoli.filter((item) => item.codice === 'AGE');
            if (r && r.length > 0) {
                return r[0].id_ruolo;
            }
        }
        return null;
    }

    return (
        <RuoliContext.Provider
            value={{
                ruoli,
                isRuoloAdmin,
                isRuoloSegreteria,
                isRuoloCommissario,
                isRuoloSocio,
                isRuoloPresidente,
                isRuoloClub,
                isRuoloAgenzia,
                getIdRuoloClub,
                getIdRuoloAgenzia
        }}>{children}</RuoliContext.Provider>
    );
};
