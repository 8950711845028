import {Accordion} from "react-bootstrap";
import React from "react";

function NsAccordion({titolo, children, label = '', defaultKey = '0'}) {

    return (
        <Accordion className={'mb-3'} defaultActiveKey={defaultKey}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    {
                        label && label !== '' ?
                            <i className={label + ' ns-tree-row-icon pe-2'}/>
                            : ''
                    }
                    {titolo}
                </Accordion.Header>
                <Accordion.Body>
                    {
                        children
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default NsAccordion