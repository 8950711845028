import React, {Fragment, useContext, useEffect, useState} from "react";
import Card from "../../components/Card";
import {Link, useHistory} from "react-router-dom";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import Pager from "../../components/Pager";
import NsDataTable from "../../components/tabelle/NsDataTable";
import NsAccordion from "../../components/accordion/NsAccordion";
import {
    convertiDateInDataPerDb, getIconeStatoSocio, getLabelScadenza,
    getRigaInfoSocio,
    ORDINE_SOCI_ABARTH_ASC, ORDINE_SOCI_CAP_ASC, ORDINE_SOCI_COGNOME_ASC,
    ORDINE_SOCI_RFI_ASC,
    ORDINE_SOCI_TIPO_SOCIO
} from "../../utility/common";
import ModalConfirm from "../../components/ModalConfirm";
import Translate from "../../components/Translate";
import {Form, Formik} from "formik";
import ModalMsg from "../../components/ModalMsg";
import {FiltersContext} from "../../context/FiltersContext";
import {RuoliContext} from "../../context/RuoliContext";
import ModalLegendaStato from "./ModalLegendaStato";

function ListPersone({tipoStato = ''}) {

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {filtroSoci, initFiltroSoci, updateFiltroSoci} = useContext(FiltersContext);
    const {isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    const [regione, setRegione] = useState('');
    const [tipoSocio, setTipoSocio] = useState('');
    const [daRatificare, setDaRatificare] = useState('');
    const [newsletter, setNewsletter] = useState('');
    const [club, setClub] = useState('');

    const [loadingData, setLoadingData] = useState(true);
    const [showLegendaStato, setShowLegendaStato] = useState(false);
    const [soci, setSoci] = useState([]);
    const [totSoci, setTotSoci] = useState(0);
    const [regioni, setRegioni] = useState([]);
    const [tipiSoci, setTipiSoci] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [agenzie, setAgenzie] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();
    const [tipiRegistro, setTipiRegistro] = useState([]);
    const [utenteSel, setUtenteSel] = useState(null);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalRimuoviAdesione, setShowModalRimuoviAdesione] = useState(false);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [tipoStatoSocio, setTipoStatoSocio] = useState(filtroSoci.tipoStato && filtroSoci.tipoStato !== '' ? filtroSoci.tipoStato : (tipoStato || ''));

    const history = useHistory()

    function getFiltriSoci() {
        let f = '?pag=' + pagina;
        if (filtroSoci.nome && filtroSoci.nome !== '') {
            f += '&nome=' + filtroSoci.nome;
        }
        if (filtroSoci.cognome && filtroSoci.cognome !== '') {
            f += '&cognome=' + filtroSoci.cognome;
        }
        if (filtroSoci.filtroTessera && filtroSoci.filtroTessera !== '') {
            f += '&tessera=' + filtroSoci.filtroTessera;
        }
        if (filtroSoci.registro && filtroSoci.registro !== '') {
            let reg = filtroSoci.registro;
            if (reg === '3' || reg === '5') {
                reg += ',15';
            }
            f += '&registro=' + reg;
        }
        if (filtroSoci.codiceFiscale && filtroSoci.codiceFiscale !== '') {
            f += '&cf=' + filtroSoci.codiceFiscale;
        }
        if (filtroSoci.azienda && filtroSoci.azienda !== '') {
            f += '&azienda=' + filtroSoci.azienda;
        }
        if (filtroSoci.note && filtroSoci.note !== '') {
            f += '&note=' + filtroSoci.note;
        }
        if (filtroSoci.indirizzo && filtroSoci.indirizzo !== '') {
            f += '&indirizzo=' + filtroSoci.indirizzo;
        }
        if (filtroSoci.cap && filtroSoci.cap !== '') {
            f += '&cap=' + filtroSoci.cap;
        }
        if (filtroSoci.localita && filtroSoci.localita !== '') {
            f += '&localita=' + filtroSoci.localita;
        }
        if (filtroSoci.provincia && filtroSoci.provincia !== '') {
            f += '&provincia=' + filtroSoci.provincia;
        }
        if (filtroSoci.regione && filtroSoci.regione !== '') {
            f += '&regione=' + filtroSoci.regione;
        }
        if (filtroSoci.nazione && filtroSoci.nazione !== '') {
            f += '&nazione=' + filtroSoci.nazione;
        }
        if (filtroSoci.tipoSocio && filtroSoci.tipoSocio !== '') {
            f += '&tipoSocio=' + filtroSoci.tipoSocio;
        }
        if (filtroSoci.annoIscrizione && filtroSoci.annoIscrizione !== '') {
            f += '&annoIscrizione=' + filtroSoci.annoIscrizione;
        }
        if (filtroSoci.scadenza && filtroSoci.scadenza !== '') {
            f += '&scadenza=' + filtroSoci.scadenza;
        }
        if (filtroSoci.minVeicoli && filtroSoci.minVeicoli !== '') {
            f += '&minVeicoli=' + filtroSoci.minVeicoli;
        }
        if (filtroSoci.daRatificare && filtroSoci.daRatificare !== '') {
            f += '&daRatificare=' + filtroSoci.daRatificare;
        }
        if (filtroSoci.newsletter && filtroSoci.newsletter !== '') {
            f += '&newsletter=' + filtroSoci.newsletter;
        }
        if (filtroSoci.delegazione && filtroSoci.delegazione !== '') {
            f += '&delegazione=' + filtroSoci.delegazione;
        }
        if (filtroSoci.tesseraAsi && filtroSoci.tesseraAsi !== '') {
            f += '&tesseraAsi=' + filtroSoci.tesseraAsi;
        }
        if (filtroSoci.club && filtroSoci.club !== '') {
            f += '&club=' + filtroSoci.club;
        }
        if (filtroSoci.id_agenzia && filtroSoci.id_agenzia !== '') {
            f += '&id_agenzia=' + filtroSoci.id_agenzia;
        }
        if (filtroSoci.ordine && filtroSoci.ordine !== '') {
            f += '&ordine=' + filtroSoci.ordine;
        }
        if (filtroSoci.esteri && filtroSoci.esteri !== '') {
            f += '&esteri=' + filtroSoci.esteri;
        }
        if (filtroSoci.email && filtroSoci.email !== '') {
            f += '&email=' + filtroSoci.email;
        }
        if (tipoStatoSocio && tipoStatoSocio !== '') {
            f += '&tipoStato=' + tipoStatoSocio;
        }
        return f;
    }

    function getSoci() {
        let f = getFiltriSoci();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/" + f).then((response) => {
            setSoci(response.data.data);
            setTotPagine(response.data.pages);
            setTotSoci(response.data.tot);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getRegioni() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/regione/").then((response) => {
            setRegioni(response.data.data)
            endChiamata()
        });
    }

    function getTipiSoci() {
        if (filtroSoci.registro && filtroSoci.registro !== '') {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/?tipo=" + filtroSoci.registro).then((response) => {
                setTipiSoci(response.data.data)
                endChiamata()
            });
        }
    }

    function getClubs() {
        if (filtroSoci.registro && filtroSoci.registro !== '') {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/club/?tipo=" + filtroSoci.registro).then((response) => {
                setClubs(response.data.data)
                endChiamata()
            });
        }
    }

    function getAgenzie() {
        if (filtroSoci.registro && filtroSoci.registro !== '') {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/agenzie/?tipo=" + filtroSoci.registro).then((response) => {
                setAgenzie(response.data.data)
                endChiamata()
            });
        }
    }

    function getTipiRegistro() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-registro/").then((response) => {
            setTipiRegistro(response.data);
            endChiamata()
        });
    }

    function stampaEtichette() {
        let f = getFiltriSoci();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/stampa/etichetta/" + f).then((response) => {
            endChiamata();
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data;
                if (resp) {
                    var a = document.createElement("a"); //Create <a>
                    a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
                    a.download = resp.nome_file; //File name Here
                    a.click(); //Downloaded file
                }
            }
        });
    }

    useEffect(() => {
        getSoci();
        getTipiSoci();

        if (!isRuoloClub() && !isRuoloAgenzia()) {
            getClubs();
            getAgenzie();
        }

        if (loadingData === true) {
            getRegioni();
            getTipiRegistro();
        }
    }, [pagina, filtroSoci.registro, regione, tipoSocio, daRatificare, newsletter, club]);

    const cambioPagina = (nuovaPagina) => {
        setPagina(nuovaPagina)
    }

    const openModalDel = (utente) => {
        setUtenteSel(utente)
        setShowModalDel(true);
    }

    const openModalRimuoviAdesione = (utente) => {
        setUtenteSel(utente)
        setShowModalRimuoviAdesione(true);
    }

    const eliminaUtente = () => {
        let f = getFiltriSoci();

        setShowModalDel(false);
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/" + utenteSel.id_utente + f).then((response) => {
            setSoci(response.data.data);
            setTotPagine(response.data.pages);
            setTotSoci(response.data.tot);
            endChiamata()
        });
    }

    const rimuoviAdesione = () => {
        setShowModalRimuoviAdesione(false);
        startChiamata()

        let params = '';

        let usrNew = utenteSel;
        if (isRuoloClub()) {
            params = '?resetClub=1';
        } else if (isRuoloAgenzia()) {
            params = '?resetAgenzia=1';
        }

        axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + utenteSel.id_utente + params, usrNew).then((response) => {
            endChiamata();
            getSoci();
        });
    }

    function getDownloadCsv() {
        let f = getFiltriSoci();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/csv/" + f).then((response) => {
            endChiamata()
            let resp = response.data.data;
            if (resp) {
                var a = document.createElement("a");
                a.href = "data:text/csv;base64," + resp;
                a.download = 'elenco-soci-' + convertiDateInDataPerDb(new Date()) + '.csv';
                a.click(); //Downloaded file
            }
        });
    }

    return (
        <Card
            icon={'fas fa-user'}
            title={'Archivio Soci'}
            getBottoniDx={() =>
                <Link to={"/upd-socio/"}
                      className="btn btn-card"
                      data-toggle="tooltip" title="Aggiungi">
                    <i className="fas fa-plus-circle"/> Aggiungi
                </Link>
            }>
            <ModalLegendaStato
                show={showLegendaStato}
                handleClose={() => {
                    setShowLegendaStato(false);
                }}
            />
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <ModalConfirm
                show={showModalDel}
                handleClose={() => setShowModalDel(false)}
                handleOk={eliminaUtente}
                testo={"Sicuro di voler eliminare il socio: " + (utenteSel
                    && (utenteSel.nome + ' ' + utenteSel.cognome)) + "?"}/>
            <ModalConfirm
                show={showModalRimuoviAdesione}
                handleClose={() => setShowModalRimuoviAdesione(false)}
                handleOk={rimuoviAdesione}
                testo={"Sicuro di voler rimuovere l'adesione del socio: " + (utenteSel
                    && (utenteSel.nome + ' ' + utenteSel.cognome)) + "?"}/>
            <Formik
                onSubmit={getSoci}
                initialValues={{}}>
                <Form>
                    <div className={'row pb-2'}>
                        <div className={'col-md-1'}>
                            <input
                                type={"text"}
                                id={"nome"}
                                placeholder={"Nome"}
                                className="form-control"
                                value={filtroSoci.nome}
                                onChange={(ev) => {
                                    updateFiltroSoci("nome", ev.target.value);
                                }}/>
                        </div>
                        <div className={'col-md-1'}>
                            <input
                                type={"text"}
                                id={"cognome"}
                                placeholder={"Cognome"}
                                className="form-control"
                                value={filtroSoci.cognome}
                                onChange={(ev) => {
                                    updateFiltroSoci("cognome", ev.target.value);
                                }}/>
                        </div>
                        <div className={'col-md-2'}>
                            <input
                                type={"text"}
                                id={"tessera"}
                                placeholder={"N° tessera RFI/Abarth"}
                                className="form-control"
                                value={filtroSoci.filtroTessera}
                                onChange={(ev) => {
                                    updateFiltroSoci("filtroTessera", ev.target.value);
                                }}/>
                        </div>
                        <div className={'col-md-2'}>
                            <select
                                id={"tipiRegistro"}
                                className="form-control form-select"
                                value={filtroSoci.registro}
                                onChange={(ev) => {
                                    updateFiltroSoci("registro", ev.target.value);
                                }}
                            >
                                <option value={""}>Tutti i registri</option>
                                {
                                    tipiRegistro && tipiRegistro.map((reg) => {
                                        return <option value={reg.numero_primo}
                                                       key={reg.numero_primo}>{reg.descrizione}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            (isRuoloAgenzia() || isRuoloClub()) &&
                            <div className={'col-md-2'}>
                                <select
                                    id={"statoSoci"}
                                    className="form-control form-select"
                                    value={tipoStatoSocio}
                                    onChange={(ev) => {
                                        updateFiltroSoci("tipoStato", ev.target.value);
                                        setTipoStatoSocio(ev.target.value);
                                    }}
                                >
                                    <option value={""}>Tutti i soci</option>
                                    <option value={"1"}>Nuove registrazioni</option>
                                    <option value={"2"}>Soci effettivi</option>
                                </select>
                            </div>
                        }
                        <div className={(isRuoloAgenzia() || isRuoloClub()) ? 'col-md-4' : 'col-md-6'}>
                            <button type={"submit"} className={"btn btn-dark"}>
                                <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                            </button>
                            <button type="button" className={"btn btn-dark"} onClick={() => {
                                initFiltroSoci();
                                setTipoStatoSocio('');
                                setPagina(1);
                            }}>
                                <i className="fas fa-trash"></i> <Translate>Azzera filtri</Translate>
                            </button>
                            <button className={"btn btn-card"}
                                    type={"button"}
                                    onClick={getDownloadCsv}
                                    data-toggle="tooltip" title="Download CSV">
                                <i className="fas fa-download"/> <span
                                className={'cmms-display-mobile'}>Download CSV</span>
                            </button>
                            {
                                !isRuoloClub() && !isRuoloAgenzia() &&
                                <button className={"btn btn-card"}
                                        type={"button"}
                                        onClick={stampaEtichette}
                                        data-toggle="tooltip" title="Stampa etichette">
                                    <i className="fas fa-tag"/> <span
                                    className={'cmms-display-mobile'}>Stampa etichette</span>
                                </button>
                            }
                        </div>
                    </div>
                    <NsAccordion
                        titolo={'Filtri'}
                        label={'fas fa-filter'}
                        defaultKey={""}
                    >
                        <p><strong>Dati anagrafici del proprietario</strong></p>
                        <div className={'row'}>
                            <div className={'col-md-2'}>
                                <label htmlFor={"email"}>E-mail:</label>
                                <input
                                    type={"text"}
                                    id={"email"}
                                    placeholder={"Indirizzo e-mail"}
                                    className="form-control"
                                    value={filtroSoci.email}
                                    onChange={(ev) => {
                                        updateFiltroSoci("email", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"codiceFiscale"}>Codice fiscale:</label>
                                <input
                                    type={"text"}
                                    id={"codiceFiscale"}
                                    placeholder={"Codice fiscale"}
                                    className="form-control"
                                    value={filtroSoci.codiceFiscale}
                                    onChange={(ev) => {
                                        updateFiltroSoci("codiceFiscale", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"azienda"}>Azienda:</label>
                                <input
                                    type={"text"}
                                    id={"azienda"}
                                    placeholder={"Azienda"}
                                    className="form-control"
                                    value={filtroSoci.azienda}
                                    onChange={(ev) => {
                                        updateFiltroSoci("azienda", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-6'}>
                                <label htmlFor={"note"}>Note persona:</label>
                                <input
                                    type={"text"}
                                    id={"note"}
                                    placeholder={"Note persona"}
                                    className="form-control"
                                    value={filtroSoci.note}
                                    onChange={(ev) => {
                                        updateFiltroSoci("note", ev.target.value);
                                    }}/>
                            </div>
                        </div>
                        <div className={'row pt-2'}>
                            <div className={'col-md-2'}>
                                <label htmlFor={"indirizzo"}>Indirizzo:</label>
                                <input
                                    type={"text"}
                                    id={"indirizzo"}
                                    placeholder={"Indirizzo"}
                                    className="form-control"
                                    value={filtroSoci.indirizzo}
                                    onChange={(ev) => {
                                        updateFiltroSoci("indirizzo", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"cap"}>CAP:</label>
                                <input
                                    type={"text"}
                                    id={"cap"}
                                    placeholder={"CAP"}
                                    className="form-control"
                                    value={filtroSoci.cap}
                                    onChange={(ev) => {
                                        updateFiltroSoci("cap", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"localita"}>Località:</label>
                                <input
                                    type={"text"}
                                    id={"localita"}
                                    placeholder={"Località"}
                                    className="form-control"
                                    value={filtroSoci.localita}
                                    onChange={(ev) => {
                                        updateFiltroSoci("localita", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"provincia"}>Provincia:</label>
                                <input
                                    type={"text"}
                                    id={"provincia"}
                                    placeholder={"Provincia"}
                                    className="form-control"
                                    value={filtroSoci.provincia}
                                    onChange={(ev) => {
                                        updateFiltroSoci("provincia", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"regione"}>Regione:</label>
                                <select id={"regione"}
                                        placeholder={"Regione"}
                                        className="form-control form-select"
                                        value={filtroSoci.regione}
                                        onChange={(ev) => {
                                            updateFiltroSoci("regione", ev.target.value);
                                            setRegione(ev.target.value)
                                        }}
                                >
                                    <option value={""}>Tutte le regioni</option>
                                    {
                                        regioni && regioni.map((reg) => <option value={reg} key={reg}>{reg}</option>)
                                    }
                                </select>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"nazione"}>Nazione:</label>
                                <input
                                    type={"text"}
                                    id={"nazione"}
                                    placeholder={"Nazione"}
                                    className="form-control"
                                    value={filtroSoci.nazione}
                                    onChange={(ev) => {
                                        updateFiltroSoci("nazione", ev.target.value);
                                    }}/>
                            </div>
                        </div>
                        <div className={'row pt-2'}>
                            <div className={'col-md-2'}>
                                <label htmlFor={"esteri"}>Soci esteri:</label>
                                <select id={"esteri"}
                                        className="form-control form-select"
                                        value={filtroSoci.esteri}
                                        onChange={(ev) => {
                                            updateFiltroSoci("esteri", ev.target.value);
                                            setRegione(ev.target.value)
                                        }}
                                >
                                    <option value={""}>Tutte le nazioni</option>
                                    <option value={"1"}>Solo soci esteri</option>
                                    <option value={"2"}>Solo soci italiani</option>
                                </select>
                            </div>
                        </div>
                        <p className={"pt-2"}><strong>Dati sull'iscrizione del proprietario</strong></p>
                        <div className={"row"}>
                            <div className={'col-md-2'}>
                                <label htmlFor={"tipoSocio"}>Tipo Socio:</label>
                                <select id={"tipoSocio"}
                                        placeholder={"Tipo Socio"}
                                        className="form-control form-select"
                                        value={filtroSoci.tipoSocio}
                                        onChange={(ev) => {
                                            updateFiltroSoci("tipoSocio", ev.target.value);
                                            setTipoSocio(ev.target.value);
                                        }}
                                >
                                    <option value={""}>Tutti tipi socio</option>
                                    {
                                        tipiSoci && tipiSoci.map((soc) => <option value={soc.id_tipo_soci}
                                                                                  key={soc.id_tipo_soci}>{soc.descrizione}</option>)
                                    }
                                </select>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"annoIscrizione"}>Anno iscrizione:</label>
                                <input
                                    type={"text"}
                                    id={"annoIscrizione"}
                                    placeholder={"Anno iscrizione"}
                                    className="form-control"
                                    value={filtroSoci.annoIscrizione}
                                    onChange={(ev) => {
                                        updateFiltroSoci("annoIscrizione", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"scadenza"}>Scadenza:</label>
                                <input
                                    type={"text"}
                                    id={"scadenza"}
                                    placeholder={"Scadenza"}
                                    className="form-control"
                                    value={filtroSoci.scadenza}
                                    onChange={(ev) => {
                                        updateFiltroSoci("scadenza", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"minVeicoli"}>Min. veicoli:</label>
                                <input
                                    type={"number"}
                                    id={"minVeicoli"}
                                    placeholder={"Min. veicoli"}
                                    className="form-control"
                                    value={filtroSoci.minVeicoli}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    onChange={(ev) => {
                                        updateFiltroSoci("minVeicoli", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"daRatificare"}>Da Ratificare:</label>
                                <select id={"daRatificare"}
                                        placeholder={"Da Ratificare"}
                                        className="form-control form-select"
                                        value={filtroSoci.daRatificare}
                                        onChange={(ev) => {
                                            updateFiltroSoci("daRatificare", ev.target.value);
                                            setDaRatificare(ev.target.value);
                                        }}
                                >
                                    <option value={""}>Tutti</option>
                                    <option value={"1"}>Da ratificare</option>
                                    <option value={"0"}>Già ratificato</option>
                                </select>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"newsletter"}>Newsletter:</label>
                                <select id={"newsletter"}
                                        placeholder={"Newsletter"}
                                        className="form-control form-select"
                                        value={filtroSoci.newsletter}
                                        onChange={(ev) => {
                                            updateFiltroSoci("newsletter", ev.target.value);
                                            setNewsletter(ev.target.value);
                                        }}
                                >
                                    <option value={""}>Tutti</option>
                                    <option value={"1"}>Abbonati</option>
                                    <option value={"0"}>Non abbonati</option>
                                </select>
                            </div>
                        </div>
                        <div className={"row pt-2"}>
                            <div className={'col-md-2'}>
                                <label htmlFor={"delegazione"}>Delegazione:</label>
                                <input
                                    type={"text"}
                                    id={"delegazione"}
                                    placeholder={"Delegazione"}
                                    className="form-control"
                                    value={filtroSoci.delegazione}
                                    onChange={(ev) => {
                                        updateFiltroSoci("delegazione", ev.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <label htmlFor={"tesseraAsi"}>Numero tessera ASI:</label>
                                <input
                                    type={"text"}
                                    id={"tesseraAsi"}
                                    placeholder={"N° tessera ASI"}
                                    className="form-control"
                                    value={filtroSoci.tesseraAsi}
                                    onChange={(ev) => {
                                        updateFiltroSoci("tesseraAsi", ev.target.value);
                                    }}/>
                            </div>
                            {
                                !isRuoloClub() && !isRuoloAgenzia() &&
                                <Fragment>
                                    <div className={'col-md-4'}>
                                        <label htmlFor={"club"}>Club:</label>
                                        <select id={"club"}
                                                className="form-control form-select"
                                                value={filtroSoci.club}
                                                onChange={(ev) => {
                                                    updateFiltroSoci("club", ev.target.value);
                                                    setClub(ev.target.value);
                                                }}
                                        >
                                            <option value={""}>Tutti i club</option>
                                            {
                                                clubs && clubs.map((club) => <option value={club.id_club}
                                                                                     key={club.id_club}>{club.descrizione !== '' ? club.descrizione : club.id_club}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className={'col-md-4'}>
                                        <label htmlFor={"id_agenzia"}>Agenzia:</label>
                                        <select id={"id_agenzia"}
                                                className="form-control form-select"
                                                value={filtroSoci.id_agenzia}
                                                onChange={(ev) => {
                                                    updateFiltroSoci("id_agenzia", ev.target.value);
                                                    setClub(ev.target.value);
                                                }}
                                        >
                                            <option value={""}>Tutte le agenzie</option>
                                            {
                                                agenzie && agenzie.map((age) => <option value={age.id_agenzia}
                                                                                     key={age.id_agenzia}>{age.descrizione}</option>)
                                            }
                                        </select>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        <p className={"pt-2"}><strong>Ordinamento</strong></p>
                        <div className={"row"}>
                            <div className={'col-md-4'}>
                                <label htmlFor={"ordine"}>Ordina per:</label>
                                <select id={"ordine"}
                                        className="form-control form-select"
                                        value={filtroSoci.ordine}
                                        onChange={(ev) => {
                                            updateFiltroSoci("ordine", ev.target.value);
                                            setClub(ev.target.value);
                                        }}
                                >
                                    <option value={""}></option>
                                    <option value={ORDINE_SOCI_RFI_ASC}>Numero RFI</option>
                                    <option value={ORDINE_SOCI_ABARTH_ASC}>Numero Abarth</option>
                                    <option value={ORDINE_SOCI_TIPO_SOCIO}>Tipo Socio</option>
                                    <option value={ORDINE_SOCI_COGNOME_ASC}>Cognome</option>
                                    <option value={ORDINE_SOCI_CAP_ASC}>CAP</option>
                                </select>
                            </div>
                        </div>
                    </NsAccordion>
                </Form>
            </Formik>
            {
                (isRuoloAgenzia() || isRuoloClub()) &&
                <p>
                    Legenda Stato:&nbsp;&nbsp;
                    <i className="fas fa-info-circle rfi-cursor--pointer"
                       onClick={() => {
                           setShowLegendaStato(true)
                       }}/>

                </p>
            }
            {
                loadingData ? (
                    <p>Caricamento in corso...</p>
                ) : (
                    <NsDataTable
                        data={soci}
                        totRighe={totSoci}
                        columns={[
                            {
                                name: 'N° tessera RFI',
                                selector: row => row.numero_tessera_rfi,
                                sortable: true,
                            },
                            {
                                name: 'N° tessera Abarth',
                                selector: row => row.numero_tessera_abarth,
                                sortable: true,
                            },
                            {
                                name: 'Scadenza',
                                selector: row => row.scadenza ? getLabelScadenza(row.scadenza) : '',
                                sortable: true,
                                sortFunction: (a, b) => {
                                    if (a.scadenza < b.scadenza) {
                                        return -1;
                                    }
                                    if (a.scadenza > b.scadenza) {
                                        return 1;
                                    }
                                    return 0;
                                }
                            },
                            {
                                name: 'Tipo socio',
                                selector: row => row.tipo_socio,
                                sortable: true,
                                visible: () => {
                                    return !isRuoloClub() && !isRuoloAgenzia();
                                }
                            },
                            {
                                name: 'Cognome',
                                selector: row => row.cognome,
                                sortable: true,
                            },
                            {
                                name: 'Nome',
                                selector: row => row.nome,
                                sortable: true,
                            },
                            {
                                name: 'Club',
                                selector: row => row.club,
                                sortable: true,
                                visible: () => {
                                    return !isRuoloClub() && !isRuoloAgenzia();
                                }
                            },
                            {
                                name: 'Agenzia',
                                selector: row => row.agenzia,
                                sortable: true,
                                visible: () => {
                                    return !isRuoloClub() && !isRuoloAgenzia();
                                }
                            },
                            {
                                name: 'Regione',
                                selector: row => row.regione,
                                sortable: true,
                            },
                            {
                                name: 'Provincia',
                                selector: row => row.provincia,
                                sortable: true,
                            },
                            {
                                name: 'Località',
                                selector: row => row.localita,
                                sortable: true,
                            },
                            {
                                name: 'N° veicoli',
                                selector: row => row.num_veicoli,
                                sortable: true,
                            },
                            {
                                name: 'Stato',
                                selector: row => {
                                    return getIconeStatoSocio(row)
                                },
                                sortable: true,
                                visible: () => {
                                    return isRuoloClub() || isRuoloAgenzia();
                                }
                            },
                            {
                                name: '',
                                selector: row => <Fragment>
                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                        <div className="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa-solid fa-bars"></i>
                                            </button>
                                            {
                                                isRuoloClub() || isRuoloAgenzia() ?
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <li>
                                                            <Link to={"/info-socio-club/" + row.id_utente}
                                                                  className="dropdown-item"
                                                                  data-toggle="tooltip" title="Informazioni">
                                                                <i className="fas fa-eye"/> Informazioni
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={"/upd-profilo/" + row.id_utente}
                                                                  className="dropdown-item"
                                                                  data-toggle="tooltip" title="Modifica">
                                                                <i className="fas fa-pen"/> Modifica
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Rimuovi adesione"
                                                                    onClick={() => {
                                                                        openModalRimuoviAdesione(row)
                                                                    }}>
                                                                <i className="fas fa-remove"/> Rimuovi adesione
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <li>
                                                            <Link to={"/info-socio/" + row.id_utente}
                                                                  className="dropdown-item"
                                                                  data-toggle="tooltip" title="Informazioni">
                                                                <i className="fas fa-eye"/> Informazioni
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={"/upd-socio/" + row.id_utente}
                                                                  className="dropdown-item"
                                                                  data-toggle="tooltip" title="Modifica">
                                                                <i className="fas fa-pen"/> Modifica
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Elimina"
                                                                    onClick={() => {
                                                                        openModalDel(row)
                                                                    }}>
                                                                <i className="fas fa-trash"/> Elimina
                                                            </button>
                                                        </li>
                                                    </ul>
                                            }
                                        </div>
                                    </div>
                                </Fragment>,
                                sortable: false,
                                right: true,
                            }
                        ]}
                        onRowDbClick={(el) => {
                            history.push(isRuoloClub() || isRuoloAgenzia() ? "/info-socio-club/" + el.id_utente : "/info-socio/" + el.id_utente)
                        }}
                        addPagination={false}
                        addFiltri={false}
                        addDetail={true}
                        getDettaglio={(el) => getRigaInfoSocio(el, false, true)}
                    >
                    </NsDataTable>
                )}
            {
                totPagine ?
                    (
                        <Pager
                            pagina={pagina}
                            totale={totPagine}
                            handlerCambioPagina={cambioPagina}
                        >
                        </Pager>
                    ) : ''
            }
        </Card>
    );
}

export default ListPersone;
