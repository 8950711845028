import {Modal} from "react-bootstrap";
import {ClipLoader} from "react-spinners";

function Spinner({loading}) {

    return (
        <Modal
            show={loading}
            onHide={() => {
            }}
            backdrop="static"
            keyboard={false}
            className={'ns-modal-spinner'}
        >
            <Modal.Body>
            <ClipLoader
                color={'#355d98'}
                loading={loading}
                size={80}/>
            </Modal.Body>
        </Modal>
    )

}

export default Spinner;