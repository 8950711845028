import React from "react";
import CruscottoVeicoli from "./CruscottoVeicoli";

function CruscottoVeicoliRfi() {

    return (
        <CruscottoVeicoli tipo={'1'}/>
    );
}

export default CruscottoVeicoliRfi;
