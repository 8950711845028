import {Modal} from "react-bootstrap";
import React from "react";
import NsModalHeader from "../../components/modal/NsModalHeader";

export default function ModalAssegno({show, handleClose, handlePagamento}) {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <NsModalHeader title={"Pagamento tramite Assegno"}/>
            <Modal.Body>
                <p className={"mt-3"}>Se proseguirai con questa modalità di pagamento, riceverai una E-mail con
                    riassunte le informazioni
                    sulla quota da pagare.</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className={"btn btn-danger btn-lg"} onClick={() => {
                    handlePagamento('ASSEGN');
                }}>
                    <i className="fas fa-credit-card"></i> Procedi al pagamento
                </button>
            </Modal.Footer>
        </Modal>
    )

}