import React, {Fragment, useEffect, useState} from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {ClipLoader} from "react-spinners";
import axios from "axios";

export default function ContainerFoto({
                                          label,
                                          content_type,
                                          endpointContent,
                                          nome,
                                          isEliminabile = false,
                                          isScaduto = false,
                                          eliminaFoto = () => {
                                          }
                                      }) {

    const [contenuto, setContenuto] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);

    useEffect(() => {
        if (endpointContent) {
            setLoadSpinner(true);
            axios
                .get(endpointContent)
                .then((response) => {
                    setLoadSpinner(false);
                    setContenuto(response.data.data.contenuto)
                });
        }
    }, [endpointContent]);

    function showDocumento() {
        var byteCharacters = atob(contenuto);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], {type: content_type + ';base64'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    return (
        <Fragment>
            <div className={"ns-container-foto-upl"}>
                <div className={"ns-container-foto-upl-label text-center"}>
                    <label aria-hidden="true">
                        <div>
                            <p>{label} {isScaduto === true ? <strong>[scaduto]</strong> : ''}</p>
                        </div>
                        <div className={"ns-foto"}>
                            {
                                loadSpinner === true ?
                                    <ClipLoader
                                        color={'#355d98'}
                                        loading={loadSpinner}
                                        size={80}/>
                                    :
                                    <Fragment>
                                        {
                                            contenuto && content_type && content_type.startsWith('image/') && content_type !== 'image/heic' ?
                                                <div className={"ns-foto-img"}>
                                                    {/*<ReactImageMagnify
                                                        {...{
                                                            smallImage: {
                                                                alt: 'Immagine di esempio',
                                                                isFluidWidth: true,
                                                                src: `data:${content_type};base64, ${contenuto}`,
                                                            },
                                                            largeImage: {
                                                                src: `data:${content_type};base64, ${contenuto}`,
                                                                width: 1200,
                                                                height: 1800,
                                                            },
                                                            isHintEnabled: true,
                                                            shouldHideHintAfterFirstActivation: false
                                                        }}
                                                    />*/}
                                                    <Zoom>
                                                        <img src={`data:${content_type};base64, ${contenuto}`}
                                                             className={"ns-foto-img"}/>
                                                    </Zoom>
                                                </div>
                                                :
                                                <div className={"ns-container-doc"}>
                                                    <span><strong>{nome}</strong></span>
                                                </div>
                                        }
                                    </Fragment>
                            }
                        </div>
                        <div className={"ns-foto-button-bar"}>
                            {
                                isEliminabile === true &&
                                <button
                                    type={"button"}
                                    className={"btn btn-danger btn-sm"}
                                    data-toggle="tooltip"
                                    title="Elimina"
                                    onClick={() => {
                                        eliminaFoto();
                                    }}
                                >
                                    <i className="fas fa-trash"/> Elimina
                                </button>
                            }
                            <button
                                    type={"button"}
                                    className="btn btn-dark btn-sm"
                                    data-toggle="tooltip" title="Visualizza"
                                    onClick={() => {
                                        showDocumento();
                                    }}
                                >
                                    <i className="fas fa-eye"/> Visualizza
                                </button>
                        </div>
                    </label>
                </div>
            </div>
        </Fragment>
    )
}
