import React, {Fragment} from "react";

function PagamentoKo() {

    return (
        <Fragment>
            <h1>Pagamento non riuscito</h1>
        </Fragment>
    )
}

export default PagamentoKo;
