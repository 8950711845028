import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import UplFotoSocio from "../soci/UplFotoSocio";
import {Form, Formik} from "formik";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {STATO_CARTA_ID_UPL} from "../../utility/common";

export default function HomeSocioMailVerificata({usr, handlerConferma}) {

    const [tipiDocumento, setTipiDocumento] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const formikRef = useRef();

    const token = localStorage.getItem('rfi-login');

    const getTipiDocumentoUtente = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata()
        });
    }

    useEffect(() => {
        getTipiDocumentoUtente();
    }, [usr]);

    return (
        <Fragment>
            <div className={"row text-center mb-4"}>
                <div className={"col-md-12"}>
                    <h1>Inserisci il tuo documento d'identità</h1>
                    <h5>Ci sei quasi! Manca ancora un passaggio per completare l'iscrizione: inserisci il tuo documento d'identità e codice fiscale negli appositi riquadri!</h5>
                </div>
            </div>
            <Formik
                initialValues={{}}
                innerRef={formikRef}
                onSubmit={values => {
                    startChiamata()
                    axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + usr.id_utente + "/stato/", {
                        stato: STATO_CARTA_ID_UPL,
                        note: ''
                    }).then((response) => {
                        endChiamata()
                        if (response.data.data && response.data.data.length > 0) {
                            handlerConferma(response.data.data[0])
                        }
                    });
                }}
            >
                {({values, errors, touched, submitCount}) => (
                    <Form>
                        <div className="row g-3">
                            {
                                tipiDocumento && tipiDocumento.map((doc, idx) => {
                                    return (
                                        <div className={"col-md-4 mt-4"} key={idx}>
                                            <UplFotoSocio
                                                formikRef={formikRef}
                                                errors={errors}
                                                touched={touched}
                                                submitCount={submitCount}
                                                socio={usr}
                                                documento={doc}
                                                checkIsEliminabile={() => true}
                                                token={token}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className={"row text-center mt-4"}>
                            <div className={"col-md-12"}>
                                <button type="button" id="salva" name="salva"
                                        className="btn btn-card"
                                        onClick={() => {
                                            if (formikRef.current) {
                                                formikRef.current.handleSubmit()
                                            }
                                        }}>
                                    <i className="fas fa-save"/> Conferma
                                </button>
                            </div>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
