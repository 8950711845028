import React, {useContext, useEffect, useState} from "react";
import RegistrazioneUtente from "./RegistrazioneUtente";
import Card from "../../components/Card";
import Steps from "../../components/Steps";
import ConfermaEmail from "./ConfermaEmail";
import RegistraCartaIdentita from "./RegistraCartaIdentita";
import {TranslateContext} from "../../context/TranslateContext";
import Translate from "../../components/Translate";
import {useParams} from "react-router-dom";

function Registrati() {
    const {tipo} = useParams()

    const [step, setStep] = useState(0);
    const [user, setUser] = useState();
    const [token, setToken] = useState();

    let debounce = null;

    const {traduci} = useContext(TranslateContext);

    useEffect(() => {
        return () => {
            if (debounce) {
                clearTimeout(debounce);
            }
        }
    }, []);

    const getTitolo = () => {
        let steps = [
            {idx: 1, name: traduci('Dati utente')},
            {idx: 2, name: traduci('Verifica indirizzo e-mail')},
            {idx: 3, name: traduci('Caricamento immagine documento d\'identità')}
        ]

        return (
            <Steps steps={steps} current={step}/>
        )
    }

    const setNextStep = (nStep) => {
        setStep(nStep)
        window.scrollTo(0, 0)
        document.querySelector("body").scrollTo(0,0)

        // Per Safari
        debounce = setTimeout(() => { window.scrollTo(0, 0); }, 100);
    }

    const setUtenteRegistrato = (usr) => {
        setUser(usr)
    }

    const setTokenRegistrato = (token) => {
        setToken(token)
    }

    return (
        <div className={"rfi-pagina"}>
            <div className={"row"}>
                <div className={"col-md-12 text-center"}>
                    <h1><Translate>Richiesta di iscrizione</Translate></h1>
                </div>
                <div className={"col-md-12 text-center pb-3"}>
                    <h6>Compilando la richiesta, la vostra iscrizione verrà verificata dal Registro e qualora le
                        informazioni risultino complete vi verrà inviato un link per completare l'iscrizione mediante il
                        pagamento della quota associativa. </h6>
                </div>
                <div className={"col-md-12 text-start pb-3 mt-2"}>
                    <strong>Quali sono i dati per diventare socio di Registro Fiat Italiano?</strong>
                    <p>Per effettuare la richiesta dovrai avere a portata di mano:</p>
                    <ul>
                        <li>Nome e Cognome del socio</li>
                        <li>Indirizzo e-mail</li>
                        <li>Indirizzo di residenza e spedizione</li>
                        <li>Dati anagrafici</li>
                        <li>Documento d'Identità e Codice Fiscale</li>
                    </ul>
                </div>
            </div>
            <Card
                title={getTitolo()}
            >
                {
                    step === 0 ? <RegistrazioneUtente
                        setNextStep={setNextStep}
                        setUser={setUtenteRegistrato}
                        tipo={tipo}/> : ''
                }
                {
                    step === 1 ? <ConfermaEmail
                        setNextStep={setNextStep}
                        setToken={setTokenRegistrato}
                        user={user}/> : ''
                }
                {
                    step === 2 ? <RegistraCartaIdentita
                        user={user}
                        token={token}/> : ''
                }
            </Card>
        </div>
    )
}

export default Registrati;
