import {Modal} from "react-bootstrap";
import {useContext} from "react";
import {TranslateContext} from "../context/TranslateContext";
import NsModalHeader from "./modal/NsModalHeader";

function ModalMsg({show, handleClose, titolo, testo}) {

    const {traduci} = useContext(TranslateContext)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <NsModalHeader title={titolo || traduci('ATTENZIONE')}/>
            <Modal.Body>
                {testo}
            </Modal.Body>
        </Modal>
    )

}

export default ModalMsg;