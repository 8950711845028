import React, {Fragment, useContext, useEffect, useState} from "react";
import Card from "../../components/Card";
import NsDataTable from "../../components/tabelle/NsDataTable";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {
    getDataFormattata,
    getLabelValidita,
    getRigaInfoSocio, TIPO_STAMPA_USR_ATTESTATI, TIPO_STAMPA_USR_BOLLI,
    TIPO_STAMPA_USR_LETTERA,
    TIPO_STAMPA_USR_TESSERA
} from "../../utility/common";
import {Link, useHistory, useParams} from "react-router-dom";
import ModalConfirm from "../../components/ModalConfirm";
import TabellaVeicoli from "../veicoli/TabellaVeicoli";
import TabellaCertificati from "../certificati/TabellaCertificati";
import ModalMsg from "../../components/ModalMsg";
import NsAccordion from "../../components/accordion/NsAccordion";

function InfoSocio() {
    const {id} = useParams()

    const [socio, setSocio] = useState();
    const [quotaSel, setQuotaSel] = useState();
    const [veicoliSocio, setVeicoliSocio] = useState([]);
    const [certificatiSocio, setCertificatiSocio] = useState([]);
    const [quoteSocio, setQuoteSocio] = useState([]);
    const [quoteSocioOld, setQuoteSocioOld] = useState([]);
    const [showModalDelQuota, setShowModalDelQuota] = useState(false);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const history = useHistory();

    function getVeicoliSocio() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/?id_utente=" + id).then((response) => {
            setVeicoliSocio(response.data.data);
            endChiamata()
        });
    }

    function getCertificati() {
        let f = '?pag=-1';

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id + "/certificato/" + f).then((response) => {
            setCertificatiSocio(response.data.data);
            endChiamata()
        });
    }

    function getQuoteSocio() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?id_utente=" + id).then((response) => {
            impostaQuote(response.data.data);
            endChiamata()
        });
    }

    function stampaLettera() {
        stampa(TIPO_STAMPA_USR_LETTERA);
    }

    function stampaTessera() {
        stampa(TIPO_STAMPA_USR_TESSERA);
    }

    function stampaBolliVeicoli() {
        stampa(TIPO_STAMPA_USR_BOLLI);
    }

    function stampaAttestitiVeicoli() {
        stampa(TIPO_STAMPA_USR_ATTESTATI);
    }

    function stampa(tipo) {
        let endpoint = process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id + "/stampa/?tipo=" + tipo + '&setStampato=1';

        startChiamata()
        axios.get(endpoint).then((response) => {
            endChiamata();
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    if (Array.isArray(resp)) {
                        for (let i = 0; i < resp.length; i++) {
                            downloadDoc(resp[i]);
                        }
                    } else {
                        downloadDoc(resp);
                    }
                }
            }
        });
    }

    function downloadDoc(resp) {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
        a.download = resp.nome_file; //File name Here
        a.click(); //Downloaded file
    }

    function getSocio() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id)
            .then((response) => {
                setSocio(response.data.data);
                endChiamata()
            });
    }

    useEffect(() => {
        getSocio();
        getVeicoliSocio();
        getQuoteSocio();
        getCertificati();
    }, [id]);

    const deleteVeicolo = (veicolo) => {
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + veicolo.id_veicolo + '?id_utente=' + id).then((response) => {
            setVeicoliSocio(response.data.data);
            endChiamata()
        });
    }

    const deleteCertificato = (certificato) => {
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + certificato.id_certificato + '?id_utente=' + id).then((response) => {
            setCertificatiSocio(response.data.data);
            endChiamata()
        });
    }

    const eliminaQuota = () => {
        setShowModalDelQuota(false);
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + quotaSel.id_quota + "?id_utente=" + id).then((response) => {
            impostaQuote(response.data.data);
            endChiamata()
        });
    }

    function impostaQuote(quote) {
        if (quote && quote.length > 0) {
            let annoRecente = quote[0].anno_validita;

            setQuoteSocio(quote.filter(q => q.anno_validita === annoRecente));
            setQuoteSocioOld(quote.filter(q => q.anno_validita !== annoRecente));
        } else {
            setQuoteSocio([]);
            setQuoteSocioOld([]);
        }
    }

    return (
        <Fragment>
            <ModalConfirm
                show={showModalDelQuota}
                handleClose={() => setShowModalDelQuota(false)}
                handleOk={eliminaQuota}
                testo={"Sicuro di voler eliminare la quota del: " + (quotaSel
                    && quotaSel.anno_validita) + "?"}/>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <Card
                        title={"Profilo"}
                        icon={"fas fa-user"}
                        getBottoniDx={() =>
                            <Fragment>
                                <button
                                    type={"button"}
                                    className="btn btn-card"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    <i className="fas fa-angle-left"/> Indietro
                                </button>
                                <button
                                    type={"button"}
                                    className="btn btn-card"
                                    onClick={stampaLettera}
                                >
                                    <i className="fas fa-print"/> Lettera
                                </button>
                                <button
                                    type={"button"}
                                    className="btn btn-card"
                                    onClick={stampaTessera}
                                >
                                    <i className="fas fa-print"/> Tessera
                                </button>
                                <Link to={"/upd-socio/" + id} className={"btn btn-card"}>
                                    <i className={"fas fa-pen"}/> Modifica
                                </Link>
                            </Fragment>
                        }>
                        {
                            socio ?
                                <Fragment>
                                    {
                                        getRigaInfoSocio(socio)
                                    }
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <hr/>
                                            <h6>Note</h6>
                                        </div>
                                        <div className={"col-md-12"}>
                                            {socio.note || '-'}
                                        </div>
                                    </div>
                                </Fragment>
                                : ''
                        }
                    </Card>
                </div>
                <div className={"col-md-6"}>
                    <Card
                        title={"Quote"}
                        icon={"fas fa-credit-card"}
                        getBottoniDx={() =>
                            <Link to={"/upd-quota/" + id}
                                  className="btn btn-card"
                                  data-toggle="tooltip" title="Aggiungi">
                                <i className="fas fa-plus-circle"/> Aggiungi
                            </Link>
                        }
                    >
                        <NsDataTable
                            data={quoteSocio}
                            columns={[
                                {
                                    name: 'Data',
                                    selector: row => getDataFormattata(row.data_pagamento),
                                    sortable: true,
                                },
                                {
                                    name: 'Importo €',
                                    selector: row => row.importo,
                                    sortable: true,
                                },
                                {
                                    name: 'Anno validità',
                                    selector: row => <Fragment>{row.anno_validita} ({getLabelValidita(row)})</Fragment>,
                                    sortable: true,
                                },
                                {
                                    name: 'Note',
                                    selector: row => row.note,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <Link to={"/upd-quota/" + row.id_utente + '/' + row.id_quota}
                                              className="btn btn-dark rfi-btn-table"
                                              data-toggle="tooltip" title="Modifica">
                                            <i className="fas fa-pen"/> <span
                                            className={'rfi-display-mobile'}>Modifica</span>
                                        </Link>
                                        <button className="btn btn-danger rfi-btn-table"
                                                data-toggle="tooltip" title="Elimina" onClick={() => {
                                            setQuotaSel(row)
                                            setShowModalDelQuota(true);
                                        }}>
                                            <i className="fas fa-trash"/> <span
                                            className={'rfi-display-mobile'}>Elimina</span>
                                        </button>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            addPagination={false}
                            addFiltri={false}
                        />
                        {
                            quoteSocioOld && quoteSocioOld.length > 0 &&
                            <NsAccordion
                                titolo={"Meno recenti"}
                                defaultKey={"-1"}
                            >
                                <NsDataTable
                                    data={quoteSocioOld}
                                    columns={[
                                        {
                                            name: 'Data',
                                            selector: row => getDataFormattata(row.data_pagamento),
                                            sortable: true,
                                        },
                                        {
                                            name: 'Importo €',
                                            selector: row => row.importo,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Anno validità',
                                            selector: row => <Fragment>{row.anno_validita} ({getLabelValidita(row)})</Fragment>,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Note',
                                            selector: row => row.note,
                                            sortable: true,
                                        },
                                        {
                                            name: '',
                                            selector: row => <Fragment>
                                                <Link to={"/upd-quota/" + row.id_utente + '/' + row.id_quota}
                                                      className="btn btn-dark rfi-btn-table"
                                                      data-toggle="tooltip" title="Modifica">
                                                    <i className="fas fa-pen"/> <span
                                                    className={'rfi-display-mobile'}>Modifica</span>
                                                </Link>
                                                <button className="btn btn-danger rfi-btn-table"
                                                        data-toggle="tooltip" title="Elimina" onClick={() => {
                                                    setQuotaSel(row)
                                                    setShowModalDelQuota(true);
                                                }}>
                                                    <i className="fas fa-trash"/> <span
                                                    className={'rfi-display-mobile'}>Elimina</span>
                                                </button>
                                            </Fragment>,
                                            sortable: false,
                                            right: true,
                                        }
                                    ]}
                                    addPagination={false}
                                    addFiltri={false}
                                />
                            </NsAccordion>
                        }
                    </Card>
                </div>
                <div className={"col-md-12"}>
                    <Card
                        title={"Veicoli"}
                        icon={"fas fa-car"}
                        getBottoniDx={() =>
                            <Fragment>
                                <button
                                    type={"button"}
                                    className="btn btn-card"
                                    onClick={stampaBolliVeicoli}
                                >
                                    <i className="fas fa-print"/> Stampa bolli
                                </button>
                                <button
                                    type={"button"}
                                    className="btn btn-card"
                                    onClick={stampaAttestitiVeicoli}
                                >
                                    <i className="fas fa-print"/> Stampa attestati
                                </button>
                                <Link to={"/upd-veicolo/" + id}
                                      className="btn btn-card"
                                      data-toggle="tooltip" title="Aggiungi">
                                    <i className="fas fa-plus-circle"/> Aggiungi
                                </Link>
                            </Fragment>
                        }
                    >
                        <TabellaVeicoli
                            veicoli={veicoliSocio}
                            deleteVeicolo={deleteVeicolo}
                        />
                    </Card>
                </div>
                <div className={"col-md-12"}>
                    <Card
                        title={"Certificati"}
                        icon={"fas fa-file-signature"}
                        getBottoniDx={() =>
                            <Link to={"/upd-certificato/" + id}
                                  className="btn btn-card"
                                  data-toggle="tooltip" title="Aggiungi">
                                <i className="fas fa-plus-circle"/> Aggiungi
                            </Link>
                        }
                    >
                        <TabellaCertificati
                            certificati={certificatiSocio}
                            deleteCertificato={deleteCertificato}
                        />
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}

export default InfoSocio;
