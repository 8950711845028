import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";
import {TranslateContext} from "../../../context/TranslateContext";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";

function ModificaRegistroVeicoli() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getRegistroVeicolo() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/registro/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_registro", val.id_registro);
                    formikRef.current.setFieldValue("descrizione", val.descrizione);
                    formikRef.current.setFieldValue("suffisso", val.suffisso);
                    formikRef.current.setFieldValue("sort", val.sort);
                    formikRef.current.setFieldValue("quale", val.quale);
                }
                endChiamata()
            });
    }

    useEffect(() => {
        if (loadingData) {
            if (id && id !== '') {
                getRegistroVeicolo();
            }
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        id_registro: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        descrizione: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        suffisso: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri')),
        sort: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        quale: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
    });

    return (
        <Card
            icon={'fas fa-folder'}
            title={id ? 'Modifica Registro di Veicoli' : 'Inserisci Registro di Veicoli'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
        }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_registro: '',
                    descrizione: '',
                    suffisso: '',
                    sort: '0',
                    quale: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/registro/" + id,
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/registro/",
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-12">
                            <label htmlFor="id_registro" className="form-label">Codice registro*:</label>
                            <Field
                                name="id_registro"
                                className="form-control"
                                readOnly={id}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_registro"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="descrizione" className="form-label">Descrizione club*:</label>
                            <Field
                                name="descrizione"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"descrizione"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="suffisso" className="form-label">Suffisso:</label>
                            <Field
                                name="suffisso"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"suffisso"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="sort" className="form-label">Ordinamento:</label>
                            <Field
                                name="sort"
                                className="form-control"
                                type={"number"}
                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"suffisso"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="abarth" className="form-label">Tipo di registro*:</label>
                            <Field
                                as={"select"}
                                name="quale"
                                className="form-control form-select"
                                value={values['quale']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("quale", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                <option value={'3'}>RFI</option>
                                <option value={'5'}>Abarth</option>
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"quale"}
                                              submitCount={submitCount}/>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaRegistroVeicoli;