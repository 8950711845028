import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import {Field} from "formik";
import 'react-medium-image-zoom/dist/styles.css'
import LabelErroreCampo from "../../components/LabelErroreCampo";
import ContainerFotoCaricata from "../../components/ContainerFotoCaricata";
import ModalConfirm from "../../components/ModalConfirm";
import NsFilePond from "../../components/NsFilePond";
import {convertiDateInDataPerDb, isDocumentoScaduto} from "../../utility/common";
import NsDatePicker from "../../components/NsDatePicker";
import ModalMsg from "../../components/ModalMsg";
import {ClipLoader} from "react-spinners";

function UplFotoSocio({
                          documento,
                          socio,
                          errors,
                          touched,
                          submitCount,
                          formikRef,
                          checkIsEliminabile = (documento) => true,
                          token
                      }) {

    const [foto, setFoto] = useState(socio.documenti?.filter(foto => foto.tipo === documento.id_tipo_documento_utente))
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [scadenza, setScadenza] = useState();
    const [infoDoc, setInfoDoc] = useState();

    const url = process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + socio.id_utente + '/carta-identita/';

    useEffect(() => {
        formikRef.current.setFieldValue("foto_" + documento.id_tipo_documento_utente, '')

        if (foto && foto.length > 0) {
            formikRef.current.setFieldValue("foto_" + documento.id_tipo_documento_utente, '1')

            setLoadSpinner(true);
            axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + socio.id_utente + '/documento/' + foto[0].id_documento_utente)
                .then((response) => {
                    setLoadSpinner(false);
                    setInfoDoc(response.data.data)
                });
        }
    }, [foto]);

    function getContainerFotoCaricata() {
        return <ContainerFotoCaricata
            label={documento.descrizione}
            content_type={infoDoc.content_type}
            contenuto={infoDoc.contenuto}
            nome={infoDoc.nome_file_originale}
            isEliminabile={checkIsEliminabile(foto[0])}
            isScaduto={isDocumentoScaduto(foto[0])}
            eliminaFoto={() => {
                setShow(true)
            }}
        />
    }

    function getFilePondUploadFoto() {
        return <Fragment>
            <div className={"row mb-2"}>
                <div className={"col-md-12"}>
                    {
                        documento.richiedi_scadenza == '1' ?
                            <NsDatePicker
                                anniEnd={30}
                                nome={"scadenza_" + documento.id_tipo_documento_utente}
                                setSelectedDate={(date, ev) => {
                                    setScadenza(date);
                                }}
                                minDate={new Date()}
                                placeholder={"Data di scadenza"}
                                dataSelezionata={scadenza}
                            />
                            : <p>&nbsp;</p>
                    }
                </div>
            </div>
            <NsFilePond
                multiple={false}
                id={documento.id_tipo_documento_utente}
                acceptedFileTypes={["image/x-png", "image/png", "image/gif", "image/jpeg", "image/bmp", "image/svg+xml"
                    , "image/heic", "application/pdf", "application/pdf", "application/msword"
                    , "application/vnd.openxmlformats-", "officedocument.wordprocessingml.document"]}
                handleFiles={(file) => {
                    if ((documento && documento.richiedi_scadenza != '1') || (scadenza && scadenza !== '')) {
                        setLoadSpinner(true);

                        const formData = new FormData();
                        formData.append("files", file);

                        let params = '?tipo=' + documento.id_tipo_documento_utente
                            + (scadenza && scadenza !== '' ? '&scadenza=' + convertiDateInDataPerDb(scadenza) : '');
                        axios
                            .post(url + params, formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                    'jwt': token
                                },
                            })
                            .then((response) => {
                                setFoto([response.data])
                                setLoadSpinner(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoadSpinner(false);
                            });
                    } else {
                        setShowAlert(true);
                    }
                }}
            >
                <div>
                    <p>{documento.descrizione}</p>
                </div>
                <div className="ns-foto--gray">
                    {
                        loadSpinner === true ?
                            <ClipLoader
                                color={'#355d98'}
                                loading={loadSpinner}
                                size={80}/>
                            :
                            <img src={"assets/img/" + documento.immagine}/>
                    }
                </div>
            </NsFilePond>
        </Fragment>
    }

    return (
        <Fragment>
            <ModalMsg
                show={showAlert}
                handleClose={() => setShowAlert(false)}
                testo={"Inserire la Data di scadenza prima di caricare l'immagine"}/>
            <ModalConfirm
                show={show}
                handleClose={() => setShow(false)}
                handleOk={() => {
                    setShow(false);
                    if (foto && foto.length > 0) {
                        axios.delete(url + foto[0].id_documento_utente + '?tipo=' + documento.id_tipo_documento_utente).then((response) => {
                            setFoto([])
                            formikRef.current.setFieldValue("foto_" + documento.id_tipo_documento_utente, '')
                        });
                    }
                }}
                testo={"Sicuro di voler eliminare la foto del documento?"}/>
            {
                formikRef.current.values["foto_" + documento.id_tipo_documento_utente] &&
                <Fragment>
                    <Field name={"foto_" + documento.id_tipo_documento_utente} type={"hidden"}
                           validate={(value) => {
                               if ((!value || value === '') && documento.obbligatorio) {
                                   return 'Inserire foto'
                               }
                           }}/>
                    <LabelErroreCampo errors={errors}
                                      touched={touched}
                                      name={"foto_" + documento.id_tipo_documento_utente}
                                      submitCount={submitCount}/>
                </Fragment>
            }
            {
                foto && foto.length > 0 && infoDoc ?
                    getContainerFotoCaricata()
                    : getFilePondUploadFoto()
            }
        </Fragment>
    )
}

export default UplFotoSocio;
