import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {Field, Form, Formik} from "formik";
import ModalMsg from "../../components/ModalMsg";
import {useHistory} from "react-router-dom";
import {formattaImporto, TIPO_MODULO_CERTIFICATO_VEICOLO, TIPO_MODULO_REGISTRAZIONE} from "../../utility/common";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import Card from "../../components/Card";
import ModalBonifico from "./ModalBonifico";
import ModalBollettino from "./ModalBollettino";
import ModalContanti from "./ModalContanti";
import ModalAssegno from "./ModalAssegno";

function ModuloPagamentoQuota({usr, tipo, idCertificato = null}) {

    const [infoPagamento, setInfoPagamento] = useState('');
    const [show, setShow] = useState(false);
    const [showModalBonifico, setShowModalBonifico] = useState(false);
    const [showModalBollettino, setShowModalBollettino] = useState(false);
    const [showModalContanti, setShowModalContanti] = useState(false);
    const [showModalAssegno, setShowModalAssegno] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [costo, setCosto] = useState(0);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const formikRef = useRef();
    const history = useHistory();

    function getInfoPagamento() {
        if (tipo == TIPO_MODULO_CERTIFICATO_VEICOLO) {
            if (idCertificato) {
                startChiamata()
                axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/rfi/certificato/${idCertificato}/info-pagamento/`).then((response) => {
                    setInfoPagamento(response.data.data)
                    getImportoDaPagare(response.data.data);
                    endChiamata()
                });
            }
        } else {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/rfi/utente/${usr.id_utente}/info-pagamento/`).then((response) => {
                setInfoPagamento(response.data)
                getImportoDaPagare(response.data);
                endChiamata()
            });
        }
    }

    useEffect(() => {
        getInfoPagamento()
    }, [usr, idCertificato]);

    function initQuota2Save(values, modalitaPagamento) {
        let newValues = values;
        newValues['nome'] = usr.nome;
        newValues['cognome'] = usr.cognome;
        newValues['tel_casa'] = usr.tel_casa;
        newValues['email'] = usr.email;
        newValues['id_marca'] = infoPagamento.tipo;
        newValues['rinnovo'] = infoPagamento.rinnovo;
        newValues['targa'] = infoPagamento.targa;
        newValues['tipo_certificato'] = infoPagamento.tipo_certificato;
        newValues['id_tipo_certificato'] = infoPagamento.id_tipo_certificato;

        // Adesione ASI
        if (newValues['chk_asi'] && newValues['chk_asi'] !== 'chk_noasi') {
            newValues[newValues['chk_asi']] = '1';
        }
        delete newValues['chk_asi'];

        if (modalitaPagamento && modalitaPagamento !== '') {
            newValues['id_modalita'] = modalitaPagamento;
        }

        if (infoPagamento && infoPagamento.pagamentiRichiesti) {
            infoPagamento.pagamentiRichiesti.forEach(tipo => {
                if (infoPagamento.pagamentiRichiesti.length === 1
                    || tipo.obbligatorio == '1') {
                    newValues["chk_" + tipo.id_tipo_versamento] = '1';
                }
            });
        }
        return newValues;
    }

    function salvaQuotaTemp(modalitaPagamento) {
        let newValues = initQuota2Save(formikRef.current.values, modalitaPagamento);

        if (tipo == TIPO_MODULO_CERTIFICATO_VEICOLO) {
            startChiamata()
            axios
                .post(process.env.REACT_APP_API_ENDPOINT + `/api/rfi/certificato/${idCertificato}/quota-temp/`,
                    newValues)
                .then((response) => {
                    endChiamata();
                    if (response.data.result === 0) {
                        history.push("/richiesta-pagamento-ok");
                    } else {
                        setModalMsg(response.data.description);
                        setShow(true);
                    }
                });
        } else {
            startChiamata()
            axios
                .post(process.env.REACT_APP_API_ENDPOINT + `/api/rfi/utente/${usr.id_utente}/quota-temp/`,
                    newValues)
                .then((response) => {
                    endChiamata();
                    if (response.data.result === 0) {
                        history.push("/richiesta-pagamento-ok");
                    } else {
                        setModalMsg(response.data.description);
                        setShow(true);
                    }
                });
        }
    }

    function getImportoDaPagare(infoPag, values) {
        let importo = 0;
        let ip = infoPag || infoPagamento;
        let v = values || (formikRef && formikRef.current ? formikRef.current.values : null);

        if (ip && ip.pagamentiRichiesti) {
            ip.pagamentiRichiesti.forEach(pag => {
                if (pag.obbligatorio == '1') {
                    importo += parseFloat(pag.costo);
                }
            });

            if (ip.pagamentiRichiesti.length === 1) {
                importo = ip.pagamentiRichiesti[0].costo;
            }

            if (v) {
                Object.keys(v).forEach(key => {
                    if (key.startsWith('chk_')) {
                        let idTipoPag = '';

                        if (key === 'chk_asi') {
                            if (v[key] !== 'chk_noasi') {
                                idTipoPag = v[key].replaceAll('chk_', '');
                            }
                        } else if (v[key] === '1') {
                            idTipoPag = key.replaceAll('chk_', '');
                        }

                        if (idTipoPag !== '') {
                            ip.pagamentiRichiesti.forEach(pag => {
                                if (pag.id_tipo_versamento == idTipoPag && pag.obbligatorio == '0') {
                                    importo += parseFloat(pag.costo);
                                }
                            });
                        }
                    }
                })
            }
        }
        setCosto(parseFloat(importo));
    }

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <ModalBonifico
                show={showModalBonifico}
                handleClose={() => setShowModalBonifico(false)}
                handlePagamento={(modalitaPagamento) => salvaQuotaTemp(modalitaPagamento)}
            />
            <ModalBollettino
                show={showModalBollettino}
                handleClose={() => setShowModalBollettino(false)}
                handlePagamento={(modalitaPagamento) => salvaQuotaTemp(modalitaPagamento)}
            />
            <ModalContanti
                show={showModalContanti}
                handleClose={() => setShowModalContanti(false)}
                handlePagamento={(modalitaPagamento) => salvaQuotaTemp(modalitaPagamento)}
            />
            <ModalAssegno
                show={showModalAssegno}
                handleClose={() => setShowModalAssegno(false)}
                handlePagamento={(modalitaPagamento) => salvaQuotaTemp(modalitaPagamento)}
            />
            <Formik
                initialValues={{
                    nome: usr.nome,
                    cognome: usr.cognome,
                    numero_tessera: '', // TODO
                    tel_casa: usr.tel_casa,
                    email: usr.email,
                    id_marca: infoPagamento.tipo,
                    rinnovo: infoPagamento.rinnovo,
                    targa: infoPagamento.targa,
                    tipo_certificato: infoPagamento.tipo_certificato,
                    id_tipo_certificato: infoPagamento.id_tipo_certificato,
                    chk_asi: 'chk_noasi',
                }}
                innerRef={formikRef}
                onSubmit={values => {
                    let newValues = initQuota2Save(values);

                    startChiamata()
                    let servizio = tipo == TIPO_MODULO_CERTIFICATO_VEICOLO ? `/api/rfi/certificato/${idCertificato}/pagamento/`
                        : `/api/rfi/utente/${usr.id_utente}/pagamento/`;
                    axios
                        .post(process.env.REACT_APP_API_ENDPOINT + servizio,
                            newValues)
                        .then((response) => {
                            if (response.data.result === 0) {
                                endChiamata()

                                // Recupero il risultato del pagamento e faccio una redirect su Sella
                                let esitoPagamento = response.data.data;
                                if (esitoPagamento) {
                                    history.push({
                                        pathname: '/go-to-sella',
                                        state: {esito: esitoPagamento},
                                    });
                                }
                            } else {
                                setModalMsg(response.data.description);
                                setShow(true);
                                endChiamata()
                            }
                        });
                }}
            >
                {({values, errors, touched, submitCount}) => (
                    <Form>
                        <div className={"row text-center mb-4"}>
                            <div className={"col-md-12"}>
                                {
                                    tipo == TIPO_MODULO_REGISTRAZIONE ?
                                        <Fragment>
                                            <h1>Versa
                                                Quota {infoPagamento && infoPagamento.tipo == '2' ? 'Abarth' : 'Fiat'}</h1>
                                            <h5>Non vediamo l'ora di averti tra noi! Hai 30 giorni di tempo per versare
                                                la tua
                                                Quota {infoPagamento && infoPagamento.tipo == '2' ? 'Abarth' : 'Fiat'}.
                                                Dopo tale
                                                data la tua registrazione verrà automaticamente rifiutata e dovrai
                                                ripetere l'intero
                                                procedimento.</h5>
                                        </Fragment>
                                        : ''
                                }
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={'col-md-4'}>
                                <Card
                                    icon={"fas fa-shopping-cart"}
                                    title={"Quote iscrizione"}
                                >
                                    {
                                        infoPagamento && infoPagamento.pagamentiRichiesti ?
                                            infoPagamento.pagamentiRichiesti.filter(tipo => tipo.asi == '0')
                                                .map(tipo => <p key={tipo.id_tipo_versamento}>
                                                    <label htmlFor={"chk_" + tipo.id_tipo_versamento}
                                                           className="form-label">
                                                        <Field
                                                            type="checkbox"
                                                            name={"chk_" + tipo.id_tipo_versamento}
                                                            checked={infoPagamento.pagamentiRichiesti.length === 1
                                                                || values["chk_" + tipo.id_tipo_versamento] == '1'
                                                                || tipo.obbligatorio == '1'}
                                                            disabled={infoPagamento.pagamentiRichiesti.length === 1
                                                                || tipo.obbligatorio == '1'}
                                                            onChange={(ev) => {
                                                                values["chk_" + tipo.id_tipo_versamento] = ev.target.checked ? '1' : '0';
                                                                getImportoDaPagare(infoPagamento, values);
                                                                formikRef.current.setFieldValue("chk_" + tipo.id_tipo_versamento, ev.target.checked ? '1' : '0')
                                                            }}
                                                            className="form-check-input"/>
                                                        &nbsp;
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: tipo.descrizione_html}}/>
                                                        {
                                                            tipo.info && tipo.info !== '' &&
                                                            <span>&nbsp;({tipo.info})</span>
                                                        }
                                                    </label>
                                                </p>)
                                            : ''
                                    }
                                    {
                                        infoPagamento && infoPagamento.pagamentiRichiesti &&
                                        infoPagamento.pagamentiRichiesti.filter(tipo => tipo.asi == '1').length > 0 &&
                                        <div className={"mt-2 mb-2"}>
                                            <p key={'noasi'}>
                                                <label htmlFor={"chk_" + tipo.id_tipo_versamento}
                                                       className="form-label">
                                                    <Field
                                                        type="radio"
                                                        name={"chk_asi"}
                                                        value={"chk_noasi"}
                                                        checked={values && values["chk_asi"] && values["chk_asi"] == 'chk_noasi'}
                                                        onChange={(ev) => {
                                                            if (ev.target.checked) {
                                                                values["chk_asi"] = "chk_noasi";
                                                                getImportoDaPagare(infoPagamento, values);
                                                                formikRef.current.setFieldValue("chk_asi", 'chk_noasi');
                                                            }
                                                        }}/>
                                                    &nbsp;
                                                    {
                                                        infoPagamento && infoPagamento.rinnovo == '1' ?
                                                            <span>Non desidero rinnovare la tessera ASI</span>
                                                            :
                                                            <span>Non desidero ricevere la tessera ASI</span>
                                                    }
                                                </label>
                                            </p>
                                            {
                                                infoPagamento && infoPagamento.rinnovo == '1' ?
                                                    <h5>Per chi desidera rinnovare la tessera ASI, integrare il versamento
                                                        con:</h5>
                                                    :
                                                    <h5>Per chi desidera la tessera ASI, integrare il versamento
                                                        con:</h5>
                                            }
                                        </div>
                                    }
                                    {
                                        infoPagamento && infoPagamento.pagamentiRichiesti ?
                                            infoPagamento.pagamentiRichiesti.filter(tipo => tipo.asi == '1')
                                                .map(tipo => <p key={tipo.id_tipo_versamento}>
                                                    <label htmlFor={"chk_asi"} className="form-label">
                                                        <Field
                                                            type="radio"
                                                            name={"chk_asi"}
                                                            value={"chk_" + tipo.id_tipo_versamento}
                                                            checked={infoPagamento.pagamentiRichiesti.length === 1
                                                                || values["chk_asi"] == "chk_" + tipo.id_tipo_versamento
                                                                || tipo.obbligatorio == '1'}
                                                            disabled={infoPagamento.pagamentiRichiesti.length === 1
                                                                || tipo.obbligatorio == '1'}
                                                            onChange={(ev) => {
                                                                if (ev.target.checked) {
                                                                    values["chk_asi"] = "chk_" + tipo.id_tipo_versamento;
                                                                    getImportoDaPagare(infoPagamento, values);
                                                                    formikRef.current.setFieldValue("chk_asi", "chk_" + tipo.id_tipo_versamento);
                                                                }
                                                            }}/>
                                                        &nbsp;
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: tipo.descrizione_html}}/>
                                                        {
                                                            tipo.info && tipo.info !== '' &&
                                                            <span className={"rfi-font--12"}>&nbsp;({tipo.info})</span>
                                                        }
                                                    </label>
                                                </p>)
                                            : ''
                                    }
                                </Card>
                            </div>
                            <div className={'col-md-8'}>
                                <Card
                                    icon={"fas fa-euro-sign"}
                                    title={"Importo da pagare"}
                                >
                                    <div className={"row text-center"}>
                                        <div className={"col-12"}>
                                            <h1>{formattaImporto(costo)}&nbsp;€</h1>
                                        </div>
                                    </div>
                                </Card>

                                <Card
                                    icon={"fas fa-credit-card"}
                                    title={"Banca Sella"}
                                    classiAggiuntiveHeader={"rfi-card-header-sella"}
                                >
                                    <div className={"row text-center"}>
                                        <div className={"col-12"}>
                                            <img src={"assets/img/pagamento-sella.png"}
                                                 className={"img-fluid m-4 rfi-img-pagamento-sella"}/>

                                            <p>Effettua il pagamento con carta di credito attraverso il sito sicuro di
                                                Banca
                                                Sella.</p>
                                            <p>Al termine dell’operazione riceverai una e-mail di conferma. La
                                                validazione del pagamento sarà immediata.</p>

                                            <button
                                                className={"btn btn-danger btn-lg btn-sella"}
                                                disabled={costo <= 0}
                                            >
                                                <i className="fas fa-money-bill-wave"></i> Procedi
                                            </button>
                                        </div>
                                    </div>
                                </Card>

                                <Card
                                    icon={"fas fa-credit-card"}
                                    title={"Altri metodi di pagamento"}
                                    classiAggiuntiveHeader={"rfi-card-header-altri-pagamenti"}
                                >
                                    <div className={"row text-center"}>
                                        <div className={"col-md-12"}>
                                            <button type="button" className={"btn btn-link btn-lg"} disabled={costo <= 0} onClick={() => {
                                                setShowModalBonifico(true)
                                            }}>
                                                <i className="fas fa-piggy-bank"></i> Bonifico bancario
                                            </button>
                                            <button type="button" className={"btn btn-link btn-lg"} disabled={costo <= 0} onClick={() => {
                                                setShowModalBollettino(true)
                                            }}>
                                                <i className="fas fa-money-check-alt"></i> Bollettino postale
                                            </button>
                                            <button type="button" className={"btn btn-link btn-lg"} disabled={costo <= 0} onClick={() => {
                                                setShowModalContanti(true)
                                            }}>
                                                <i className="fas fa-money-bill-wave"/> Contanti
                                            </button>
                                            <button type="button" className={"btn btn-link btn-lg"} disabled={costo <= 0} onClick={() => {
                                                setShowModalAssegno(true)
                                            }}>
                                                <i className="fas fa-money-check"/> Assegno
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default ModuloPagamentoQuota;
