import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {
    getIconaStatoCertificato,
    getStatoCertificato,
    downloadDoc,
    STATO_CERTIFICATO_APPROVATO_DA_STAFF,
    STATO_CERTIFICATO_BOZZA,
    STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE,
    STATO_CERTIFICATO_IN_APPROVAZIONE,
    STATO_IN_VERIFICA_COMMISSARIO
} from "../../utility/common";
import {RuoliContext} from "../../context/RuoliContext";
import axios from "axios";
import ModalConfirm from "../../components/ModalConfirm";
import {LoaderContext} from "../../context/LoaderContext";
import ModalAssegnaCommissario from "./ModalAssegnaCommissario";
import ModalMsg from "../../components/ModalMsg";
import ModalNotaCert from "./ModalNotaCert";
import {AuthContext} from "../../context/AuthContext";

function TabellaCertificati({certificati, totCertificati, deleteCertificato, postUpdate}) {

    const [show, setShow] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [showRiportaBozza, setShowRiportaBozza] = useState(false);
    const [showRiportaRichiestaEff, setShowRiportaRichiestaEff] = useState(false);
    const [showModalCommissari, setShowModalCommissari] = useState(false);
    const [showModalNoteCert, setShowModalNoteCert] = useState(false);
    const [certificatoSel, setCertificatoSel] = useState(null);
    const [modalMsg, setModalMsg] = useState(false);

    const {isRuoloSocio, isRuoloAdmin, isRuoloSegreteria, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);
    const {isValido} = useContext(AuthContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const history = useHistory();

    useEffect(() => {
    }, []);

    function stampaCertificato(row) {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + row.id_certificato + "/stampa/" + row.id_tipo_certificato).then((response) => {
            endChiamata();
            if (response.data.result == -1) {
                setModalMsg(response.data.description)
                setShowMsg(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    if (Array.isArray(resp)) {
                        for (let i = 0; i < resp.length; i++) {
                            downloadDoc(resp[i]);
                        }
                    } else {
                        downloadDoc(resp);
                    }
                }
            }
        });
    }

    return (
        <Fragment>
            <ModalMsg
                show={showMsg}
                handleClose={() => setShowMsg(false)}
                testo={modalMsg}/>
            <ModalConfirm
                show={show}
                handleClose={() => setShow(false)}
                handleOk={() => {
                    setShow(false);
                    deleteCertificato(certificatoSel);
                }}
                testo={"Sicuro di voler eliminare il certificato: " + (certificatoSel
                    && certificatoSel.numero_certificato) + "?"}/>
            <ModalConfirm
                show={showRiportaBozza}
                handleClose={() => setShowRiportaBozza(false)}
                handleOk={() => {
                    setShowRiportaBozza(false);

                    let newCert = certificatoSel;
                    newCert.stato = STATO_CERTIFICATO_BOZZA;

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                            + certificatoSel.id_certificato + '/stato/', newCert)
                        .then((response) => {
                            endChiamata()

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShow(true);
                            } else {
                                postUpdate();
                            }
                        });
                }}
                testo={"Sicuro di voler riportare in bozza il certificato: " + (certificatoSel
                    && certificatoSel.numero_certificato) + "?"}/>
            <ModalConfirm
                show={showRiportaRichiestaEff}
                handleClose={() => setShowRiportaRichiestaEff(false)}
                handleOk={() => {
                    setShowRiportaRichiestaEff(false);

                    let newCert = certificatoSel;
                    newCert.stato = STATO_CERTIFICATO_IN_APPROVAZIONE;

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                            + certificatoSel.id_certificato + '/stato/', newCert)
                        .then((response) => {
                            endChiamata()

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShow(true);
                            } else {
                                postUpdate();
                            }
                        });
                }}
                testo={"Sicuro di voler riportare in richiesta effettuta il certificato: " + (certificatoSel
                    && certificatoSel.numero_certificato) + "?"}/>
            {
                (isRuoloSegreteria() || isRuoloAdmin()) &&
                <ModalAssegnaCommissario
                    show={showModalCommissari}
                    handleClose={() => setShowModalCommissari(false)}
                    certificato={certificatoSel}
                    postUpdate={postUpdate}
                />
            }
            <ModalNotaCert
                certificato={certificatoSel}
                show={showModalNoteCert}
                handleClose={() => {
                    setShowModalNoteCert(false)
                }}
                handleOk={(note) => {
                    setShowModalNoteCert(false)

                    startChiamata();
                    axios
                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                            + certificatoSel.id_certificato + '/nota/', {
                            id_utente: certificatoSel.id_utente,
                            id_certificato: certificatoSel.id_certificato,
                            note: note
                        })
                        .then((response) => {
                            endChiamata()

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShow(true);
                            }
                        });
                }}
            />
            <NsDataTable
                data={certificati}
                totRighe={totCertificati}
                columns={[
                    {
                        name: 'Numero Certificato',
                        selector: row => row.numero_certificato,
                        sortable: true,
                    },
                    {
                        name: 'Socio',
                        selector: row => (row.nome || '') + ' ' + (row.cognome || ''),
                        sortable: true,
                    },
                    {
                        name: 'Tipo Certificato',
                        selector: row => row.tipo_certificato,
                        sortable: true,
                    },
                    {
                        name: 'Marca',
                        selector: row => row.marca,
                        sortable: true,
                    },
                    {
                        name: 'Modello',
                        selector: row => row.tipo,
                        sortable: true,
                    },
                    {
                        name: 'N° registrazione',
                        selector: row => (row.numero_registrazione + row.suffisso || '-'),
                        sortable: true,
                        visible: () => {
                            return !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia();
                        }
                    },
                    {
                        name: 'Targa',
                        selector: row => row.targa,
                        sortable: true,
                    },
                    {
                        name: 'Stato',
                        selector: row => isRuoloSocio() || isRuoloClub() || isRuoloAgenzia() ? getStatoCertificato(row) : getIconaStatoCertificato(row),
                        sortable: true,
                    },
                    {
                        name: 'In carico',
                        selector: row => (row.segreteria || '-') + (row.commissario && row.commissario !== '' ? ' - ' + row.commissario : ''),
                        sortable: true,
                        visible: () => {
                            return !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia();
                        }
                    },
                    {
                        name: 'Note',
                        selector: row => <i
                            className={"fas fa-comment rfi-icon-table ns-span" + (row.num_note && row.num_note > 0 ? '--verde' : '--rosso')}
                            data-toggle="tooltip"
                            title={"Note " + (row.num_note && row.num_note > 0 ? 'presenti' : 'NON presenti')}/>,
                        sortable: true,
                        visible: () => {
                            return !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia();
                        }
                    },
                    {
                        name: '',
                        selector: row => <Fragment>
                            {
                                ((!isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia()) || (row.stato == STATO_CERTIFICATO_BOZZA && isValido())) &&
                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                    <div className="btn-group" role="group">
                                        <button id="btnGroupDrop1" type="button"
                                                className="btn btn-dark dropdown-toggle"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa-solid fa-bars"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                            {
                                                !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() ?
                                                    <li>
                                                        <Link to={"/info-certificato/" + row.id_certificato}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Dettaglio">
                                                            <i className="fas fa-eye"/> Dettaglio
                                                        </Link>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                /*
                                                Possono modificare tutti finché lo stato è bozza. Altrimenti possono modificare solo
                                                gli admin e commissari fintanto che lo stato non è "approvato da staff"
                                                 */
                                                (row.stato == STATO_CERTIFICATO_BOZZA || (!isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia())) &&
                                                <li>
                                                    <Link
                                                        to={"/upd-certificato/" + row.id_utente + "/" + row.id_certificato}
                                                        className="dropdown-item"
                                                        data-toggle="tooltip" title="Modifica">
                                                        <i className="fas fa-pen"/> Modifica
                                                    </Link>
                                                </li>
                                            }
                                            {
                                                (isRuoloAdmin() || isRuoloSegreteria()) &&
                                                row.stato != STATO_CERTIFICATO_BOZZA &&
                                                row.stato != STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE &&
                                                row.stato != STATO_CERTIFICATO_APPROVATO_DA_STAFF ?
                                                    <li>
                                                        <button className="dropdown-item" data-toggle="tooltip"
                                                                title="Assegna a commissario"
                                                                onClick={() => {
                                                                    setCertificatoSel(row);
                                                                    setShowModalCommissari(true);
                                                                }}>
                                                            <i className="fas fa-user-tag"/> Assegna a commissario
                                                        </button>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                (isRuoloAdmin() || isRuoloSegreteria()) &&
                                                row.stato == STATO_IN_VERIFICA_COMMISSARIO &&
                                                <li>
                                                    <button className="dropdown-item" data-toggle="tooltip"
                                                            title="Riprendi in carico"
                                                            onClick={() => {
                                                                startChiamata()
                                                                axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + row.id_certificato + "/segreteria/", row).then((response) => {
                                                                    endChiamata();
                                                                    postUpdate();
                                                                });
                                                            }}>
                                                        <i className="fas fa-user-cog"/> Riprendi in carico
                                                    </button>
                                                </li>
                                            }
                                            {
                                                (isRuoloAdmin() || isRuoloSegreteria()) &&
                                                <Fragment>
                                                    <li>
                                                        <button className="dropdown-item" data-toggle="tooltip"
                                                                title="Invia nota al socio"
                                                                onClick={() => {
                                                                    setCertificatoSel(row);
                                                                    setShowModalNoteCert(true);
                                                                }}>
                                                            <i className="fas fa-paper-plane"/> Invia nota al socio
                                                        </button>
                                                    </li>
                                                    {
                                                        row.stato != STATO_CERTIFICATO_BOZZA ?
                                                            <li>
                                                                <button className="dropdown-item" data-toggle="tooltip"
                                                                        title="Riporta in bozza"
                                                                        onClick={() => {
                                                                            setCertificatoSel(row);
                                                                            setShowRiportaBozza(true);
                                                                        }}>
                                                                    <i className="fab fa-firstdraft"/> Riporta in bozza
                                                                </button>
                                                            </li>
                                                            :
                                                            <li>
                                                                <button className="dropdown-item" data-toggle="tooltip"
                                                                        title="Riporta in richiesta effettuata"
                                                                        onClick={() => {
                                                                            setCertificatoSel(row);
                                                                            setShowRiportaRichiestaEff(true);
                                                                        }}>
                                                                    <i className="fas fa-paper-plane"/> Riporta in richiesta effettuata
                                                                </button>
                                                            </li>
                                                    }
                                                </Fragment>
                                            }
                                            {
                                                !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() ?
                                                    <li>
                                                        <button
                                                            className="dropdown-item"
                                                            data-toggle="tooltip"
                                                            title="Scarica documento"
                                                            onClick={() => {
                                                                stampaCertificato(row);
                                                            }}>
                                                            <i className="fas fa-file-download"/> Scarica documento
                                                        </button>
                                                    </li>
                                                    : ''
                                            }
                                            {
                                                isRuoloAdmin() || isRuoloSegreteria() || row.stato == STATO_CERTIFICATO_BOZZA ?
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina" onClick={() => {
                                                            setCertificatoSel(row)
                                                            setShow(true);
                                                        }}>
                                                            <i className="fas fa-trash"/> Elimina
                                                        </button>
                                                    </li>
                                                    : ''
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </Fragment>,
                        sortable: false,
                        right: true,
                    }
                ]}
                onRowDbClick={row => {
                    if (!isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia()) {
                        history.push("/info-certificato/" + row.id_certificato);
                    }
                }}
                addPagination={false}
                addFiltri={false}
            >
            </NsDataTable>
        </Fragment>
    );
}

export default TabellaCertificati;
