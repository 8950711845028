import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import * as Yup from "yup";
import {TranslateContext} from "../../context/TranslateContext";
import {Field, Form, Formik} from "formik";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import NsDatePicker from "../../components/NsDatePicker";
import {convertiTimestampInDataPerDb, STATO_RELAZIONE_APPROVATA, STATO_RELAZIONE_RIFIUTATA} from "../../utility/common";

function ModificaRelazione() {
    const {idCertificato, idRelazione} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getRelazione() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato + '/relazione/' + idRelazione).then((response) => {
            setLoadingData(false);

            let relazione = response.data.data;
            if (relazione && formikRef.current) {
                formikRef.current.setFieldValue("id_relazione_certificato", relazione.id_relazione_certificato);
                formikRef.current.setFieldValue("id_certificato", relazione.id_certificato);
                formikRef.current.setFieldValue("id_utente", relazione.id_utente);
                formikRef.current.setFieldValue("luogo", relazione.luogo);
                formikRef.current.setFieldValue("data", relazione.data);
                formikRef.current.setFieldValue("relazione", relazione.relazione);
                formikRef.current.setFieldValue("osservazioni", relazione.osservazioni);
                formikRef.current.setFieldValue("classificazione", relazione.classificazione);
                formikRef.current.setFieldValue("data_modifica", relazione.data_modifica);
                formikRef.current.setFieldValue("stato_veicolo", relazione.stato_veicolo || '');
                formikRef.current.setFieldValue("stato_interni", relazione.stato_interni || '');
                formikRef.current.setFieldValue("stato", relazione.stato);
            }
            endChiamata();
        });
    }

    useEffect(() => {
        if (loadingData) {
            if (idRelazione && idRelazione !== '') {
                getRelazione()
            }
        }
    }, [idCertificato, idRelazione]);

    const SignupSchema = Yup.object().shape({
        luogo: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        data: Yup.string()
            .required(traduci('Questo campo è obbligatorio')).nullable(),
        relazione: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        classificazione: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
    });

    return (
        <Card
            icon={'fas fa-signature'}
            title={idRelazione ? 'Modifica Relazione' : 'Inserisci Relazione'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.handleSubmit()
                            }
                        }}
                    >
                        <i className="fas fa-save"/> Salva bozza
                    </button>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.setFieldValue("stato", STATO_RELAZIONE_RIFIUTATA);
                                formikRef.current.handleSubmit()
                            }
                        }}
                    >
                        <i className="fas fa-ban"/> Salva e rifiuta
                    </button>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.setFieldValue("stato", STATO_RELAZIONE_APPROVATA);
                                formikRef.current.handleSubmit()
                            }
                        }}
                    >
                        <i className="fas fa-check"/> Salva ed approva
                    </button>
                </Fragment>
            }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_relazione_certificato: '',
                    id_certificato: '',
                    id_utente: '',
                    luogo: '',
                    data: '',
                    relazione: '',
                    osservazioni: '',
                    classificazione: '',
                    stato_veicolo: '',
                    stato_interni: '',
                    stato: '0'
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let newValues = values;
                    newValues['data'] = convertiTimestampInDataPerDb(newValues['data'])

                    if (idRelazione) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato + '/relazione/' + idRelazione,
                                newValues)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.push('/list-certificati');
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato + '/relazione/',
                                newValues)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.push('/list-certificati');
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="luogo" className="form-label">Luogo della visita:</label>
                            <Field
                                name="luogo"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"luogo"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="data_nascita" className="form-label">Data iscrizione*:</label>
                            <NsDatePicker
                                nome={"data"}
                                dataSelezionata={values["data"]}
                                maxDate={new Date()}
                                setSelectedDate={(date, event) => {
                                    formikRef.current.setFieldValue("data", date)
                                }}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"data"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="relazione" className="form-label">Relazione del Commissario
                                tecnico*:</label>
                            <Field
                                name="relazione"
                                className="form-control"
                                as={"textarea"}
                                rows={"5"}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"relazione"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="osservazioni" className="form-label">Osservazioni:</label>
                            <Field
                                name="osservazioni"
                                className="form-control"
                                as={"textarea"}
                                rows={"5"}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"osservazioni"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="stato_veicolo" className="form-label">Stato del veicolo:</label>
                            <Field
                                as={"select"}
                                name="stato_veicolo"
                                className="form-control form-select"
                                value={values['stato_veicolo']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("stato_veicolo", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                <option value={'1'}>Ottimo</option>
                                <option value={'2'}>Buono</option>
                                <option value={'3'}>Sufficiente</option>
                                <option value={'4'}>Mediocre</option>
                                <option value={'5'}>Insufficiente</option>
                                <option value={'6'}>Conservato</option>
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"stato_veicolo"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="stato_interni" className="form-label">Stato degli interni:</label>
                            <Field
                                as={"select"}
                                name="stato_interni"
                                className="form-control form-select"
                                value={values['stato_interni']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("stato_interni", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                <option value={'1'}>Ottimo</option>
                                <option value={'2'}>Buono</option>
                                <option value={'3'}>Sufficiente</option>
                                <option value={'4'}>Mediocre</option>
                                <option value={'5'}>Insufficiente</option>
                                <option value={'6'}>Conservato</option>
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"stato_interni"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="classificazione" className="form-label">Classificazione:</label>
                            <Field
                                name="classificazione"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"classificazione"}
                                              submitCount={submitCount}/>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaRelazione;