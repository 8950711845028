import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../../components/ModalConfirm";
import NsDataTable from "../../../components/tabelle/NsDataTable";

function ListTipiMov() {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [tipoSel, setTipoSel] = useState(null);
    const [show, setShow] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getTipiMovimento() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/tipo-movimento/").then((response) => {
            setData(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        if (loadingData === true) {
            getTipiMovimento()
        }
    }, []);

    const openModalDel = (utente) => {
        setTipoSel(utente)
        setShow(true);
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-exchange-alt'}
                title={'Tipi Movimento'}
                getBottoniDx={() =>
                    <Link to="/upd-tipo-mov" className={"btn btn-card"}>
                        <i className="fas fa-plus-circle"/> Aggiungi
                    </Link>
                }>
                <ModalConfirm
                    show={show}
                    handleClose={() => setShow(false)}
                    handleOk={() => {
                        setShow(false);
                        startChiamata()
                        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/tipo-movimento/" + tipoSel.id_tipo_movimento).then((response) => {
                            setData(response.data.data);
                            endChiamata()
                        });
                    }}
                    testo={"Sicuro di voler eliminare il tipo movimento: " + (tipoSel
                        && (tipoSel.descrizione)) + "?"}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={data}
                            columns={[
                                {
                                    name: 'Codice tipo movimento',
                                    selector: row => row.id_tipo_movimento,
                                    sortable: true,
                                },
                                {
                                    name: 'Descrizione',
                                    selector: row => row.descrizione,
                                    sortable: true,
                                },
                                {
                                    name: 'Prelievo',
                                    selector: row => row.is_prelievo == '1' ? 'Sì' : 'No',
                                    sortable: true,
                                },
                                {
                                    name: 'Versamento',
                                    selector: row => row.is_versamento == '1' ? 'Sì' : 'No',
                                    sortable: true,
                                },
                                {
                                    name: 'Tipo',
                                    selector: row => row.descr_plur,
                                    sortable: true,
                                },
                                {
                                    name: 'Ordinamento',
                                    selector: row => row.sort,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <div className="btn-group" role="group">
                                                <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                    <li>
                                                        <Link to={"/upd-tipo-mov/" + row.id_tipo_movimento}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Modifica">
                                                            <i className="fas fa-pen"/> Modifica
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina" onClick={() => {
                                                            openModalDel(row)
                                                        }}>
                                                            <i className="fas fa-trash"/> Elimina
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            addPagination={true}
                            funzioneFiltro={(item, filtro) => {
                                return (item.id_tipo_movimento && item.id_tipo_movimento.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.descr_plur && item.descr_plur.toLowerCase().includes(filtro.toLowerCase()))
                            }}
                        >
                        </NsDataTable>
                    )}
            </Card>
        </CardContainer>
    );
}

export default ListTipiMov;
