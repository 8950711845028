import {Modal} from "react-bootstrap";

export default function NsModalHeader({
                                          title
                                      }) {

    return (
        <Modal.Header
            className={'ns-modal-header'}
            closeVariant="white"
            closeButton
        >
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
    );
}