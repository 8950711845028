import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../../components/ModalConfirm";
import NsDataTable from "../../../components/tabelle/NsDataTable";

function ListTipiCarrozzeria() {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [tipoCarrSel, setTipoCarrSel] = useState(null);
    const [show, setShow] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getTipiCarrozzeria() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/carrozzeria/").then((response) => {
            setData(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        if (loadingData === true) {
            getTipiCarrozzeria()
        }
    }, []);

    const openModalDel = (utente) => {
        setTipoCarrSel(utente)
        setShow(true);
    }

    const closeModalDel = () => setShow(false);

    const eliminaTipoCarrozzeria = () => {
        setShow(false);
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/carrozzeria/" + tipoCarrSel.id_tipo_carrozzeria).then((response) => {
            setData(response.data.data);
            endChiamata()
        });
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-car'}
                title={'Tipi di Carrozzeria dei Veicoli'}
                getBottoniDx={() =>
                    <Link to="/upd-tipi-carr" className={"btn btn-card"}>
                        <i className="fas fa-plus-circle"/> Aggiungi
                    </Link>
                }>
                <ModalConfirm
                    show={show}
                    handleClose={closeModalDel}
                    handleOk={eliminaTipoCarrozzeria}
                    testo={"Sicuro di voler eliminare il tipo carrozzeria: " + (tipoCarrSel
                        && (tipoCarrSel.id_tipo_carrozzeria)) + "?"}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={data}
                            columns={[
                                {
                                    name: 'Codice tipo',
                                    selector: row => row.id_tipo_carrozzeria,
                                    sortable: true,
                                },
                                {
                                    name: 'Tipo di carrozzeria',
                                    selector: row => row.descrizione,
                                    sortable: true,
                                },
                                {
                                    name: 'Ordinamento',
                                    selector: row => row.sort,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <div className="btn-group" role="group"
                                             aria-label="Button group with nested dropdown">
                                            <div className="btn-group" role="group">
                                                <button id="btnGroupDrop1" type="button"
                                                        className="btn btn-dark dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                    <li>
                                                        <Link to={"/upd-tipi-carr/" + row.id_tipo_carrozzeria}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Modifica">
                                                            <i className="fas fa-pen"/> Modifica
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina" onClick={() => {
                                                            openModalDel(row)
                                                        }}>
                                                            <i className="fas fa-trash"/> Elimina
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            addPagination={true}
                            funzioneFiltro={(item, filtro) => {
                                return (item.id_tipo_carrozzeria && item.id_tipo_carrozzeria.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
                            }}
                        >
                        </NsDataTable>
                    )}
            </Card>
        </CardContainer>
    );
}

export default ListTipiCarrozzeria;
