import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../components/ModalConfirm";
import NsDataTable from "../../components/tabelle/NsDataTable";

function ListNote() {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [notaSel, setNotaSel] = useState(null);
    const [show, setShow] = useState(false);
 /*   const [descrizione, setDescrizione] = useState('');
    const [marca, setMarca] = useState('');
    const [modello, setModello] = useState('');
    const [note, setNote] = useState('');*/

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getNote() {
        /*let filtro = [];
        if (descrizione && descrizione !== '') {
            filtro.push('descrizione=' + descrizione);
        }
        if (marca && marca !== '') {
            filtro.push('marca=' + marca);
        }
        if (modello && modello !== '') {
            filtro.push('tipo=' + modello);
        }
        if (note && note !== '') {
            filtro.push('note=' + note);
        }
        let f = filtro.join('&');
        f = f !== '' ? ('?' + f) : '';*/
        let f = '';

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/nota-commissario/" + f).then((response) => {
            setData(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        if (loadingData === true) {
            getNote()
        }
    }, []);

    return (
        <CardContainer>
            <Card
                icon={'fas fa-sticky-note'}
                title={'Note'}
                getBottoniDx={() =>
                    <Link to="/upd-nota" className={"btn btn-card"}>
                        <i className="fas fa-plus-circle"/> Aggiungi
                    </Link>
                }>
                <ModalConfirm
                    show={show}
                    handleClose={() => setShow(false)}
                    handleOk={() => {
                        setShow(false);
                        startChiamata()
                        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/nota-commissario/" + notaSel.id_nota_commissario).then((response) => {
                            setData(response.data.data);
                            endChiamata()
                        });
                    }}
                    testo={"Sicuro di voler eliminare la nota: " + (notaSel
                        && (notaSel.descrizione)) + "?"}/>
                {/*<NsAccordion
                    titolo={'Filtri'}
                    label={'fas fa-filter'}

                >
                    <form>
                        <div className={'row'}>
                            <div className={'col-md-2'}>
                                <input
                                    type={"text"}
                                    id={"descrizione"}
                                    placeholder={"Descrizione"}
                                    className="form-control"
                                    onChange={(event) => {
                                        setDescrizione(event.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <input
                                    type={"text"}
                                    id={"marca"}
                                    placeholder={"Marca"}
                                    className="form-control"
                                    onChange={(event) => {
                                        setMarca(event.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <input
                                    type={"text"}
                                    id={"tipo"}
                                    placeholder={"Modello"}
                                    className="form-control"
                                    onChange={(event) => {
                                        setModello(event.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-4'}>
                                <input
                                    type={"text"}
                                    id={"note"}
                                    placeholder={"Note"}
                                    className="form-control"
                                    onChange={(event) => {
                                        setNote(event.target.value);
                                    }}/>
                            </div>
                            <div className={'col-md-2'}>
                                <button className={"btn btn-dark"} onClick={getNote}>
                                    <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                                </button>
                            </div>
                        </div>
                    </form>
                </NsAccordion>*/}
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={data}
                            columns={[
                                {
                                    name: 'Descrizione',
                                    selector: row => row.descrizione,
                                    sortable: true,
                                },
                                {
                                    name: 'Marca',
                                    selector: row => row.marca,
                                    sortable: true,
                                },
                                {
                                    name: 'Modello',
                                    selector: row => row.tipo,
                                    sortable: true,
                                },
                                {
                                    name: 'Nota',
                                    selector: row => row.note,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                            <div className="btn-group" role="group">
                                                <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                    <li>
                                                        <Link to={"/upd-nota/" + row.id_nota_commissario}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Modifica">
                                                            <i className="fas fa-pen"/> Modifica
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina" onClick={() => {
                                                            setNotaSel(row)
                                                            setShow(true);
                                                        }}>
                                                            <i className="fas fa-trash"/> Elimina
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            addPagination={true}
                            funzioneFiltro={(item, filtro) => {
                                return (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.marca && item.marca.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.tipo && item.tipo.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.note && item.note.toLowerCase().includes(filtro.toLowerCase()))
                            }}
                        >
                        </NsDataTable>
                    )}
            </Card>
        </CardContainer>
    );
}

export default ListNote;
