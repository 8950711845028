import React from "react";
import {Modal} from "react-bootstrap";
import NsModalHeader from "../../components/modal/NsModalHeader";

function ModalGdpr({show, handleClose, handlerGdprOk}) {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"INFORMATIVA PRIVACY"}/>
            <Modal.Body>
                <h4 className={"mb-4"}>INFORMATIVA PRIVACY AI SENSI E PER GLI EGGETTI DI CUI ALL'ART. 13-14, REG. UE 2016/679 (Regolamento
                    Europeo in materia di protezione dei dati personali)</h4>
                <p>
                    Gentile Associato/Cliente<br/>
                    Il <strong>REGISTRO FIAT ITALIANO</strong> desidera informarla che il Reg. UE 2016/679 ("Regolamento
                    Europeo in materia di protezione dei dati personali") prevede la tutela delle persone e di altri
                    soggetti ed il rispetto al trattamento dei dati personali. Ai sensi degli articoli 13 e 14,
                    pertanto, Le forniamo le seguenti informazioni:
                </p>
                <p>
                    <strong>TITOLARE DEL TRATTAMENTO</strong><br/>
                    Ai sensi del Regolamento UE 2016/679, i vostri dati personali saranno trattati dal <strong>REGISTRO
                    FIAT ITALIANO</strong> in qualità di titolare del trattamento.
                </p>
                <p>
                    <strong>FINALITÀ, BASE GIURIDICA DEL TRATTAMENTO CUI SONO DESTINATI I DATI</strong><br/>
                    Il trattamento dei dati personali da Lei forniti (nome, cognome, ragione sociale, indirizzo, P.IVA,
                    telefono, e-mail, riferimenti bancari) è finalizzato unicamente ad eseguire gli obblighi
                    contrattuali e ad adempiere a sue specifiche richieste, nonché ad adempiere a tutti gli obblighi
                    normativi e di legge. In particolare le principali finalità del trattamento sono:
                    <ul>
                        <li>Predisposizione atti associativi;</li>
                        <li>Predisposizione dei contratti di collaborazione e/o fornitura;</li>
                        <li>Regolare tenuta delle scritture contabili ai sensi di legge;</li>
                        <li>Più in generale, l'adempimento di qualunque tipo di obbligo previsto dalla legge, dai
                            regolamenti e dalla normativa comunitaria.
                        </li>
                    </ul>
                </p>
                <p>
                    <strong>MODALITÀ DEL TRATTAMENTO</strong><br/>
                    I suoi dati personali saranno inseriti nei nostri database e saranno trattati soltanto da personale
                    autorizzato o dagli incaricati al trattamento nominati dal Titolare i quali operano in conformità e
                    nei limiti stabiliti dalla legge. In relazione alle indicate finalità i Suoi dati saranno oggetto di
                    trattamento informatico e cartaceo. Le operazioni di trattamento sono attuate in modo da garantire
                    la sicurezza logica, fisica e la riservatezza dei Suoi dati personali.
                </p>
                <p>
                    <strong>MODALITÀ E DURATA DELLA CONSERVAZIONE DEI DATI PERSONALI</strong><br/>
                    I suoi dati personali saranno custoditi in appositi archivi, fisici ed elettronici, protetti ed
                    accessibili solo al personale addetto nel pieno rispetto delle norme di sicurezza previste dalla
                    normativa al fine di prevenire la perdita dei dati stessi, usi illeciti o non corretti ed accessi
                    non autorizzati. I Suoi dati personali saranno conservati presso la sede del Titolare che tratterà i
                    Suoi dati personali, per il tempo necessario per adempiere alle finalità di cui sopra, e comunque
                    per non oltre 10 anni dalla cessazione del rapporto come previsto dalle leggi vigenti.
                </p>
                <p>
                    <strong>NATURA DEL CONFERIMENTO DEI DATI E CONSEGUENZE IN CASO DI RIFIUTO</strong><br/>
                    Il conferimento dei Suoi dati personali, ha natura obbligatoria, l'eventuale rifiuto o errata
                    comunicazione renderebbe impossibile l'erogazione dei servizi da Lei richiesti e l'adempimento di
                    specifici obblighi previsti dalla legge.
                </p>
                <p>
                    <strong>AMBITO DI COMUNICAZIONE E DIFFUSIONE DEI DATI</strong><br/>
                    I Suoi dati potranno essere resi accessibili:
                    <ul>
                        <li>ai nostri collaboratori, dipendenti, nell'ambito delle relative mansioni;</li>
                        <li>a tutte quelle persone fisiche e/o giuridiche, pubbliche e/o private quando la comunicazione
                            risulti necessaria o funzionale allo svolgimento della nostra attività e nei modi e per le
                            finalità sopra illustrate;
                        </li>
                        <li>a tutti i soggetti cui la facoltà di accesso a tali dati è riconosciuta in forza di
                            provvedimenti normativi.
                        </li>
                    </ul>
                    I Suoi dati non saranno diffusi e non saranno trasferiti in paesi terzi o ad altre organizzazioni
                    internazionali e non saranno oggetto di profilazione in quanto il Titolare non effettua trattamenti
                    che consistano in processi decisionali automatizzati.
                </p>
                <p>
                    <strong>DIRITTI DELL'INTERESSATO</strong><br/>
                    L'interessato, ai sensi degli art. 15 e 15 del Reg. UE 2016/679, ha il diritto di ottenere dal
                    titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo
                    riguardano e caso, di ottenere l'accesso ai dati personali e alle seguenti informazioni:
                    <ul>
                        <li>le finalità del trattamento;</li>
                        <li>le categorie di dati personali in questione;</li>
                        <li>i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno
                            comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;
                        </li>
                        <li>il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i
                            criteri utilizzati per determinare tale periodo;
                        </li>
                        <li>l'esistenza del diritto dell'interessato di chiedere al titolare del trattamento la
                            rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati
                            personali che lo riguardano o di opporsi al loro trattamento;
                        </li>
                        <li>il diritto di porre reclamo a un'autorità di controllo;</li>
                        <li>l'esistenza di un processo decisionale automatizzato, compresa la profilazione e, almeno in
                            tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le
                            conseguenze previste di tale trattamento per l'interessato.
                        </li>
                    </ul>
                    L'interessato, ai sensi del art. 17 del Reg. UE 2016/679, ha il diritto di ottenere dal titolare del
                    trattamento la cancellazione dei dati personali che lo riguardano, "diritto all'oblio", senza
                    ingiustificato riguardo e il titolare del trattamento ha l'obbligo di cancellare senza
                    ingiustificato riguardo i dati personali, se sussiste uno dei motivi seguenti:
                    <ul>
                        <li>i dati personali non sono più necessari rispetto alle finalità per le quali sono stati
                            raccolti o altrimenti trattati;
                        </li>
                        <li>l'interessato revoca il consenso su cui si basa il trattamento conformemente all'articolo 6,
                            paragrafo 1, lettera a), o all'articolo 9, paragrafo 2, lettera a), e se non sussiste altro
                            fondamento giuridico per il trattamento;
                        </li>
                        <li>l'interessato si oppone al trattamento ai sensi dell'articolo 21, paragrafo 1, e non
                            sussiste alcun motivo legittimo prevalente per procedere al trattamento, oppure si oppone al
                            trattamento ai sensi dell'articolo 21, paragrafo 2;
                        </li>
                        <li>i dati personali sono stati trattati illecitamente;</li>
                        <li>i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal
                            diritto dell'Unione o dello Stato membro cui è soggetto il titolare del trattamento;
                        </li>
                        <li>I dati personali sono stati raccolti relativamente all'offerta di servizio della società
                            dell'informazione di cui all'articolo 8, paragrafo 1 del Reg. UE 2016/679.
                        </li>
                    </ul>
                    L'interessato, ai sensi del art. 18 del Reg. UE 2016/679, ha il diritto di ottenere dal titolare del
                    trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:
                    <ul>
                        <li>l'interessato contesta l'esattezza dei dati personali, per il periodo necessario al titolare
                            del trattamento per verificare l'esattezza di tali dati personali;
                        </li>
                        <li>il trattamento è illecito e l'interessato si oppone alla cancellazione dei dati personali e
                            chiede invece che ne sia limitato l'utilizzo;
                        </li>
                        <li>benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati
                            personali sono necessari all'interessato per l'accertamento,
                            l'esercizio o la difesa di un diritto in sede giudiziaria;
                        </li>
                        <li>l'interessato si è opposto al trattamento ai sensi dell'articolo 21, paragrafo 1, Reg UE
                            2016/679 in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi
                            del titolare del trattamento rispetto a quelli dell'interessato.
                        </li>
                    </ul>
                    L'interessato, ai sensi del art. 19 del Reg. UE 2016/679, ha il diritto di ricevere in un formato
                    strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano
                    forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare
                    del trattamento senza impedimenti da parte del titolare del trattamento.
                </p>
                <p>
                    <strong>REVOCA DEL CONSENSO AL TRATTAMENTO</strong><br/>
                    Le è riconosciuta la facoltà di revocare il consenso al trattamento dei Suoi dati personali,
                    inviando una raccomandata A/R intestata al <strong>REGISTRO FIAT ITALIANO</strong> al seguente
                    indirizzo: <strong>Via Cesare Battisti, 2 - 10123 Torino (TO) - Italia</strong> corredato da
                    fotocopia del suo documento di identità, con il seguente testo: "revoca del consenso al trattamento
                    di tutti i miei dati personali".<br/>
                    Al termine di questa operazione i Suoi dati personali saranno rimossi dagli archivi nel più breve
                    tempo possibile. Se desidera avere maggiori informazioni sul trattamento dei Suoi dati personali,
                    ovvero esercitare i diritti esplicitati in precedenza, può inviare una raccomandata A/R intestata
                    a <strong>REGISTRO FIAT ITALIANO</strong> al seguente indirizzo: <strong>Via Cesare Battisti, 2 -
                    10123 Torino (TO) - Italia</strong>.<br/>
                    Prima di poterLe fornire, o modificare qualsiasi informazione, potrebbe essere necessario verificare
                    la Sua identità e rispondere ad alcune domande. Una risposta sarà fornita al più presto.
                </p>

                <p className={"mt-4"}>
                    <strong>Registro Fiat Italiano</strong><br/>
                    Via Cesare Battisti, 2 - 10123 - Torino (Italy)<br/>
                    C.F./ P.Iva 05810070010<br/>
                    Associazione fra collezionisti di vetture Fiat d'epoca
                </p>
                <div className={"row mt-4"}>
                    <div className={"col-md-12 text-center"}>
                        <button
                            type={"button"}
                            className={"btn btn-dark"}
                            onClick={() => {
                                handlerGdprOk();
                            }}
                        >
                            <i className="fas fa-signature"></i> Acconsento
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalGdpr;
