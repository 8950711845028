import React, {useEffect, useState} from "react";

export const MobileContext = React.createContext('');

export const MobileProvider = ({value, children}) => {

    const [isMobile, setIsMobile] = useState(false)

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, []);

    return (
        <MobileContext.Provider
            value={{isMobile}}>{children}</MobileContext.Provider>
    );
};
