import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {LoaderContext} from "./LoaderContext";
import {AuthContext} from "./AuthContext";
import {RuoliContext} from "./RuoliContext";

export const RichiedentiContext = React.createContext(0);

export const RichiedentiProvider = ({value, children}) => {

    const [numRichiedenti, setNumRichiedenti] = useState(0)

    const {user} = useContext(AuthContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloSocio} = useContext(RuoliContext);


    function getNumeroRichiedenti() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/richiedente/?statoRichiesta=1").then((response) => {
            if (response.data.data) {
                setNumRichiedenti(response.data.data.length)
            }
            endChiamata()
        });
    }

    function aggiornaContatore() {
        getNumeroRichiedenti();
    }

    useEffect(() => {
        if (user && user.codice_ruolo !== 'SOCIO') {
            getNumeroRichiedenti();
        }

        // Ogni 5 minuti aggiorno
        const timer = setTimeout(() => {
            if (user && user.codice_ruolo !== 'SOCIO') {
                getNumeroRichiedenti();
            }
        }, 18000000);

        /*
        Questa funzione di pulizia verrà eseguita quando il componente si smonta o prima che il prossimo effetto
        venga eseguito
         */
        return () => clearTimeout(timer);
    }, [user]);

    return (
        <RichiedentiContext.Provider
            value={{numRichiedenti, aggiornaContatore}}>{children}</RichiedentiContext.Provider>
    );
};
