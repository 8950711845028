import {Fragment, useContext} from "react";
import {MobileContext} from "../context/MobileContext";

const Card = ({
                  children,
                  title,
                  subtitle,
                  icon,
                  getBottoniDx,
                  classiAggiuntive = '',
                  classiAggiuntiveHeader = '',
                  stili = null
              }) => {
    var bottoniPresenti = getBottoniDx;

    const {isMobile} = useContext(MobileContext);

    return (
        <div className={"card ns-card " + classiAggiuntive} style={stili}>
            <div className="card-body">
                <div className={"row card-title " + classiAggiuntiveHeader}>
                    <div className={(bottoniPresenti ? 'col-md-6' : 'col-md-12') + " text-start ns-card-title-container"}>
                        <h5 className="">
                            {
                                icon && icon !== '' ? <Fragment><i className={icon}/>&nbsp;</Fragment> : ''
                            }
                            {
                                title
                            }
                        </h5>
                        {
                            subtitle && subtitle !== '' ?
                                <h6 className="card-subtitle mb-2 cmms_text-muted text-start">
                                    {subtitle}
                                </h6> : ''
                        }
                    </div>
                    <div className={(bottoniPresenti ? 'col-md-6' : '') + " text-end"}>
                        {
                            getBottoniDx ? getBottoniDx() : ''
                        }
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
}

export default Card;