import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../components/ModalConfirm";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {getDataFormattata, getTimestampFormattato} from "../../utility/common";
import {Link} from "react-router-dom";
import ModalMsg from "../../components/ModalMsg";
import ModalNoteConfirm from "../soci/ModalNoteConfirm";

export default function ListQuoteTemp() {

    const [loadingData, setLoadingData] = useState(true);
    const [quote, setQuote] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [quotaSel, setQuotaSel] = useState(null);
    const [socioSel, setSocioSel] = useState();
    const [showMdNote, setShowMdNote] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    var f = '?pag=-1&attiva=0&quoteVeicoli=1';

    function getQuoteTemp() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + f).then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function attivaQuota(idQuota) {
        startChiamata()
        axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + idQuota + '/attiva/' + f).then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function attivaQuotaVeicolo(idQuota) {
        startChiamata()
        axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + idQuota + '/veicolo/attiva/' + f).then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    const visualizzaDocumento = (quota) => {
        startChiamata()

        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + quota.id_quota + "/documento/" + quota.id_documento_quota).then((response) => {
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    var byteCharacters = atob(resp.contenuto);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], {type: resp.content_type + ';base64'});
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            }
            endChiamata()
        });
    }

    useEffect(() => {
        getQuoteTemp();
    }, []);

    const aggiornaStatoNota = (nota = '') => {
        setShow(false);
        setShowMdNote(false);

        startChiamata()
        axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + socioSel + "/nota/", {
            note: nota
        }).then((response) => {
            endChiamata();
        });
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-credit-card'}
                title={'Quote da approvare'}
                getBottoniDx={() => {
                }}>
                <Fragment>
                    <ModalNoteConfirm
                        show={showMdNote}
                        handleClose={() => setShowMdNote(false)}
                        handleOk={(nota) => {
                            aggiornaStatoNota(nota)
                        }}
                        titolo={"Inserisci Nota"}
                    />
                    <ModalMsg
                        show={show}
                        handleClose={() => setShow(false)}
                        testo={modalMsg}/>
                    <ModalConfirm
                        show={showConfirm}
                        handleClose={() => setShowConfirm(false)}
                        handleOk={() => {
                            setShowConfirm(false);
                            if (quotaSel.id_certificato && quotaSel.id_certificato !== '') {
                                startChiamata()
                                axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + quotaSel.id_quota + '/veicolo/' + f).then((response) => {
                                    setQuote(response.data.data);
                                    endChiamata()
                                });
                            } else {
                                startChiamata()
                                axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + quotaSel.id_quota + f).then((response) => {
                                    setQuote(response.data.data);
                                    endChiamata()
                                });
                            }
                        }}
                        testo={"Sicuro di voler eliminare la quota: " + (quotaSel && quotaSel.id_quota) + "?"}/>
                    {
                        loadingData ? (
                            <p>Caricamento in corso...</p>
                        ) : (
                            <NsDataTable
                                data={quote}
                                columns={[
                                    {
                                        name: 'ID',
                                        selector: row => row.id_quota,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Data',
                                        selector: row => getTimestampFormattato(row.data_pagamento),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Socio',
                                        selector: row => row.socio,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Oggetto',
                                        selector: row => row.tipo_versamento,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Importo €',
                                        selector: row => row.importo,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Anno validità',
                                        selector: row => row.anno_validita,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Modalità di pagamento',
                                        selector: row => row.modalita,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Conto',
                                        selector: row => row.conto,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Ricevuta presente',
                                        selector: row => row.id_documento_quota ? <i className="fas fa-check ns-span--verde"/> :
                                            <i className="fas fa-times ns-span--rosso"/>,
                                        sortable: true,
                                    },
                                    {
                                        name: '',
                                        selector: row => <Fragment>
                                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa-solid fa-bars"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        {
                                                            row.id_documento_quota &&
                                                            <Fragment>
                                                                <li>
                                                                    <button className="dropdown-item"
                                                                            data-toggle="tooltip"
                                                                            title="Visualizza ricevuta"
                                                                            onClick={() => {
                                                                                visualizzaDocumento(row)
                                                                            }}>
                                                                        <i className="fas fa-eye"/> Visualizza ricevuta
                                                                    </button>
                                                                </li>
                                                            </Fragment>
                                                        }
                                                        {
                                                            row.id_certificato && row.id_certificato !== '' ?
                                                                <li>
                                                                    <Link
                                                                        to={"/upd-quota-veicolo/" + row.id_certificato + '/' + row.id_quota}
                                                                        className="dropdown-item"
                                                                        data-toggle="tooltip" title="Modifica">
                                                                        <i className="fas fa-pen"/> Modifica
                                                                    </Link>
                                                                </li>
                                                                :
                                                                <li>
                                                                    <Link
                                                                        to={"/upd-quota/" + row.id_utente + '/' + row.id_quota}
                                                                        className="dropdown-item"
                                                                        data-toggle="tooltip" title="Modifica">
                                                                        <i className="fas fa-pen"/> Modifica
                                                                    </Link>
                                                                </li>
                                                        }
                                                        {
                                                            row.id_modalita !== 'BANCOMAT' &&
                                                            <li>
                                                                <button className="dropdown-item"
                                                                        data-toggle="tooltip" title="Approva"
                                                                        onClick={() => {
                                                                            if (row.id_certificato && row.id_certificato !== '') {
                                                                                attivaQuotaVeicolo(row.id_quota);
                                                                            } else {
                                                                                attivaQuota(row.id_quota);
                                                                            }
                                                                        }}>
                                                                    <i className="fas fa-check"/> Approva
                                                                </button>
                                                            </li>
                                                        }
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Nota"
                                                                    onClick={() => {
                                                                        setSocioSel(row.id_utente)
                                                                        setShowMdNote(true)
                                                                    }}
                                                            >
                                                                <i className="fas fa-sticky-note"/> Invia una nota
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Elimina"
                                                                    onClick={() => {
                                                                        setQuotaSel(row)
                                                                        setShowConfirm(true);
                                                                    }}>
                                                                <i className="fas fa-trash"/> Elimina
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Fragment>,
                                        sortable: false,
                                        right: true,
                                    }
                                ]}
                                addPagination={true}
                                funzioneFiltro={(item, filtro) => {
                                    return (item.id_quota && item.id_quota.toString().toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.socio && item.socio.toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.importo && item.importo.toString().toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.anno_validita && item.anno_validita.toString().toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.modalita && item.modalita.toLowerCase().includes(filtro.toLowerCase()))
                                        || (getDataFormattata(item.data_pagamento) && getDataFormattata(item.data_pagamento).toLowerCase().includes(filtro.toLowerCase()))
                                }}
                            >
                            </NsDataTable>
                        )}
                </Fragment>
            </Card>
        </CardContainer>
    );
}