import {Modal} from "react-bootstrap";
import React from "react";
import NsModalHeader from "../../components/modal/NsModalHeader";

function ModalLegendaStato({show, handleClose}) {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Legenda Stato"}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <ul className={"ns-list-no-bullet"}>
                            <li>
                                    <span><i className={"fas fa-envelope rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip" title={"E-mail non verificata"}/> E-mail non verificata</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-id-card rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip" title={"Documento d'identità NON presente"}/> Documento d'identità NON presente</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-check rfi-icon-table me-2 ns-span--rosso"}
                                             data-toggle="tooltip" title={"Non approvato dalla Segreteria"}/> Non approvato dalla Segreteria</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-dollar-sign rfi-icon-table ns-span--rosso"}
                                             data-toggle="tooltip" title={"Pagamento NON effettuato"}/> Pagamento NON effettuato</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-comment rfi-icon-table ns-span--rosso"}
                                             data-toggle="tooltip" title={"Note NON presenti"}/> Note NON presenti</span>
                            </li>
                        </ul>
                    </div>
                    <div className={"col-md-6"}>
                        <ul className={"ns-list-no-bullet"}>
                            <li>
                                    <span><i className={"fas fa-envelope rfi-icon-table me-2 ns-span--verde"}
                                             data-toggle="tooltip"
                                             title={"E-mail verificata"}/> E-mail verificata</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-id-card rfi-icon-table me-2 ns-span--verde"}
                                             data-toggle="tooltip" title={"Documento d'identità presente"}/> Documento d'identità presente</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-check rfi-icon-table me-2 ns-span--verde"}
                                             data-toggle="tooltip" title={"Approvato dalla Segreteria"}/> Approvato dalla Segreteria</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-dollar-sign rfi-icon-table ns-span--verde"}
                                             data-toggle="tooltip" title={"Pagamento effettuato"}/> Pagamento effettuato</span>
                            </li>
                            <li>
                                    <span><i className={"fas fa-comment rfi-icon-table ns-span--verde"}
                                             data-toggle="tooltip"
                                             title={"Note presenti"}/> Note presenti</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default ModalLegendaStato;