import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import 'react-medium-image-zoom/dist/styles.css'
import axios from "axios";
import {FilePond} from "react-filepond";
import Translate from "../../components/Translate";
import NsModalHeader from "../../components/modal/NsModalHeader";

function ModalDocVeicoloCert({show, handleClose, veicolo, submitOk, tipiDocumento}) {

    const [files, setFiles] = useState([])
    const [tipoDocumento, setTipoDocumento] = useState();
    const [descrizione, setDescrizione] = useState('');

    const token = localStorage.getItem('rfi-login');
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + (veicolo ? veicolo.id_veicolo : '') + '/documento/';


    useEffect(() => {
    }, []);

    const onCloseModal = () => {
        handleClose();
        setFiles([]);
        setTipoDocumento(null);
        setDescrizione('');
    }

    return (
        <Modal
            show={show}
            onHide={onCloseModal}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Documento del veicolo"}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <label htmlFor="tipoDocumento" className="form-label">Tipo di documento*:</label>
                        <select
                            name="tipoDocumento"
                            className={'form-control form-select'}
                            value={tipoDocumento}
                            onChange={(ev) => {
                                setTipoDocumento(ev.target.value);
                            }}>
                            <option value={""}></option>
                            {
                                tipiDocumento && tipiDocumento.filter(doc => doc.codice !== 'scansione_crs')
                                    .map((doc, idx) => {
                                            let obbligatorio = doc.obbligatorio == '1';

                                            if (veicolo && doc.condizione && doc.condizione === 'dichiarazione_officina') {
                                                obbligatorio = obbligatorio || veicolo.dichiarazione_officina == '1';
                                            }

                                            return <option key={idx}
                                                           value={doc.codice}>{doc.descrizione + (obbligatorio ? ' [OBBLIGATORIO]' : '')}</option>
                                        }
                                    )
                            }
                        </select>
                    </div>
                    <div className={"col-md-6"}>
                        <label htmlFor="descrizione" className="form-label">Descrizione:</label>
                        <input
                            type={"text"}
                            id={"descrizione"}
                            name={"descrizione"}
                            className={"form-control"}
                            value={descrizione}
                            onChange={(ev) => {
                                setDescrizione(ev.target.value);
                            }}
                        />
                    </div>
                    {
                        tipoDocumento && tipoDocumento !== '' ?
                            <div className={"col-md-12 mt-4"}>
                                <FilePond
                                    files={files}
                                    onupdatefiles={(file) => {
                                        setFiles(file);
                                        submitOk();
                                    }}
                                    acceptedFileTypes={["image/x-png", "image/png", "image/gif", "image/jpeg", "image/bmp", "image/svg+xml"
                                        , "image/heic", "application/pdf", "application/msword", "application/vnd.openxmlformats-"
                                        , "officedocument.wordprocessingml.document"]}
                                    allowFileTypeValidation={true}
                                    fileValidateTypeDetectType={(source, type) =>
                                        new Promise((resolve, reject) => {
                                            // Do custom type detection here and return with promise
                                            resolve(type);
                                        })
                                    }
                                    allowMultiple={true}
                                    allowRevert={true}
                                    allowReorder={true}
                                    allowImagePreview={true}
                                    imagePreviewMaxHeight={200}
                                    imagePreviewMarkupFilter={console.log}
                                    server=
                                        {
                                            {
                                                url: url + "?tipo=" + tipoDocumento + "&descrizione=" + descrizione,
                                                process: {
                                                    headers: {
                                                        'jwt': token
                                                    },
                                                },
                                                revert: (uniqueFileId, load, error) => {
                                                    axios.delete(url + parseInt(uniqueFileId)).then((response) => {
                                                        load(response)
                                                    });
                                                }
                                            }
                                        }
                                    name="files"
                                    labelIdle={
                                        tipoDocumento && tipoDocumento !== '' ?
                                            'Trascina qui l\'immagine del tuo documento o <span class="filepond--label-action">Cercala all\'interno delle tue cartelle</span>'
                                            : 'Valorizza il tipo di documento e la sua data di scadenza'
                                    }
                                />
                            </div>
                            : ''
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={onCloseModal}>
                    <Translate>Chiudi</Translate>
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalDocVeicoloCert;