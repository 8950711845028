import './App.css';
import "react-datepicker/dist/react-datepicker.css";

import {
    HashRouter
} from "react-router-dom";
import React from "react";
import ApplicationBody from "./ApplicationBody";
import ProvidersComponent from "./ProvidersComponent";
import ScrollToTop from "./components/ScrollToTop";

function App() {

    return (
        <div className="App">
            <ProvidersComponent>
                <HashRouter>
                    <ScrollToTop />
                    <ApplicationBody/>
                </HashRouter>
            </ProvidersComponent>
        </div>
    );
}

export default App;
