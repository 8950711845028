import React, {Fragment, useState} from "react";
import {Field} from "formik";

function Password({name, values, formikRef, showControlli = false}) {

    const [type, setType] = useState('password');
    const [pwd, setPwd] = useState('');

    return <Fragment>
        <div className={"ns-password-container"}>
            <Field
                name={name}
                className="form-control"
                type={type}
                value={values[name]}
                onChange={(ev) => {
                    formikRef.current.setFieldValue(name, ev.target.value)
                    setPwd(ev.target.value)
                }}
                onCopy={(e) => {
                    e.preventDefault()
                    return false;
                }}
            />
            <i className={"far fa-eye" + (type === 'text' ? '-slash' : '')} id="togglePassword" onClick={() => {
                if (type === 'password') {
                    setType('text')
                } else {
                    setType('password')
                }
            }}/>
        </div>
        {
            showControlli === true ?
                <div className={"row mt-2"}>
                    <div className={"col-md-12"}>
                        <ul className={"ns-list-no-bullet"}>
                            <li className={pwd && pwd.length >= 8 ? "ns-span--verde" : ""}>
                                {
                                    pwd && pwd.length >= 8 ?
                                        <i className="fas fa-check-circle"></i>
                                        : <i className="far fa-circle"></i>
                                }
                                &nbsp;
                                Usa almeno 8 caratteri
                            </li>
                            <li className={pwd && /[A-Z]/.test(pwd) && /[a-z]/.test(pwd) ? "ns-span--verde" : ""}>
                                {
                                    pwd && /[A-Z]/.test(pwd) && /[a-z]/.test(pwd) ?
                                        <i className="fas fa-check-circle"></i>
                                        : <i className="far fa-circle"></i>
                                }
                                &nbsp;
                                Usa almeno una lettera maiuscola ed una minuscola
                            </li>
                            <li className={pwd && /\d/.test(pwd) ? "ns-span--verde" : ""}>
                                {
                                    pwd && /\d/.test(pwd) ?
                                        <i className="fas fa-check-circle"></i>
                                        : <i className="far fa-circle"></i>
                                }
                                &nbsp;
                                Usa almeno un numero
                            </li>
                            <li className={pwd && /[!#$%&'()*+,\-.:;<=>@]/.test(pwd) ? "ns-span--verde" : ""}>
                                {
                                    pwd && /[!#$%&'()*+,\-.:;<=>@]/.test(pwd) ?
                                        <i className="fas fa-check-circle"></i>
                                        : <i className="far fa-circle"></i>
                                }
                                &nbsp;
                                Usa almeno un simbolo (come !@#$)
                            </li>
                        </ul>
                    </div>
                </div>
                : ''
        }
    </Fragment>
}

export default Password;