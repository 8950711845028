import React, {useState, useEffect, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory} from 'react-router-dom'
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import {TranslateContext} from "../../context/TranslateContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import NsDatePicker from "../../components/NsDatePicker";
import {
    convertiTimestampInDataPerDb,
    TIPO_FOTO_C_CORPO,
    TIPO_FOTO_C_FILETTATURA,
    TIPO_FOTO_C_PANNELLI_ANTERIORI,
    TIPO_FOTO_C_PANNELLI_POSTERIORI,
    TIPO_FOTO_C_PARAFANGHI,
    TIPO_FOTO_C_RUOTE,
    TIPO_FOTO_C_SEDILI_ANTERIORI,
    TIPO_FOTO_C_SEDILI_POSTERIORI,
    TIPO_FOTO_V_3_4_ANTERIORE,
    TIPO_FOTO_V_3_4_POSTERIORE,
    TIPO_FOTO_V_ALTRO,
    TIPO_FOTO_V_BAGAGLIAIO,
    TIPO_FOTO_V_INTERNI_ANT,
    TIPO_FOTO_V_INTERNI_POST,
    TIPO_FOTO_V_MOTORE_LATO_DX,
    TIPO_FOTO_V_MOTORE_LATO_SX,
    TIPO_FOTO_V_PLANCIA,
    TIPO_FOTO_V_PONTE,
    TIPO_FOTO_V_PUNZONATURA_MOTORE,
    TIPO_FOTO_V_PUNZONATURA_TELAIO,
    TIPO_FOTO_V_RUOTA_ANT,
    TIPO_FOTO_V_RUOTA_POST,
    TIPO_FOTO_V_TARGHETTA,
    TIPO_REQ_VEICOLO_ALBO,
    TIPO_REQ_VEICOLO_CRS,
    TIPO_REQ_VEICOLO_CRS_REIMM_ALBO,
    TIPO_REQ_VEICOLO_CRS_REIM,
    TIPO_REQ_VEICOLO_LISTA_CHIUSA,
    TIPO_REQ_VEICOLO_OMOLOGAZIONE,
    TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE,
    TIPO_FOTO_V_3_4_ANTERIORE_MOTO,
    TIPO_FOTO_V_3_4_POSTERIORE_MOTO,
    TIPO_FOTO_V_CRUSCOTTO_MOTO,
    TIPO_FOTO_V_SELLA_MOTO,
    TIPO_FOTO_V_RUOTA_ANT_MOTO,
    TIPO_FOTO_V_RUOTA_POST_MOTO,
    TIPO_FOTO_V_PUNZONATURA_TELAIO_MOTO,
    TIPO_FOTO_V_TARGHETTA_MOTO,
    TIPO_FOTO_V_PUNZONATURA_MOTORE_MOTO
} from "../../utility/common";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import UplFotoVeicolo from "./UplFotoVeicolo";
import {RuoliContext} from "../../context/RuoliContext";
import AutocompleteText from "../../components/AutocompleteText";
import FieldContainer from "../../components/FieldContainer";
import CertDivContainer from "../../components/certificati/CertDivContainer";

function FormModificaVeicolo({
                                 idUsr,
                                 formikRef,
                                 idVeicolo = '',
                                 tipoRichiesta,
                                 isModificaCertificato = false,
                                 goBack = false,
                                 showMsgSalvataggioOk = false,
                                 showAllCampi = true,
                                 showCampiRegistro = true,
                                 onSubmitVeicolo = (values) => {
                                 },
                                 registro = '',
                                 numeroRegistrazione = '',
                                 suff = '',
                             }) {

    const [veicolo, setVeicolo] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [suffisso, setSuffisso] = useState(suff);
    const [carrozzerie, setCarrozzerie] = useState([]);
    const [colori, setColori] = useState([]);
    const [registriVeicoli, setRegistriVeicoli] = useState([]);
    const [marche, setMarche] = useState([]);
    const [modelli, setModelli] = useState([]);

    const {
        isRuoloSocio,
        isRuoloSegreteria,
        isRuoloAdmin,
        isRuoloCommissario,
        isRuoloClub,
        isRuoloAgenzia,
    } = useContext(RuoliContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();

    function getVeicolo() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + idVeicolo)
            .then((response) => {
                let val = response.data.data;
                setVeicolo(val);

                setLoadingData(false);

                if (formikRef.current && val) {
                    setSuffisso(suffisso || '');

                    /*
                    Dati registrazione del veicolo (quindi quelli relativi all'ultimo certificato se presente) nel
                    caso in cui venga indicato un nuovo certificato
                     */
                    if (!registro || registro === '') {
                        formikRef.current.setFieldValue("id_registro", val.id_registro || 'NOREG');
                    }
                    if (!numeroRegistrazione || numeroRegistrazione === '') {
                        formikRef.current.setFieldValue("numero_registrazione", val.numero_registrazione || '');
                    }
                    if (!suffisso || suffisso === '') {
                        setSuffisso(val.suffisso || '');
                    }

                    formikRef.current.setFieldValue("id_veicolo", val.id_veicolo || '');
                    formikRef.current.setFieldValue("id_utente", val.id_utente || '');
                    formikRef.current.setFieldValue("data_registrazione", val.data_registrazione || '');
                    formikRef.current.setFieldValue("bollo_rfi", val.bollo_rfi || '');
                    formikRef.current.setFieldValue("id_club", val.id_club || '');
                    formikRef.current.setFieldValue("marca", val.marca || '');
                    formikRef.current.setFieldValue("tipo", val.tipo || '');
                    formikRef.current.setFieldValue("tipo_veicolo", val.tipo_veicolo || '');
                    formikRef.current.setFieldValue("anno", val.anno || '');
                    formikRef.current.setFieldValue("anno_prima_immatricolazione", val.anno_prima_immatricolazione || '');
                    formikRef.current.setFieldValue("targa", val.targa || '');
                    formikRef.current.setFieldValue("targa_prec", val.targa_prec || '');
                    formikRef.current.setFieldValue("numero_telaio", val.numero_telaio || '');
                    formikRef.current.setFieldValue("tipo_motore", val.tipo_motore || '');
                    formikRef.current.setFieldValue("numero_motore", val.numero_motore || '');
                    formikRef.current.setFieldValue("marca_carrozzeria", val.marca_carrozzeria || '');
                    formikRef.current.setFieldValue("id_tipo_carrozzeria", val.id_tipo_carrozzeria || '');
                    formikRef.current.setFieldValue("note_carrozzeria", val.note_carrozzeria || '');
                    formikRef.current.setFieldValue("categoria", val.categoria || '');
                    formikRef.current.setFieldValue("note", val.note || '');
                    formikRef.current.setFieldValue("num_registrazione_val", val.num_registrazione_val || '');
                    formikRef.current.setFieldValue("omologato", val.omologato || '');
                    formikRef.current.setFieldValue("omologazione", val.omologazione || '');
                    formikRef.current.setFieldValue("lrid", val.lrid || '');
                    formikRef.current.setFieldValue("id_colore", val.id_colore || '');
                    formikRef.current.setFieldValue("canc", val.canc || '0');
                    formikRef.current.setFieldValue("targa_estera", val.targa_estera || '');
                    formikRef.current.setFieldValue("targa_estera_prec", val.targa_estera_prec || '');
                    formikRef.current.setFieldValue("posizione_motore", val.posizione_motore || '');
                    formikRef.current.setFieldValue("tempi", val.tempi || '');
                    formikRef.current.setFieldValue("cilindri", val.cilindri || '');
                    formikRef.current.setFieldValue("cilindrata", val.cilindrata || '');
                    formikRef.current.setFieldValue("alesaggio", val.alesaggio || '');
                    formikRef.current.setFieldValue("corsa", val.corsa || '');
                    formikRef.current.setFieldValue("potenza", val.potenza || '');
                    formikRef.current.setFieldValue("potenza_giri_motore", val.potenza_giri_motore || '');
                    formikRef.current.setFieldValue("velocita", val.velocita || '');
                    formikRef.current.setFieldValue("alimentazione", val.alimentazione || '');
                    formikRef.current.setFieldValue("tipo_trazione", val.tipo_trazione || '');
                    formikRef.current.setFieldValue("cavalli", val.cavalli || '');
                    formikRef.current.setFieldValue("posizione_telaio", val.posizione_telaio || '');
                    formikRef.current.setFieldValue("targhetta_identificativa", val.targhetta_identificativa || '');
                    formikRef.current.setFieldValue("tipo_cambio", val.tipo_cambio || '');
                    formikRef.current.setFieldValue("numero_rapporti", val.numero_rapporti || '');
                    formikRef.current.setFieldValue("rapporto_tot", val.rapporto_tot || '');
                    formikRef.current.setFieldValue("tipo_freni_ant", val.tipo_freni_ant || '');
                    formikRef.current.setFieldValue("tipo_freni_post", val.tipo_freni_post || '');
                    formikRef.current.setFieldValue("freno_servizio", val.freno_servizio || '');
                    formikRef.current.setFieldValue("tipo_freno_stazionamento", val.tipo_freno_stazionamento || '');
                    formikRef.current.setFieldValue("freno_motore", val.freno_motore || '');
                    formikRef.current.setFieldValue("rallentatore", val.rallentatore || '');
                    formikRef.current.setFieldValue("misura_pneumatici_ant", val.misura_pneumatici_ant || '');
                    formikRef.current.setFieldValue("misura_pneumatici_post", val.misura_pneumatici_post || '');
                    formikRef.current.setFieldValue("altri_pneumatici", val.altri_pneumatici || '');
                    formikRef.current.setFieldValue("tipo_raffreddamento", val.tipo_raffreddamento || '');
                    formikRef.current.setFieldValue("tipo_frizione", val.tipo_frizione || '');
                    formikRef.current.setFieldValue("tipo_trasmissione", val.tipo_trasmissione || '');
                    formikRef.current.setFieldValue("numero_assi", val.numero_assi || '');
                    formikRef.current.setFieldValue("numero_assi_motore", val.numero_assi_motore || '');
                    formikRef.current.setFieldValue("interasse", val.interasse || '');
                    formikRef.current.setFieldValue("sbalzo_ant", val.sbalzo_ant || '');
                    formikRef.current.setFieldValue("sbalzo_post", val.sbalzo_post || '');
                    formikRef.current.setFieldValue("carreggiata_asse_ant", val.carreggiata_asse_ant || '');
                    formikRef.current.setFieldValue("carreggiata_asse_post", val.carreggiata_asse_post || '');
                    formikRef.current.setFieldValue("tipo_telaio", val.tipo_telaio || '');
                    formikRef.current.setFieldValue("tipo_sosp_ant", val.tipo_sosp_ant || '');
                    formikRef.current.setFieldValue("tipo_sosp_post", val.tipo_sosp_post || '');
                    formikRef.current.setFieldValue("tipo_scatola_guida", val.tipo_scatola_guida || '');
                    formikRef.current.setFieldValue("tipo_ruote", val.tipo_ruote || '');
                    formikRef.current.setFieldValue("impianto_elettrico", val.impianto_elettrico || '');
                    formikRef.current.setFieldValue("massa", val.massa || '');
                    formikRef.current.setFieldValue("tara", val.tara || '');
                    formikRef.current.setFieldValue("massa_rim", val.massa_rim || '');
                    formikRef.current.setFieldValue("massa_max_1asse", val.massa_max_1asse || '');
                    formikRef.current.setFieldValue("massa_max_2asse", val.massa_max_2asse || '');
                    formikRef.current.setFieldValue("massa_max_n_asse", val.massa_max_n_asse || '');
                    formikRef.current.setFieldValue("lunghezza", val.lunghezza || '');
                    formikRef.current.setFieldValue("larghezza", val.larghezza || '');
                    formikRef.current.setFieldValue("altezza", val.altezza || '');
                    formikRef.current.setFieldValue("num_porte", val.num_porte || '');
                    formikRef.current.setFieldValue("num_posti_ant", val.num_posti_ant || '');
                    formikRef.current.setFieldValue("num_posti_tot", val.num_posti_tot || '');
                    formikRef.current.setFieldValue("posto_guida", val.posto_guida || '');
                    formikRef.current.setFieldValue("parabrezza", val.parabrezza || '');
                    formikRef.current.setFieldValue("vetri_lat", val.vetri_lat || '');
                    formikRef.current.setFieldValue("lunotto_post", val.lunotto_post || '');
                    formikRef.current.setFieldValue("retrovisori", val.retrovisori || '');
                    formikRef.current.setFieldValue("retrovisore_interno", val.retrovisore_interno || '');
                    formikRef.current.setFieldValue("retrovisore_sx", val.retrovisore_sx || '');
                    formikRef.current.setFieldValue("retrovisore_dx", val.retrovisore_dx || '');
                    formikRef.current.setFieldValue("disp_sil_legge", (val.disp_sil_legge && val.disp_sil_legge == '1') || false);
                    formikRef.current.setFieldValue("disp_sil_org_traino", (val.disp_sil_org_traino && val.disp_sil_org_traino == '1') || false);
                    formikRef.current.setFieldValue("disp_illuminazione_ok", (val.disp_illuminazione_ok && val.disp_illuminazione_ok == '1') || false);
                    formikRef.current.setFieldValue("proiett_abbagliante", (val.proiett_abbagliante && val.proiett_abbagliante == '1') || false);
                    formikRef.current.setFieldValue("proiett_anabagliante", (val.proiett_anabagliante && val.proiett_anabagliante == '1') || false);
                    formikRef.current.setFieldValue("fendinebbia_ant", (val.fendinebbia_ant && val.fendinebbia_ant == '1') || false);
                    formikRef.current.setFieldValue("luce_pos_ant", (val.luce_pos_ant && val.luce_pos_ant == '1') || false);
                    formikRef.current.setFieldValue("luce_pos_lat", (val.luce_pos_lat && val.luce_pos_lat == '1') || false);
                    formikRef.current.setFieldValue("ind_dir_ant", (val.ind_dir_ant && val.ind_dir_ant == '1') || false);
                    formikRef.current.setFieldValue("ind_dir_lat", (val.ind_dir_lat && val.ind_dir_lat == '1') || false);
                    formikRef.current.setFieldValue("ind_dir_post", (val.ind_dir_post && val.ind_dir_post == '1') || false);
                    formikRef.current.setFieldValue("luce_pos_post", (val.luce_pos_post && val.luce_pos_post == '1') || false);
                    formikRef.current.setFieldValue("luce_arresto", (val.luce_arresto && val.luce_arresto == '1') || false);
                    formikRef.current.setFieldValue("catadiottro_post", (val.catadiottro_post && val.catadiottro_post == '1') || false);
                    formikRef.current.setFieldValue("proiettore_retromarcia", (val.proiettore_retromarcia && val.proiettore_retromarcia == '1') || false);
                    formikRef.current.setFieldValue("fendinebbia_post", (val.fendinebbia_post && val.fendinebbia_post == '1') || false);
                    formikRef.current.setFieldValue("luce_ingombro_ant", (val.luce_ingombro_ant && val.luce_ingombro_ant == '1') || false);
                    formikRef.current.setFieldValue("luce_ingombro_post", (val.luce_ingombro_post && val.luce_ingombro_post == '1') || false);
                    formikRef.current.setFieldValue("catadiottro_lat", (val.catadiottro_lat && val.catadiottro_lat == '1') || false);
                    formikRef.current.setFieldValue("disp_illum_targa", (val.disp_illum_targa && val.disp_illum_targa == '1') || false);
                    formikRef.current.setFieldValue("id_tipo_marca", val.id_tipo_marca || '');
                    formikRef.current.setFieldValue("parti_sostituite", val.parti_sostituite || '');
                    formikRef.current.setFieldValue("stato_veicolo_imm", val.stato_veicolo_imm || '');
                    formikRef.current.setFieldValue("numero_telaio_sidecar", val.numero_telaio_sidecar || '');
                    formikRef.current.setFieldValue("posizione_num_telaio_sidecar", val.posizione_num_telaio_sidecar || '');
                    formikRef.current.setFieldValue("officina", val.officina || '');
                    formikRef.current.setFieldValue("sede_officina", val.sede_officina || '');
                    formikRef.current.setFieldValue("iva_officina", val.iva_officina || '');
                    formikRef.current.setFieldValue("data_revisione", val.data_revisione || '');
                    formikRef.current.setFieldValue("um_potenza", val.um_potenza || '');
                    formikRef.current.setFieldValue("posizione_targhetta_id", val.posizione_targhetta_id || '');
                    formikRef.current.setFieldValue("dichiarazione_officina", val.dichiarazione_officina || '');
                    formikRef.current.setFieldValue("spostato", val.spostato || '0');

                    /*
                    Il freno di soccorso un tempo conteneva un valore booleano (0=No, 1=Sì), ma ora è una stringa.
                    Se leggo uno 0 o un 1, lo trasformo quindi in stringa "Sì", "No" in modo da procedere
                    successivamente con una stringa
                     */
                    let frenoSocc = val.freno_soccorso || '';
                    if (frenoSocc == 0) {
                        frenoSocc = "No"
                    }
                    if (frenoSocc == 1) {
                        frenoSocc = "Sì"
                    }
                    formikRef.current.setFieldValue("freno_soccorso", frenoSocc);
                }
                endChiamata()
            });
    }

    function getCarrozzerie() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/carrozzeria/").then((response) => {
            setCarrozzerie(response.data.data)
            endChiamata()
        });
    }

    function getColori() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/colore/").then((response) => {
            setColori(response.data.data)
            endChiamata()
        });
    }

    function getMarche(marca) {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/marca/?marca=" + marca).then((response) => {
            setMarche(response.data.data)
        });
    }

    function getModelli(modelli) {
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/modello/?modello=" + modelli).then((response) => {
            setModelli(response.data.data)
        });
    }

    function getRegistriVeicoli() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/registro/").then((response) => {
            setRegistriVeicoli(response.data.data)
            endChiamata()
        });
    }

    useEffect(() => {
        if (loadingData) {
            getCarrozzerie();
            getColori();
            getRegistriVeicoli();

            if (idVeicolo && idVeicolo !== '') {
                getVeicolo();
            }
        }
    }, [idVeicolo, idUsr, tipoRichiesta]);

    const SignupSchema = Yup.object().shape({
        id_tipo_marca: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        marca: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        tipo: Yup.string()
            .max(25, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        anno: Yup.string()
            .max(4, traduci('Attenzione! Hai inserito troppi caratteri')),
        anno_prima_immatricolazione: Yup.string()
            .max(4, traduci('Attenzione! Hai inserito troppi caratteri')),
        targa: Yup.string()
            .max(40, traduci('Attenzione! Hai inserito troppi caratteri')),
        numero_telaio: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        numero_motore: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri')),
        marca_carrozzeria: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri')),
        note_carrozzeria: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        note: Yup.string()
            .max(200, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        targa_estera: Yup.string()
            .max(40, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        targa_prec: Yup.string()
            .max(40, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        targa_estera_prec: Yup.string()
            .max(40, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tempi: Yup.string()
            .max(30, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        posizione_telaio: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        posizione_targhetta_id: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        targhetta_identificativa: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        omologazione: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        posizione_motore: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_trazione: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_cambio: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        numero_rapporti: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        rapporto_tot: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        freno_servizio: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_freni_ant: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_freni_post: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_freno_stazionamento: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        freno_motore: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        freno_soccorso: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        cilindri: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        rallentatore: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        alimentazione: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        altri_pneumatici: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_raffreddamento: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_frizione: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_trasmissione: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_telaio: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_sosp_ant: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_sosp_post: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_scatola_guida: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        tipo_ruote: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        impianto_elettrico: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        parabrezza: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        vetri_lat: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        lunotto_post: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        retrovisori: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        categoria: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        posto_guida: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        massa_max_1asse: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        massa_max_2asse: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        massa_max_n_asse: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        parti_sostituite: Yup.string()
            .max(250, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        stato_veicolo_imm: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        numero_telaio_sidecar: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        posizione_num_telaio_sidecar: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        officina: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        sede_officina: Yup.string()
            .max(250, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        iva_officina: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        misura_pneumatici_ant: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        misura_pneumatici_post: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        num_posti_tot: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
    });

    function isCertificatoCrs(tipoCert) {
        return tipoCert && (parseInt(tipoCert) == TIPO_REQ_VEICOLO_CRS
            || parseInt(tipoCert) == TIPO_REQ_VEICOLO_CRS_REIMM_ALBO
            || parseInt(tipoCert) == TIPO_REQ_VEICOLO_CRS_REIM
            || parseInt(tipoCert) == TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE);
    }

    function isCertificatoCrsReimm(tipoCert) {
        return tipoCert && (parseInt(tipoCert) == TIPO_REQ_VEICOLO_CRS_REIMM_ALBO
            || parseInt(tipoCert) == TIPO_REQ_VEICOLO_CRS_REIM);
    }

    function isRuoloNoAd() {
        return isRuoloSocio() || isRuoloClub() || isRuoloAgenzia();
    }

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_veicolo: '',
                    id_utente: '',
                    id_registro: registro,
                    numero_registrazione: numeroRegistrazione,
                    data_registrazione: '',
                    bollo_rfi: '',
                    id_club: '',
                    marca: '',
                    tipo: '',
                    tipo_veicolo: '',
                    anno: '',
                    anno_prima_immatricolazione: '',
                    targa: '',
                    targa_prec: '',
                    targa_estera: '',
                    targa_estera_prec: '',
                    numero_telaio: '',
                    tipo_motore: '',
                    numero_motore: '',
                    marca_carrozzeria: '',
                    id_tipo_carrozzeria: '',
                    note_carrozzeria: '',
                    categoria: '',
                    note: '',
                    parti_sostituite: '',
                    stato_veicolo_imm: '',
                    numero_telaio_sidecar: '',
                    posizione_num_telaio_sidecar: '',
                    num_registrazione_val: '',
                    omologato: '',
                    omologazione: '',
                    lrid: '',
                    id_colore: '',
                    posizione_motore: '',
                    tempi: '',
                    cilindri: '',
                    cilindrata: '',
                    alesaggio: '',
                    corsa: '',
                    potenza: '',
                    potenza_giri_motore: '',
                    velocita: '',
                    alimentazione: '',
                    tipo_trazione: '',
                    lunghezza: '',
                    larghezza: '',
                    altezza: '',
                    cavalli: '',
                    posizione_telaio: '',
                    targhetta_identificativa: '',
                    tipo_cambio: '',
                    numero_rapporti: '',
                    rapporto_tot: '',
                    tipo_freni_ant: '',
                    tipo_freni_post: '',
                    tipo_freno_stazionamento: '',
                    freno_motore: '',
                    rallentatore: '',
                    misura_pneumatici_ant: '',
                    misura_pneumatici_post: '',
                    altri_pneumatici: '',
                    tipo_raffreddamento: '',
                    tipo_frizione: '',
                    tipo_trasmissione: '',
                    numero_assi: '',
                    numero_assi_motore: '',
                    interasse: '',
                    sbalzo_ant: '',
                    sbalzo_post: '',
                    carreggiata_asse_ant: '',
                    carreggiata_asse_post: '',
                    tipo_telaio: '',
                    tipo_sosp_ant: '',
                    tipo_sosp_post: '',
                    tipo_scatola_guida: '',
                    tipo_ruote: '',
                    impianto_elettrico: '',
                    parabrezza: '',
                    vetri_lat: '',
                    lunotto_post: '',
                    retrovisori: '',
                    retrovisore_interno: '',
                    retrovisore_sx: '',
                    retrovisore_dx: '',
                    freno_servizio: '',
                    freno_soccorso: '',
                    disp_sil_legge: '',
                    disp_sil_org_traino: '',
                    disp_illuminazione_ok: '',
                    proiett_abbagliante: '',
                    proiett_anabagliante: '',
                    fendinebbia_ant: '',
                    luce_pos_ant: '',
                    luce_pos_lat: '',
                    ind_dir_ant: '',
                    ind_dir_lat: '',
                    ind_dir_post: '',
                    luce_pos_post: '',
                    luce_arresto: '',
                    catadiottro_post: '',
                    proiettore_retromarcia: '',
                    fendinebbia_post: '',
                    luce_ingombro_ant: '',
                    luce_ingombro_post: '',
                    catadiottro_lat: '',
                    disp_illum_targa: '',
                    id_tipo_marca: '',
                    posto_guida: '',
                    officina: '',
                    sede_officina: '',
                    iva_officina: '',
                    canc: '0',
                    foto_3_4_anteriore: '',
                    foto_3_4_posteriore: '',
                    foto_motore_lato_dx: '',
                    foto_motore_lato_sx: '',
                    foto_ruota_ant: '',
                    foto_ruota_post: '',
                    foto_interni_ant: '',
                    foto_interni_post: '',
                    foto_plancia: '',
                    foto_punzonatura_motore: '',
                    foto_punzonatura_telaio: '',
                    foto_targhetta: '',
                    foto_bagagliaio: '',
                    data_revisione: '',
                    um_potenza: '',
                    posizione_targhetta_id: '',
                    dichiarazione_officina: '',
                    spostato: '0'
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let newVeicolo = values;
                    newVeicolo['data_registrazione'] = newVeicolo['data_registrazione'] && newVeicolo['data_registrazione'] !== '' ?
                        convertiTimestampInDataPerDb(newVeicolo['data_registrazione']) : '';
                    newVeicolo['data_revisione'] = newVeicolo['data_revisione'] && newVeicolo['data_revisione'] !== '' ?
                        convertiTimestampInDataPerDb(newVeicolo['data_revisione']) : '';

                    if (idVeicolo) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + idUsr + "/veicolo/" + idVeicolo,
                                newVeicolo)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()

                                    onSubmitVeicolo(response.data.data);

                                    if (goBack === true) {
                                        history.goBack()
                                    }
                                    if (showMsgSalvataggioOk === true) {
                                        setModalMsg(response.data.description);
                                        setShow(true);
                                    }
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + idUsr + "/veicolo/",
                                newVeicolo)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()

                                    onSubmitVeicolo(response.data.data);

                                    if (goBack === true) {
                                        history.goBack()
                                    }

                                    if (showMsgSalvataggioOk === true) {
                                        setModalMsg(response.data.description);
                                        setShow(true);
                                    }
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, values, submitCount}) => (
                    <Form>
                        {
                            !isRuoloNoAd() &&
                            <div className={"row g-3 mb-2"}>
                                <Fragment>
                                    {
                                        showCampiRegistro === true &&
                                        <Fragment>
                                            <div className="col-md-2">
                                                <FieldContainer
                                                    name={'numero_registrazione'}
                                                    label={"Numero registrazione"}
                                                    errors={errors}
                                                    touched={touched}
                                                    submitCount={submitCount}
                                                >
                                                    <Field
                                                        name="numero_registrazione"
                                                        className="form-control"
                                                        readOnly={!isRuoloAdmin() && !isRuoloSegreteria()}
                                                        placeholder={"Generato al salvataggio"}
                                                    />
                                                </FieldContainer>
                                            </div>
                                            <div className={'col-md-1'}>
                                                <label htmlFor={"suffisso"} className="form-label">Suffisso:</label>
                                                <input name={"suffisso"} className="form-control" value={suffisso}
                                                       readOnly={true}/>
                                            </div>
                                            <div className={'col-md-4'}>
                                                <FieldContainer
                                                    name={'id_registro'}
                                                    label={"Registro"}
                                                    errors={errors}
                                                    touched={touched}
                                                    submitCount={submitCount}
                                                    obbligatorio={true}
                                                >
                                                    <Field
                                                        as={"select"}
                                                        name={"id_registro"}
                                                        className="form-control form-select"
                                                        value={values['id_registro']}
                                                        disabled={idVeicolo && !isRuoloAdmin() && !isRuoloSegreteria()}
                                                        onChange={(ev) => {
                                                            let idRegistro = ev.target.value;
                                                            formikRef.current.setFieldValue("id_registro", idRegistro);
                                                            formikRef.current.setFieldValue("numero_registrazione", '');

                                                            let suffisso = idRegistro && idRegistro !== '' ?
                                                                registriVeicoli.filter(reg => reg.id_registro === idRegistro)
                                                                    .map(reg => reg.suffisso).reduce((item) => item) : '';
                                                            setSuffisso(suffisso);
                                                        }}
                                                        validate={(value) => {
                                                            if (value === '' && showCampiRegistro === true) {
                                                                return 'Questo campo è obbligatorio'
                                                            }
                                                        }}
                                                    >
                                                        <option value={""}>Tutti i registri</option>
                                                        {
                                                            registriVeicoli && registriVeicoli.map((reg) => <option
                                                                value={reg.id_registro}
                                                                key={reg.id_registro}>{reg.descrizione !== '' ? reg.descrizione : reg.id_registro}</option>)
                                                        }
                                                    </Field>
                                                </FieldContainer>
                                            </div>
                                            <div className="col-md-3">
                                                <FieldContainer
                                                    name={'data_registrazione'}
                                                    label={"Data registrazione"}
                                                    errors={errors}
                                                    touched={touched}
                                                    submitCount={submitCount}
                                                >
                                                    <NsDatePicker
                                                        nome={"data_registrazione"}
                                                        dataSelezionata={values["data_registrazione"]}
                                                        maxDate={new Date()}
                                                        setSelectedDate={(date, event) => {
                                                            formikRef.current.setFieldValue("data_registrazione", date)
                                                        }}
                                                    />
                                                </FieldContainer>
                                            </div>
                                        </Fragment>
                                    }
                                    <div className="col-md-2">
                                        <FieldContainer
                                            name={'spostato'}
                                            label={"Veicolo spostato"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                        >
                                            <Field
                                                as={"select"}
                                                name={"spostato"}
                                                className="form-control form-select"
                                            >
                                                <option value={"0"}>Veicolo nuovo</option>
                                                <option value={"1"}>Veicolo spostato</option>
                                            </Field>
                                        </FieldContainer>
                                    </div>
                                </Fragment>
                            </div>
                        }
                        <div className={"row g-3"}>
                            <CertDivContainer
                                name={'id_tipo_marca'}
                                label={"Categoria veicolo"}
                                infoLabel={<Fragment><h5>Categoria veicolo da certificare</h5>Indica se il veicolo
                                    inserito
                                    è un motoveicolo, un automobile di marca FIAT o ABARTH, o altro.</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={true}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    as={"select"}
                                    name={"id_tipo_marca"}
                                    className="form-control form-select"
                                    value={values['id_tipo_marca']}
                                    disabled={idVeicolo && !isRuoloAdmin() && !isRuoloSegreteria() && values['id_tipo_marca'] && values['id_tipo_marca'] !== ''}
                                    onChange={(ev) => {
                                        formikRef.current.setFieldValue("id_tipo_marca", ev.target.value)
                                    }}
                                >
                                    <option value={""}></option>
                                    <option value={"1"}>FIAT/Abarth</option>
                                    <option value={"2"}>Motoveicolo</option>
                                    <option value={"3"}>Altre Marche</option>
                                </Field>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'note'}
                                label={"Note"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={12}
                                showAllCampi={showAllCampi}
                                show={!isRuoloNoAd()}
                            >
                                <Field name="note" className="form-control" as={"textarea"}/>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'parti_sostituite'}
                                label={"Parti sostituite"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={12}
                                showAllCampi={showAllCampi}
                                show={!isRuoloNoAd()}
                            >
                                <Field name="parti_sostituite" className="form-control" as={"textarea"}/>
                            </CertDivContainer>
                            {
                                !isRuoloNoAd() && <Fragment>
                                    <div className="col-md-3">
                                        <div className="rfi-va--center rfi-display--flex">
                                            <Field type="checkbox"
                                                   name={"bollo_rfi"}
                                                   checked={values['bollo_rfi'] == '1'}
                                                   onChange={e => {
                                                       formikRef.current.setFieldValue('bollo_rfi', e.target.checked ? '1' : '0')
                                                   }}
                                                   className="form-check-input"/>
                                            &nbsp;
                                            &nbsp;
                                            <label htmlFor={"bollo_rfi"} className="form-label">Bollo RFI</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="rfi-va--center rfi-display--flex">
                                            <Field type="checkbox"
                                                   name={"omologato"}
                                                   checked={values['omologato'] == '1'}
                                                   onChange={e => {
                                                       formikRef.current.setFieldValue('omologato', e.target.checked ? '1' : '0')
                                                   }}
                                                   className="form-check-input"/>
                                            &nbsp;
                                            &nbsp;
                                            <label htmlFor={"omologato"} className="form-label">Omologato</label>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>

                        <h4 className={"separatoreform"}>Dati Immatricolazione</h4>
                        <div className="row g-3">
                            <CertDivContainer
                                name={'anno_prima_immatricolazione'}
                                label={"Anno di prima immatricolazione"}
                                infoLabel={<Fragment><h5>Anno di prima immatricolazione</h5>Puoi trovare la data di
                                    prima
                                    immatricolazione del tuo veicolo sul relativo libretto di circolazione a fianco
                                    alla lettera (B).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd()}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    name="anno_prima_immatricolazione"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur() } // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd()) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'targa'}
                                label={"Targa"}
                                infoLabel={<Fragment><h5>Targa del tuo veicolo</h5>Puoi trovare la targa del tuo
                                    veicolo
                                    sul relativo libretto di circolazione a fianco alla lettera
                                    (A).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    name="targa"
                                    className="form-control"
                                    value={values['targa']}
                                    onChange={(ev) => {
                                        let newVal = ev.target.value && ev.target.value !== '' ? ev.target.value.toUpperCase() : '';
                                        formikRef.current.setFieldValue('targa', newVal);
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'targa_prec'}
                                label={"Targa precedente nazionale (se esiste)"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field name="targa_prec" className="form-control"/>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'targa_estera'}
                                label={"Targa estera"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field name="targa_estera" className="form-control"/>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'targa_estera_prec'}
                                label={"Targa precedente estera (se esiste)"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field name="targa_estera_prec" className="form-control"/>
                            </CertDivContainer>
                        </div>

                        <h4 className={"separatoreform"}>Dati Generali</h4>
                        <div className="row g-3">
                            <CertDivContainer
                                name={'anno'}
                                label={"Anno di costruzione"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    name="anno"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'marca'}
                                label={"Marca veicolo"}
                                infoLabel={<Fragment><h5>Marca del tuo veicolo</h5>Puoi trovare la marca del tuo
                                    veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (D.1).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={true}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <AutocompleteText
                                    name={"marca"}
                                    formikRef={formikRef}
                                    formikValues={values}
                                    filterFunction={getMarche}
                                    filteredValues={marche}
                                    toUpper={true}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo'}
                                label={"Tipo"}
                                infoLabel={<Fragment><h5>Tipo del tuo veicolo</h5>Puoi trovare il tipo del tuo
                                    veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (D.2).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={true}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <AutocompleteText
                                    name={"tipo"}
                                    formikRef={formikRef}
                                    formikValues={values}
                                    filterFunction={getModelli}
                                    filteredValues={modelli}
                                    toUpper={true}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'omologazione'}
                                label={"Numero omologazione"}
                                infoLabel={<Fragment><h5>Numero di omologazione del tipo di veicolo</h5>Puoi
                                    trovare
                                    numero di omologazione del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla lettera
                                    (K).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrsReimm(tipoRichiesta)}
                            >
                                <Field
                                    name="omologazione"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'id_tipo_carrozzeria'}
                                label={"Tipo carrozzeria"}
                                infoLabel={<Fragment><h5>Tipo di carrozzeria del tuo veicolo</h5>Puoi trovare il
                                    tipo di
                                    carrozzeria del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (J.2).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd()}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    as={"select"}
                                    name={"id_tipo_carrozzeria"}
                                    className="form-control form-select"
                                    value={values['id_tipo_carrozzeria']}
                                    onChange={(ev) => {
                                        formikRef.current.setFieldValue("id_tipo_carrozzeria", ev.target.value)
                                    }}
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd()) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                >
                                    <option value={""}>Seleziona un tipo</option>
                                    {
                                        carrozzerie && carrozzerie.map((carrozzeria) => <option
                                            value={carrozzeria.id_tipo_carrozzeria}
                                            key={carrozzeria.id_tipo_carrozzeria}>{carrozzeria.descrizione !== '' ? carrozzeria.descrizione : carrozzeria.id_tipo_carrozzeria}</option>)
                                    }
                                </Field>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo_veicolo'}
                                label={"Tipo veicolo"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloCommissario()}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={!isRuoloNoAd()}
                            >
                                <Field
                                    name="tipo_veicolo"
                                    className="form-control"
                                    placeholder={"autovettura / motoveicolo"}
                                    validate={(value) => {
                                        if (value === '' && isRuoloCommissario()) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'categoria'}
                                label={"Categoria (art. 47 C.d.S)"}
                                infoLabel={<Fragment><h5>Categoria del veicolo</h5>
                                    <ul>
                                        <li>M1 - veicoli trasporto persone fino a 9 post totali</li>
                                        <li>M2 - veicoli trasporto persone con più di 9 post totali e massa totale fino
                                            a 5 t
                                        </li>
                                        <li>M3 - veicoli trasporto persone con più di 9 post totali e massa totale
                                            superiore a 5 t
                                        </li>
                                        <li>N1 - veicoli trasporto merci con massa totale fino a 3,5 t</li>
                                        <li>N2 - veicoli trasporto merci con massa totale tra 3,5 e 12 t</li>
                                        <li>N3 - veicoli trasporto merci con massa totale superiore a 12 t</li>
                                        <li>O - rimorchi o semi rimorchi</li>
                                        <li>Altri tipi (specificare)</li>
                                    </ul>
                                </Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="categoria"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo_raffreddamento'}
                                label={"Tipo raffreddamento"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                infoLabel={<Fragment><h5>Tipo di raffreddamento</h5>Esempio: ad acqua; ad aria; con
                                    pompa-termosifone-ventola; etc...</Fragment>}
                            >
                                <Field
                                    name="tipo_raffreddamento"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo_scatola_guida'}
                                label={"Tipo scatola guida"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                infoLabel={<Fragment><h5>Tipo di scatola di guida</h5>Esempio: cremagliera vite e rullo
                                    settori idraulica; etc...</Fragment>}
                            >
                                <Field
                                    name="tipo_scatola_guida"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo_ruote'}
                                label={"Tipo ruote"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                infoLabel={<Fragment><h5>Tipo di ruote</h5>Esempio: cerchi a disco; in legno; a raggi; a
                                    tallone; etc...</Fragment>}
                            >
                                <Field
                                    name="tipo_ruote"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'impianto_elettrico'}
                                label={"Impianto elettrico"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                infoLabel={<Fragment><h5>Tipo di impianto elettrico</h5>Esempio: magnete-spinterogeno;
                                    batteria 6-12-24 V; dinamo-alternatore; etc...</Fragment>}
                            >
                                <Field
                                    name="impianto_elettrico"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                        if (value.length > 50) {
                                            return 'Attenzione! Hai inserito troppi caratteri'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                        </div>

                        <h4 className={"separatoreform"}>Dati Identificativi</h4>
                        <div className="row g-3">
                            <CertDivContainer
                                name={'numero_telaio'}
                                label={"Numero telaio"}
                                infoLabel={<Fragment><h5>Il numero del telaio del tuo veicolo</h5>Puoi trovare
                                    numero del telaio del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (E: VIN).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd()}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    name="numero_telaio"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd()) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'posizione_telaio'}
                                label={"Posizione telaio"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                                infoLabel={<Fragment><h5>Posizione del telaio</h5>Esempio: lato destro, lato
                                    sinistro</Fragment>}
                            >
                                <Field
                                    name="posizione_telaio"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'numero_telaio_sidecar'}
                                label={"Numero di telaio Sidecar"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={!isRuoloNoAd()}
                            >
                                <Field
                                    name="numero_telaio_sidecar"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'posizione_num_telaio_sidecar'}
                                label={"Posizione telaio Sidecar"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={!isRuoloNoAd()}
                            >
                                <Field
                                    name="posizione_num_telaio_sidecar"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'targhetta_identificativa'}
                                label={"Targhetta identificativa"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                                infoLabel={<Fragment><h5>Targhetta identificativa</h5>Esempio: originaria; riprodotta
                                    dal RFI</Fragment>}
                            >
                                <Field
                                    name="targhetta_identificativa"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'posizione_targhetta_id'}
                                label={"Posizione targhetta identificativa originaria"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrsReimm(tipoRichiesta)}
                            >
                                <Field
                                    name="posizione_targhetta_id"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo_telaio'}
                                label={"Tipo telaio"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                infoLabel={<Fragment><h5>Tipo di telaio</h5>Esempio: a longheroni; a trave centrale;
                                    tubolare monoscocca; etc...</Fragment>}
                            >
                                <Field
                                    name="tipo_telaio"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                        </div>

                        <h4 className={"separatoreform"}>Dati Motore</h4>
                        <div className="row g-3">
                            <CertDivContainer
                                name={'numero_motore'}
                                label={"Numero motore"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE || isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="numero_motore"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tipo_motore'}
                                label={"Tipo motore"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd()}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    name="tipo_motore"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd()) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'posizione_motore'}
                                label={"Posizione motore"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                                infoLabel={<Fragment><h5>Posizione del motore</h5>Esempio: anteriore; posteriore;
                                    centrale; etc...</Fragment>}
                            >
                                <Field
                                    name="posizione_motore"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'tempi'}
                                label={"Tempi motore"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="tempi"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'alimentazione'}
                                label={"Alimentazione"}
                                infoLabel={<Fragment><h5>Tipo di combustibile o di alimentazione del tuo
                                    veicolo</h5>Puoi trovare l'alimentazione del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (P.3).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                    || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                    && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                            >
                                <Field
                                    name="alimentazione"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                            || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'cilindri'}
                                label={"Numero cilindri"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                    || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                    && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                            >
                                <Field
                                    name="cilindri"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                            || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'cilindrata'}
                                label={"Cilindrata totale (cc)"}
                                infoLabel={<Fragment><h5>Cilindrata del tuo veicolo</h5>Puoi trovare la
                                    cilindrata del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (P.1).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                    || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                    && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                            >
                                <Field
                                    name="cilindrata"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    step="0.01"
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                            || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'alesaggio'}
                                label={"Alesaggio/diametro mm"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrsReimm(tipoRichiesta)}
                            >
                                <Field
                                    name="alesaggio"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'corsa'}
                                label={"Corsa mm"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrsReimm(tipoRichiesta)}
                            >
                                <Field
                                    name="corsa"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'potenza'}
                                label={"Potenza massima (kw o CV)"}
                                infoLabel={<Fragment><h5>Potenza massima del tuo veicolo</h5>Puoi
                                    trovare la
                                    potenza massima del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (P.2).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                    || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                    && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                            >
                                <Field
                                    name="potenza"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    step="0.01"
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                            || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'um_potenza'}
                                label={"Unità di misura potenza"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                    || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                    && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                            >
                                <Field
                                    as={"select"}
                                    name={"um_potenza"}
                                    className="form-control form-select"
                                    value={values['um_potenza']}
                                    onChange={(ev) => {
                                        formikRef.current.setFieldValue("um_potenza", ev.target.value)
                                    }}
                                >
                                    <option value={""}></option>
                                    <option value={"1"}>CV</option>
                                    <option value={"2"}>KW</option>
                                </Field>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'potenza_giri_motore'}
                                label={"Giri motore potenza massima"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrsReimm(tipoRichiesta)}
                            >
                                <Field
                                    name="potenza_giri_motore"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    step="0.01"
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'cavalli'}
                                label={"Cavalli (cv)"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                            >
                                <Field
                                    name="cavalli"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    step="0.01"
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                            || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'velocita'}
                                label={"Velocità massima del veicolo (km/h)"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="velocita"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                        </div>
                        {
                            !isRuoloNoAd() || (tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO) ?
                                <Fragment>
                                    <h4 className={"separatoreform"}>Trasmissione</h4>
                                    <div className="row g-3">
                                        <CertDivContainer
                                            name={'tipo_trazione'}
                                            label={"Tipo di trazione"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Tipo di trazione</h5>Esempio: anteriore;
                                                posteriore; integrale; etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_trazione"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_trasmissione'}
                                            label={"Tipo di trasmissione"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            infoLabel={<Fragment><h5>Tipo di trasmissione</h5>Esempio: albero con
                                                giunti-catena; etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_trasmissione"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_cambio'}
                                            label={"Tipo di cambio"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            infoLabel={<Fragment><h5>Tipo di cambio</h5>Esempio: meccanico; automatico;
                                                2-3-4-5 marce; semiautomatico; etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_cambio"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                        || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'numero_assi_motore'}
                                            label={"Numero assi motore"}
                                            infoLabel={<Fragment><h5>Numero assi motore del tuo veicolo</h5>Puoi
                                                trovare il numero di assi del tuo veicolo
                                                sul relativo libretto di circolazione a fianco alla lettera
                                                (L).</Fragment>}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="numero_assi_motore"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'numero_rapporti'}
                                            label={"Numero rapporti (marce)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="numero_rapporti"
                                                className="form-control"
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'rapporto_tot'}
                                            label={"Rapporto totale al ponte"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="rapporto_tot"
                                                className="form-control"
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_frizione'}
                                            label={"Frizione"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            infoLabel={<Fragment><h5>Tipo di frizione</h5>Esempio: comando
                                                meccanico-idraulicoautomatico; a disco; a cono; a dischi multipli;
                                                etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_frizione"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                        || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                    </div>

                                    <h4 className={"separatoreform"}>Freni</h4>
                                    <div className="row g-3">
                                        <CertDivContainer
                                            name={'freno_servizio'}
                                            label={"Freno di servizio"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Tipo di freno di servizio</h5>Esempio: meccanico;
                                                idraulico; pneumatico; idropneumatico; etc...</Fragment>}
                                        >
                                            <Field
                                                name="freno_servizio"
                                                className="form-control"
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'freno_soccorso'}
                                            label={"Freno di soccorso"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="freno_soccorso"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_freno_stazionamento'}
                                            label={"Freno di stazionamento"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Tipo di freno di stazionamento</h5>Esempio:
                                                meccanico; pneumatico; etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_freno_stazionamento"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'freno_motore'}
                                            label={"Freno motore (per autocarri ed autobus)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="freno_motore"
                                                className="form-control form-select"
                                                as={"select"}
                                                value={values['freno_motore']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("freno_motore", ev.target.value)
                                                }}
                                            >
                                                <option value={""}></option>
                                                <option value={"1"}>Sì</option>
                                                <option value={"0"}>No</option>
                                            </Field>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'rallentatore'}
                                            label={"Rallentatore (per autocarri ed autobus)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="rallentatore"
                                                className="form-control form-select"
                                                as={"select"}
                                                value={values['rallentatore']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("rallentatore", ev.target.value)
                                                }}
                                            >
                                                <option value={""}></option>
                                                <option value={"1"}>Sì</option>
                                                <option value={"0"}>No</option>
                                            </Field>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_freni_ant'}
                                            label={"Tipo di freno anteriore"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            infoLabel={<Fragment><h5>Tipo di freni anteriori</h5>Esempio:
                                                idraulici-meccanici; a tamburo; a disco; prnumatico; idropneumatico;
                                                etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_freni_ant"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_freni_post'}
                                            label={"Tipo di freno posteriore"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                            infoLabel={<Fragment><h5>Tipo di freni posteriori</h5>Esempio:
                                                idraulici-meccanici; a tamburo; a disco; prnumatico; idropneumatico;
                                                etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_freni_post"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                    </div>
                                </Fragment>
                                : ''
                        }
                        {
                            !isRuoloNoAd() || isCertificatoCrs(tipoRichiesta) ?
                                <Fragment>

                                    <h4 className={"separatoreform"}>Massa e dimensioni</h4>
                                    <div className="row g-3">
                                        <CertDivContainer
                                            name={'massa'}
                                            label={"Massa complessiva (peso totale) Kg"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="massa"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tara'}
                                            label={"Tara (Kg)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="tara"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'massa_rim'}
                                            label={"Massa rimorchiabile (Kg)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="massa_rim"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'massa_max_1asse'}
                                            label={"Massa massima 1° asse"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="massa_max_1asse"
                                                className="form-control"
                                                placeholder={"Solo per autocarri"}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'massa_max_2asse'}
                                            label={"Massa massima 2° asse"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="massa_max_2asse"
                                                className="form-control"
                                                placeholder={"Solo per autocarri"}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'massa_max_n_asse'}
                                            label={"Massa massima enn.mo asse"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="massa_max_n_asse"
                                                className="form-control"
                                                placeholder={"Solo per autocarri"}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'lunghezza'}
                                            label={"Lunghezza massima (mm)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="lunghezza"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'larghezza'}
                                            label={"Larghezza massima (mm)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="larghezza"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'altezza'}
                                            label={"Altezza massima (a scarico) mm"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="altezza"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'numero_assi'}
                                            label={"Numero assi"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="numero_assi"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'interasse'}
                                            label={"Interasse(i) mm"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="interasse"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'sbalzo_ant'}
                                            label={"Sbalzo anteriore mm"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="sbalzo_ant"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'sbalzo_post'}
                                            label={"Sbalzo posteriore mm"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="sbalzo_post"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'carreggiata_asse_ant'}
                                            label={"Carreggiata per ogni asse anteriore (mm)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="carreggiata_asse_ant"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'carreggiata_asse_post'}
                                            label={"Carreggiata per ogni asse posteriore (mm)"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <Field
                                                name="carreggiata_asse_post"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                            />
                                        </CertDivContainer>
                                    </div>

                                    <h4 className={"separatoreform"}>Pneumatici e sospensioni</h4>
                                    <div className="row g-3">
                                        <CertDivContainer
                                            name={'misura_pneumatici_ant'}
                                            label={"Misura pneumatici anteriori"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                        >
                                            <Field
                                                name="misura_pneumatici_ant"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                        || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'misura_pneumatici_post'}
                                            label={"Misura pneumatici posteriori"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                        >
                                            <Field name="misura_pneumatici_post"
                                                   className="form-control"
                                                   validate={(value) => {
                                                       if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                           || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                                           return 'Questo campo è obbligatorio'
                                                       }
                                                   }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_sosp_ant'}
                                            label={"Sospensioni anteriori"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                            infoLabel={<Fragment><h5>Tipo di sospensioni anteriori</h5>Esempio: ruote
                                                indipendenti; assale rigido ant/post; etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_sosp_ant"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                        || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'tipo_sosp_post'}
                                            label={"Sospensioni posteriori"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                            infoLabel={<Fragment><h5>Tipo di sospensioni posteriori</h5>Esempio: ruote
                                                indipendenti; assale rigido ant/post; etc...</Fragment>}
                                        >
                                            <Field
                                                name="tipo_sosp_post"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                        || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'altri_pneumatici'}
                                            label={"Altri pneumatici consentiti"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field name="altri_pneumatici" className="form-control"/>
                                        </CertDivContainer>
                                    </div>
                                </Fragment>
                                : ''
                        }

                        <h4 className={"separatoreform"}>Carrozzeria</h4>
                        <div className="row g-3">
                            <CertDivContainer
                                name={'num_porte'}
                                label={"Numero porte"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="num_porte"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'num_posti_ant'}
                                label={"Posti anteriori"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="num_posti_ant"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'num_posti_tot'}
                                label={"Posti totali"}
                                infoLabel={<Fragment><h5>Numero posti del tuo veicolo</h5>Puoi trovare
                                    il
                                    numero di posti a sedere del tuo veicolo
                                    sul relativo libretto di circolazione a fianco alla dicitura
                                    (S.1).</Fragment>}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={isCertificatoCrs(tipoRichiesta)}
                            >
                                <Field
                                    name="num_posti_tot"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'posto_guida'}
                                label={"Posto di guida"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={!isRuoloNoAd() && isCertificatoCrsReimm(tipoRichiesta)}
                                infoLabel={<Fragment><h5>Posto di guida</h5>Esempio: sinistra; destra</Fragment>}
                            >
                                <Field
                                    name="posto_guida"
                                    className="form-control"
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'marca_carrozzeria'}
                                label={"Marca carrozzeria"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={isRuoloNoAd()}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field
                                    name="marca_carrozzeria"
                                    className="form-control"
                                    validate={(value) => {
                                        if (value === '' && isRuoloNoAd()) {
                                            return 'Questo campo è obbligatorio'
                                        }
                                    }}
                                />
                            </CertDivContainer>
                            <CertDivContainer
                                name={'note_carrozzeria'}
                                label={"Specificazioni carrozzeria"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <Field name="note_carrozzeria" className="form-control"/>
                            </CertDivContainer>
                            <CertDivContainer
                                name={'id_colore'}
                                label={"Colore carrozzeria"}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                colSize={3}
                                showAllCampi={showAllCampi}
                                show={true}
                            >
                                <select id={"id_colore"}
                                        className="form-control form-select"
                                        value={values['id_colore']}
                                        onChange={(ev) => {
                                            formikRef.current.setFieldValue("id_colore", ev.target.value)
                                        }}
                                >
                                    <option value={""}>Seleziona un colore</option>
                                    {
                                        colori && colori.map((colore) => <option value={colore.id_colore}
                                                                                 key={colore.id_colore}>{colore.descrizione !== '' ? colore.descrizione : colore.id_colore}</option>)
                                    }
                                </select>
                            </CertDivContainer>
                        </div>
                        {
                            isCertificatoCrs(tipoRichiesta) || !isRuoloNoAd() ?
                                <Fragment>

                                    <h4 className={"separatoreform"}>Dispositivi di segnalazione visiva e di
                                        illuminazione</h4>
                                    <div className="row g-3">
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"proiett_abbagliante"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"proiett_abbagliante"} className="form-label">Proiettore
                                                    abbagliante</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"proiett_abbagliante"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"proiett_anabagliante"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"proiett_anabagliante"} className="form-label">Proiettore
                                                    anabbagliante</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"proiett_anabagliante"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"fendinebbia_ant"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"fendinebbia_ant"} className="form-label">Proiettore
                                                    fendinebbia anteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"fendinebbia_ant"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"luce_pos_ant"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"luce_pos_ant"} className="form-label">Luce di
                                                    posizione anteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"luce_pos_ant"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"luce_pos_lat"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"luce_pos_lat"} className="form-label">Luce di
                                                    posizione laterale</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"luce_pos_lat"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"ind_dir_ant"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"ind_dir_ant"} className="form-label">Indicatore di
                                                    direzione anteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"ind_dir_ant"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"ind_dir_lat"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"ind_dir_lat"} className="form-label">Indicatore di
                                                    direzione laterale</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"ind_dir_lat"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"ind_dir_post"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"ind_dir_post"} className="form-label">Indicatore di
                                                    direzione posteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"ind_dir_post"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"luce_pos_post"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"luce_pos_post"} className="form-label">Luce di
                                                    posizione posteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"luce_pos_post"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"luce_arresto"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"luce_arresto"} className="form-label">Luce di
                                                    arresto</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"luce_arresto"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"catadiottro_post"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"catadiottro_post"} className="form-label">Catadiottro
                                                    posteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"catadiottro_post"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"proiettore_retromarcia"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"proiettore_retromarcia"} className="form-label">Proiettore
                                                    per la retromarcia</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"proiettore_retromarcia"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"fendinebbia_post"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"fendinebbia_post"} className="form-label">Proiettore
                                                    fendinebbia posteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"fendinebbia_post"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"luce_ingombro_ant"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"luce_ingombro_ant"} className="form-label">Luce
                                                    ingombro anteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"luce_ingombro_ant"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"luce_ingombro_post"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"luce_ingombro_post"} className="form-label">Luce
                                                    ingombro posteriore</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"luce_ingombro_post"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"catadiottro_lat"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"catadiottro_lat"} className="form-label">Catadiottro
                                                    laterale</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"catadiottro_lat"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"disp_illum_targa"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"disp_illum_targa"} className="form-label">Dispositivo
                                                    di illuminazione targa</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"disp_illum_targa"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                    </div>
                                    <div className={"row mt-4"}>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field
                                                    type="checkbox"
                                                    name={"disp_sil_legge"}
                                                    className="form-check-input"
                                                />
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"disp_sil_legge"} className="form-label">Dispositivo
                                                    silenziatore conforme alla legge</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"disp_sil_legge"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"disp_sil_org_traino"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"disp_sil_org_traino"} className="form-label">Dispositivo
                                                    silenziatore organi di traino presente</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"disp_sil_org_traino"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            addFieldContainer={false}
                                            colSize={6}
                                            showAllCampi={showAllCampi}
                                            show={tipoRichiesta == TIPO_REQ_VEICOLO_CRS}
                                        >
                                            <div className="rfi-va--center rfi-display--flex">
                                                <Field type="checkbox" name={"disp_illuminazione_ok"}
                                                       className="form-check-input"/>
                                                &nbsp;
                                                &nbsp;
                                                <label htmlFor={"disp_illuminazione_ok"} className="form-label">Dispositivi
                                                    di segnalazione visiva, illuminazione e sonora nel rispetto delle
                                                    norme e convalidati in sede di revisione</label>
                                                <LabelErroreCampo errors={errors} touched={touched}
                                                                  name={"disp_illuminazione_ok"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                        </CertDivContainer>
                                    </div>

                                    <h4 className={"separatoreform"}>Vetri e retrovisori</h4>
                                    <div className={"row g-3"}>

                                        <CertDivContainer
                                            name={'parabrezza'}
                                            label={"Parabrezza con vetro"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Tipo di parabrezza con vetro</h5>Esempio: piano;
                                                temprato; stratificato; etc...</Fragment>}
                                        >
                                            <Field
                                                name="parabrezza"
                                                className="form-control"
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'vetri_lat'}
                                            label={"Vetri laterali"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Tipo di vetri laterali</h5>Esempio: piano;
                                                temprato; stratificato; etc...</Fragment>}
                                        >
                                            <Field
                                                name="vetri_lat"
                                                className="form-control"
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'lunotto_post'}
                                            label={"Lunotto posteriore con vetro"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrsReimm(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Tipo di lunotto posteriore</h5>Esempio: piano;
                                                temprato; stratificato; di plastica; etc...</Fragment>}
                                        >
                                            <Field
                                                name="lunotto_post"
                                                className="form-control"
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'retrovisori'}
                                            label={"Retrovisori"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            obbligatorio={isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={isCertificatoCrs(tipoRichiesta)}
                                            infoLabel={<Fragment><h5>Retrovisori</h5>Esempio: sx; dx; interno;
                                                altri</Fragment>}
                                        >
                                            <Field
                                                name="retrovisori"
                                                className="form-control"
                                                validate={(value) => {
                                                    if (value === '' && isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)) {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'retrovisore_interno'}
                                            label={"Retrovisore interno"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={!isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="retrovisore_interno"
                                                className="form-control form-select"
                                                as={"select"}
                                                value={values['retrovisore_interno']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("retrovisore_interno", ev.target.value)
                                                }}
                                            >
                                                <option value={""}></option>
                                                <option value={"1"}>Sì</option>
                                                <option value={"0"}>No</option>
                                            </Field>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'retrovisore_sx'}
                                            label={"Retrovisore(i) lato sinistro"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={!isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="retrovisore_sx"
                                                className="form-control form-select"
                                                as={"select"}
                                                value={values['retrovisore_sx']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("retrovisore_sx", ev.target.value)
                                                }}
                                            >
                                                <option value={""}></option>
                                                <option value={"1"}>Sì</option>
                                                <option value={"0"}>No</option>
                                            </Field>
                                        </CertDivContainer>
                                        <CertDivContainer
                                            name={'retrovisore_dx'}
                                            label={"Retrovisore(i) lato destro"}
                                            errors={errors}
                                            touched={touched}
                                            submitCount={submitCount}
                                            colSize={3}
                                            showAllCampi={showAllCampi}
                                            show={!isRuoloNoAd() && isCertificatoCrs(tipoRichiesta)}
                                        >
                                            <Field
                                                name="retrovisore_dx"
                                                className="form-control form-select"
                                                as={"select"}
                                                value={values['retrovisore_dx']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("retrovisore_dx", ev.target.value)
                                                }}
                                            >
                                                <option value={""}></option>
                                                <option value={"1"}>Sì</option>
                                                <option value={"0"}>No</option>
                                            </Field>
                                        </CertDivContainer>
                                    </div>
                                </Fragment>
                                : ''
                        }
                        {
                            (!isRuoloNoAd() || isCertificatoCrs(tipoRichiesta)) && <Fragment>

                                <h4 className={"separatoreform"}>Dichiarazioni acquisite (art. 4, commi 2 e 3 del
                                    Decreto)</h4>
                                <div className="row g-3">
                                    <CertDivContainer
                                        name={'dichiarazione_officina'}
                                        label={"Sei in possesso della dichiarazione dell'officina?"}
                                        errors={errors}
                                        touched={touched}
                                        submitCount={submitCount}
                                        colSize={3}
                                        showAllCampi={showAllCampi}
                                        show={isCertificatoCrs(tipoRichiesta)}
                                    >
                                        <Field
                                            name="dichiarazione_officina"
                                            className="form-control form-select"
                                            as={"select"}
                                            value={values['dichiarazione_officina']}
                                            onChange={(ev) => {
                                                formikRef.current.setFieldValue("dichiarazione_officina", ev.target.value)
                                            }}
                                        >
                                            <option value={""}></option>
                                            <option value={"1"}>Sì</option>
                                            <option value={"0"}>No</option>
                                        </Field>
                                    </CertDivContainer>
                                    <CertDivContainer
                                        name={'officina'}
                                        label={"Descrizione officina"}
                                        errors={errors}
                                        touched={touched}
                                        submitCount={submitCount}
                                        colSize={3}
                                        showAllCampi={showAllCampi}
                                        show={isCertificatoCrs(tipoRichiesta)}
                                    >
                                        <Field name="officina" className="form-control"/>
                                    </CertDivContainer>
                                    <CertDivContainer
                                        name={'sede_officina'}
                                        label={"Sede officina"}
                                        errors={errors}
                                        touched={touched}
                                        submitCount={submitCount}
                                        colSize={3}
                                        showAllCampi={showAllCampi}
                                        show={isCertificatoCrs(tipoRichiesta)}
                                    >
                                        <Field name="sede_officina" className="form-control"
                                               placeholder={"Via - CAP comune (provincia)"}/>
                                    </CertDivContainer>
                                    <CertDivContainer
                                        name={'iva_officina'}
                                        label={"Partita IVA officina"}
                                        errors={errors}
                                        touched={touched}
                                        submitCount={submitCount}
                                        colSize={3}
                                        showAllCampi={showAllCampi}
                                        show={isCertificatoCrs(tipoRichiesta)}
                                    >
                                        <Field name="iva_officina" className="form-control"/>
                                    </CertDivContainer>
                                    <CertDivContainer
                                        name={'data_revisione'}
                                        label={"Data revisione"}
                                        errors={errors}
                                        touched={touched}
                                        submitCount={submitCount}
                                        colSize={3}
                                        showAllCampi={showAllCampi}
                                        show={isCertificatoCrs(tipoRichiesta)}
                                        obbligatorio={isRuoloNoAd() && values['dichiarazione_officina'] == '1'}
                                    >
                                        <Field
                                            component={NsDatePicker}
                                            nome={"data_revisione"}
                                            name={"data_revisione"}
                                            dataSelezionata={values["data_revisione"]}
                                            maxDate={new Date()}
                                            setSelectedDate={(date, event) => {
                                                formikRef.current.setFieldValue("data_revisione", date)
                                            }}
                                            validate={(value) => {
                                                if ((value === '' || !value) && isRuoloNoAd() && values['dichiarazione_officina'] == '1') {
                                                    return 'Questo campo è obbligatorio'
                                                }
                                            }}
                                        />
                                    </CertDivContainer>
                                    <CertDivContainer
                                        name={'stato_veicolo_imm'}
                                        label={"Stato del veicolo"}
                                        errors={errors}
                                        touched={touched}
                                        submitCount={submitCount}
                                        colSize={3}
                                        showAllCampi={showAllCampi}
                                        show={!isRuoloNoAd()}
                                    >
                                        <Field
                                            name="stato_veicolo_imm"
                                            className="form-control"
                                            placeholder={"Veicolo in buono stato d'uso"}
                                        />
                                    </CertDivContainer>
                                </div>
                            </Fragment>
                        }
                        {
                            veicolo && (isModificaCertificato || !isRuoloNoAd()) ?
                                <Fragment>

                                    <h4 className={"separatoreform"}>Foto</h4>
                                    <h6 className={"pb-4"}>Le fotografie devono essere a colori, nitide, scattate
                                        possibilmente in luce diurna ed inquadrare il soggetto interamente per meglio
                                        valutarne le caratteristiche ed i dettagli senza elementi di disturbo (persone
                                        ed animali). Non verranno accettate foto con tracce di ruggine o sporco.</h6>
                                    <div className="row g-3">
                                        {
                                            values['id_tipo_marca'] == '2' ?
                                                <Fragment>
                                                    {/* Motoveicoli */}
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_3_4_ANTERIORE_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto vista 3/4 anteriore lato destro"}
                                                            immagineDefault={"assets/img/moto_3_4_ant.png"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_3_4_POSTERIORE_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto vista 3/4 posteriore lato sinistro"}
                                                            immagineDefault={"assets/img/moto_3_4_post.png"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_CRUSCOTTO_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto eventuale cruscotto portastrumenti"}
                                                            immagineDefault={null} // TODO
                                                            obbligatoria={false}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_SELLA_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto sella/selle (se singole)"}
                                                            immagineDefault={null} // TODO
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_RUOTA_ANT_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto ruota/e anteriore/i"}
                                                            immagineDefault={"assets/img/moto_ruota_ant.jpg"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_RUOTA_POST_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto ruota/e posteriore/i"}
                                                            immagineDefault={"assets/img/moto_ruota_post.jpg"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_PUNZONATURA_MOTORE_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto punzonatura del numero motore"}
                                                            immagineDefault={"assets/img/punzonatura_motore.jpg"} // TODO
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                            && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_PUNZONATURA_TELAIO_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto punzonatura del numero di telaio"}
                                                            immagineDefault={"assets/img/punzonatura_telaio.jpg"} // TODO
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_TARGHETTA_MOTO}
                                                            veicolo={veicolo}
                                                            label={"Foto targhetta identificazione"}
                                                            immagineDefault={"assets/img/targhetta.jpg"} // TODO
                                                            obbligatoria={false}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    {/* Autoveicoli */}
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_3_4_ANTERIORE}
                                                            veicolo={veicolo}
                                                            label={"Foto vista 3/4 anteriore sinistra"}
                                                            immagineDefault={"assets/img/auto_3_4_anteriore.png"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_3_4_POSTERIORE}
                                                            veicolo={veicolo}
                                                            label={"Foto vista 3/4 posteriore destra"}
                                                            immagineDefault={"assets/img/auto_3_4_posteriore.png"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_MOTORE_LATO_DX}
                                                            veicolo={veicolo}
                                                            label={"Foto motore lato destro"}
                                                            immagineDefault={"assets/img/motore_dx.png"}
                                                            obbligatoria={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                            && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_MOTORE_LATO_SX}
                                                            veicolo={veicolo}
                                                            label={"Foto motore lato sinistro"}
                                                            immagineDefault={"assets/img/motore_sx.png"}
                                                            obbligatoria={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                            && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_RUOTA_ANT}
                                                            veicolo={veicolo}
                                                            label={"Foto ruote anteriori con misure leggibili"}
                                                            immagineDefault={"assets/img/ruota_ant.png"}
                                                            obbligatoria={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_RUOTA_POST}
                                                            veicolo={veicolo}
                                                            label={"Foto ruote posteriori con misure leggibili"}
                                                            immagineDefault={"assets/img/ruota_post.png"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_INTERNI_ANT}
                                                            veicolo={veicolo}
                                                            label={"Foto vista interni anteriori"}
                                                            immagineDefault={"assets/img/interni_ant.jpg"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_INTERNI_POST}
                                                            veicolo={veicolo}
                                                            label={"Foto vista interni posteriori"}
                                                            immagineDefault={"assets/img/interni_post.png"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_PLANCIA}
                                                            veicolo={veicolo}
                                                            label={"Foto vista plancia portastrumenti"}
                                                            immagineDefault={"assets/img/plancia.png"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_PUNZONATURA_MOTORE}
                                                            veicolo={veicolo}
                                                            label={"Foto punzonatura numero e tipo del motore (obbligatorio solo per i veicoli con data di costruzione anteriore al 1990)"}
                                                            immagineDefault={"assets/img/punzonatura_motore.jpg"}
                                                            obbligatoria={false}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta != TIPO_REQ_VEICOLO_LISTA_CHIUSA
                                                            && tipoRichiesta != TIPO_REQ_VEICOLO_ALBO}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_PUNZONATURA_TELAIO}
                                                            veicolo={veicolo}
                                                            label={"Foto punzonatura numero e tipo del telaio"}
                                                            immagineDefault={"assets/img/punzonatura_telaio.jpg"}
                                                            obbligatoria={isRuoloNoAd() && (isCertificatoCrs(tipoRichiesta)
                                                                || tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE)}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_TARGHETTA}
                                                            veicolo={veicolo}
                                                            label={"Foto targhetta identificazione"}
                                                            immagineDefault={"assets/img/targhetta.jpg"}
                                                            obbligatoria={isRuoloNoAd()}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_BAGAGLIAIO}
                                                            veicolo={veicolo}
                                                            label={"Foto vista bagagliaio"}
                                                            immagineDefault={"assets/img/bagagliaio.png"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_PONTE}
                                                            veicolo={veicolo}
                                                            label={"Foto della vettura su ponte"}
                                                            immagineDefault={"assets/img/ponte.png"}
                                                            obbligatoria={false}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={true}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_V_ALTRO}
                                                            veicolo={veicolo}
                                                            label={"Altro"}
                                                            obbligatoria={false}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                </Fragment>
                                        }
                                    </div>
                                    {
                                        tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE || !isRuoloNoAd() ?
                                            <Fragment>

                                                <h4 className={"separatoreform"}>Foto ad alta definizione dei colori e
                                                    materiali</h4>
                                                <div className="row g-3">
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_CORPO}
                                                            veicolo={veicolo}
                                                            label={"Foto colore corpo vettura"}
                                                            immagineDefault={"assets/img/colore_carrozzeria.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_PARAFANGHI}
                                                            veicolo={veicolo}
                                                            label={"Foto colore parafanghi"}
                                                            immagineDefault={"assets/img/parafanghi.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_FILETTATURA}
                                                            veicolo={veicolo}
                                                            label={"Foto colore filettatura"}
                                                            immagineDefault={"assets/img/filettatura.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_RUOTE}
                                                            veicolo={veicolo}
                                                            label={"Foto colore ruote"}
                                                            immagineDefault={"assets/img/colore_ruota.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_SEDILI_ANTERIORI}
                                                            veicolo={veicolo}
                                                            label={"Foto materiale sedili anteriori"}
                                                            immagineDefault={"assets/img/materiale_sedili_ant.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_SEDILI_POSTERIORI}
                                                            veicolo={veicolo}
                                                            label={"Foto materiale sedili posteriori"}
                                                            immagineDefault={"assets/img/materiale_sedili_post.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_PANNELLI_ANTERIORI}
                                                            veicolo={veicolo}
                                                            label={"Foto materiale pannelli anteriori"}
                                                            immagineDefault={"assets/img/pannello_anteriore.jpg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                    <CertDivContainer
                                                        addFieldContainer={false}
                                                        colSize={6}
                                                        showAllCampi={showAllCampi}
                                                        show={tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                    >
                                                        <UplFotoVeicolo
                                                            tipo={TIPO_FOTO_C_PANNELLI_POSTERIORI}
                                                            veicolo={veicolo}
                                                            label={"Foto materiale pannelli posteriori"}
                                                            immagineDefault={"assets/img/pannello_posteriore.jpeg"}
                                                            obbligatoria={isRuoloNoAd() && tipoRichiesta == TIPO_REQ_VEICOLO_OMOLOGAZIONE}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            formikRef={formikRef}
                                                        />
                                                    </CertDivContainer>
                                                </div>
                                            </Fragment>
                                            : ''
                                    }
                                </Fragment>
                                : ''
                        }
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
}

export default FormModificaVeicolo;