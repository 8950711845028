import React, {Fragment, useContext, useEffect, useState} from "react";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import {
    getRigaInfoSocio, getTimestampFormattato,
    STATO_APPROVATO,
    STATO_CANCELLATO, STATO_CARTA_ID_UPL, STATO_IN_RICHIESTA, STATO_IN_RICHIESTA_VALIDO
} from "../../utility/common";
import ModalConfirm from "../../components/ModalConfirm";
import ModalNoteConfirm from "./ModalNoteConfirm";
import ModalCartaIdentita from "./ModalCartaIdentita";
import {Link} from "react-router-dom";
import ModalSociSimili from "./ModalSociSimili";

function DettaglioSociDaApprovare({socio, updFun}) {

    const [stato, setStato] = useState();
    const [msgConf, setMsgConf] = useState('');
    const [show, setShow] = useState(false);
    const [showMdNote, setShowMdNote] = useState(false);
    const [showMdCartaId, setShowMdCartaId] = useState(false);
    const [showMdSociSimili, setShowMdSociSimili] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    useEffect(() => {
    }, [socio]);

    const aggiornaStato = (nota = '') => {
        aggiornaStatoNota('')
    }

    const aggiornaStatoNota = (nota = '') => {
        setShow(false);
        setShowMdNote(false);

        startChiamata()
        axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + socio.id_utente + "/stato/", {
            stato: stato,
            note: nota
        }).then((response) => {
            updFun();
            endChiamata();
        });
    }

    const apriConfirmCambioStato = (row, stato, msg) => {
        setStato(stato)
        setShow(true)
        setMsgConf(msg)
    }

    return (
        <Fragment>
            <ModalConfirm
                show={show}
                handleClose={() => setShow(false)}
                handleOk={aggiornaStato}
                testo={msgConf}/>
            <ModalNoteConfirm
                show={showMdNote}
                handleClose={() => setShowMdNote(false)}
                handleOk={(nota) => {
                    aggiornaStatoNota(nota)
                }}
                titolo={"Inserisci Nota"}
            />
            <ModalCartaIdentita
                show={showMdCartaId}
                handleClose={() => setShowMdCartaId(false)}
                user={socio}
            />
            <ModalSociSimili
                show={showMdSociSimili}
                handleClose={(unitoSocio) => {
                    setShowMdSociSimili(false)
                }}
                user={socio}
            />
            <div className={"row mb-2 text-center"}>
                <div className={"col-md-12"}>
                    <div className="btn-group" role="group">
                        <button type={"button"}
                                className="btn btn-dark rfi-btn-table"
                                data-toggle="tooltip" title="Documenti d'identità"
                                onClick={() => {
                                    setShowMdCartaId(true)
                                }}
                                disabled={socio.stato == STATO_IN_RICHIESTA || socio.stato == STATO_IN_RICHIESTA_VALIDO}
                        >
                            <i className="fas fa-id-card"/> Documenti d'Identità
                        </button>
                        <button type={"button"}
                                onClick={() => {
                                    setShowMdSociSimili(true)
                                }}
                                className="btn btn-dark rfi-btn-table"
                                data-toggle="tooltip" title="Soci con dati simili"
                                disabled={!socio.idSimili || socio.idSimili === ''}
                        >
                            <i className="fas fa-users"/> Soci con dati simili
                        </button>
                        <Link to={"/upd-socio/" + socio.id_utente}
                              className="btn btn-dark rfi-btn-table"
                              data-toggle="tooltip" title="Modifica">
                            <i className="fas fa-pen"/> Modifica
                        </Link>
                        <Link to={"/info-socio/" + socio.id_utente}
                              className="btn btn-dark rfi-btn-table"
                              data-toggle="tooltip" title="Informazioni">
                            <i className="fas fa-eye"/> Informazioni
                        </Link>
                        <button type={"button"}
                                className="btn btn-warning rfi-btn-table"
                                data-toggle="tooltip" title="Nota"
                                onClick={() => {
                                    setStato(socio.stato)
                                    setShowMdNote(true)
                                }}
                        >
                            <i className="fas fa-sticky-note"/> Invia una nota
                        </button>
                    </div>
                </div>
            </div>
            {
                getRigaInfoSocio(socio, true, true)
            }
            {
                socio && socio.note_segreteria && socio.note_segreteria.length > 0 &&
                <Fragment>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <hr/>
                        <h6>Note della segreteria</h6>
                    </div>
                    <div className={"col-md-12"}>
                        <ul>
                            {
                                socio.note_segreteria.map((n, idx) => <li key={idx}>[{getTimestampFormattato(n.data_insert)}]&nbsp;-&nbsp;<strong>{n.note}</strong></li>)
                            }
                        </ul>
                    </div>
                </div>
                </Fragment>
            }
            <div className={"row mt-2 text-center"}>
                <div className={"col-md-12"}>
                    <div className={"btn-group"} role="group">
                        <button type={"button"}
                                className="btn btn-success rfi-btn-table"
                                data-toggle="tooltip" title="Approva"
                                onClick={() => {
                                    apriConfirmCambioStato(socio, STATO_APPROVATO, 'Confermi l\'approvazione della richiesta per ' + socio.cognome + ' ' + socio.nome + '?')
                                }}
                        >
                            <i className="fas fa-check"/> Approva iscrizione
                        </button>
                        <button type={"button"}
                                className="btn btn-danger rfi-btn-table"
                                data-toggle="tooltip" title="Rifiuta"
                                onClick={() => {
                                    apriConfirmCambioStato(socio, STATO_CANCELLATO, 'Confermi il rifiuto della richiesta per ' + socio.cognome + ' ' + socio.nome + '?')
                                }}
                        >
                            <i className="fas fa-times"/> Rifiuta iscrizione
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DettaglioSociDaApprovare;
