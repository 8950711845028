import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {LoaderContext} from "./LoaderContext";
import {AuthContext} from "./AuthContext";

export const CertificatiContext = React.createContext(0);

export const CertificatiProvider = ({value, children}) => {

    const [numCertificati, setNumCertificati] = useState(0)

    const {user} = useContext(AuthContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);


    function getNumeroCertificati() {
        let f = '?pag=-1&certDaGestire=1';

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + f).then((response) => {
            if (response.data.data) {
                setNumCertificati(response.data.data.length);
            }
            endChiamata()
        });
    }

    function aggiornaContatore() {
        getNumeroCertificati();
    }

    useEffect(() => {
        if (user) {
            getNumeroCertificati();
        }

        // Ogni 5 minuti aggiorno
        const timer = setTimeout(() => {
            if (user) {
                getNumeroCertificati();
            }
        }, 18000000);

        /*
        Questa funzione di pulizia verrà eseguita quando il componente si smonta o prima che il prossimo effetto
        venga eseguito
         */
        return () => clearTimeout(timer);
    }, [user]);

    return (
        <CertificatiContext.Provider value={{numCertificati, aggiornaContatore}}>{children}</CertificatiContext.Provider>
    );
};
