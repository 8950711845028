import {Modal} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import 'react-medium-image-zoom/dist/styles.css'
import Translate from "../../components/Translate";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import NsModalHeader from "../../components/modal/NsModalHeader";

function ModalAssegnaCommissario({show, handleClose, certificato, postUpdate}) {

    const [commissario, setCommissario] = useState()
    const [commissari, setCommissari] = useState([])

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getCommissari() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/commissario/").then((response) => {
            setCommissari(response.data.data);
            endChiamata();
        });
    }

    useEffect(() => {
        getCommissari()
    }, [certificato]);

    const aggiornaCommissario = () => {
        if (certificato && commissario && commissario !== '') {
            certificato.id_commissario = commissario;

            startChiamata()
            axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + certificato.id_certificato + "/commissario/", certificato).then((response) => {
                endChiamata();
                postUpdate();
                handleClose();
            });
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Assegna a commissario"}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <label htmlFor="id_commissario" className="form-label">Commissario*:</label>
                        <select
                            name="id_commissario"
                            className={'form-control form-select'}
                            value={commissario}
                            onChange={(ev) => {
                                setCommissario(ev.target.value);
                            }}>
                            <option value={""}></option>
                            {
                                commissari && commissari.map(comm =>
                                    <option
                                        key={comm.id_utente}
                                        value={comm.id_utente}>{comm.cognome + ' ' + comm.nome}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type={"button"} className="btn btn-dark" onClick={handleClose}>
                    <Translate>Chiudi</Translate>
                </button>
                <button
                    type={"button"}
                    className="btn btn-principal"
                    onClick={() => {
                        aggiornaCommissario();
                    }}
                    disabled={certificato && (!commissario || commissario === '')}
                >
                    <Translate>Conferma</Translate>
                </button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalAssegnaCommissario;