import Accesso from "./Accesso";
import {Link, useHistory, withRouter} from 'react-router-dom';
import React, {Fragment, useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import ModalMsg from "../../components/ModalMsg";

function Login(props) {

    var hash = props?.location?.state?.hash || ''
    var messaggio = props?.location?.state?.messaggio || ''
    var titolo = props?.location?.state?.titolo || ''

    const [modalMsg, setModalMsg] = useState(messaggio);
    const history = useHistory();

    const authContext = useContext(AuthContext);
    const isLoggedIn = authContext.isLogged();

    if (isLoggedIn) {
        history.push("/home");
    }

    return (
        <Fragment>
            <ModalMsg
                show={modalMsg && modalMsg !== ''}
                handleClose={() => {
                    setModalMsg('')
                }}
                titolo={titolo}
                testo={modalMsg}/>
            <div className={"text-center mt-4 mb-4 rfi-titolo"}>
                <h1>Area Soci</h1>
            </div>
            <div className="rfi-login">
                <Accesso hash={hash}/>
            </div>
        </Fragment>
    );
}

export default withRouter(Login);
