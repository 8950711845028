import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../../components/ModalConfirm";
import NsDataTable from "../../../components/tabelle/NsDataTable";

export default function ListAgenzie() {

    const [loadingData, setLoadingData] = useState(true);
    const [agenzie, setAgenzie] = useState([]);
    const [agenziaSel, setAgenziaSel] = useState(null);
    const [show, setShow] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getAgenzie() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/agenzie/").then((response) => {
            setAgenzie(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        if (loadingData === true) {
            getAgenzie()
        }
    }, []);

    return (
        <CardContainer>
            <Card
                icon={'fas fa-users'}
                title={'Agenzie affiliate'}
                getBottoniDx={() =>
                    <Link to="/upd-agenzia" className={"btn btn-card"}>
                        <i className="fas fa-plus-circle"/> Aggiungi
                    </Link>
                }
            >
                <ModalConfirm
                    show={show}
                    handleClose={() => setShow(false)}
                    handleOk={() => {
                        setShow(false);
                        startChiamata()
                        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/agenzie/" + agenziaSel.id_agenzia).then((response) => {
                            setAgenzie(response.data.data);
                            endChiamata()
                        });
                    }}
                    testo={"Sicuro di voler eliminare l'agenzia: " + (agenziaSel && (agenziaSel.id_agenzia)) + "?"}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={agenzie}
                            columns={[
                                {
                                    name: 'Nome Agenzia',
                                    selector: row => row.descrizione,
                                    sortable: true,
                                },
                                {
                                    name: 'RFI / Abarth',
                                    selector: row => row.abarth == '1' ?
                                        <span className={"rfi-etichetta rfi-etichetta--arancione"}>Abarth</span>
                                        : <span className={"rfi-etichetta rfi-etichetta--blu"}>RFI</span>,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <div className="btn-group" role="group"
                                             aria-label="Button group with nested dropdown">
                                            <div className="btn-group" role="group">
                                                <button id="btnGroupDrop1" type="button"
                                                        className="btn btn-dark dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                    <li>
                                                        <Link to={"/upd-agenzia/" + row.id_agenzia}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Modifica">
                                                            <i className="fas fa-pen"/> Modifica
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina" onClick={() => {
                                                                setAgenziaSel(row);
                                                                setShow(true);
                                                        }}>
                                                            <i className="fas fa-trash"/> Elimina
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            addPagination={true}
                            funzioneFiltro={(item, filtro) => {
                                return (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
                                    || (item.abarth == '1' && 'abarth'.includes(filtro.toLowerCase()))
                                    || (item.abarth != '1' && 'rfi'.includes(filtro.toLowerCase()))
                            }}
                        >
                        </NsDataTable>
                    )}
            </Card>
        </CardContainer>
    );
}