import React, {Fragment} from "react";
import ListPersone from "../soci/ListPersone";

export default function HomeClub() {

    return (
        <Fragment>
            <ListPersone tipoStato={'1'} />
        </Fragment>
    )
}