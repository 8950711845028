import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import 'react-medium-image-zoom/dist/styles.css'
import Translate from "../../components/Translate";
import NsModalHeader from "../../components/modal/NsModalHeader";

export default function ModalNotaRifiuto({show, handleClose, handleOk}) {

    const [note, setNote] = useState('')


    useEffect(() => {
    }, []);

    return (
        <Modal
            show={show}
            onHide={() => {
                setNote('');
                handleClose();
            }}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Rifiuta certificato"}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <label htmlFor="note" className="form-label">Note rifiuto:</label>
                        <textarea
                            name={"note"}
                            className={'form-control'}
                            defaultValue={note}
                            onChange={e => {
                                setNote(e.target.value);
                            }}
                        ></textarea>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type={"button"} className="btn btn-dark" onClick={handleClose}>
                    <Translate>Chiudi</Translate>
                </button>
                <button
                    type={"button"}
                    className="btn btn-principal"
                    onClick={() => {
                        handleOk(note);
                        setNote('');
                    }}
                >
                    <Translate>Conferma</Translate>
                </button>
            </Modal.Footer>
        </Modal>
    )

}