import React, {Fragment, useContext, useState} from "react";
import Menu from "./components/Menu";
import ModalMsg from "./components/ModalMsg";
import {LoaderContext} from "./context/LoaderContext";
import Spinner from "./components/Spinner";
import {Route, Switch, useHistory} from "react-router-dom";
import ResetPassword from "./pages/login/recupera_pwd/ResetPassword";
import AttivaAccount from "./pages/login/AttivaAccount";
import RecuperaPwd from "./pages/login/recupera_pwd/RecuperaPwd";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Footer from "./components/Footer";
import axios from "axios";
import {TranslateContext} from "./context/TranslateContext";
import {AuthContext} from "./context/AuthContext";
import Registrati from "./pages/registrati/Registrati";
import ConfImpostazioni from "./pages/configura/impostazioni/ConfImpostazioni";
import ModificaImpostazione from "./pages/configura/impostazioni/ModificaImpostazione";
import ConfUtenti from "./pages/configura/utenti/ConfUtenti";
import ListPersone from "./pages/soci/ListPersone";
import ListNuoviSoci from "./pages/soci/ListNuoviSoci";
import ModificaSocio from "./pages/soci/ModificaSocio";
import ModificaUtente from "./pages/configura/utenti/ModificaUtente";
import ListVeicoli from "./pages/veicoli/ListVeicoli";
import InfoSocio from "./pages/soci/InfoSocio";
import ModificaQuota from "./pages/quote/ModificaQuota";
import ListQuote from "./pages/quote/ListQuote";
import ModificaVeicolo from "./pages/veicoli/ModificaVeicolo";
import PagamentoQuota from "./pages/pagamenti/PagamentoQuota";
import GoToSella from "./pages/pagamenti/GoToSella";
import PagamentoOk from "./pages/pagamenti/PagamentoOk";
import PagamentoKo from "./pages/pagamenti/PagamentoKo";
import ListTransazioni from "./pages/pagamenti/ListTransazioni";
import ModificaProfiloSocio from "./pages/soci/ModificaProfiloSocio";
import ListCertificati from "./pages/certificati/ListCertificati";
import ModificaCertificato from "./pages/certificati/ModificaCertificato";
import DichiarazioneRespCrs from "./pages/certificati/DichiarazioneRespCrs";
import DocAggiuntiviCert from "./pages/certificati/DocAggiuntiviCert";
import InfoCertificato from "./pages/certificati/InfoCertificato";
import ListTipiCarrozzeria from "./pages/configura/tipi_carrozzeria/ListTipiCarrozzeria";
import ModificaTipiCarrozzeria from "./pages/configura/tipi_carrozzeria/ModificaTipiCarrozzeria";
import ListColori from "./pages/configura/colori/ListColori";
import ModificaColore from "./pages/configura/colori/ModificaColore";
import ListTitoli from "./pages/configura/titoli/ListTitoli";
import ModificaTitolo from "./pages/configura/titoli/ModificaTitolo";
import ListModalitaPag from "./pages/configura/modalita_pagamento/ListModalitaPag";
import ModificaModalitaPag from "./pages/configura/modalita_pagamento/ModificaModalitaPag";
import ListClub from "./pages/configura/club/ListClub";
import ModificaClub from "./pages/configura/club/ModificaClub";
import ListRegistriVeicoli from "./pages/configura/registri_veicoli/ListRegistriVeicoli";
import ModificaRegistroVeicoli from "./pages/configura/registri_veicoli/ModificaRegistroVeicoli";
import ListTipiSoci from "./pages/configura/tipi_soci/ListTipiSoci";
import ModificaTipoSocio from "./pages/configura/tipi_soci/ModificaTipoSocio";
import ModificaRelazione from "./pages/certificati/ModificaRelazione";
import ListConti from "./pages/configura/conti/ListConti";
import ModificaConto from "./pages/configura/conti/ModificaConto";
import ListTipiMov from "./pages/configura/tipi_movimento/ListTipiMov";
import ModificaTipoMov from "./pages/configura/tipi_movimento/ModificaTipoMov";
import ListCatCausali from "./pages/configura/categorie_causali/ListCatCausali";
import ModificaCatCausale from "./pages/configura/categorie_causali/ModificaCatCausale";
import ListCausaliMov from "./pages/configura/causali_movimenti/ListCausaliMov";
import ModificaCausaleMov from "./pages/configura/causali_movimenti/ModificaCausaleMov";
import ListMovimenti from "./pages/configura/movimenti/ListMovimenti";
import ModificaMovimenti from "./pages/configura/movimenti/ModificaMovimenti";
import StampaMovimenti from "./pages/configura/movimenti/StampaMovimenti";
import PagamentoQuotaCertificato from "./pages/certificati/PagamentoQuotaCertificato";
import ListNote from "./pages/note/ListNote";
import ModificaNota from "./pages/note/ModificaNota";
import CruscottoVeicoliRfi from "./pages/veicoli/CruscottoVeicoliRfi";
import CruscottoVeicoliAbarth from "./pages/veicoli/CruscottoVeicoliAbarth";
import StampeFineGiornata from "./pages/soci/StampeFineGiornata";
import RichiestaPagamentoOk from "./pages/pagamenti/RichiestaPagamentoOk";
import ListQuoteTemp from "./pages/quote/ListQuoteTemp";
import InfoVeicolo from "./pages/veicoli/InfoVeicolo";
import ListQuoteAsi from "./pages/quote/ListQuoteAsi";
import ModificaQuotaVeicolo from "./pages/quote/ModificaQuotaVeicolo";
import ModificaQuotaCertificato from "./pages/certificati/ModificaQuotaCertificato";
import InfoSocioClub from "./pages/soci/InfoSocioClub";
import ListAgenzie from "./pages/configura/agenzie/ListAgenzie";
import ModificaAgenzia from "./pages/configura/agenzie/ModificaAgenzia";

function ApplicationBody() {

    const [show, setShow] = useState(false);

    const history = useHistory()

    const {getLingua} = useContext(TranslateContext);
    const {resetSpinner, loading} = useContext(LoaderContext);
    const {handleLogout} = useContext(AuthContext);

    // For GET requests
    axios.interceptors.request.use(
        (req) => {
            req.headers['Content-Type'] = 'application/json';

            const token = localStorage.getItem('rfi-login');
            if (token && token !== '' && (!req.headers['jwt'] || req.headers['jwt'] === '')) {
                req.headers['jwt'] = token;
            }

            let lang = getLingua();
            if (lang && lang !== '') {
                req.headers['lang'] = lang;
            }
            return req;
        },
        (err) => {
            setShow(true)
            resetSpinner()
            return Promise.reject(err);
        }
    );

    axios.interceptors.response.use(
        (res) => {
            // Token scaduto o non valido
            if (res.data && res.data.result && (res.data.result === -100 || res.data.result === -101)) {
                console.log('Token non più valido')

                // Logout utente
                resetSpinner();
                handleLogout();
                history.push("/login");
            }

            if (res.status !== 201) {
                // Esempio
            }
            return res;
        },
        (err) => {
            setShow(true)
            resetSpinner()
            return Promise.reject(err)
        }
    );

    const closeModal = () => setShow(false);

    return (
        <Fragment>
            <Menu/>
            <div className={'container-fluid'}>
                <ModalMsg
                    show={show}
                    handleClose={closeModal}
                    testo={"Si è verificato un errore. Riprovare più tardi."}/>

                {/* Gestione spinner di caricamento */}
                <Spinner loading={loading()}/>

                <div className="container-fluid rfi-container">
                    <Switch>
                        <Route path="/reset-password/:token" render={() => <ResetPassword/>}/>
                        <Route path="/attiva-account/:token" render={() => <AttivaAccount/>}/>
                        <Route path="/recuperaPwd" render={() => <RecuperaPwd/>}/>
                        <Route path="/registrati/:tipo" render={() => <Registrati/>}/>
                        <PrivateRoute path="/home" component={Home}/>
                        <PrivateRoute path="/conf-parametri" component={ConfImpostazioni}/>
                        <PrivateRoute path="/upd-parametro/:id?" component={ModificaImpostazione}/>
                        <PrivateRoute path="/conf-utenti" component={ConfUtenti}/>
                        <PrivateRoute path="/upd-utente/:id?" component={ModificaUtente}/>
                        <PrivateRoute path="/list-persone" component={ListPersone}/>
                        <PrivateRoute path="/list-nuovi-soci" component={ListNuoviSoci}/>
                        <PrivateRoute path="/stampe-fine-giornata" component={StampeFineGiornata}/>
                        <PrivateRoute path="/upd-socio/:id?" component={ModificaSocio}/>
                        <PrivateRoute path="/upd-profilo/:id" component={ModificaProfiloSocio}/>
                        <PrivateRoute path="/info-socio/:id?" component={InfoSocio}/>
                        <PrivateRoute path="/info-socio-club/:id?" component={InfoSocioClub}/>
                        <PrivateRoute path="/list-veicoli" component={ListVeicoli}/>
                        <PrivateRoute path="/info-veicolo/:idVeicolo?" component={InfoVeicolo}/>
                        <PrivateRoute path="/list-quote" component={ListQuote}/>
                        <PrivateRoute path="/list-quote-temp" component={ListQuoteTemp}/>
                        <PrivateRoute path="/list-quote-asi" component={ListQuoteAsi}/>
                        <PrivateRoute path="/upd-quota/:idUsr?/:idQuota?" component={ModificaQuota}/>
                        <PrivateRoute path="/upd-quota-veicolo/:idCertificato?/:idQuota?" component={ModificaQuotaVeicolo}/>
                        <PrivateRoute path="/upd-veicolo/:idUsr?/:idVeicolo?" component={ModificaVeicolo}/>
                        <PrivateRoute path="/pagamento-quota/:idUsr?" component={PagamentoQuota}/>
                        <PrivateRoute path="/go-to-sella" component={GoToSella}/>
                        <PrivateRoute path="/pagamento-ok" component={PagamentoOk}/>
                        <PrivateRoute path="/pagamento-ko" component={PagamentoKo}/>
                        <PrivateRoute path="/richiesta-pagamento-ok" component={RichiestaPagamentoOk}/>
                        <PrivateRoute path="/list-transazioni" component={ListTransazioni}/>
                        <PrivateRoute path="/pagamenti/:idUsr?" component={PagamentoQuota}/>
                        <PrivateRoute path="/list-certificati" component={ListCertificati}/>
                        <PrivateRoute path="/upd-certificato/:idUsr?/:idCertificato?" component={ModificaCertificato}/>
                        <PrivateRoute path="/dich-resp-crs/:idUsr?/:idCertificato?" component={DichiarazioneRespCrs}/>
                        <PrivateRoute path="/doc-aggiuntivi-cert/:idUsr?/:idCertificato?" component={DocAggiuntiviCert}/>
                        <PrivateRoute path="/quota-cert/:idCertificato?" component={PagamentoQuotaCertificato}/>
                        <PrivateRoute path="/info-certificato/:idCertificato?" component={InfoCertificato}/>
                        <PrivateRoute path="/list-tipi-carr" component={ListTipiCarrozzeria}/>
                        <PrivateRoute path="/upd-tipi-carr/:id?" component={ModificaTipiCarrozzeria}/>
                        <PrivateRoute path="/list-colori" component={ListColori}/>
                        <PrivateRoute path="/upd-colore/:id?" component={ModificaColore}/>
                        <PrivateRoute path="/list-titoli" component={ListTitoli}/>
                        <PrivateRoute path="/upd-titolo/:id?" component={ModificaTitolo}/>
                        <PrivateRoute path="/list-modalita-pag" component={ListModalitaPag}/>
                        <PrivateRoute path="/upd-modalita-pag/:id?" component={ModificaModalitaPag}/>
                        <PrivateRoute path="/list-club" component={ListClub}/>
                        <PrivateRoute path="/upd-club/:id?" component={ModificaClub}/>
                        <PrivateRoute path="/list-agenzie" component={ListAgenzie}/>
                        <PrivateRoute path="/upd-agenzia/:id?" component={ModificaAgenzia}/>
                        <PrivateRoute path="/list-registri-veic" component={ListRegistriVeicoli}/>
                        <PrivateRoute path="/upd-registro-veic/:id?" component={ModificaRegistroVeicoli}/>
                        <PrivateRoute path="/list-tipi-socio" component={ListTipiSoci}/>
                        <PrivateRoute path="/upd-tipo-socio/:id?" component={ModificaTipoSocio}/>
                        <PrivateRoute path="/upd-tipo-socio/:id?" component={ModificaTipoSocio}/>
                        <PrivateRoute path="/upd-relazione/:idCertificato?/:idRelazione?" component={ModificaRelazione}/>
                        <PrivateRoute path="/list-conti" component={ListConti}/>
                        <PrivateRoute path="/upd-conto/:id?" component={ModificaConto}/>
                        <PrivateRoute path="/list-tipi-mov" component={ListTipiMov}/>
                        <PrivateRoute path="/upd-tipo-mov/:id?" component={ModificaTipoMov}/>
                        <PrivateRoute path="/list-cat-causali" component={ListCatCausali}/>
                        <PrivateRoute path="/upd-cat-causale/:id?" component={ModificaCatCausale}/>
                        <PrivateRoute path="/list-causali-mov" component={ListCausaliMov}/>
                        <PrivateRoute path="/upd-causale-mov/:id?" component={ModificaCausaleMov}/>
                        <PrivateRoute path="/list-movimenti" component={ListMovimenti}/>
                        <PrivateRoute path="/upd-movimento/:id?" component={ModificaMovimenti}/>
                        <PrivateRoute path="/stampa-movimenti" component={StampaMovimenti}/>
                        <PrivateRoute path="/cruscotto-rfi" component={CruscottoVeicoliRfi}/>
                        <PrivateRoute path="/cruscotto-abarth" component={CruscottoVeicoliAbarth}/>
                        <PrivateRoute path="/upd-quota-cert/:idCertificato?/:idQuota?" component={ModificaQuotaCertificato}/>

                        <PrivateRoute path="/list-note" component={ListNote}/>
                        <PrivateRoute path="/upd-nota/:id?" component={ModificaNota}/>

                        {/* Default: Login */}
                        <Route render={() => <Login/>}/>
                    </Switch>
                </div>
            </div>
            <Footer/>
        </Fragment>
    )
}

export default ApplicationBody;