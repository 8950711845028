import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import CalcolaCodiceFiscale from "../../components/codice_fiscale/CalcolaCodiceFiscale";
import {TranslateContext} from "../../context/TranslateContext";
import Translate from "../../components/Translate";
import NsDatePicker from "../../components/NsDatePicker";
import {checkDocumentiScaduti, convertiDateInDataPerDb, isDocumentoScaduto} from "../../utility/common";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import UplFotoSocio from "./UplFotoSocio";
import {RuoliContext} from "../../context/RuoliContext";
import FieldContainer from "../../components/FieldContainer";
import AutocompleteText from "../../components/AutocompleteText";
import NsField from "../../components/NsField";

function ModificaSocio() {
    const {id} = useParams()

    const [user, setUser] = useState();
    const [titoli1, setTitoli1] = useState([]);
    const [titoli2, setTitoli2] = useState([]);
    const [province, setProvince] = useState([]);
    const [comuniNascita, setComuniNascita] = useState([]);
    const [comuniResidenza, setComuniResidenza] = useState([]);
    const [comuniSped, setComuniSped] = useState([]);
    const [nazioni, setNazioni] = useState([]);
    const [tipiRegistro, setTipiRegistro] = useState([]);
    const [tipiSoci, setTipiSoci] = useState([]);
    const [tipiSociAbarth, setTipiSociAbarth] = useState([]);
    const [agenzie, setAgenzie] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [clubsAbarth, setClubsAbarth] = useState([]);
    const [tipiDocumento, setTipiDocumento] = useState([]);
    const [nazione, setNazione] = useState('I');
    const [nazioneNascita, setNazioneNascita] = useState('I');
    const [nazioneSped, setNazioneSped] = useState('I');
    const [spedUgualeResidenza, setSpedUgualeResidenza] = useState(true);

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);
    const {isRuoloAdmin, isRuoloSegreteria, isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    const history = useHistory();
    const formikRef = useRef();

    const token = localStorage.getItem('rfi-login');

    function getUtente() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);
                setUser(val)
                setNazione(val.nazione)

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_utente", val.id_utente || '')
                    formikRef.current.setFieldValue("id_ruolo", val.id_ruolo || '')
                    formikRef.current.setFieldValue("stato", val.stato || '')
                    formikRef.current.setFieldValue("nome", val.nome || '')
                    formikRef.current.setFieldValue("cognome", val.cognome || '')
                    formikRef.current.setFieldValue("email", val.email || '')
                    formikRef.current.setFieldValue("tit1", val.tit1 || '')
                    formikRef.current.setFieldValue("tit2", val.tit2 || '')
                    formikRef.current.setFieldValue("genere", val.genere || '')
                    formikRef.current.setFieldValue("data_nascita", val.data_nascita || '')
                    formikRef.current.setFieldValue("luogo_nascita", val.luogo_nascita || '')
                    formikRef.current.setFieldValue("codice_fiscale", val.codice_fiscale || '')
                    formikRef.current.setFieldValue("indirizzo", val.indirizzo || '')
                    formikRef.current.setFieldValue("cap", val.cap || '')
                    formikRef.current.setFieldValue("cap_sped", val.cap_sped || '')
                    formikRef.current.setFieldValue("nominativo_sped", val.nominativo_sped || '')
                    formikRef.current.setFieldValue("numero_civico", val.numero_civico || '')
                    formikRef.current.setFieldValue("nazione", val.nazione || '')
                    formikRef.current.setFieldValue("nazione_nascita", val.nazione_nascita || '')
                    formikRef.current.setFieldValue("localita", val.localita || '')
                    formikRef.current.setFieldValue("provincia", val.provincia || '')
                    formikRef.current.setFieldValue("provincia_nascita", val.provincia_nascita || '')
                    formikRef.current.setFieldValue("tel_casa", val.tel_casa || '')
                    formikRef.current.setFieldValue("tel_lavoro", val.tel_lavoro || '')
                    formikRef.current.setFieldValue("fax", val.fax || '')
                    formikRef.current.setFieldValue("note", val.note || '')
                    formikRef.current.setFieldValue("comunicazioni_email", val.comunicazioni_email || '')
                    formikRef.current.setFieldValue("nazione_sped", val.nazione_sped || '')
                    formikRef.current.setFieldValue("provincia_sped", val.provincia_sped || '')
                    formikRef.current.setFieldValue("localita_sped", val.localita_sped || '')
                    formikRef.current.setFieldValue("indirizzo_sped", val.indirizzo_sped || '')
                    formikRef.current.setFieldValue("numero_civico_sped", val.numero_civico_sped || '')
                    formikRef.current.setFieldValue("adesione", val.adesione || '')
                    formikRef.current.setFieldValue("id_tipo_socio", val.id_tipo_socio || '')
                    formikRef.current.setFieldValue("id_tipo_socio_abarth", val.id_tipo_socio_abarth || '')
                    formikRef.current.setFieldValue("numero_tessera_rfi", val.numero_tessera_rfi || '')
                    formikRef.current.setFieldValue("numero_tessera_abarth", val.numero_tessera_abarth || '')
                    formikRef.current.setFieldValue("id_agenzia", val.id_agenzia || '')
                    formikRef.current.setFieldValue("id_club", val.id_club || '')
                    formikRef.current.setFieldValue("id_club_abarth", val.id_club_abarth || '')
                    formikRef.current.setFieldValue("numero_tessera_asi", val.numero_tessera_asi || '')
                    formikRef.current.setFieldValue("azienda", val.azienda || '')
                    formikRef.current.setFieldValue("delegazione", val.delegazione || '')
                    formikRef.current.setFieldValue("anno_iscrizione", val.anno_iscrizione || '')
                    formikRef.current.setFieldValue("da_ratificare", val.da_ratificare || '')
                    formikRef.current.setFieldValue("newsletter", val.newsletter || '')
                    formikRef.current.setFieldValue("newsletter_abarth", val.newsletter_abarth || '')
                    formikRef.current.setFieldValue("sped_eq_res", val.sped_eq_res || '0')

                    setNazioneNascita(val.nazione_nascita);
                    setSpedUgualeResidenza(val.sped_eq_res == '1');

                    if (val.provincia_nascita && val.provincia_nascita !== '') {
                        searchComuniNascita(val.provincia_nascita, '', true);
                    }
                    if (val.provincia && val.provincia !== '', '', true) {
                        searchComuniResidenza(val.provincia);
                    }
                    if (val.provincia_sped && val.provincia_sped !== '') {
                        searchComuniSpedizione(val.provincia_sped, '', true);
                    }
                }
                endChiamata()
            });
    }

    function getTitoli(tipo) {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/titolo/?tipo=" + tipo)
            .then((response) => {
                let val = response.data.data;

                if (tipo === 1) {
                    setTitoli1(val)
                } else if (tipo === 2) {
                    setTitoli2(val)
                }
                endChiamata()
            });
    }

    const getProvince = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/provincia/").then((response) => {
            setProvince(response.data.data);

            if (user) {
                formikRef.current.setFieldValue("provincia", user.provincia)
                formikRef.current.setFieldValue("provincia_nascita", user.provincia_nascita)
            }
            endChiamata()
        });
    }

    const searchComuniNascita = (provincia, comune, initVal) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
            setComuniNascita(response.data.data)
            if (user && initVal) {
                formikRef.current.setFieldValue("luogo_nascita", user.luogo_nascita);
            }
        });
    }

    const searchComuniResidenza = (provincia, comune, initVal) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
            setComuniResidenza(response.data.data)
            if (user && initVal) {
                formikRef.current.setFieldValue("localita", user.localita);
            }
        });
    }

    const searchComuniSpedizione = (provincia, comune, initVal) => {
        let f = comune && comune !== '' ? '&nome=' + comune : '';

        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/indirizzo/comune/?provincia=' + provincia + f).then((response) => {
            setComuniSped(response.data.data);
            if (user && initVal) {
                formikRef.current.setFieldValue("localita_sped", user.localita_sped);
            }
        });
    }

    function getCap(comune, arrayComuni) {
        let cap = '';

        if (comune !== '') {
            let comuni = arrayComuni.filter(c => c.nome === comune);
            if (comuni && comuni.length > 0) {
                cap = comuni.map(c => c.cap).reduce(c => c) || '';
            }
        }
        return cap;
    }

    const getNazioni = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/nazione/").then((response) => {
            setNazioni(response.data.data);
            endChiamata()
        });
    }

    function getTipiRegistro() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-registro/").then((response) => {
            setTipiRegistro(response.data);
            endChiamata()
        });
    }

    function getTipiSoci() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/?tipo=3").then((response) => {
            setTipiSoci(response.data.data)
            endChiamata()
        });
    }

    function getTipiSociAbarth() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/?tipo=5").then((response) => {
            setTipiSociAbarth(response.data.data)
            endChiamata()
        });
    }

    function getAgenzie() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/agenzie/").then((response) => {
            setAgenzie(response.data.data)
            endChiamata()
        });
    }

    function getClubs() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/club/?tipo=3").then((response) => {
            setClubs(response.data.data)
            endChiamata()
        });
    }

    function getClubsAbarth() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/club/?tipo=5").then((response) => {
            setClubsAbarth(response.data.data)
            endChiamata()
        });
    }

    const getTipiDocumentoUtente = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata()
        });
    }

    useEffect(() => {

        if (loadingData) {
            getNazioni();
            getProvince();
            getTipiRegistro();
            getTipiSoci();
            getTipiSociAbarth();
            if (!isRuoloClub() && !isRuoloAgenzia()) {
                getAgenzie();
                getClubs();
                getClubsAbarth();
            }
            getTitoli(1);
            getTitoli(2);
            getTipiDocumentoUtente();
        }
        if (id && id !== '') {
            getUtente();
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        nome: Yup.string()
            .max(150, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        cognome: Yup.string()
            .max(150, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        email: Yup.string()
            .email('Indirizzo E-mail non valido'),
        luogo_nascita: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        localita: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        localita_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        indirizzo: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        indirizzo_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
        tel_casa: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        codice_fiscale: Yup.string()
            .max(16, traduci('Attenzione! Hai inserito troppi caratteri'))
            .min(16, traduci('Dato errato! Per favore ricontrolla')),
        numero_civico: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri')),
        numero_civico_sped: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri')),
        nazione: Yup.string()
            .max(3, traduci('Attenzione! Hai inserito troppi caratteri')),
        nazione_sped: Yup.string()
            .max(3, traduci('Attenzione! Hai inserito troppi caratteri')),
        tel_lavoro: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        fax: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri')),
        note: Yup.string()
            .max(255, traduci('Attenzione! Hai inserito troppi caratteri')),
        nominativo_sped: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri')),
    });

    return (
        <Card
            icon={'fas fa-user'}
            title={id ? 'Modifica Socio' : 'Inserisci Socio'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_utente: '',
                    id_ruolo: '',
                    stato: '',
                    nome: '',
                    cognome: '',
                    tit1: '',
                    tit2: '',
                    genere: '',
                    data_nascita: '',
                    luogo_nascita: '',
                    codice_fiscale: '',
                    indirizzo: '',
                    cap: '',
                    numero_civico: '',
                    nazione_nascita: 'I',
                    nazione: 'I',
                    localita: '',
                    provincia_nascita: '',
                    provincia: '',
                    tel_casa: '',
                    tel_lavoro: '',
                    fax: '',
                    email: '',
                    comunicazioni_email: '1',
                    nazione_sped: 'I',
                    provincia_sped: '',
                    localita_sped: '',
                    indirizzo_sped: '',
                    cap_sped: '',
                    nominativo_sped: '',
                    numero_civico_sped: '',
                    note: '',
                    id_tipo_socio: '',
                    id_tipo_socio_abarth: '',
                    numero_tessera_rfi: '',
                    numero_tessera_abarth: '',
                    id_agenzia: '',
                    id_club: '',
                    id_club_abarth: '',
                    numero_tessera_asi: '',
                    azienda: '',
                    delegazione: '',
                    anno_iscrizione: '',
                    da_ratificare: '',
                    newsletter: '',
                    newsletter_abarth: '',
                    adesione: '',
                    sped_eq_res: '1',
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let nuovoSocio = values;
                    nuovoSocio['data_nascita'] = convertiDateInDataPerDb(values['data_nascita']);

                    /*
                     * Se ho richiesto l'utilizzo della residenza come indirizzo di spedizione, per sicurezza,
                     * copio ulteriormente i valori. Questo perché attualmente la copia avviene solo sull'evento di
                     * selezione e deselezione della checkbox, quindi potrebbe non essere richiamata
                     */
                    if (values['sped_eq_res'] == '1') {
                        nuovoSocio['nazione_sped'] = nuovoSocio['nazione']
                        nuovoSocio['provincia_sped'] = nuovoSocio['provincia']
                        nuovoSocio['localita_sped'] = nuovoSocio['localita']
                        nuovoSocio['indirizzo_sped'] = nuovoSocio['indirizzo']
                        nuovoSocio['cap_sped'] = nuovoSocio['cap']
                        nuovoSocio['numero_civico_sped'] = nuovoSocio['numero_civico']
                    }

                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id,
                                nuovoSocio)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    setModalMsg("Salvataggio avvenuto con successo");
                                    setShow(true);
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/",
                                nuovoSocio)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata();
                                    setUser(response.data.data);

                                    setModalMsg("Salvataggio avvenuto con successo");
                                    setShow(true);
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({values, errors, touched, submitCount}) => (
                    <Form>
                        <div className="row g-3">
                            {
                                !isRuoloClub() && !isRuoloAgenzia() &&
                                <Fragment>
                                    <div className="col-md-2">
                                        <label htmlFor="id_utente" className="form-label">ID:</label>
                                        <Field name="id_utente" className="form-control" readOnly={true}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="adesione" className="form-label">Tipo di adesione*:</label>
                                        <Field as="select"
                                               name="adesione"
                                               className={'form-select'}
                                               value={values['adesione']}
                                               onChange={(ev) => {
                                                   formikRef.current.setFieldValue("adesione", ev.target.value)
                                               }}
                                               validate={(value) => {
                                                   if (value === '' && (isRuoloClub() || isRuoloAgenzia())) {
                                                       return 'Questo campo è obbligatorio'
                                                   }
                                               }}>
                                            <option value={""}></option>
                                            {
                                                tipiRegistro && tipiRegistro.map((reg) => {
                                                    return <option value={reg.numero_primo}
                                                                   key={reg.numero_primo}>{reg.descrizione}</option>
                                                })
                                            }
                                        </Field>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"adesione"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="azienda" className="form-label">Azienda:</label>
                                        <Field name="azienda" className="form-control"/>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"azienda"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="delegazione" className="form-label">Delegazione:</label>
                                        <Field name="delegazione" className="form-control"/>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"delegazione"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="anno_iscrizione" className="form-label">Anno
                                            iscrizione:</label>
                                        <Field
                                            name="anno_iscrizione"
                                            className="form-control rfi-number-no-arrows"
                                            type={"number"}
                                            onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                        />
                                        <LabelErroreCampo errors={errors} touched={touched} name={"anno_iscrizione"}
                                                          submitCount={submitCount}/>
                                    </div>
                                </Fragment>
                            }
                            <div className="col-md-2">
                                <label htmlFor="numero_tessera_asi" className="form-label">Numero tessera
                                    ASI:</label>
                                <Field name="numero_tessera_asi" className="form-control"/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"numero_tessera_asi"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                !isRuoloClub() && !isRuoloAgenzia() &&
                                <Fragment>
                                    <div className="col-md-2">
                                        <label htmlFor="id_tipo_socio" className="form-label">Tipo Socio
                                            RFI:</label>
                                        <Field as="select"
                                               name="id_tipo_socio"
                                               className={'form-select'}
                                               value={values['id_tipo_socio']}
                                               onChange={(ev) => {
                                                   formikRef.current.setFieldValue("id_tipo_socio", ev.target.value)
                                               }}>
                                            <option value={""}></option>
                                            {
                                                tipiSoci && tipiSoci.map((soc) => <option value={soc.id_tipo_soci}
                                                                                          key={soc.id_tipo_soci}>{soc.descrizione}</option>)
                                            }
                                        </Field>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"id_tipo_socio"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="numero_tessera_rfi" className="form-label">Numero tessera
                                            RFI:</label>
                                        <Field name="numero_tessera_rfi" className="form-control"/>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"numero_tessera_rfi"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <label htmlFor={"id_club"} className="form-label">Club RFI:</label>
                                        <select id={"id_club"}
                                                className="form-control form-select"
                                                value={values['id_club']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("id_club", ev.target.value)
                                                }}
                                        >
                                            <option value={""}></option>
                                            {
                                                clubs && clubs.map((club) => <option value={club.id_club}
                                                                                     key={club.id_club}>{club.descrizione !== '' ? club.descrizione : club.id_club}</option>)
                                            }
                                        </select>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"id_club"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <label htmlFor={"id_tipo_socio_abarth"} className="form-label">Tipo Socio
                                            Abarth:</label>
                                        <Field
                                            as={"select"}
                                            name="id_tipo_socio_abarth"
                                            className={'form-select'}
                                            value={values['id_tipo_socio_abarth']}
                                            onChange={(ev) => {
                                                formikRef.current.setFieldValue("id_tipo_socio_abarth", ev.target.value)
                                            }}>
                                            <option value={""}></option>
                                            {
                                                tipiSociAbarth && tipiSociAbarth.map((soc) => <option
                                                    value={soc.id_tipo_soci}
                                                    key={soc.id_tipo_soci}>{soc.descrizione}</option>)
                                            }
                                        </Field>
                                        <LabelErroreCampo errors={errors} touched={touched}
                                                          name={"id_tipo_socio_abarth"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="numero_tessera_abarth" className="form-label">Numero tessera
                                            Abarth:</label>
                                        <Field name="numero_tessera_abarth" className="form-control"/>
                                        <LabelErroreCampo errors={errors} touched={touched}
                                                          name={"numero_tessera_abarth"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className={'col-md-2'}>
                                        <label htmlFor={"id_club_abarth"} className="form-label">Club
                                            Abarth:</label>
                                        <select id={"id_club_abarth"}
                                                className="form-control form-select"
                                                value={values['id_club_abarth']}
                                                onChange={(ev) => {
                                                    formikRef.current.setFieldValue("id_club_abarth", ev.target.value)
                                                }}
                                        >
                                            <option value={""}></option>
                                            {
                                                clubsAbarth && clubsAbarth.map((club) => <option
                                                    value={club.id_club}
                                                    key={club.id_club}>{club.descrizione !== '' ? club.descrizione : club.id_club}</option>)
                                            }
                                        </select>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"id_club_abarth"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="tit1" className="form-label">Titolo 1:</label>
                                        <Field as="select"
                                               name="tit1"
                                               className={'form-select'}>
                                            <option value={""}></option>
                                            {
                                                titoli1 && titoli1.map((titolo, index) => {
                                                    return <option
                                                        key={index}
                                                        value={titolo.id_titolo}
                                                    >{titolo.id_titolo}</option>
                                                })
                                            }
                                        </Field>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"tit1"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="tit2" className="form-label">Titolo 2:</label>
                                        <Field as="select"
                                               name="tit2"
                                               className={'form-select'}>
                                            <option value={""}></option>
                                            {
                                                titoli2 && titoli2.map((titolo, index) => {
                                                    return <option
                                                        key={index}
                                                        value={titolo.id_titolo}
                                                    >{titolo.id_titolo}</option>
                                                })
                                            }
                                        </Field>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"tit2"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="id_agenzia" className="form-label">Agenzia:</label>
                                        <Field as="select"
                                               name="id_agenzia"
                                               className={'form-select'}>
                                            <option value={""}></option>
                                            {
                                                agenzie && agenzie.map((age, index) => {
                                                    return <option
                                                        key={index}
                                                        value={age.id_agenzia}
                                                    >{age.descrizione}</option>
                                                })
                                            }
                                        </Field>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"id_agenzia"}
                                                          submitCount={submitCount}/>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        {
                            !isRuoloClub() && !isRuoloAgenzia() &&
                            <Fragment>
                                <div className={"row mt-4"}>
                                    <div className="col-md-2">
                                        <div className="rfi-va--center rfi-display--flex">
                                            <label htmlFor={"da_ratificare"} className="form-label">
                                                <Field
                                                    type="checkbox"
                                                    name={"da_ratificare"}
                                                    className="form-check-input"
                                                    checked={values['da_ratificare'] == '1'}
                                                    onChange={e => {
                                                        formikRef.current.setFieldValue('da_ratificare', e.target.checked ? '1' : '0')
                                                    }}
                                                />
                                                &nbsp;
                                                Da ratificare
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="rfi-va--center rfi-display--flex">
                                            <label htmlFor={"newsletter"} className="form-label">
                                                <Field
                                                    type="checkbox"
                                                    name={"newsletter"}
                                                    className="form-check-input"
                                                    checked={values['newsletter'] == '1'}
                                                    onChange={e => {
                                                        formikRef.current.setFieldValue('newsletter', e.target.checked ? '1' : '0')
                                                    }}
                                                />
                                                &nbsp;
                                                Abbonato alla rivista
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Dati Anagrafici</h4>
                        <div className="row g-3">
                            <div className="col-md-5">
                                <NsField
                                    label="Nome*"
                                    name="nome"
                                    className="form-control"
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-5">
                                <NsField
                                    label="Cognome*"
                                    name="cognome"
                                    className="form-control"
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="genere" className="form-label">Genere:</label>
                                <Field
                                    name="genere"
                                    as="select"
                                    className="form-select"
                                >
                                    <option value={""}></option>
                                    <option value={"M"}>M</option>
                                    <option value={"F"}>F</option>
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"genere"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-2">
                                <FieldContainer
                                    name={'data_nascita'}
                                    label={"Data di nascita"}
                                    errors={errors}
                                    touched={touched}
                                    submitCount={submitCount}
                                    obbligatorio={isRuoloSocio()}
                                >
                                    <NsDatePicker
                                        nome={"data_nascita"}
                                        dataSelezionata={values["data_nascita"]}
                                        maxDate={new Date()}
                                        setSelectedDate={(date, event) => {
                                            formikRef.current.setFieldValue("data_nascita", date)
                                        }}
                                        validate={(value) => {
                                            if (value === '' && isRuoloSocio()) {
                                                return 'Questo campo è obbligatorio'
                                            }
                                        }}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="nazione_nascita" className="form-label"><Translate>Nazione di
                                    nascita</Translate>:</label>
                                <Field
                                    as={"select"}
                                    name="nazione_nascita"
                                    className="form-select"
                                    onChange={(event) => {
                                        formikRef.current.setFieldValue('nazione_nascita', event.target.value)
                                        setNazioneNascita(event.target.value);
                                    }}>
                                    <option value={""}><Translate>Sel. nazione</Translate></option>
                                    {
                                        nazioni ? nazioni.map((op, index) => {
                                            return <option key={index}
                                                           value={op.codice}>{op.descrizione.toUpperCase()}</option>
                                        }) : ''
                                    }
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"nazione_nascita"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                nazioneNascita === 'I' ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="provincia_nascita" className="form-label">Provincia di
                                                nascita:</label>
                                            <select
                                                id="provincia_nascita"
                                                name="provincia_nascita"
                                                className="form-select"
                                                value={values["provincia_nascita"]}
                                                onChange={(event) => {
                                                    formikRef.current.setFieldValue("provincia_nascita", event.target.value)
                                                    searchComuniNascita(event.target.value);
                                                }}>
                                                <option value={""}>Sel. provincia</option>
                                                {
                                                    province ? province.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.sigla_provincia}>{op.descrizione.toUpperCase()}</option>
                                                    }) : ''
                                                }
                                            </select>
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"provincia_nascita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="luogo_nascita" className="form-label">Luogo di
                                                nascita:</label>
                                            <AutocompleteText
                                                name={"luogo_nascita"}
                                                formikRef={formikRef}
                                                formikValues={values}
                                                filterFunction={(comune) => {
                                                    searchComuniNascita(formikRef.current.values['provincia_nascita'], comune);
                                                }}
                                                filteredValues={comuniNascita.map(c => c.nome)}
                                                toUpper={true}
                                            />
                                            <LabelErroreCampo errors={errors} touched={touched} name={"luogo_nascita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <div className="col-md-2">
                                            <NsField
                                                label="Provincia di nascita"
                                                name="provincia_nascita"
                                                className="form-control"
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <NsField
                                                label="Luogo di nascita"
                                                name="luogo_nascita"
                                                className="form-control"
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                    </Fragment>
                            }
                            <div className="col-md-3">
                                <label htmlFor="codice_fiscale" className="form-label">Codice
                                    Fiscale:</label>
                                <Field
                                    name="codice_fiscale"
                                    className="form-control"
                                    onInput={e => {
                                        e.target.value = ("" + e.target.value).toUpperCase();
                                    }}
                                />
                                <button type="button" className={"btn btn-dark"} onClick={() => {
                                    if (values['nome'] !== '' && values['cognome'] !== ''
                                        && values['genere'] !== '' && values['luogo_nascita'] !== ''
                                        && values['provincia_nascita'] !== '') {

                                        let dataN = new Date(values['data_nascita'])

                                        let params = {
                                            name: values['nome'],
                                            surname: values['cognome'],
                                            gender: values['genere'],
                                            day: dataN.getDate().toString().padStart(2, '0'),
                                            month: (dataN.getMonth() + 1).toString().padStart(2, '0'),
                                            year: dataN.getFullYear(),
                                            birthplace: values['luogo_nascita'],
                                            birthplace_provincia: values['provincia_nascita']
                                        }

                                        let cf = CalcolaCodiceFiscale.compute(params)

                                        formikRef.current.setFieldValue("codice_fiscale", cf)
                                    }
                                }}>
                                    <span className={"fas fa-calculator"}></span> Calcola CF
                                </button>
                                <LabelErroreCampo errors={errors} touched={touched} name={"codice_fiscale"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Dati Residenza</h4>
                        <div className="row g-3">
                            <div className="col-md-2">
                                <label htmlFor="nazione"
                                       className="form-label"><Translate>Nazione</Translate>:</label>
                                <Field
                                    as={"select"}
                                    name="nazione"
                                    className="form-select"
                                    value={values['nazione']}
                                    onChange={(event) => {
                                        formikRef.current.setFieldValue("nazione", event.target.value)
                                        setNazione(event.target.value);
                                    }}>
                                    <option value={""}><Translate>Sel. nazione</Translate></option>
                                    {
                                        nazioni ? nazioni.map((op, index) => {
                                            return <option key={index}
                                                           value={op.codice}>{op.descrizione.toUpperCase()}</option>
                                        }) : ''
                                    }
                                </Field>
                                <LabelErroreCampo errors={errors} touched={touched} name={"nazione"}
                                                  submitCount={submitCount}/>
                            </div>
                            {
                                nazione && nazione === 'I' ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="provincia" className="form-label">Provincia di
                                                residenza:</label>
                                            <select
                                                id="provincia"
                                                name="provincia"
                                                className="form-select"
                                                value={values["provincia"]}
                                                onChange={(event) => {
                                                    formikRef.current.setFieldValue("provincia", event.target.value)
                                                    searchComuniResidenza(event.target.value);
                                                }}>
                                                <option value={""}>Sel. provincia</option>
                                                {
                                                    province ? province.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.sigla_provincia}>{op.descrizione.toUpperCase()}</option>
                                                    }) : ''
                                                }
                                            </select>
                                            <LabelErroreCampo errors={errors} touched={touched} name={"provincia"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="localita" className="form-label"><Translate>Residente
                                                a</Translate>:</label>
                                            <AutocompleteText
                                                name={"localita"}
                                                formikRef={formikRef}
                                                formikValues={values}
                                                filterFunction={(comune) => {
                                                    searchComuniResidenza(formikRef.current.values['provincia'], comune);
                                                }}
                                                filteredValues={comuniResidenza.map(c => c.nome)}
                                                handlerChange={(event) => {
                                                    let comune = event.target.value;
                                                    let cap = getCap(comune, comuniResidenza);
                                                    formikRef.current.setFieldValue('cap', cap)
                                                }}
                                                handlerSelect={(comune) => {
                                                    let cap = getCap(comune, comuniResidenza);
                                                    formikRef.current.setFieldValue('cap', cap)
                                                }}
                                                toUpper={true}
                                            />
                                            <LabelErroreCampo errors={errors}
                                                              touched={touched} name={"localita"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    : <Fragment>
                                        <div className="col-md-2">
                                            <NsField
                                                label="Provincia di residenza"
                                                name="provincia"
                                                className="form-control"
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <NsField
                                                label="Residente a"
                                                name="localita"
                                                className="form-control"
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                    </Fragment>
                            }
                            <div className="col-md-3">
                                <NsField
                                    label="Indirizzo e numero civico"
                                    name="indirizzo"
                                    className="form-control"
                                    placeholder={"Via Roma 15"}
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-1">
                                <NsField
                                    label="N° civico"
                                    name="numero_civico"
                                    className="form-control"
                                    formikRef={formikRef}
                                    submitCount={submitCount}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    toUpperCase={true}
                                />
                            </div>
                            <div className="col-md-1">
                                <label htmlFor="cap" className="form-label">CAP:</label>
                                <Field
                                    name="cap"
                                    className="form-control rfi-number-no-arrows"
                                    type={"number"}
                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"cap"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Dati Indirizzo Spedizione</h4>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <div className="rfi-va--center rfi-display--flex">
                                    <label htmlFor={"sped_eq_res"} className="form-label">
                                        <Field type="checkbox"
                                               name={"sped_eq_res"}
                                               className="form-check-input"
                                               onChange={e => {
                                                   formikRef.current.setFieldValue('sped_eq_res', e.target.checked ? '1' : '0')
                                                   setSpedUgualeResidenza(e.target.checked)

                                                   if (e.target.checked) {
                                                       formikRef.current.setFieldValue('nazione_sped', values['nazione'])
                                                       formikRef.current.setFieldValue('provincia_sped', values['provincia'])
                                                       formikRef.current.setFieldValue('localita_sped', values['localita'])
                                                       formikRef.current.setFieldValue('indirizzo_sped', values['indirizzo'])
                                                       formikRef.current.setFieldValue('cap_sped', values['cap'])
                                                       formikRef.current.setFieldValue('numero_civico_sped', values['numero_civico'])
                                                   }
                                               }}
                                               checked={values['sped_eq_res'] == '1'}
                                        />
                                        &nbsp;
                                        Utilizzare per le spedizioni l'indirizzo di residenza?
                                    </label>
                                </div>
                            </div>

                            {
                                spedUgualeResidenza === false ?
                                    <Fragment>
                                        <div className="col-md-2">
                                            <label htmlFor="nazione"
                                                   className="form-label"><Translate>Nazione</Translate>:</label>
                                            <Field
                                                as={"select"}
                                                name="nazione_sped"
                                                className="form-select"
                                                value={values['nazione_sped']}
                                                onChange={(event) => {
                                                    values['nazione_sped'] = event.target.value
                                                    setNazioneSped(event.target.value);
                                                }}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] == '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            >
                                                <option value={""}><Translate>Sel. nazione</Translate></option>
                                                {
                                                    nazioni ? nazioni.map((op, index) => {
                                                        return <option key={index}
                                                                       value={op.codice}>{op.descrizione.toUpperCase()}</option>
                                                    }) : ''
                                                }
                                            </Field>
                                            <LabelErroreCampo errors={errors} name={"nazione_sped"}
                                                              touched={touched}
                                                              submitCount={submitCount}/>
                                        </div>
                                        {
                                            nazioneSped && nazioneSped === 'I' ?
                                                <Fragment>
                                                    <div className="col-md-2">
                                                        <label htmlFor="provincia_sped"
                                                               className="form-label"><Translate>Provincia
                                                            spedizione</Translate>:</label>
                                                        <select
                                                            id="provincia_sped"
                                                            name="provincia_sped"
                                                            className="form-select"
                                                            value={values['provincia_sped']}
                                                            onChange={(event) => {
                                                                values['provincia_sped'] = event.target.value
                                                                searchComuniSpedizione(event.target.value);
                                                            }}>
                                                            <option value={""}><Translate>Sel. provincia</Translate>
                                                            </option>
                                                            {
                                                                province ? province.map((op, index) => {
                                                                    return <option key={index}
                                                                                   value={op.sigla_provincia}>{op.descrizione.toUpperCase()}</option>
                                                                }) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="localita"
                                                               className="form-label"><Translate>Comune</Translate>:</label>
                                                        <AutocompleteText
                                                            name={"localita_sped"}
                                                            formikRef={formikRef}
                                                            formikValues={values}
                                                            filterFunction={(comune) => {
                                                                searchComuniSpedizione(formikRef.current.values['provincia_sped'], comune);
                                                            }}
                                                            filteredValues={comuniSped.map(c => c.nome)}
                                                            toUpper={true}
                                                            handlerChange={(event) => {
                                                                let comune = event.target.value;
                                                                let cap = getCap(comune, comuniSped);
                                                                formikRef.current.setFieldValue('cap_sped', cap)
                                                            }}
                                                            handlerSelect={(comune) => {
                                                                let cap = getCap(comune, comuniSped);
                                                                formikRef.current.setFieldValue('cap_sped', cap)
                                                            }}
                                                        />
                                                        <LabelErroreCampo errors={errors}
                                                                          touched={touched}
                                                                          name={"localita_sped"}
                                                                          submitCount={submitCount}/>
                                                    </div>
                                                </Fragment>
                                                : <Fragment>
                                                    <div className="col-md-2">
                                                        <NsField
                                                            label="Provincia spedizione"
                                                            name="provincia_sped"
                                                            className="form-control"
                                                            formikRef={formikRef}
                                                            submitCount={submitCount}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            toUpperCase={true}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <NsField
                                                            label="Comune"
                                                            name="localita_sped"
                                                            className="form-control"
                                                            validate={(value) => {
                                                                if (values['sped_eq_res'] == '0' && value === '') {
                                                                    return 'Questo campo è obbligatorio'
                                                                }
                                                            }}
                                                            formikRef={formikRef}
                                                            submitCount={submitCount}
                                                            values={values}
                                                            errors={errors}
                                                            touched={touched}
                                                            toUpperCase={true}
                                                        />
                                                    </div>
                                                </Fragment>
                                        }
                                        <div className="col-md-3">
                                            <NsField
                                                label="Indirizzo"
                                                name="indirizzo_sped"
                                                className="form-control"
                                                placeholder={"Via Roma"}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] == '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <NsField
                                                label="N° civico"
                                                name="numero_civico_sped"
                                                className="form-control"
                                                placeholder={"15"}
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] == '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                                formikRef={formikRef}
                                                submitCount={submitCount}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                toUpperCase={true}
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <label htmlFor="cap_sped" className="form-label">CAP:</label>
                                            <Field
                                                name="cap_sped"
                                                className="form-control rfi-number-no-arrows"
                                                type={"number"}
                                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                validate={(value) => {
                                                    if (values['sped_eq_res'] == '0' && value === '') {
                                                        return 'Questo campo è obbligatorio'
                                                    }
                                                }}
                                            />
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"cap_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="nominativo_sped" className="form-label">Nominativo:</label>
                                            <Field name="nominativo_sped" className="form-control"/>
                                            <LabelErroreCampo errors={errors} touched={touched}
                                                              name={"nominativo_sped"}
                                                              submitCount={submitCount}/>
                                        </div>
                                    </Fragment>
                                    : ''
                            }
                        </div>
                        <hr className={"mt-4"}/>
                        <h4 className={"pt-2 pb-4"}>Contatti</h4>
                        <div className="row g-3">
                            <div className="col-md-3">
                                <FieldContainer
                                    name={'email'}
                                    label={"E-mail"}
                                    errors={errors}
                                    touched={touched}
                                    submitCount={submitCount}
                                    obbligatorio={isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()}
                                >
                                    <Field
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        validate={(value) => {
                                            if (value === '' && (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia())) {
                                                return 'Questo campo è obbligatorio'
                                            }
                                        }}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="tel_casa" className="form-label">Tel. casa:</label>
                                <Field name="tel_casa" className="form-control"/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"tel_casa"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="tel_lavoro" className="form-label">Tel. lavoro:</label>
                                <Field name="tel_lavoro" className="form-control"/>
                                <LabelErroreCampo errors={errors} touched={touched}
                                                  name={"tel_lavoro"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="fax" className="form-label">Fax:</label>
                                <Field name="fax" className="form-control"/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"fax"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-6">
                                <div className="rfi-va--center rfi-display--flex1 rfi-va--center">
                                    <label htmlFor={"comunicazioni_email"} className="form-label">
                                        <Field
                                            type="checkbox"
                                            name={"comunicazioni_email"}
                                            className="form-check-input"
                                            checked={values['comunicazioni_email'] == '1'}
                                            onChange={e => {
                                                formikRef.current.setFieldValue('comunicazioni_email', e.target.checked ? '1' : '0')
                                            }}
                                        />
                                        &nbsp;
                                        Desidero ricevere le comunicazioni via E-Mail
                                    </label>
                                    <img src={"assets/img/eco-friendly.png"} className={"rfi-img-eco"}/>
                                </div>
                            </div>
                        </div>
                        {
                            !isRuoloClub() && !isRuoloAgenzia() &&
                            <Fragment>
                                <hr className={"mt-4"}/>
                                <div className="row g-3">
                                    <div className="col-md-12">
                                        <label htmlFor="note" className="form-label">Note:</label>
                                        <Field name="note" className="form-control" as={"textarea"}/>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"note"}
                                                          submitCount={submitCount}/>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        {
                            user && user.id_utente !== '' ?
                                <Fragment>
                                    <hr className={"mt-4"}/>
                                    <br/>
                                    <h4 className={"pt-2 pb-4"}>Documenti d'Identità</h4>
                                    {
                                        checkDocumentiScaduti(user.documenti) &&
                                        <h5>Sono presenti dei documenti scaduti. Eliminali e carica una nuova foto del
                                            documento valido</h5>
                                    }
                                    <div className="row g-3">
                                        {
                                            tipiDocumento && tipiDocumento.map((doc, idx) => {
                                                return (
                                                    <div className={"col-md-4 mt-4"} key={idx}>
                                                        <UplFotoSocio
                                                            formikRef={formikRef}
                                                            errors={errors}
                                                            touched={touched}
                                                            submitCount={submitCount}
                                                            socio={user}
                                                            documento={doc}
                                                            checkIsEliminabile={(doc) => {
                                                                return isRuoloAdmin() || isRuoloSegreteria() || (isDocumentoScaduto(doc))
                                                            }}
                                                            token={token}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </Fragment>
                                : ''
                        }

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaSocio;