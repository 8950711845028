import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link, useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {Tab, Tabs} from "react-bootstrap";
import InfoVeicoloCrs from "./InfoVeicoloCrs";
import GalleryVeicolo from "../veicoli/GalleryVeicolo";
import Separatore from "../../components/Spearatore";
import TabellaDocAggiuntivi from "./TabellaDocAggiuntivi";
import ButtonBar from "../../components/ButtonBar";
import ModalDocVeicoloCert from "../certificati/ModalDocVeicoloCert";

export default function InfoVeicolo() {
    const {idVeicolo} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [veicolo, setVeicolo] = useState();
    const [key, setKey] = useState('veicolo');
    const [showModalDocumenti, setShowModalDocumenti] = useState(false);
    const [tipiDocumento, setTipiDocumento] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const history = useHistory();

    function getVeicolo() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + idVeicolo)
            .then((response) => {
                let val = response.data.data;
                setVeicolo(val);
                setLoadingData(false);
                endChiamata();
            });
    }

    function getTipiDocumentoVeicolo() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata();
        });
    }

    useEffect(() => {
        if (loadingData === true) {
            getTipiDocumentoVeicolo();
        }

        if (idVeicolo && idVeicolo !== '') {
            getVeicolo();
        }
    }, [idVeicolo]);

    return (
        <Card
            icon={'fas fa-car'}
            title={'Dettaglio Veicolo'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <Link to={"/upd-veicolo/" + (veicolo ? veicolo.id_utente : '') + '/' + (veicolo ? veicolo.id_veicolo : '')}
                          className="btn btn-card"
                          data-toggle="tooltip" title="Modifica">
                        <i className="fas fa-pen"/> Modifica
                    </Link>
                </Fragment>
            }>
            <ModalDocVeicoloCert
                veicolo={veicolo}
                show={showModalDocumenti}
                handleClose={() => {
                    setShowModalDocumenti(false);
                    getVeicolo();
                }}
                tipiDocumento={tipiDocumento}
                submitOk={getVeicolo}
            />
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="veicolo" title="Dati veicolo">
                    {
                        veicolo &&
                        <InfoVeicoloCrs veicolo={veicolo}/>
                    }
                </Tab>
                <Tab eventKey="foto" title="Foto veicolo">
                    <Separatore>
                        Fotografie del veicolo
                    </Separatore>
                    {
                        veicolo && veicolo.foto ?
                            <GalleryVeicolo veicolo={veicolo}/>
                            : ''
                    }
                </Tab>
                <Tab eventKey="documenti" title="Documenti">
                    <Separatore>
                        Documenti aggiuntivi
                        <ButtonBar>
                            <button
                                type={"button"}
                                className="btn btn-card"
                                onClick={() => {
                                    setShowModalDocumenti(true);
                                }}
                            >
                                <i className="fas fa-plus-circle"/> Aggiungi
                            </button>
                        </ButtonBar>
                    </Separatore>
                    {
                        veicolo && veicolo.documenti ?
                            <TabellaDocAggiuntivi veicolo={veicolo}/>
                            : ''
                    }
                </Tab>
            </Tabs>
        </Card>
    );
}