import {useContext} from "react";
import {TranslateContext} from "../context/TranslateContext";

function Translate({children}) {

    const {traduci} = useContext(TranslateContext);

    return ' ' + traduci(children)

}

export default Translate;