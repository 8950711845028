import React, {useEffect, useState} from "react";
import Select from "react-select";

export default function NSSelectAutocomplete({
                                                 startVal,
                                                 formikRef = null,
                                                 name,
                                                 options,
                                                 onChange = () => {
                                                 },
                                                 isDisabled = false
                                             }) {

    const [valore, setValore] = useState(null);

    useEffect(() => {
        if (options) {
            let opts = Array.isArray(options) ? options : options();
            let valoreSelezionato = opts && opts.filter(o => o.value === startVal);
            setValore(valoreSelezionato && valoreSelezionato.length > 0 ? valoreSelezionato[0] : null);
        }
    }, [options]);

    return (
        <Select
            isDisabled={isDisabled}
            name={name}
            value={valore}
            placeholder={""}
            onChange={c => {
                setValore(c);
                if (formikRef) {
                    formikRef.current.setFieldValue(name, c ? c.value : '');
                }
                onChange(c);
            }}
            noOptionsMessage={() => 'Nessun dato presente'}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    borderColor: '#d8dbe0',
                    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
                    transition: 'background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                    ':hover': {
                        borderColor: state.isFocused ? '#66afe9' : '#d8dbe0',
                        boxShadow: state.isFocused ?
                            'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)' :
                            'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
                    }
                }),
                // stili per il valore selezionato
                singleValue: (provided, state) => ({
                    ...provided,
                    color: '#000000'
                }),
                menuList: (provided, state) => ({
                    ...provided,
                    marginTop: '-8px',
                    borderColor: '#d8dbe0',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                }),
                option: (provided, state) => ({
                    ...provided,
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    backgroundColor: state.isFocused ? '#0d6efd' : '#ffffff',
                    color: state.isFocused ? '#ffffff' : '#000000',
                }),
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999 // Imposta lo z-index desiderato per il menu delle opzioni
                })
            }}
            options={Array.isArray(options) ? options : options()}
        />
    )
}