import React, {Fragment, useContext, useEffect, useState} from "react";
import StampeFineGiornata from "../soci/StampeFineGiornata";
import Card from "../../components/Card";
import {RichiedentiContext} from "../../context/RichiedentiContext";
import {CertificatiContext} from "../../context/CertificatiContext";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {Link} from "react-router-dom";

function HomeAdminAndStaff() {

    const [quoteTemp, setQuoteTemp] = useState(0);
    const [quoteAsi, setQuoteAsi] = useState(0);
    const [rinnoviDaStampare, setRinnoviDaStampare] = useState(0);

    const {numRichiedenti} = useContext(RichiedentiContext);
    const {numCertificati} = useContext(CertificatiContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getQuoteTemp() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?pag=-1&attiva=0&quoteVeicoli=1").then((response) => {
            setQuoteTemp(response.data.tot);
            endChiamata()
        });
    }

    function getQuoteAsi() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?pag=-1&versato=0&id_causale_movimento=QuoteASI&attiva=1").then((response) => {
            setQuoteAsi(response.data.tot);
            endChiamata()
        });
    }

    function getRinnoviDaStampare() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/rinnovo/").then((response) => {
            setRinnoviDaStampare(response.data.tot);
            endChiamata()
        });
    }

    useEffect(() => {
        getQuoteTemp();
        getQuoteAsi();
        getRinnoviDaStampare();
    }, []);

    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-5"}>
                    <Card
                        title={"Nuovi dati"}
                        icon={"fas fa-user-plus"}
                    >
                        <Link to={"/list-nuovi-soci"}>
                            <div className={"row rfi-row-link"}>
                                <div className={"col-md-8"}>
                                    <h4><i className={"fas fa-users"}/> Nuove registrazioni</h4>
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <h3>{numRichiedenti}</h3>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/list-certificati"}>
                            <div className={"row rfi-row-link"}>
                                <div className={"col-md-8"}>
                                    <h4><i className={"fas fa-car"}/> Certificati da gestire</h4>
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <h3>{numCertificati}</h3>
                                </div>
                            </div>
                        </Link>
                        <Link to={'/list-quote-temp'}>
                            <div className={"row rfi-row-link"}>
                                <div className={"col-md-8"}>
                                    <h4><i className={"fas fa-credit-card"}/> Quote da approvare</h4>
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <h3>{quoteTemp}</h3>
                                </div>
                            </div>
                        </Link>
                        <Link to={'/stampe-fine-giornata'}>
                            <div className={"row rfi-row-link"}>
                                <div className={"col-md-8"}>
                                    <h4><i className={"fas fa-print"}/> Rinnovi da stampare</h4>
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <h3>{rinnoviDaStampare}</h3>
                                </div>
                            </div>
                        </Link>
                        <Link to={'/list-quote-asi'}>
                            <div className={"row rfi-row-link"}>
                                <div className={"col-md-8"}>
                                    <h4><i className={"fas fa-credit-card"}/> Quote ASI da versare</h4>
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <h3>{quoteAsi}</h3>
                                </div>
                            </div>
                        </Link>
                    </Card>
                </div>
                <div className={"col-md-7"}>
                    <StampeFineGiornata nascondiTabellaUtenti={true}/>
                </div>
            </div>
        </Fragment>
    )
}

export default HomeAdminAndStaff;
