import React, {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {
    formattaImporto, getDataFormattata,
    getStatoCertificato,
    STATO_CERTIFICATO_BOZZA, getLabelValidita,
} from "../../utility/common";
import {Link, useHistory} from "react-router-dom";
import ButtonBar from "../../components/ButtonBar";
import {AuthContext} from "../../context/AuthContext";
import NsDataTable from "../../components/tabelle/NsDataTable";
import Card from "../../components/Card";
import TabellaQuoteTmpSocio from "./TabellaQuoteTmpSocio";
import {RuoliContext} from "../../context/RuoliContext";

function HomeSocioCompleto({usr}) {

    const [veicoliSocio, setVeicoliSocio] = useState([]);
    const [quoteSocio, setQuoteSocio] = useState([]);
    const [quoteTemp, setQuoteTemp] = useState([]);
    const [certificati, setCertificati] = useState([]);
    const [loadVeicoli, setLoadVeicoli] = useState(true);
    const [loadCertificati, setLoadCertificati] = useState(true);
    const [loadQuoteTemp, setLoadQuoteTemp] = useState(true);

    const history = useHistory();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isValido} = useContext(AuthContext);
    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    function getVeicoliSocio() {
        let f = '?pag=-1&id_utente=' + usr.id_utente

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + f).then((response) => {
            setVeicoliSocio(response.data.data);
            setLoadVeicoli(false);
            endChiamata()
        });
    }

    function getCertificati() {
        let f = '?pag=-1'

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + usr.id_utente + "/certificato/" + f).then((response) => {
            let cert = response.data.data;
            setLoadCertificati(false);
            if (cert) {
                setCertificati(cert);
            }

            endChiamata()
        });
    }

    function getQuoteSocio() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?id_utente=" + usr.id_utente + '&ultimoAnno=1').then((response) => {
            setQuoteSocio(response.data.data);
            endChiamata()
        });
    }

    function getQuoteTemp() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?pag=-1&id_utente=" + usr.id_utente + "&attiva=0&quoteVeicoli=1").then((response) => {
            setQuoteTemp(response.data.data);
            setLoadQuoteTemp(false);
            endChiamata()
        });
    }

    useEffect(() => {
        getVeicoliSocio();
        getCertificati();
        getQuoteSocio();
        getQuoteTemp();
    }, [usr]);

    return (
        <Fragment>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Card
                        title={"Profilo"}
                        icon={"fas fa-user"}
                    >
                        <div>
                            <h5><strong>{usr.cognome || '-'} {usr.nome || '-'}</strong></h5>
                            <p><strong>{usr.email || '-'}</strong></p>
                            <div className={"row"}>
                                {
                                    usr.numero_tessera_rfi &&
                                    <Fragment>
                                        <div className={"col-md-4"}>
                                            N° tessera RFI:
                                        </div>
                                        <div className={"col-md-8 text-end"}>
                                            <h4><strong>{usr.numero_tessera_rfi}</strong></h4>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    usr.numero_tessera_abarth &&
                                    <Fragment>
                                        <div className={"col-md-4"}>
                                            N° tessera Abarth:
                                        </div>
                                        <div className={"col-md-8 text-end"}>
                                            <h4><strong>{usr.numero_tessera_abarth}</strong></h4>
                                        </div>
                                    </Fragment>
                                }
                                <div className={"col-md-4"}>
                                    <i className="fa-solid fa-calendar-day"/> Scadenza:
                                </div>
                                <div className={"col-md-8 text-end"}>
                                    <h4>{usr.scadenza ? <strong>{'31/12/' + usr.scadenza}</strong> : '-'}</h4>
                                </div>
                                {
                                    (!isRuoloSocio() || isValido()) ?
                                        <ButtonBar align={"text-center"}>
                                            <Link to={"/upd-profilo/" + usr.id_utente} className={"btn btn-principal"}>
                                                <i className={"fas fa-pen"}/> Aggiorna profilo
                                            </Link>
                                        </ButtonBar>
                                        : ''
                                }
                            </div>
                        </div>
                    </Card>
                    <Card
                        title={"Quote"}
                        icon={"fas fa-credit-card"}
                    >
                        <div>
                            {
                                quoteSocio && quoteSocio.length > 0 &&
                                <NsDataTable
                                    data={quoteSocio}
                                    columns={[
                                        {
                                            name: 'Data',
                                            selector: row => getDataFormattata(row.data_pagamento),
                                            sortable: false,
                                        },
                                        {
                                            name: 'Importo',
                                            selector: row => formattaImporto(row.importo) + ' €',
                                            sortable: false,
                                        },
                                        {
                                            name: 'Validità',
                                            selector: row =>
                                                <Fragment>{row.anno_validita} ({getLabelValidita(row)})
                                                </Fragment>,
                                            sortable: false,
                                        }
                                    ]}
                                    addPagination={false}
                                    addFiltri={false}
                                >
                                </NsDataTable>
                            }
                            {
                                usr.visualizza_rinnovo == '1' &&
                                <div>
                                    <h5><strong>Rinnova la tua quota associativa</strong></h5>
                                    <ButtonBar align={"text-center"}>
                                        <Link to={"/pagamenti/" + usr.id_utente} className={"btn btn-principal"}>
                                            <i className={"fas fa-credit-card-alt"}/> Rinnova
                                        </Link>
                                    </ButtonBar>
                                </div>
                            }
                        </div>
                    </Card>
                </div>
                {
                    // Solo se ho terminato la chiamata di get veicoli e get certificati
                    !loadVeicoli && !loadCertificati &&
                    <div className={"col-md-8 ps-4"}>
                        {
                            // Quote temporanee per aggiunta ricevuta
                            loadQuoteTemp === false && quoteTemp.length > 0 &&
                            <Fragment>
                                <Card
                                    title={"Quote in attesa di approvazione"}
                                    icon={"fas fa-credit-card"}
                                    classiAggiuntiveHeader={"rfi-card-header-sella"}
                                >
                                    <div className={"text-center mb-4 rfi-titolo"}>
                                        <h3>Allega le tue ricevute e riceverai una E-mail appena la nostra Segreteria
                                            avrà verificato l'avvenuto pagamento.</h3>
                                    </div>
                                    <TabellaQuoteTmpSocio
                                        usr={usr}
                                        quote={quoteTemp}
                                        updateQuote={getQuoteTemp}
                                    />
                                </Card>
                            </Fragment>
                        }
                        {
                            /*
                            ===========================================
                            == Nessun veicolo
                            ===========================================
                             */
                            veicoliSocio && veicoliSocio.length === 0 && (!isRuoloSocio() || isValido()) ?
                                <Fragment>
                                    <Card
                                        title={"Non hai ancora inserito nessun veicolo"}
                                        icon={"fas fa-car"}
                                    >
                                        <h5>Inserisci i dati dei tuoi veicoli e richiedi la nostra
                                            certificazione.</h5>
                                        <div className={"row text-center"}>
                                            <div className={"col-md-12"}>
                                                <Link to={"/upd-veicolo/" + usr.id_utente}
                                                      className="btn btn-principal"
                                                      data-toggle="tooltip" title="Aggiungi">
                                                    <i className="fas fa-plus-circle"/> Aggiungi veicolo
                                                </Link>
                                            </div>
                                        </div>
                                    </Card>
                                </Fragment>
                                :
                                <Fragment>
                                    {
                                        /*
                                        * ==========================================
                                        * === Sono presenti veicoli
                                        * ==========================================
                                        */
                                        veicoliSocio.map(veicolo =>
                                            <Fragment key={veicolo.id_veicolo}>
                                                <Card
                                                    title={veicolo.marca + ' ' + veicolo.tipo + ' ' + veicolo.targa}
                                                    icon={"fas fa-car"}
                                                >
                                                    {
                                                        certificati && certificati.filter(cert => cert.id_veicolo == veicolo.id_veicolo).length > 0 ?
                                                            <Fragment>
                                                                <NsDataTable
                                                                    data={certificati.filter(cert => cert.id_veicolo == veicolo.id_veicolo)}
                                                                    columns={[
                                                                        {
                                                                            name: 'Numero Registrazione',
                                                                            selector: row => row.numero_registrazione + (row.suffisso && row.suffisso !== '' ? row.suffisso : ''),
                                                                            sortable: true,
                                                                        },
                                                                        {
                                                                            name: 'Tipo Certificato',
                                                                            selector: row => row.tipo_certificato,
                                                                            sortable: true,
                                                                        },
                                                                        {
                                                                            name: 'Stato',
                                                                            selector: row => getStatoCertificato(row),
                                                                            sortable: true,
                                                                        },
                                                                        {
                                                                            name: '',
                                                                            selector: row => <Fragment>
                                                                                {
                                                                                    /*
                                                                                    Possono modificare tutti finché lo stato è bozza. Altrimenti possono modificare solo
                                                                                    gli admin e commissari fintanto che lo stato non è "approvato da staff"
                                                                                     */
                                                                                    row.stato == STATO_CERTIFICATO_BOZZA && (!isRuoloSocio() || isValido()) &&
                                                                                    <div className="btn-group"
                                                                                         role="group"
                                                                                         aria-label="Button group with nested dropdown">
                                                                                        <div className="btn-group"
                                                                                             role="group">
                                                                                            <button id="btnGroupDrop1"
                                                                                                    type="button"
                                                                                                    className="btn btn-dark dropdown-toggle"
                                                                                                    data-bs-toggle="dropdown"
                                                                                                    aria-expanded="false">
                                                                                                <i className="fa-solid fa-bars"></i>
                                                                                            </button>
                                                                                            <ul className="dropdown-menu"
                                                                                                aria-labelledby="btnGroupDrop1">
                                                                                                <li>
                                                                                                    <Link
                                                                                                        to={"/upd-certificato/" + row.id_utente + "/" + row.id_certificato}
                                                                                                        className="dropdown-item"
                                                                                                        data-toggle="tooltip"
                                                                                                        title="Modifica">
                                                                                                        <i className="fas fa-pen"/> Modifica
                                                                                                    </Link>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </Fragment>,
                                                                            sortable: false,
                                                                            right: true,
                                                                        }
                                                                    ]}
                                                                    addPagination={false}
                                                                    addFiltri={false}
                                                                >
                                                                </NsDataTable>
                                                                {
                                                                    (isRuoloClub() || isRuoloAgenzia()) &&
                                                                    <div className={"row text-center mt-4"}>
                                                                        <div className={"col-12"}>
                                                                            <button
                                                                                type={"button"}
                                                                                className={"btn btn-dark"}
                                                                                onClick={() => {
                                                                                    history.push({
                                                                                        pathname: "/upd-certificato/" + veicolo.id_utente,
                                                                                        state: {idVeicolo: veicolo.id_veicolo},
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <i className={"fas fa-file-signature"}/> Richiedi
                                                                                un altro certificato
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                            : <Fragment>
                                                                {
                                                                    isValido() &&
                                                                    <div className={"row text-center"}>
                                                                        <div className={"col-12"}>
                                                                            <button
                                                                                type={"button"}
                                                                                className={"btn btn-dark"}
                                                                                onClick={() => {
                                                                                    history.push({
                                                                                        pathname: "/upd-certificato/" + veicolo.id_utente,
                                                                                        state: {idVeicolo: veicolo.id_veicolo},
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <i className={"fas fa-file-signature"}/> Richiedi
                                                                                il tuo
                                                                                primo certificato
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Fragment>
                                                    }
                                                </Card>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (isRuoloClub() || isRuoloAgenzia()) &&
                                        <div className={"row text-center mt-4"}>
                                            <div className={"col-md-12"}>
                                                <Link to={"/upd-veicolo/" + usr.id_utente}
                                                      className="btn btn-principal"
                                                      data-toggle="tooltip" title="Aggiungi">
                                                    <i className="fas fa-plus-circle"/> Aggiungi veicolo
                                                </Link>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                        }
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default HomeSocioCompleto;
