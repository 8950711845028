import React, {Fragment, useContext} from "react";
import {MobileContext} from "../../context/MobileContext";

function NsTabellaMobile({
                             data,
                             conditionalRowStyles,
                             additionalClasses,
                             onRowClicked,
                             onRowDbClick,
                             columns,
                             addDetail = false,
                             getDettaglio
                         }) {

    return data.map((el, idx) => {
        var classiAggiuntive = ''

        // Controllo se ci sono classi da aggiungere
        if (additionalClasses) {
            additionalClasses.forEach((condizione) => {
                if (condizione.when(el)) {
                    classiAggiuntive = condizione.classNames;
                }
            })
        }

        let style = {};
        if (conditionalRowStyles) {
            style = conditionalRowStyles(el);
        }

        return (
            <div
                className={'ns-card-riga-mobile ' + classiAggiuntive}
                style={style}
                key={idx}
                onClick={() => {
                    if (onRowClicked) {
                        onRowClicked(el)
                    }
                }}
                onDoubleClick={() => {
                    if (onRowDbClick) {
                        onRowDbClick(el)
                    }
                }}
            >
                <div className={'row'}>
                    {
                        columns.filter(colonna => !colonna.visible || colonna.visible() === true).map((col, idxCol) => {
                            if (!col.hideMobile || col.hideMobile !== true) {
                                return (
                                    <Fragment key={idxCol}>
                                        {
                                            // Sezione dettaglio prima dei bottoni
                                            col.name === '' && addDetail === true ?
                                                <div className={"ns-riga-dettaglio"}>
                                                    {
                                                        getDettaglio(el)
                                                    }
                                                </div> : ''
                                        }

                                        <div className={col.name !== '' ? 'col-4 text-end' : 'col-12'}>
                                            {
                                                col.name + (col.name !== '' ? ':' : '')
                                            }
                                        </div>
                                        <div className={col.name !== '' ? 'col-7' : 'col-12 text-center pt-4'}>
                                            {
                                                col.name !== '' ?
                                                    <strong>
                                                        {
                                                            col.selector(el)
                                                        }
                                                    </strong>
                                                    :
                                                    col.selector(el)
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    })
}

export default NsTabellaMobile;