import React, {Fragment, useContext, useEffect, useState} from "react";
import {Link, useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import {Tab, Tabs} from "react-bootstrap";
import {
    formattaImporto,
    getDataFormattata,
    getIconaStatoRelazione,
    getRigaInfoSocio,
    getStatoMantenimentoVeicolo,
    getTimestampFormattato,
    downloadDoc,
    STATO_CERTIFICATO_APPROVATO_DA_STAFF, STATO_CERTIFICATO_BOZZA, STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE,
    STATO_CERTIFICATO_RIFIUTATO,
    STATO_CERTIFICATO_RIGETTATO_DA_PRESIDENTE, STATO_IN_VERIFICA_COMMISSARIO,
    STATO_RELAZIONE_BOZZA, STATO_CERTIFICATO_IN_APPROVAZIONE
} from "../../utility/common";
import Zoom from "react-medium-image-zoom";
import InfoStoricoCertificato from "./InfoStoricoCertificato";
import GalleryVeicolo from "../veicoli/GalleryVeicolo";
import TabellaDocAggiuntiviCert from "./TabellaDocAggiuntiviCert";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {RuoliContext} from "../../context/RuoliContext";
import Separatore from "../../components/Spearatore";
import InfoVeicoloCrs from "../veicoli/InfoVeicoloCrs";
import ModalNotaRifiuto from "./ModalNotaRifiuto";
import ButtonBar from "../../components/ButtonBar";
import ModalDocVeicoloCert from "./ModalDocVeicoloCert";
import ContainerFoto from "../../components/ContainerFoto";
import ModalNotaCert from "./ModalNotaCert";
import ModalConfirm from "../../components/ModalConfirm";
import ModalAssegnaCommissario from "./ModalAssegnaCommissario";

function InfoCertificato() {
    const {idCertificato} = useParams()

    const [socio, setSocio] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [veicolo, setVeicolo] = useState();
    const [certificato, setCertificato] = useState();
    const [relazioni, setRelazioni] = useState([]);
    const [quote, setQuote] = useState([]);
    const [movimenti, setMovimenti] = useState([]);
    const [show, setShow] = useState(false);
    const [showModalNoteRifiuto, setShowModalNoteRifiuto] = useState(false);
    const [showModalDocumenti, setShowModalDocumenti] = useState(false);
    const [showRiportaBozza, setShowRiportaBozza] = useState(false);
    const [showRiportaRichiestaEff, setShowRiportaRichiestaEff] = useState(false);
    const [showModalCommissari, setShowModalCommissari] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [showModalNoteCert, setShowModalNoteCert] = useState(false);
    const [key, setKey] = useState('veicolo');
    const [tipiDocumento, setTipiDocumento] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {
        isRuoloAdmin,
        isRuoloSocio,
        isRuoloSegreteria,
        isRuoloCommissario,
        isRuoloPresidente,
        isRuoloClub,
        isRuoloAgenzia
    } = useContext(RuoliContext);

    const history = useHistory();

    function getQuote() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?quoteVeicoli=1&attiva=1&id_certificato=" + idCertificato).then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getMovimenti() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/?id_certificato=" + idCertificato).then((response) => {
            setMovimenti(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getCertificato() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato).then((response) => {
            let certificato = response.data.data;
            if (certificato) {
                setCertificato(certificato);
                getVeicolo(certificato.id_veicolo);
                getSocio(certificato.id_utente);
                getRelazioni(certificato.id_certificato)
            }
            endChiamata();
        });
    }

    function getVeicolo(idVeicolo) {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + idVeicolo)
            .then((response) => {
                let val = response.data.data;
                setVeicolo(val);
                endChiamata()
            });
    }

    function getSocio(idSocio) {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + idSocio)
            .then((response) => {
                setSocio(response.data.data);
                endChiamata()
            });
    }

    function getRelazioni(idCertificato) {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato + '/relazione/').then((response) => {
            setRelazioni(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getTipiDocumentoVeicolo() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata();
        });
    }

    useEffect(() => {
        if (loadingData) {
            if (idCertificato && idCertificato !== '') {
                getCertificato();
            }
            getQuote();
            if (!isRuoloCommissario()) {
                getMovimenti();
            }
            getTipiDocumentoVeicolo();
        }
    }, [idCertificato]);


    function updateStatoCertificato(newCert) {
        startChiamata()
        axios
            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                + idCertificato + '/stato/', newCert)
            .then((response) => {
                endChiamata()

                if (response.data.result !== 0) {
                    setModalMsg(response.data.description);
                    setShow(true);
                } else {
                    history.push('/list-certificati');
                }
            });
    }

    function stampaCertificato(row) {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + row.id_certificato + "/stampa/" + row.id_tipo_certificato).then((response) => {
            endChiamata();
            if (response.data.result == -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    if (Array.isArray(resp)) {
                        for (let i = 0; i < resp.length; i++) {
                            downloadDoc(resp[i]);
                        }
                    } else {
                        downloadDoc(resp);
                    }
                }
            }
        });
    }

    return (
        <Card
            icon={'fas fa-file-signature'}
            title={'Dettaglio Certificato'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.push("/list-certificati");
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    {
                        certificato && certificato.stato !== STATO_CERTIFICATO_APPROVATO_DA_STAFF
                        && certificato.stato !== STATO_CERTIFICATO_RIFIUTATO ?
                            <Fragment>
                                <Link
                                    to={"/upd-certificato/" + certificato.id_utente + "/" + certificato.id_certificato}
                                    className="btn btn-card"
                                    data-toggle="tooltip" title="Modifica">
                                    <i className="fas fa-pen"/> Modifica dati veicolo
                                </Link>
                                {
                                    !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() && !isRuoloPresidente() &&
                                    <Link to={"/upd-relazione/" + (certificato?.id_certificato || '')}
                                          className="btn btn-card"
                                          data-toggle="tooltip" title="Aggiungi relazione">
                                        <i className="fas fa-plus-circle"/> Aggiungi relazione
                                    </Link>
                                }
                            </Fragment>
                            : ''
                    }
                    {
                        (isRuoloAdmin() || isRuoloSegreteria()) &&
                        <Fragment>
                            <button
                                type={"button"}
                                className="btn btn-card"
                                data-toggle="tooltip" title="Rifiuta"
                                // disabled={!relazioni || relazioni.length === 0}
                                onClick={() => {
                                    setShowModalNoteRifiuto(true);
                                }}
                            >
                                <i className="fas fa-ban"/> Rifiuta
                            </button>
                            <button
                                type={"button"}
                                className="btn btn-card"
                                data-toggle="tooltip" title="Approva"
                                // disabled={!relazioni || relazioni.length === 0}
                                onClick={() => {
                                    let newCert = certificato;
                                    newCert.stato = STATO_CERTIFICATO_APPROVATO_DA_STAFF;

                                    updateStatoCertificato(newCert);
                                }}
                            >
                                <i className="fas fa-check"/> Approva
                            </button>
                        </Fragment>
                    }
                    {
                        (isRuoloAdmin() || isRuoloPresidente()) &&
                        <button
                            type={"button"}
                            className="btn btn-card"
                            data-toggle="tooltip" title="Rimanda in segreteria"
                            onClick={() => {
                                let newCert = certificato;
                                newCert.stato = STATO_CERTIFICATO_RIGETTATO_DA_PRESIDENTE;

                                updateStatoCertificato(newCert);
                            }}
                        >
                            <i className="fas fa-ban"/> Rimanda in segreteria
                        </button>
                    }
                    {
                        (isRuoloAdmin() || isRuoloSegreteria() || isRuoloPresidente()) &&
                        <button
                            type={"button"}
                            className="btn btn-card"
                            data-toggle="tooltip" title="Firma certificato"
                            onClick={() => {
                                let newCert = certificato;
                                newCert.stato = STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE;

                                updateStatoCertificato(newCert);
                            }}
                        >
                            <i className="fas fa-check"/> Firma certificato
                        </button>
                    }
                    {
                        certificato &&
                        <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <div className="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button"
                                        className="btn btn-card dropdown-toggle"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-bars"></i>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    {
                                        (isRuoloAdmin() || isRuoloSegreteria()) &&
                                        certificato.stato != STATO_CERTIFICATO_BOZZA &&
                                        certificato.stato != STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE &&
                                        certificato.stato != STATO_CERTIFICATO_APPROVATO_DA_STAFF ?
                                            <li>
                                                <button className="dropdown-item" data-toggle="tooltip"
                                                        title="Assegna a commissario"
                                                        onClick={() => {
                                                            setShowModalCommissari(true);
                                                        }}>
                                                    <i className="fas fa-user-tag"/> Assegna a commissario
                                                </button>
                                            </li>
                                            : ''
                                    }
                                    {
                                        (isRuoloAdmin() || isRuoloSegreteria()) &&
                                        certificato.stato == STATO_IN_VERIFICA_COMMISSARIO &&
                                        <li>
                                            <button className="dropdown-item" data-toggle="tooltip"
                                                    title="Riprendi in carico"
                                                    onClick={() => {
                                                        startChiamata()
                                                        axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + certificato.id_certificato + "/segreteria/", certificato).then((response) => {
                                                            endChiamata();
                                                            getCertificato()
                                                        });
                                                    }}>
                                                <i className="fas fa-user-cog"/> Riprendi in carico
                                            </button>
                                        </li>
                                    }
                                    {
                                        !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() && !isRuoloClub() &&
                                        <Fragment>
                                            <li>
                                                <button className="dropdown-item" data-toggle="tooltip"
                                                        title="Invia nota al socio"
                                                        onClick={() => {
                                                            setShowModalNoteCert(true);
                                                        }}>
                                                    <i className="fas fa-paper-plane"/> Invia nota al socio
                                                </button>
                                            </li>
                                            {
                                                certificato.stato != STATO_CERTIFICATO_BOZZA ?
                                                    <li>
                                                        <button className="dropdown-item" data-toggle="tooltip"
                                                                title="Riporta in bozza"
                                                                onClick={() => {
                                                                    setShowRiportaBozza(true);
                                                                }}>
                                                            <i className="fab fa-firstdraft"/> Riporta in bozza
                                                        </button>
                                                    </li>
                                                    :
                                                    <li>
                                                        <button className="dropdown-item" data-toggle="tooltip"
                                                                title="Riporta in richiesta effettuata"
                                                                onClick={() => {
                                                                    setShowRiportaRichiestaEff(true);
                                                                }}>
                                                            <i className="fas fa-paper-plane"/> Riporta in richiesta
                                                            effettuata
                                                        </button>
                                                    </li>
                                            }
                                        </Fragment>
                                    }
                                    {
                                        !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() ?
                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    data-toggle="tooltip"
                                                    title="Scarica documento"
                                                    onClick={() => {
                                                        stampaCertificato(certificato);
                                                    }}>
                                                    <i className="fas fa-file-download"/> Scarica documento
                                                </button>
                                            </li>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                </Fragment>
            }>
            <ModalNotaCert
                certificato={certificato}
                show={showModalNoteCert}
                handleClose={() => {
                    setShowModalNoteCert(false)
                }}
                handleOk={(note) => {
                    setShowModalNoteCert(false)

                    startChiamata();
                    axios
                        .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                            + certificato.id_certificato + '/nota/', {
                            id_utente: certificato.id_utente,
                            id_certificato: certificato.id_certificato,
                            note: note
                        })
                        .then((response) => {
                            endChiamata()

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShow(true);
                            }
                        });
                }}
            />
            <ModalConfirm
                show={showRiportaBozza}
                handleClose={() => setShowRiportaBozza(false)}
                handleOk={() => {
                    setShowRiportaBozza(false);

                    let newCert = certificato;
                    newCert.stato = STATO_CERTIFICATO_BOZZA;

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                            + certificato.id_certificato + '/stato/', newCert)
                        .then((response) => {
                            endChiamata()

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShow(true);
                            } else {
                                getCertificato();
                            }
                        });
                }}
                testo={"Sicuro di voler riportare in bozza il certificato: " + (certificato
                    && certificato.numero_certificato) + "?"}/>
            <ModalConfirm
                show={showRiportaRichiestaEff}
                handleClose={() => setShowRiportaRichiestaEff(false)}
                handleOk={() => {
                    setShowRiportaRichiestaEff(false);

                    let newCert = certificato;
                    newCert.stato = STATO_CERTIFICATO_IN_APPROVAZIONE;

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                            + certificato.id_certificato + '/stato/', newCert)
                        .then((response) => {
                            endChiamata()

                            if (response.data.result !== 0) {
                                setModalMsg(response.data.description);
                                setShow(true);
                            } else {
                                getCertificato();
                            }
                        });
                }}
                testo={"Sicuro di voler riportare in richiesta effettuta il certificato: " + (certificato
                    && certificato.numero_certificato) + "?"}/>
            {
                (isRuoloSegreteria() || isRuoloAdmin()) &&
                <ModalAssegnaCommissario
                    show={showModalCommissari}
                    handleClose={() => setShowModalCommissari(false)}
                    certificato={certificato}
                    postUpdate={getCertificato}
                />
            }
            {
                veicolo &&
                <ModalDocVeicoloCert
                    veicolo={veicolo}
                    show={showModalDocumenti}
                    handleClose={() => {
                        setShowModalDocumenti(false);
                        getCertificato();
                    }}
                    tipiDocumento={tipiDocumento}
                    submitOk={getCertificato}
                />
            }
            <ModalNotaRifiuto
                show={showModalNoteRifiuto}
                handleClose={() => {
                    setShowModalNoteRifiuto(false)
                }}
                handleOk={(note) => {
                    setShowModalNoteRifiuto(false)
                    let newCert = certificato;
                    newCert.stato = STATO_CERTIFICATO_RIFIUTATO;
                    newCert.nota_rifiuto_certificato = note;

                    updateStatoCertificato(newCert);
                }}
            />
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="veicolo" title="Dati veicolo">
                    {
                        veicolo && certificato ?
                            <Fragment>
                                <h1>{certificato.tipo_certificato}</h1>
                                <InfoVeicoloCrs veicolo={veicolo} certificato={certificato}/>
                            </Fragment>
                            : ''
                    }
                </Tab>
                <Tab eventKey="foto" title="Foto veicolo">
                    <Separatore>
                        Fotografie del veicolo
                    </Separatore>
                    {
                        veicolo && veicolo.foto &&
                            <GalleryVeicolo veicolo={veicolo}/>
                    }
                </Tab>
                <Tab eventKey="documenti" title="Documenti">
                    <Separatore>
                        Documenti aggiuntivi
                        <ButtonBar>
                            <button
                                type={"button"}
                                className="btn btn-card"
                                onClick={() => {
                                    setShowModalDocumenti(true);
                                }}
                            >
                                <i className="fas fa-plus-circle"/> Aggiungi
                            </button>
                        </ButtonBar>
                    </Separatore>
                    {
                        certificato && certificato.documenti ?
                            <TabellaDocAggiuntiviCert certificato={certificato}/>
                            : ''
                    }
                </Tab>
                <Tab eventKey="socio" title="Informazioni socio">
                    <Separatore>
                        Informazioni socio
                    </Separatore>
                    {
                        socio ? getRigaInfoSocio(socio, false, true) : ''
                    }
                    <hr className={"mt-4"}/>
                    <br/>
                    <Separatore>
                        Documento d'Identità
                    </Separatore>
                    {
                        socio && socio.documenti && socio.documenti.length > 0 ?
                            <div className={"row text-center"}>
                                {
                                    socio.documenti.map((doc, idx) =>
                                        <div className={"col-md-3 rfi-container-border--sm m-2 text-center"} key={idx}>
                                            <p>{doc ? doc.nome_file_originale || '' : ''}</p>
                                            <ContainerFoto
                                                label={doc.label}
                                                content_type={doc.content_type}
                                                nome={doc.nome_file_originale}
                                                endpointContent={process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + doc.id_utente + '/documento/' + doc.id_documento_utente}
                                            />
                                            {
                                                doc.tipo_descr !== '' && doc.scadenza && doc.scadenza !== '' ?
                                                    <Fragment>
                                                        <p>
                                                            <strong>{doc.tipo_descr}</strong><br/>scad.: {getDataFormattata(doc.scadenza)}
                                                        </p>
                                                    </Fragment>
                                                    : ''
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            : ''
                    }
                </Tab>
                <Tab eventKey="storico" title="Storico modifiche">
                    {
                        certificato ?
                            <InfoStoricoCertificato certificato={certificato}/>
                            : ''
                    }
                </Tab>
                {
                    !isRuoloCommissario() &&
                    <Tab eventKey="pagamenti" title="Pagamenti">
                        <Separatore>
                            Quote versate
                        </Separatore>
                        <NsDataTable
                            data={quote}
                            columns={[
                                {
                                    name: 'Data',
                                    selector: row => getDataFormattata(row.data_pagamento),
                                    sortable: true,
                                },
                                {
                                    name: 'Oggetto',
                                    selector: row => row.tipo_versamento,
                                    sortable: true,
                                },
                                {
                                    name: 'Importo €',
                                    selector: row => row.importo,
                                    sortable: true,
                                },
                                {
                                    name: 'Modalità di pagamento',
                                    selector: row => row.modalita,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <Link to={`/upd-quota-cert/${row.id_certificato}/${row.id_quota}`}
                                              className="btn btn-dark rfi-btn-table"
                                              data-toggle="tooltip" title="Modifica">
                                            <i className="fas fa-pen"/> <span
                                            className={'rfi-display-mobile'}>Modifica</span>
                                        </Link>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            addPagination={false}
                            addFiltri={false}
                        />
                        <Separatore>
                            Movimenti
                        </Separatore>
                        <NsDataTable
                            data={movimenti}
                            columns={[
                                {
                                    name: 'ID',
                                    selector: row => row.id_movimento,
                                    sortable: true,
                                },
                                {
                                    name: 'Data',
                                    selector: row => getDataFormattata(row.data_movimento),
                                    sortable: true,
                                },
                                {
                                    name: 'Tipo',
                                    selector: row => <span
                                        className={row.id_tipo_movimento === 'VERSAMENTO' ? 'ns-span--verde' : 'ns-span--rosso'}>{row.tipo_movimento}</span>,
                                    sortable: true,
                                },
                                {
                                    name: 'Importo',
                                    selector: row => '€ ' + formattaImporto(row.importo),
                                    sortable: true,
                                },
                                {
                                    name: 'Conto',
                                    selector: row => row.conto,
                                    sortable: true,
                                },
                                {
                                    name: 'Causale',
                                    selector: row => row.causale_movimento && row.causale_movimento !== '' ? row.causale_movimento : row.id_causale_movimento,
                                    sortable: true,
                                },
                                {
                                    name: 'Riferimento persona',
                                    selector: row => row.nominativo,
                                    sortable: true,
                                },
                                {
                                    name: 'Riferimento fattura',
                                    selector: row => row.rif_fattura,
                                    sortable: true,
                                },
                                {
                                    name: 'Note',
                                    selector: row => row.note,
                                    sortable: true,
                                },
                                {
                                    name: 'RFI / Abarth',
                                    selector: row => row.abarth == '1' ?
                                        <span className={"rfi-etichetta rfi-etichetta--arancione"}>Abarth</span>
                                        : <span className={"rfi-etichetta rfi-etichetta--blu"}>RFI</span>,
                                    sortable: true,
                                }
                            ]}
                            addPagination={false}
                            addFiltri={false}
                        />
                    </Tab>
                }
                <Tab eventKey="note" title="Note">
                    <Separatore>
                        Note della Segreteria
                    </Separatore>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            {
                                certificato && certificato.note && certificato.note !== '' ?
                                    <p><strong>{certificato && certificato.note}</strong></p>
                                    : "Nessuna nota presente"
                            }
                        </div>
                    </div>

                    <Separatore>
                        Segnalazioni al socio
                    </Separatore>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            {
                                certificato && certificato.note_socio && certificato.note_socio.length > 0 ?
                                    <ul>
                                        {
                                            certificato.note_socio.map((n) =>
                                                <li key={n.id_nota_staff}>[{getTimestampFormattato(n.data_insert)}]&nbsp;-&nbsp;<strong>{n.note}</strong></li>
                                            )
                                        }
                                    </ul>
                                    : "Nessuna nota presente"
                            }
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="relazioni" title="Relazioni">
                    <Separatore>
                        Relazioni dei commissari
                    </Separatore>
                    {
                        certificato ?
                            <NsDataTable
                                data={relazioni}
                                columns={[
                                    {
                                        name: 'Data relazione',
                                        selector: row => getTimestampFormattato(row.data_modifica),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Commissario',
                                        selector: row => (row.nome || '') + ' ' + (row.cognome || ''),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Ruolo',
                                        selector: row => <Fragment>
                                            {
                                                row.codice_ruolo === 'ADMIN' &&
                                                <div className={"rfi-evidence ns-bg--verde"}>
                                                    Amministratore
                                                </div>
                                            }
                                            {
                                                row.codice_ruolo === 'STAFF' &&
                                                <div className={"rfi-evidence ns-bg--rosso"}>
                                                    Segreteria
                                                </div>
                                            }
                                            {
                                                row.codice_ruolo === 'COMM' &&
                                                <div className={"rfi-evidence ns-bg--arancio"}>
                                                    Commissario
                                                </div>
                                            }
                                            {
                                                row.codice_ruolo === 'PRES' &&
                                                <div className={"rfi-evidence ns-bg--verde"}>
                                                    Presidente
                                                </div>
                                            }
                                            {
                                                row.codice_ruolo === 'CONS' &&
                                                <div className={"rfi-evidence ns-bg--giallo"}>
                                                    Conservatore
                                                </div>
                                            }
                                        </Fragment>,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Luogo visita',
                                        selector: row => row.luogo,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Data visita',
                                        selector: row => getDataFormattata(row.data),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Relazione',
                                        selector: row => row.relazione,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Stato',
                                        selector: row => getIconaStatoRelazione(row),
                                        sortable: true,
                                    },
                                    {
                                        name: '',
                                        selector: row => <Fragment>
                                            {
                                                row.stato == STATO_RELAZIONE_BOZZA
                                                && (isRuoloAdmin() || isRuoloCommissario()) ?
                                                    <Link
                                                        to={"/upd-relazione/" + row.id_certificato + '/' + row.id_relazione_certificato}
                                                        className="btn btn-principal btn-sm"
                                                        data-toggle="tooltip" title="Modifica">
                                                        <i className="fas fa-pen"/>
                                                    </Link>
                                                    : ''
                                            }
                                        </Fragment>,
                                        sortable: false,
                                        right: true,
                                    }
                                ]}
                                addPagination={false}
                                addFiltri={false}
                                addDetail={true}
                                getDettaglio={(el) => {
                                    return <Fragment>
                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                Relazione: <strong>{el.relazione || '-'}</strong>
                                            </div>
                                            <div className={"col-md-12"}>
                                                Osservazioni: <strong>{el.osservazioni || '-'}</strong>
                                            </div>

                                            <div className={"col-md-4"}>
                                                Stato del
                                                veicolo: <strong>{getStatoMantenimentoVeicolo(el.stato_veicolo)}</strong>
                                            </div>
                                            <div className={"col-md-4"}>
                                                Stato degli
                                                interni: <strong>{getStatoMantenimentoVeicolo(el.stato_interni)}</strong>
                                            </div>
                                            <div className={"col-md-4"}>
                                                Classificazione: <strong>{el.classificazione || '-'}</strong>
                                            </div>
                                        </div>
                                    </Fragment>;
                                }}
                            >
                            </NsDataTable>
                            : ''
                    }
                </Tab>
            </Tabs>
        </Card>
    );
}

export default InfoCertificato;