import Translate from './Translate'

function Pager({pagina, totale, small, handlerCambioPagina}) {

    const pagine = [];
    const pagineVisibili = 5;

    if (pagina - 3 <= 0) {
        for (let i = 1; i <= totale; i++) {
            pagine.push(i);
            if (i === pagineVisibili) {
                break;
            }
        }
    } else {
        var start = pagina - 2;
        if (start + pagineVisibili > totale) {
            for (let i = (totale - pagineVisibili + 1); i <= totale; i++) {
                pagine.push(i);
            }
        } else {
            for (let i = start; i <= totale; i++) {
                if (i === start + pagineVisibili) {
                    break;
                }
                pagine.push(i);
            }
        }
    }

    return (
        totale > 1 ?
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    {
                        !small ?
                            <li className="page-item" onClick={() => handlerCambioPagina(1)}>
                                <span className="page-link"><Translate>Prima</Translate></span>
                            </li> : ''
                    }
                    {
                        pagina > 1 ?
                            <li className="page-item" onClick={() => handlerCambioPagina(parseInt(pagina) - 1)}>
                                <span className="page-link">&laquo;</span>
                            </li> : ''
                    }
                    {
                        pagine[0] && pagine[0] > 1 ?
                            <li className="page-item">
                                <span className="page-link">{'...'}</span>
                            </li> : ''
                    }
                    {
                        pagine.map((pag) => {
                            return <li className={"page-item " + (pagina == pag ? 'active' : '')}
                                       onClick={() => handlerCambioPagina(parseInt(pag))}
                                       key={pag}>
                                <span className="page-link">{pag}</span>
                            </li>
                        })
                    }
                    {
                        pagine[pagine.length - 1] && pagine[pagine.length - 1] < totale ?
                            <li className="page-item">
                                <span className="page-link">{'...'}</span>
                            </li> : ''
                    }
                    {
                        pagina < totale ?
                            <li className="page-item" onClick={() => handlerCambioPagina(parseInt(pagina) + 1)}>
                                <span className="page-link">&raquo;</span>
                            </li> : ''
                    }
                    {
                        !small ?
                            <li className="page-item" onClick={() => handlerCambioPagina(parseInt(totale))}>
                                <span className="page-link"><Translate>Ultima</Translate></span>
                            </li> : ''
                    }
                </ul>
            </nav> : ''
    );
}

export default Pager;