import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import ModuloPagamentoQuota from "./ModuloPagamentoQuota";
import {getUtenteFromToken, TIPO_MODULO_RINNOVO} from "../../utility/common";
import {useParams} from "react-router-dom";

function PagamentoQuota() {
    const {idUsr} = useParams()

    const [socio, setSocio] = useState();
    const [loadingData, setLoadingData] = useState(true);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getSocio() {
        let idUtente = idUsr;

        if (!idUtente || idUtente === '') {
            let token = localStorage.getItem('rfi-login');
            if (token) {
                let user = getUtenteFromToken(token);
                if (user) {
                    idUtente = user.id_utente;
                }
            }
        }
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + idUtente)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);
                setSocio(val)
                endChiamata()
            });
    }

    useEffect(() => {
        if (loadingData) {
            getSocio()
        }
    }, []);

    return socio ? <ModuloPagamentoQuota usr={socio} tipo={TIPO_MODULO_RINNOVO} /> : ''
}

export default PagamentoQuota;