import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import {TranslateContext} from "../../context/TranslateContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import Password from "../../components/Password";
import OTPInput from "react-otp-input";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";


function ConfermaEmail({setNextStep, user, setToken}) {

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);
    const formikRef = useRef();

    useEffect(() => {
        if (user) {
            formikRef.current.setFieldValue('username', user.email)
        }
    }, [user]);

    const SignupSchema = Yup.object().shape({
        otp: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        password: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        password2: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
    });

    const closeModal = () => setShow(false);

    const inviaOtp = () => {
        startChiamata()
        axios
            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + user.id_utente + "/otp/", user)
            .then((response) => {
                setModalMsg(response.data.description);
                setShow(true);
                endChiamata()
            });
    }

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    otp: '',
                    password: '',
                    password2: '',
                    username: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    if (values.password === values.password2) {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/socio/" + user.id_utente + "/password/",
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    setToken(response.data.data.data.token)

                                    // Passo al prossimo step. Da fare dopo il salvataggio ed invio della mail
                                    setNextStep(2)
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                }
                                endChiamata()
                            });
                    } else {
                        setModalMsg('Password e Conferma password non corrispondono');
                        setShow(true);
                    }
                }}
            >
                {({errors, touched, values, submitCount}) => (
                    <Form>
                        <div className="alert alert-info alert-dismissible fade show" role="alert">
                            <p>Copia il codice OTP dalla mail che ti abbiamo inviato. Il codice sarà valido entro 15 minuti dall'arrivo della e-mail.</p>
                            <button type={"button"} className={"btn btn-dark"} onClick={inviaOtp}>
                                <i className="fas fa-share"></i> Invia nuovamente il codice
                            </button>
                        </div>
                        <div className="row g-3 pt-4 pb-4">
                            <div className="col-md-3" />
                            <div className="col-md-6">
                                <OTPInput
                                    value={values['otp']}
                                    onChange={(otp) => {
                                        formikRef.current.setFieldValue('otp', otp)
                                    }}
                                    numInputs={5}
                                    //inputType={"number"}
                                    renderSeparator={<span> </span>}
                                    renderInput={(props) => <input {...props} type={"number"} className="rfi-otp-input rfi-number-no-arrows" />}
                                />
                                <LabelErroreCampo errors={errors} touched={touched} name={"otp"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-3" />
                        </div>
                        <div className="alert alert-info alert-dismissible fade show" role="alert">
                            <p>La password scelta dovrà essere composta da:</p>
                            <ul>
                                <li>Lunghezza minima di 8 caratteri</li>
                                <li>Contenere almeno una lettera maiuscola, una minuscola e una cifra numerica</li>
                                <li>Deve contenere almeno uno dei seguenti caratteri speciali ! # $ % & ' ( ) * + , - . :
                                    ; &lt; = &gt; @
                                </li>
                                <li>Non puo' contenere spazi, lettere accentate o altri caratteri diacritici (àéç§ ecc...)
                                </li>
                            </ul>
                        </div>
                        <div className="row g-3 pt-4">
                            <div className="col-md-4">
                                <label htmlFor="username" className="form-label">E-mail:</label>
                                <Field name="username" className="form-control" readOnly={true}/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"username"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="password" className="form-label">Password di accesso:</label>
                                <Password name={"password"} values={values} formikRef={formikRef} showControlli={true} />
                                <LabelErroreCampo errors={errors} touched={touched} name={"password"}
                                                  submitCount={submitCount}/>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="password2" className="form-label">Conferma password:</label>
                                <Password name={"password2"} values={values} formikRef={formikRef}/>
                                <LabelErroreCampo errors={errors} touched={touched} name={"password2"}
                                                  submitCount={submitCount}/>
                            </div>
                        </div>
                        <div className={"row pt-4"}>
                            <div className={"col-md-12 text-center"}>
                                <button type="button" id="salva" name="salva"
                                        className="btn btn-card"
                                        onClick={() => {
                                            if (formikRef.current) {
                                                formikRef.current.handleSubmit()
                                            }
                                        }}>
                                    <i className="fas fa-check"/> Conferma
                                </button>
                            </div>
                        </div>
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default ConfermaEmail;
