import React, {Fragment, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import HomeSocioCompleto from "../home/HomeSocioCompleto";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {STATO_APPROVATO, STATO_CARTA_ID_UPL, STATO_COMPLETO, STATO_IN_RICHIESTA_VALIDO} from "../../utility/common";
import HomeSocioApprovato from "../home/HomeSocioApprovato";
import HomeSocioMailVerificata from "../home/HomeSocioMailVerificata";
import HomeSocioInAttesaApprov from "../home/HomeSocioInAttesaApprov";

export default function InfoSocioClub() {
    const {id} = useParams()

    const [socio, setSocio] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getSocio() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id)
            .then((response) => {
                setSocio(response.data.data);
                endChiamata()
            });
    }

    useEffect(() => {
        if (id && id !== '') {
            getSocio();
        }
    }, [id]);

    return (
        <Fragment>
            {
                socio && socio.stato == STATO_APPROVATO &&
                <Fragment>
                    <HomeSocioApprovato usr={socio}/>
                </Fragment>
            }
            {
                socio && socio.stato == STATO_IN_RICHIESTA_VALIDO &&
                    <Fragment>
                        <HomeSocioMailVerificata
                            usr={socio}
                            handlerConferma={(usr) => setSocio(usr)}
                        />
                    </Fragment>
            }
            {
                socio && socio.stato == STATO_CARTA_ID_UPL &&
                    <Fragment>
                        <HomeSocioInAttesaApprov usr={socio}/>
                    </Fragment>
            }
            {
                socio && socio.stato == STATO_COMPLETO &&
                    <HomeSocioCompleto usr={socio}/>
            }
        </Fragment>
    )
}