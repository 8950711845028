import React, {useState} from "react";
import {getAnno, getUtenteFromToken} from "../utility/common";
import axios from "axios";

export const AuthContext = React.createContext('');

export const AuthProvider = ({value, children}) => {

    const [state, setState] = useState(() => {
        const token = localStorage.getItem("rfi-login");
        let user = token && token !== '' ? getUtenteFromToken(token) : null;
        return {inizializing: !user, user}
    })

    const handleLogin = (user, history, hash) => {
        setState({initializing: false, user})
        if (hash && hash !== '') {
            history.push(hash)
        } else {
            history.push('/home')
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('rfi-login');
        localStorage.removeItem('rfi-userid');
        setState({initializing: false, user: null})
    }

    const isLogged = () => {
        return state.user && state.user !== '';
    }

    const isValido = () => {
        return state.user && state.user !== ''
            && state.user.socio_valido == '1';
    }

    function updateUtente() {
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + state.user.id_utente)
            .then((response) => {
                let usr = response.data.data;
                if (usr) {
                    setState(prevState => ({
                        ...prevState,
                        ["user"]: usr
                    }));
                }
            });
    }

    return (
        <AuthContext.Provider
            value={{user: state.user, handleLogin, handleLogout, isLogged, isValido, updateUtente}}>{children}</AuthContext.Provider>
    );
};
