import React, {Fragment, useContext, useEffect, useState} from "react";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import axios from "axios";
import {
    getDataFormattata,
    STATO_CERTIFICATO_APPROVATO_DA_STAFF,
    STATO_CERTIFICATO_RIFIUTATO
} from "../../utility/common";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {RuoliContext} from "../../context/RuoliContext";
import ModalConfirm from "../../components/ModalConfirm";

function TabellaDocAggiuntiviCert({certificato}) {
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloAdmin, isRuoloSegreteria, isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [documentoSel, setDocumentoSel] = useState();
    const [showModalDel, setShowModalDel] = useState(false);

    const [documenti, setDocumenti] = useState(filtraDoc(certificato.documenti));

    useEffect(() => {
        setDocumenti(filtraDoc(certificato.documenti));
    }, [certificato]);

    function filtraDoc(documenti) {
        return (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) ? documenti.filter((d) => d.tipo !== 'scansione_crs') : documenti;
    }

    const downloadDocumento = (doc, download) => {
        startChiamata()

        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + certificato.id_veicolo + "/documento/" + doc.id_documento_veicolo).then((response) => {
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    if (download === true) {
                        var a = document.createElement("a"); //Create <a>
                        a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
                        a.download = resp.nome_file_originale; //File name Here
                        a.click(); //Downloaded file
                    } else {
                        var byteCharacters = atob(resp.contenuto);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var file = new Blob([byteArray], {type: resp.content_type + ';base64'});
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                }
            }
            endChiamata()
        });
    }

    const eliminaDocumentoVeicolo = () => {
        setShow(false);
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + certificato.id_veicolo + "/documento/" + documentoSel.id_documento_veicolo).then((response) => {
            setDocumenti(filtraDoc(response.data));
            endChiamata();
        });
    }

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <ModalConfirm
                show={showModalDel}
                handleClose={() => setShowModalDel(false)}
                handleOk={eliminaDocumentoVeicolo}
                testo={"Sicuro di voler eliminare il documento: " + (documentoSel && documentoSel.nome_file_originale) + "?"}/>
            <NsDataTable
                data={documenti}
                columns={[
                    {
                        name: 'Tipo',
                        selector: row => row.tipo_descr,
                        sortable: true,
                    },
                    {
                        name: 'Nome file',
                        selector: row => row.nome_file_originale,
                        sortable: true,
                    },
                    {
                        name: 'Descrizione',
                        selector: row => row.descrizione || '-',
                        sortable: true,
                    },
                    {
                        name: 'Data inserimento',
                        selector: row => getDataFormattata(row.data_ins),
                        sortable: true,
                    },
                    {
                        name: '',
                        selector: row =>
                            <Fragment>
                                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                    <div className="btn-group" role="group">
                                        <button id="btnGroupDrop1" type="button"
                                                className="btn btn-dark dropdown-toggle"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa-solid fa-bars"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                            <li>
                                                <button className="dropdown-item"
                                                        data-toggle="tooltip" title="Download" onClick={() => {
                                                    downloadDocumento(row, true)
                                                }}>
                                                    <i className="fas fa-download"/> Scarica
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item"
                                                        data-toggle="tooltip" title="Visualizza" onClick={() => {
                                                    downloadDocumento(row, false)
                                                }}>
                                                    <i className="fas fa-eye"/> Visualizza
                                                </button>
                                            </li>
                                            {
                                                certificato && certificato.stato !== STATO_CERTIFICATO_APPROVATO_DA_STAFF
                                                && certificato.stato !== STATO_CERTIFICATO_RIFIUTATO
                                                && (isRuoloAdmin() || isRuoloSegreteria()) ?
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina"
                                                                onClick={() => {
                                                                    setDocumentoSel(row)
                                                                    setShowModalDel(true);
                                                                }}>
                                                            <i className="fas fa-trash"/> Elimina
                                                        </button>
                                                    </li>
                                                    : ''
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Fragment>,
                        sortable: false,
                        right: true,
                    }
                ]}
                addPagination={false}
                addFiltri={false}
            />
        </Fragment>
    );
}

export default TabellaDocAggiuntiviCert;