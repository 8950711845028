import React, {Fragment, useContext, useEffect, useState} from "react";
import Translate from "../../components/Translate";
import NsDatePicker from "../../components/NsDatePicker";
import axios from "axios";
import {
    convertiDateInDataPerDb,
    convertiTimestampInDataPerDb, downloadDoc, getDataFormattata, getRigaInfoSocio,
    STAMPA_FG_BADGE, STAMPA_FG_BOLLI, STAMPA_FG_CONFERMA_RINNOVO,
    STAMPA_FG_ETICHETTE,
    STAMPA_FG_REGISTRAZIONE_VEICOLI
} from "../../utility/common";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import Card from "../../components/Card";
import {Link, useHistory} from "react-router-dom";
import Pager from "../../components/Pager";
import NsDataTable from "../../components/tabelle/NsDataTable";

export default function StampeFineGiornata({nascondiTabellaUtenti = false}) {

    const [dataPagamento, setDataPagamento] = useState();
    const [rinnoviDaStampare, setRinnoviDaStampare] = useState([]);
    const [totRinnoviDaStampare, setTotRinnoviDaStampare] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();
    const [registro, setRegistro] = useState('3');
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [dateDaStampare, setDateDaStampare] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const history = useHistory();

    function getRinnoviDaStampare() {
        let f = '?pag=' + pagina;
        if (dataPagamento && dataPagamento !== '') {
            f += '&dataDaStampare=' + convertiDateInDataPerDb(dataPagamento);
        }
        if (registro && registro !== '') {
            f += '&registro=' + registro;
        }

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/rinnovo/" + f).then((response) => {
            setRinnoviDaStampare(response.data.data);
            setTotRinnoviDaStampare(response.data.tot);
            setTotPagine(response.data.pages);

            let arrDateDaStampare = response.data.date_da_stampare;
            setDateDaStampare(arrDateDaStampare);

            if (!dataPagamento) {
                if (arrDateDaStampare && arrDateDaStampare.length > 0) {
                    setDataPagamento(new Date(response.data.date_da_stampare[0]));
                } else {
                    setDataPagamento(new Date());
                }
            }

            endChiamata()
        });
    }

    function stampa(tipoStampa) {
        let f = '?registro=' + registro;
        if (dataPagamento) {
            f += '&dataPagamento=' + convertiTimestampInDataPerDb(dataPagamento);
        }
        if (tipoStampa && tipoStampa !== '') {
            f += '&tipo_stampa=' + tipoStampa;
        }

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/stampa/" + f).then((response) => {
            endChiamata();
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                getRinnoviDaStampare();

                let resp = response.data;
                if (resp) {
                    if (Array.isArray(resp)) {
                        for (let i = 0; i < resp.length; i++) {
                            downloadDoc(resp[i]);
                        }
                    } else {
                        downloadDoc(resp);
                    }
                } else {
                    setModalMsg("Nessun documento da stampare")
                    setShow(true)
                }
            }
        });
    }

    useEffect(() => {
        if (nascondiTabellaUtenti === false) {
            getRinnoviDaStampare()
        }
    }, [dataPagamento, registro, pagina]);

    return (
        <Fragment>
            <Card title={"Stampe di fine giornata"} icon={"fas fa-print"}>
                <ModalMsg
                    show={show}
                    handleClose={() => setShow(false)}
                    testo={modalMsg}/>
                <div className={"text-center"}>
                    <p>Verranno stampati i documenti relativi ai Soci che hanno versato la Quota di iscrizione
                        ("data di pagamento" specificata in fase di inserimento delle Quote) nella data qui
                        specificata.</p>
                    {
                        dateDaStampare && dateDaStampare.length > 0 &&
                        <div className={"row m-4 text-center"}>
                            {
                                dateDaStampare.map((dataDaStampare) => <div className={"col-md-2"}>
                                    <div className={"rfi-odl ns-bg--blu"}
                                         onClick={() => {
                                             setDataPagamento(dataDaStampare);
                                         }}>
                                        {getDataFormattata(dataDaStampare)}
                                    </div>
                                </div>)
                            }
                        </div>
                    }
                    <div className={"row mt-4 text-start"}>
                        <div className={"col-md-6"}>
                            <label htmlFor="data_pagamento" className="form-label"><Translate>Data
                                di pagamento della Quota</Translate>:</label>
                            <NsDatePicker
                                nome={"data_pagamento"}
                                dataSelezionata={dataPagamento}
                                maxDate={new Date()}
                                setSelectedDate={date => setDataPagamento(date)}
                            />
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="registro" className="form-label">Tipo di registro:</label>
                            <select
                                id="registro"
                                name="registro"
                                className="form-control form-select"
                                value={registro}
                                onChange={e => setRegistro(e.target.value)}
                            >
                                {/*
                            3 e 5 sono i valori che assumono i registri RFI ed ABARTH (lrid)
                            */}
                                <option value={"3"}>RFI</option>
                                <option value={"5"}>ABARTH</option>
                            </select>
                        </div>
                        <div className={"col-md-12 text-center mt-4"}>
                            <p>Seleziona il tipo di documento da stampare</p>
                        </div>
                        <div className={"col-md-12 text-center btn-group"}>
                            <button name={"confermaRinnovo"}
                                    type={"button"}
                                    className={"btn btn-dark"}
                                    data-toggle="tooltip"
                                    title={"Lettere di conferma rinnovo iscrizione"}
                                    onClick={() => {
                                        stampa(STAMPA_FG_CONFERMA_RINNOVO);
                                    }}>
                                Lettere rinnovi
                            </button>
                            <button name={"bolli"}
                                    type={"button"}
                                    className={"btn btn-dark"}
                                    data-toggle="tooltip"
                                    title={"Bolli per i Veicoli dei Soci che hanno versato la quota di iscrizione"}
                                    onClick={() => {
                                        stampa(STAMPA_FG_BOLLI);
                                    }}>
                                Bolli per i Veicoli
                            </button>
                            <button name={"etichette"}
                                    type={"button"}
                                    className={"btn btn-dark"}
                                    data-toggle="tooltip"
                                    title={"Etichette per le tessere " + ((registro == '3' ? 'RFI' : 'ABARTH')) + " dei Soci che hanno versato la quota di iscrizione (preparare stampante tessere)"}
                                    onClick={() => {
                                        stampa(STAMPA_FG_ETICHETTE);
                                    }}>
                                Etichette per le tessere {(registro == '3' ? 'RFI' : 'ABARTH')}
                            </button>
                            <button name={"badge"}
                                    type={"button"}
                                    className={"btn btn-dark"}
                                    data-toggle="tooltip"
                                    title={"Badge tessere " + ((registro == '3' ? 'RFI' : 'ABARTH')) + " dei Soci che hanno versato la quota di iscrizione (preparare stampante tessere)"}
                                    onClick={() => {
                                        stampa(STAMPA_FG_BADGE);
                                    }}>
                                Badge tessere {(registro == '3' ? 'RFI' : 'ABARTH')}
                            </button>
                            <button name={"regVeicoli"}
                                    type={"button"}
                                    className={"btn btn-dark"}
                                    data-toggle="tooltip"
                                    title={"Attestati di registrazione Veicoli (in base alla data di registrazione)"}
                                    onClick={() => {
                                        stampa(STAMPA_FG_REGISTRAZIONE_VEICOLI);
                                    }}>
                                Attestati di registrazione Veicoli
                            </button>
                        </div>
                    </div>
                </div>
                {
                    nascondiTabellaUtenti === false &&
                    <div className={"mt-4"}>
                        <NsDataTable
                            data={rinnoviDaStampare}
                            totRighe={totRinnoviDaStampare}
                            columns={[
                                {
                                    name: 'N° tessera RFI',
                                    selector: row => row.numero_tessera_rfi,
                                    sortable: true,
                                },
                                {
                                    name: 'N° tessera Abarth',
                                    selector: row => row.numero_tessera_abarth,
                                    sortable: true,
                                },
                                {
                                    name: 'Scadenza',
                                    selector: row => row.scadenza,
                                    sortable: true,
                                },
                                {
                                    name: 'Tipo socio',
                                    selector: row => row.tipo_socio,
                                    sortable: true,
                                },
                                {
                                    name: 'Cognome',
                                    selector: row => row.cognome,
                                    sortable: true,
                                },
                                {
                                    name: 'Nome',
                                    selector: row => row.nome,
                                    sortable: true,
                                },
                                {
                                    name: 'Regione',
                                    selector: row => row.regione,
                                    sortable: true,
                                },
                                {
                                    name: 'Provincia',
                                    selector: row => row.provincia,
                                    sortable: true,
                                },
                                {
                                    name: 'Località',
                                    selector: row => row.localita,
                                    sortable: true,
                                },
                                {
                                    name: 'N° veicoli',
                                    selector: row => row.num_veicoli,
                                    sortable: true,
                                },
                                {
                                    name: '',
                                    selector: row => <Fragment>
                                        <div className="btn-group" role="group"
                                             aria-label="Button group with nested dropdown">
                                            <div className="btn-group" role="group">
                                                <button id="btnGroupDrop1" type="button"
                                                        className="btn btn-dark dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                    <li>
                                                        <Link to={"/info-socio/" + row.id_utente}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Informazioni">
                                                            <i className="fas fa-eye"/> Informazioni
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Fragment>,
                                    sortable: false,
                                    right: true,
                                }
                            ]}
                            onRowDbClick={(el) => {
                                history.push("/info-socio/" + el.id_utente)
                            }}
                            addPagination={false}
                            addFiltri={false}
                            addDetail={true}
                            getDettaglio={(el) => getRigaInfoSocio(el, false, true)}
                        />
                        {
                            totPagine ?
                                (
                                    <Pager
                                        pagina={pagina}
                                        totale={totPagine}
                                        handlerCambioPagina={(nuovaPagina) => {
                                            setPagina(nuovaPagina)
                                        }}
                                    >
                                    </Pager>
                                ) : ''
                        }
                    </div>
                }
            </Card>
        </Fragment>
    )
}
