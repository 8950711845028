import React, {Fragment, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

function GoToSella() {

    const location = useLocation();
    var esito = location.state.esito;

    const formRef = useRef(null);
    useEffect(() => {
        formRef.current.submit();
    }, []);

    return (
        <Fragment>
            <form name="pagamento" method="post" id="fpagam" action={esito.endpoint} ref={formRef}>
                <input name="a" type="hidden" value={esito.shopLogin}/>
                <input name="b" type="hidden" value={esito.encString[0]}/>
            </form>
        </Fragment>
    )
}

export default GoToSella;
