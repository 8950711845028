import {Button, Modal} from "react-bootstrap";
import Translate from "./Translate";
import {useContext} from "react";
import {TranslateContext} from "../context/TranslateContext";
import NsModalHeader from "./modal/NsModalHeader";

function ModalConfirm(props) {

    const {traduci} = useContext(TranslateContext)

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
        >
            <NsModalHeader title={props.titolo || traduci('ATTENZIONE')}/>
            <Modal.Body>
                <p>{props.testo}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    <Translate>Annulla</Translate>
                </Button>
                <Button variant="primary" onClick={props.handleOk}>
                    <Translate>Conferma</Translate>
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default ModalConfirm;