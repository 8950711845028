const ButtonBar = ({children, align = "text-end"}) => {

    return (
        <div className={"row mt-4"}>
            <div className={"col-md-12 " + align}>
                {children}
            </div>
        </div>
    )
}

export default ButtonBar;