import React, {useEffect, useState} from "react";
import trl from "../i18n/translactions.json"

export const TranslateContext = React.createContext();

export const TranslateProvider = ({value, children}) => {

    const [lang, setLang] = useState(value)

    useEffect(() => {
        setLang(value)
    }, [])

    const setLingua = (lingua) => {
        setLang(lingua)
    }

    const getLingua = () => lang

    const traduci = (testo) => {
        return trl[testo] && trl[testo][lang] || testo
    }

    return (
        <TranslateContext.Provider
            value={{getLingua, setLingua, traduci}}>{children}</TranslateContext.Provider>
    );
};
