import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";
import {TranslateContext} from "../../../context/TranslateContext";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";

function ModificaTipoSocio() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getTipoSocio() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_tipo_soci", val.id_tipo_soci);
                    formikRef.current.setFieldValue("descrizione", val.descrizione);
                    formikRef.current.setFieldValue("quota", val.quota);
                    formikRef.current.setFieldValue("sort", val.sort);
                    formikRef.current.setFieldValue("abarth", val.abarth);
                }
                endChiamata()
            });
    }

    useEffect(() => {
        if (loadingData) {
            if (id && id !== '') {
                getTipoSocio();
            }
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        id_tipo_soci: Yup.string()
            .max(20, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        descrizione: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        quota: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        sort: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        abarth: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
    });

    return (
        <Card
            icon={'fas fa-users'}
            title={id ? 'Modifica Tipo Socio' : 'Inserisci Tipo Socio'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_tipo_soci: '',
                    descrizione: '',
                    quota: '0',
                    sort: '0',
                    abath: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/" + id,
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/",
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-12">
                            <label htmlFor="id_tipo_soci" className="form-label">Codice Tipo Socio*:</label>
                            <Field
                                name="id_tipo_soci"
                                className="form-control"
                                readOnly={id}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_tipo_soci"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="descrizione" className="form-label">Descrizione Tipo Socio*:</label>
                            <Field
                                name="descrizione"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"descrizione"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="quota" className="form-label">Quota*:</label>
                            <Field
                                name="quota"
                                className="form-control rfi-number-no-arrows"
                                type={"number"}
                                step="0.01"
                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"quota"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="sort" className="form-label">Ordinamento*:</label>
                            <Field
                                name="sort"
                                className="form-control"
                                type={"number"}
                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"sort"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="abarth" className="form-label">Tipo di registro*:</label>
                            <Field
                                as={"select"}
                                name="abarth"
                                className="form-control form-select"
                                value={values['abarth']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("abarth", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                <option value={'0'}>RFI</option>
                                <option value={'1'}>Abarth</option>
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"abarth"}
                                              submitCount={submitCount}/>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaTipoSocio;