import React, {Fragment, useContext, useEffect} from "react";
import FieldContainer from "../FieldContainer";
import {RuoliContext} from "../../context/RuoliContext";

export default function CertDivContainer({
                                             children,
                                             name,
                                             label,
                                             infoLabel,
                                             errors,
                                             touched,
                                             submitCount,
                                             colSize,
                                             show = true,
                                             obbligatorio = false,
                                             showAllCampi = false,
                                             addFieldContainer = true,
                                         }) {

    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    useEffect(() => {
    }, []);

    return (
        <Fragment>
            {
                ((!isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() && showAllCampi === true) || (show === true)) &&
                <div className={'col-md-' + colSize}>
                    {
                        addFieldContainer === true ?
                            <FieldContainer
                                name={name}
                                label={label}
                                infoLabel={infoLabel}
                                errors={errors}
                                touched={touched}
                                submitCount={submitCount}
                                obbligatorio={obbligatorio}
                            >
                                {
                                    children
                                }
                            </FieldContainer>
                            : <Fragment>
                                {
                                    children
                                }
                            </Fragment>
                    }
                </div>
            }
        </Fragment>
    );
}