import Card from "../../components/Card";
import {getUtenteFromToken} from "../../utility/common";
import {Field, Form, Formik} from "formik"
import React, {useContext, useEffect, useRef, useState} from "react";
import ModalMsg from "../../components/ModalMsg";
import axios from "axios";
import {AuthContext} from "../../context/AuthContext";
import {Link, useHistory} from "react-router-dom";
import {LoaderContext} from "../../context/LoaderContext";
import Translate from "../../components/Translate";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import * as Yup from "yup";
import {TranslateContext} from "../../context/TranslateContext";
import Password from "../../components/Password";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";

function Accesso({hash}) {

    const [show, setShow] = useState(false);
    const [msgModal, setMsgModal] = useState('');

    const history = useHistory()

    const authContext = useContext(AuthContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const formikRef = useRef();

    const closeModal = () => setShow(false);

    useEffect(() => {
    }, []);

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .required(traduci('L\'indirizzo E-Mail deve essere valorizzato.')),
        password: Yup.string()
            .required(traduci('Password obbligatoria.')),
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={msgModal}/>
            <div>
                <div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        innerRef={formikRef}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            startChiamata()
                            axios
                                .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/login",
                                    {
                                        username: values.email,
                                        pwd: values.password
                                    })
                                .then((response) => {
                                    if (response?.data) {
                                        if (response?.data?.result === 0) {
                                            var token = response.data.data.token;
                                            localStorage.setItem('rfi-login', token);

                                            var user = getUtenteFromToken(token);
                                            if (user) {
                                                localStorage.setItem('rfi-userid', user.id_utente);
                                                authContext.handleLogin(user, history, hash);
                                            }
                                        } else {
                                            setMsgModal(response.data.description)
                                            setShow(true)
                                        }
                                    }
                                    endChiamata()
                                });
                        }}
                    >
                        {({values, errors, touched, submitCount}) => (
                            <Form className="row g-3">
                                <div className="col-12">
                                    <label htmlFor="email" className="form-label">E-mail:</label>
                                    <Field
                                        name="email"
                                        className="form-control"
                                    />
                                    <LabelErroreCampo errors={errors} touched={touched} name={"email"}
                                                      submitCount={submitCount}/>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="password" className="form-label">Password:</label>
                                    <Password name={"password"} values={values} formikRef={formikRef} autoComplete="current-password" />
                                    <LabelErroreCampo errors={errors} touched={touched} name={"password"}
                                                      submitCount={submitCount}/>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="d-grid gap-2">
                                        <button type="submit" name="accedi" id="accedi"
                                                className="btn btn-dark">
                                            <i className="fas fa-sign-in-alt"/> Accedi
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <Link to={"/recuperaPwd"}>
                                        Recupera password
                                    </Link>
                                </div>
                                <div className="col-12 text-center">
                                    <hr/>
                                </div>
                                <div className={"col-6"}>
                                    <Link aria-current="page" to="/registrati/1" className="rfi-a-reg">
                                        <div className="text-center rfi-btn-reg">
                                            <img src={"assets/img/logo_fiat.png"} className={"rfi-btn-reg-img"}/>
                                            <Translate>Iscrizione Registro FIAT</Translate>
                                        </div>
                                    </Link>
                                </div>
                                <div className={"col-6"}>
                                    <Link aria-current="page" to="/registrati/2" className="rfi-a-reg">
                                        <div className="text-center rfi-btn-reg">
                                            <img src={"assets/img/Abarth_logo.png"} className={"rfi-btn-reg-img"}/>
                                            <Translate>Iscrizione Registro Abarth</Translate>
                                        </div>
                                    </Link>
                                </div>
                                <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default Accesso;
