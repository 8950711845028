import React, {Fragment, useState} from "react";
import ModalMsg from "./ModalMsg";

export default function LabelWithInfo({
                                          name,
                                          children,
                                          info = null
                                      }) {

    const [show, setShow] = useState(false);

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={info}
                titolo={"Informazioni"}
            />
            <label htmlFor={name} className="form-label">{children}: {info &&
                <i className="fas fa-question-circle ns-cursor-pointer" onClick={() => setShow(true)}/>}</label>
        </Fragment>
    );
}