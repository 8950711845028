import React, {Fragment, useEffect} from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function ContainerFotoCaricata({
                                   label,
                                   content_type,
                                   contenuto,
                                   nome,
                                   isEliminabile = false,
                                   isScaduto = false,
                                   eliminaFoto = () => {
                                   }
                               }) {

    useEffect(() => {
    }, []);

    function showDocumento() {
        var byteCharacters = atob(contenuto);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], {type: content_type + ';base64'});
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    return (
        <Fragment>
            <div className={"ns-container-foto-upl"}>
                <div className={"ns-container-foto-upl-label text-center"}>
                    <label aria-hidden="true">
                        <div>
                            <p>{label} {isScaduto === true ? <strong>[scaduto]</strong> : ''}</p>
                        </div>
                        <div className={"ns-foto"}>
                            {
                                contenuto && content_type && content_type.startsWith('image/') && content_type !== 'image/heic' ?
                                    <Zoom>
                                        <img src={`data:${content_type};base64, ${contenuto}`}
                                             className={"ns-foto-img"}/>
                                    </Zoom>
                                    :
                                    <div className={"ns-container-doc"}>
                                        <span><strong>{nome}</strong></span>
                                    </div>
                            }
                        </div>
                        <div className={"ns-foto-button-bar"}>
                        {
                                isEliminabile === true &&
                                <button
                                    type={"button"}
                                    className={"btn btn-danger btn-sm"}
                                    data-toggle="tooltip"
                                    title="Elimina"
                                    onClick={() => {
                                        eliminaFoto();
                                    }}
                                >
                                    <i className="fas fa-trash"/> Elimina
                                </button>
                            }
                            {
                                content_type && (!content_type.startsWith('image/') || content_type === 'image/heic') &&
                                <button
                                    type={"button"}
                                    className="btn btn-dark btn-sm"
                                    data-toggle="tooltip" title="Visualizza"
                                    onClick={() => {
                                        showDocumento();
                                    }}
                                >
                                    <i className="fas fa-eye"/> Visualizza
                                </button>
                            }
                        </div>
                    </label>
                </div>
            </div>
        </Fragment>
    )
}

export default ContainerFotoCaricata;
