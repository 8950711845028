import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import '../../../node_modules/filepond/dist/filepond.min.css'
import {useHistory} from 'react-router-dom';
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {STATO_CARTA_ID_UPL} from "../../utility/common";
import UplFotoSocio from "../soci/UplFotoSocio";
import {Form, Formik} from "formik";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";

function RegistraCartaIdentita({user, token}) {

    const [tipiDocumento, setTipiDocumento] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const history = useHistory();

    const formikRef = useRef();

    useEffect(() => {
        getTipiDocumentoUtente()
    }, []);

    const getTipiDocumentoUtente = () => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipo-documento/").then((response) => {
            setTipiDocumento(response.data.data);
            endChiamata()
        });
    }

    return (
        <Fragment>
            <div className={"row"}>
                <div className="col-md-12">
                    <div className="alert alert-info alert-dismissible fade show" role="alert">
                        <p>Per completare la richiesta di iscrizione è necessario caricare una copia della carta
                            d'identità.</p>
                        <p>Per terminare la registrazione, fai click sul bottone "Completa richiesta".</p>
                    </div>
                    <div className="row g-3">
                        <Formik
                            initialValues={{
                            }}
                            innerRef={formikRef}
                            onSubmit={values => {
                                startChiamata()
                                axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + user.id_utente + "/stato/", {
                                    stato: STATO_CARTA_ID_UPL,
                                    note: ''
                                }, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'jwt': token
                                    }
                                }).then((response) => {
                                    endChiamata()
                                    history.push({
                                        pathname: '/',
                                        state: {
                                            messaggio: "La tua richiesta di adesione è stata presa in carico dal nostro Staff. Riceverai a breve un'e-mail di conferma o di richiesta di ulteriori informazioni.",
                                            titolo: "Registrazione utente"
                                        },
                                    });
                                });
                            }}
                        >
                            {({values, errors, touched, submitCount}) => (
                                <Form>
                                    <div className={"row"}>
                                    {
                                        tipiDocumento && tipiDocumento.map((doc, idx) => {
                                            return (
                                                <div className={"col-md-4 mt-4"} key={idx}>
                                                    <UplFotoSocio
                                                        formikRef={formikRef}
                                                        errors={errors}
                                                        touched={touched}
                                                        submitCount={submitCount}
                                                        socio={user}
                                                        documento={doc}
                                                        token={token}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                    </div>

                                    <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12 text-center"}>
                    <button
                        type={"button"}
                        className="btn btn-dark"
                        onClick={() => {
                            if (formikRef.current) {
                                formikRef.current.handleSubmit()
                            }
                        }}
                    >
                        <i className="fas fa-paper-plane"/> Completa richiesta
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default RegistraCartaIdentita;
