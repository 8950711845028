import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import 'react-medium-image-zoom/dist/styles.css'
import axios from "axios";
import {FilePond} from "react-filepond";
import Translate from "../../components/Translate";
import NsModalHeader from "../../components/modal/NsModalHeader";

export default function ModalDocQuota({show, handleClose, idQuota, submitOk}) {

    const [files, setFiles] = useState([])

    const token = localStorage.getItem('rfi-login');
    const url = process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + (idQuota ? idQuota : '') + '/documento/';


    useEffect(() => {
    }, []);

    const onCloseModal = () => {
        handleClose();
        setFiles([]);
    }

    return (
        <Modal
            show={show}
            onHide={onCloseModal}
            backdrop="static"
            keyboard={false}
            size={"lg"}
        >
            <NsModalHeader title={"Ricevuta del pagamento quota"}/>
            <Modal.Body>
                <div className={"row"}>
                    <div className={"col-md-12 mt-4"}>
                        <FilePond
                            files={files}
                            onupdatefiles={(file) => {
                                setFiles(file);
                                submitOk();
                            }}
                            acceptedFileTypes={["image/x-png", "image/png", "image/gif", "image/jpeg", "image/bmp", "image/svg+xml"
                                , "image/heic", "application/pdf", "application/msword", "application/vnd.openxmlformats-"
                                , "officedocument.wordprocessingml.document"]}
                            allowFileTypeValidation={true}
                            fileValidateTypeDetectType={(source, type) =>
                                new Promise((resolve, reject) => {
                                    // Do custom type detection here and return with promise
                                    resolve(type);
                                })
                            }
                            allowMultiple={true}
                            allowRevert={true}
                            allowReorder={true}
                            allowImagePreview={true}
                            imagePreviewMaxHeight={200}
                            imagePreviewMarkupFilter={console.log}
                            server=
                                {
                                    {
                                        url: url,
                                        process: {
                                            headers: {
                                                'jwt': token
                                            },
                                        },
                                        revert: (uniqueFileId, load, error) => {
                                            axios.delete(url + parseInt(uniqueFileId)).then((response) => {
                                                load(response)
                                            });
                                        }
                                    }
                                }
                            name="files"
                            labelIdle={
                                'Trascina qui l\'immagine del tuo documento o <span class="filepond--label-action">Cercala all\'interno delle tue cartelle</span>'
                            }
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={onCloseModal}>
                    <Translate>Chiudi</Translate>
                </Button>
            </Modal.Footer>
        </Modal>
    )

}