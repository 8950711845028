import {Field} from "formik";
import React, {Fragment, useEffect, useState} from "react";

export default function AutocompleteText({
                                             type = null,
                                             name,
                                             formikRef,
                                             formikValues,
                                             filterFunction,
                                             validate,
                                             filteredValues = [],
                                             toUpper = false,
                                             handlerChange = (ev) => {},
                                             handlerBlur = (ev) => {},
                                             handlerSelect = (val) => {},
                                         }) {

    const [show, setShow] = useState(false);

    let debounce = null;

    useEffect(() => {
        return () => {
            if (debounce) {
                clearTimeout(debounce);
            }
        }
    }, []);

    return (
        <Fragment>
            <Field
                as={type}
                name={name}
                className="form-control"
                autoComplete="off"
                onKeyUp={(ev) => {
                    let newVal = ev.target.value && ev.target.value !== '' ? ev.target.value.toUpperCase() : '';
                    formikRef.current.setFieldValue(name, newVal);
                    filterFunction(newVal);
                    setShow(true);
                }}
                onChange={(ev) => {
                    let newVal = ev.target.value && ev.target.value !== '' ? ev.target.value.toUpperCase() : '';
                    formikRef.current.setFieldValue(name, newVal);
                    handlerChange(ev);
                }}
                onBlur={(ev) => {
                    clearTimeout(debounce)
                    debounce = setTimeout(() => {
                        if (show) {
                            setShow(false);
                        }
                    }, 300);
                    handlerBlur(ev);
                }}
                value={formikValues[name] || ''}
                validate={validate}
            />
            <div className={"ns-autocomplete"}>
                {
                    filteredValues && filteredValues.length > 0 && show &&
                    <div className={"ns-autocomplete-container"}>
                        <ul className={'ns-list-no-bullet'}>
                            {
                                filteredValues.map((val, idx) => <li
                                    className={"ns-cursor-pointer"}
                                    key={idx}
                                    onClick={() => {
                                        formikRef.current.setFieldValue(name, toUpper ? val.toUpperCase() : val);
                                        setShow(false);
                                        handlerSelect(toUpper ? val.toUpperCase() : val);
                                    }}>{toUpper ? val.toUpperCase() : val}</li>)
                            }
                        </ul>
                    </div>
                }
            </div>

        </Fragment>
    );
}