import {Modal} from "react-bootstrap";
import React, {useContext} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import NsModalHeader from "../../components/modal/NsModalHeader";

export default function ModalBollettino({show, handleClose, handlePagamento}) {

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function scaricaModulo(tipoModulo) {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/modulo/" + tipoModulo).then((response) => {
            let resp = response.data;
            if (resp) {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
                a.download = resp.nome_file_originale; //File name Here
                a.click(); //Downloaded file
            }
            endChiamata()
        });
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <NsModalHeader title={"Pagamento tramite Bollettino Postale"}/>
            <Modal.Body>
                <p>Scarica il modulo per il pagamento tramite bollettino.</p>
                <div className={"row text-center mb-2"}>
                    <div className={"col-md-12"}>
                        <button
                            type={"button"}
                            className={"btn btn-dark"}
                            onClick={() => {
                                scaricaModulo(1)
                            }}
                        >
                            <i className="fas fa-file-download"/> Scarica modulo
                        </button>
                    </div>
                </div>
                <p className={"mt-3"}>Se proseguirai con questa modalità di pagamento, riceverai una E-mail con
                    riassunte le informazioni
                    sulle coordinate bancarie e quota da pagare.</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className={"btn btn-danger btn-lg"} onClick={() => {
                    handlePagamento('CC_POSTALE');
                }}>
                    <i className="fas fa-credit-card"></i> Procedi al pagamento
                </button>
            </Modal.Footer>
        </Modal>
    )

}