import React, {Fragment, useContext, useEffect, useState} from "react";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import {getDataFormattata} from "../../utility/common";
import NsDataTable from "../../components/tabelle/NsDataTable";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";

export default function ListQuoteAsi() {

    const [loadingData, setLoadingData] = useState(true);
    const [quote, setQuote] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    var f = '?pag=-1&versato=0&id_causale_movimento=QuoteASI&attiva=1';

    function getQuoteAsi() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + f).then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function versaQuota(idQuota) {
        startChiamata()
        axios.put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + idQuota + '/versata/' + f).then((response) => {
            setQuote(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        getQuoteAsi();
    }, []);

    return (
        <CardContainer>
            <Card
                icon={'fas fa-credit-card'}
                title={'Quote ASI da versare'}
                getBottoniDx={() => {
                }}>
                <Fragment>
                    {
                        loadingData ? (
                            <p>Caricamento in corso...</p>
                        ) : (
                            <NsDataTable
                                data={quote}
                                columns={[
                                    {
                                        name: 'ID',
                                        selector: row => row.id_quota,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Data',
                                        selector: row => getDataFormattata(row.data_pagamento),
                                        sortable: true,
                                    },
                                    {
                                        name: 'Socio',
                                        selector: row => row.socio,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Oggetto',
                                        selector: row => row.tipo_versamento,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Importo €',
                                        selector: row => row.importo,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Anno validità',
                                        selector: row => row.anno_validita,
                                        sortable: true,
                                    },
                                    {
                                        name: 'Modalità di pagamento',
                                        selector: row => row.modalita,
                                        sortable: true,
                                    },
                                    {
                                        name: '',
                                        selector: row => <Fragment>
                                            <div className="btn-group" role="group"
                                                 aria-label="Button group with nested dropdown">
                                                <div className="btn-group" role="group">
                                                    <button id="btnGroupDrop1" type="button"
                                                            className="btn btn-dark dropdown-toggle"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa-solid fa-bars"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Approva"
                                                                    onClick={() => {
                                                                        versaQuota(row.id_quota);
                                                                    }}>
                                                                <i className="fas fa-check"/> Imposta come versata
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Fragment>,
                                        sortable: false,
                                        right: true,
                                    }
                                ]}
                                addPagination={true}
                                funzioneFiltro={(item, filtro) => {
                                    return (item.id_quota && item.id_quota.toString().toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.socio && item.socio.toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.importo && item.importo.toString().toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.anno_validita && item.anno_validita.toString().toLowerCase().includes(filtro.toLowerCase()))
                                        || (item.modalita && item.modalita.toLowerCase().includes(filtro.toLowerCase()))
                                        || (getDataFormattata(item.data_pagamento) && getDataFormattata(item.data_pagamento).toLowerCase().includes(filtro.toLowerCase()))
                                }}
                            >
                            </NsDataTable>
                        )}
                </Fragment>
            </Card>
        </CardContainer>
    )
}
