import React, {Fragment, useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import NsDataTable from "../../components/tabelle/NsDataTable";
import {
    getRigaInfoVeicolo
} from "../../utility/common";
import {RuoliContext} from "../../context/RuoliContext";
import ModalConfirm from "../../components/ModalConfirm";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {AuthContext} from "../../context/AuthContext";

function TabellaVeicoli({veicoli, totVeicoli, deleteVeicolo}) {

    const [show, setShow] = useState(false);
    const [veicoloSel, setVeicoloSel] = useState(null);

    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);
    const {isValido} = useContext(AuthContext);

    const history = useHistory();

    const eliminaVeicolo = () => {
        setShow(false);
        deleteVeicolo(veicoloSel);
    }

    return (
        <Fragment>
            <ModalConfirm
                show={show}
                handleClose={() => setShow(false)}
                handleOk={eliminaVeicolo}
                testo={"Sicuro di voler eliminare il veicolo: " + (veicoloSel
                    && (veicoloSel.marca + ' ' + veicoloSel.tipo)) + "?"}/>
            <NsDataTable
                data={veicoli}
                totRighe={totVeicoli}
                columns={[
                    {
                        name: 'Registro',
                        selector: row => row.registro,
                        sortable: true,
                    },
                    {
                        name: 'N° Registrazione',
                        selector: row => (row.numero_registrazione || '') + (row.suffisso || ''),
                        sortable: true,
                    },
                    {
                        name: 'Proprietario',
                        selector: row => (row.cognome || '') + ' ' + (row.nome || ''),
                        sortable: true,
                    },
                    {
                        name: 'Marca',
                        selector: row => row.marca,
                        sortable: true,
                    },
                    {
                        name: 'Tipo',
                        selector: row => row.tipo,
                        sortable: true,
                    },
                    {
                        name: 'Anno',
                        selector: row => row.anno,
                        sortable: true,
                    },
                    {
                        name: 'Targa',
                        selector: row => row.targa,
                        sortable: true,
                    },
                    {
                        name: 'Bollo',
                        selector: row => row.bollo_rfi == '1' ?
                            <span className={"fas fa-check ns-span--verde"} /> :
                            <span className={"fas fa-times ns-span--rosso"} />,
                        sortable: false,
                    },
                    {
                        name: '',
                        selector: row => <Fragment>
                            {
                                (row.modificabile === '1' || row.eliminabile === '1') && (!isRuoloSocio() || isValido()) &&
                                <Fragment>
                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                        <div className="btn-group" role="group">
                                            <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa-solid fa-bars"></i>
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                {
                                                    !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() &&
                                                    <li>
                                                        <Link to={"/info-veicolo/" + row.id_veicolo}
                                                              className="dropdown-item"
                                                              data-toggle="tooltip" title="Informazioni">
                                                            <i className="fas fa-eye"/> Informazioni
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    row.modificabile === '1' ?
                                                        <li>
                                                            <Link to={"/upd-veicolo/" + row.id_utente + '/' + row.id_veicolo}
                                                                  className="dropdown-item"
                                                                  data-toggle="tooltip" title="Modifica">
                                                                <i className="fas fa-pen"/> Modifica
                                                            </Link>
                                                        </li>
                                                        : ''
                                                }
                                                {
                                                    row.eliminabile === '1' ?
                                                        <li>
                                                            <button className="dropdown-item"
                                                                    data-toggle="tooltip" title="Elimina" onClick={() => {
                                                                setVeicoloSel(row)
                                                                setShow(true);
                                                            }}>
                                                                <i className="fas fa-trash"/> Elimina
                                                            </button>
                                                        </li>
                                                        : ''
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </Fragment>,
                        sortable: false,
                        right: true,
                    }
                ]}
                addPagination={false}
                addFiltri={false}
                addDetail={true}
                getDettaglio={(el) => {
                    return getRigaInfoVeicolo(el, isRuoloSocio());
                }}
                onRowDbClick={(row) => {
                    if (!isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia()) {
                        history.push("/info-veicolo/" + row.id_veicolo);
                    }
                }}
            >
            </NsDataTable>
        </Fragment>
    );
}

export default TabellaVeicoli;
