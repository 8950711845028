import React, {Fragment} from "react";

export default function RichiestaPagamentoOk() {

    return (
        <Fragment>
            <div className={"text-center mt-4 mb-4 rfi-titolo"}>
                <h1>Grazie per aver completato la tua richiesta di pagamento</h1>
                <h3>Riceverai una E-mail appena la nostra Segreteria avrà verificato l'avvenuto pagamento.</h3>
                <h4>Ricordati di allegare la ricevuta dell'avvenuto pagamento nell'apposita sezione in home page</h4>
            </div>
        </Fragment>
    )
}
