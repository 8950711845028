import React, {Fragment, useContext, useEffect, useState} from "react";
import {
    getDataFormattata,
} from "../../utility/common";
import NsDataTable from "../../components/tabelle/NsDataTable";
import ModalConfirm from "../../components/ModalConfirm";
import ModalMsg from "../../components/ModalMsg";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import ModalDocQuota from "./ModalDocQuota";

export default function TabellaQuoteTmpSocio({usr, quote, updateQuote}) {

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [quotaSel, setQuotaSel] = useState();
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDocumenti, setShowModalDocumenti] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    useEffect(() => {
    }, [usr]);

    const visualizzaDocumento = (quota) => {
        startChiamata()

        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + quota.id_quota + "/documento/" + quota.id_documento_quota).then((response) => {
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    var byteCharacters = atob(resp.contenuto);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], {type: resp.content_type + ';base64'});
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            }
            endChiamata()
        });
    }

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <ModalConfirm
                show={showModalDel}
                handleClose={() => setShowModalDel(false)}
                handleOk={() => {
                    setShowModalDel(false);
                    startChiamata()
                    axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/" + quotaSel.id_quota + "/documento/" + quotaSel.id_documento_quota).then((response) => {
                        endChiamata();
                        updateQuote();
                    });
                }}
                testo={"Sicuro di voler eliminare la ricevuta?"}/>
            <ModalDocQuota
                idQuota={quotaSel && quotaSel.id_quota}
                show={showModalDocumenti}
                handleClose={() => {
                    setShowModalDocumenti(false);
                    updateQuote();
                }}
                submitOk={updateQuote}
            />
            <NsDataTable
                data={quote}
                columns={[
                    {
                        name: 'Data',
                        selector: row => getDataFormattata(row.data_pagamento),
                        sortable: true,
                    },
                    {
                        name: 'Oggetto',
                        selector: row => row.tipo_versamento,
                        sortable: true,
                    },
                    {
                        name: 'Importo €',
                        selector: row => row.importo,
                        sortable: true,
                    },
                    {
                        name: 'Modalità di pagamento',
                        selector: row => row.modalita,
                        sortable: true,
                    },
                    {
                        name: 'Ricevuta presente',
                        selector: row => row.id_documento_quota ? <i className="fas fa-check ns-span--verde"/> :
                            <i className="fas fa-times ns-span--rosso"/>,
                        sortable: true,
                    },
                    {
                        name: '',
                        selector: row => <Fragment>
                            <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <div className="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-bars"></i>
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        {
                                            row.id_documento_quota ?
                                                <Fragment>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Visualizza ricevuta"
                                                                onClick={() => {
                                                                    visualizzaDocumento(row)
                                                                }}>
                                                            <i className="fas fa-eye"/> Visualizza ricevuta
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Elimina ricevuta"
                                                                onClick={() => {
                                                                    setQuotaSel(row)
                                                                    setShowModalDel(true);
                                                                }}>
                                                            <i className="fas fa-trash"/> Elimina ricevuta
                                                        </button>
                                                    </li>
                                                </Fragment>
                                                :
                                                <li>
                                                    <li>
                                                        <button className="dropdown-item"
                                                                data-toggle="tooltip" title="Allega ricevuta"
                                                                onClick={() => {
                                                                    setQuotaSel(row);
                                                                    setShowModalDocumenti(true);
                                                                }}>
                                                            <i className="fas fa-upload"/> Allega ricevuta
                                                        </button>
                                                    </li>
                                                </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Fragment>,
                        sortable: false,
                        right: true,
                    }
                ]}
                addPagination={true}
                addFiltri={false}
            />
        </Fragment>
    )
}
