import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";
import {TranslateContext} from "../../../context/TranslateContext";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";

function ModificaCausaleMov() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [categorie, setCategorie] = useState([]);
    const [tipiMovimento, setTipiMovimento] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getCausale() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/causale-movimento/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_causale_movimento", val.id_causale_movimento);
                    formikRef.current.setFieldValue("id_categoria_causale", val.id_categoria_causale);
                    formikRef.current.setFieldValue("descrizione", val.descrizione);
                    formikRef.current.setFieldValue("id_tipo_movimento", val.id_tipo_movimento);
                    formikRef.current.setFieldValue("sort", val.sort);
                }
                endChiamata()
            });
    }

    function getCategorie() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/categoria-causale/")
            .then((response) => {
                setCategorie(response.data.data);
                endChiamata()
            });
    }

    function getTipiMovimento() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/tipo-movimento/")
            .then((response) => {
                setTipiMovimento(response.data.data);
                endChiamata()
            });
    }

    useEffect(() => {
        if (loadingData) {
            getCategorie();
            getTipiMovimento();

            if (id && id !== '') {
                getCausale();
            }
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        id_causale_movimento: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        id_categoria_causale: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        id_tipo_movimento: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        descrizione: Yup.string()
            .max(80, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        sort: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
    });

    return (
        <Card
            icon={'fas fa-stream'}
            title={id ? 'Modifica Causale Movimento' : 'Inserisci Causale Movimento'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_causale_movimento: '',
                    id_categoria_causale: '',
                    id_tipo_movimento: '',
                    descrizione: '',
                    sort: '0'
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/causale-movimento/" + id,
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/causale-movimento/",
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-12">
                            <label htmlFor="id_causale_movimento" className="form-label">Codice causale*:</label>
                            <Field
                                name="id_causale_movimento"
                                className="form-control"
                                readOnly={id}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_causale_movimento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="descrizione" className="form-label">Descrizione*:</label>
                            <Field
                                name="descrizione"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"descrizione"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_categoria_causale" className="form-label">Categoria causale*:</label>
                            <Field
                                as={"select"}
                                name="id_categoria_causale"
                                className="form-control form-select"
                                value={values['id_categoria_causale']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("id_categoria_causale", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                {
                                    categorie && categorie.map(cat => <option
                                        value={cat.id_categoria_causale}>{cat.descrizione}</option>)
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_categoria_causale"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_tipo_movimento" className="form-label">Tipo movimento*:</label>
                            <Field
                                as={"select"}
                                name="id_tipo_movimento"
                                className="form-control form-select"
                                value={values['id_tipo_movimento']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("id_tipo_movimento", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                {
                                    tipiMovimento && tipiMovimento.map(tipo => <option
                                        value={tipo.id_tipo_movimento}>{tipo.descrizione}</option>)
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_tipo_movimento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="sort" className="form-label">Ordinamento*:</label>
                            <Field
                                name="sort"
                                className="form-control"
                                type={"number"}
                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"sort"}
                                              submitCount={submitCount}/>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaCausaleMov;