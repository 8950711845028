import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import {useHistory} from 'react-router-dom'
import Card from "../../../components/Card";
import ModalMsg from "../../../components/ModalMsg";
import {LoaderContext} from "../../../context/LoaderContext";
import {Field, Form, Formik} from "formik";
import NsDatePicker from "../../../components/NsDatePicker";
import {
    convertiDateInDataPerDb,
    TIPO_STAMPA_MOV_CAUSALE,
    TIPO_STAMPA_MOV_CONTO,
    TIPO_STAMPA_MOV_DATA,
    TIPO_STAMPA_MOV_NOMINATIVO
} from "../../../utility/common";

function StampaMovimenti() {

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [periodo, setPeriodo] = useState('');
    const [dal, setDal] = useState(getPrimoGiornoMesseCorrente());
    const [al, setAl] = useState(new Date());

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    const history = useHistory();

    function getPrimoGiornoMesseCorrente() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }

    function stampaMovimenti(tipo) {
        let f = '?pag=-1';
        if (dal) {
            f += '&data_da=' + convertiDateInDataPerDb(dal);
        }
        if (al) {
            f += '&data_a=' + convertiDateInDataPerDb(al);
        }
        if (tipo && tipo !== '') {
            f += '&tipo_stampa=' + tipo;
        }

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/stampa/" + f).then((response) => {
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data.data;
                if (resp) {
                    var a = document.createElement("a"); //Create <a>
                    a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
                    a.download = resp.nome_file; //File name Here
                    a.click(); //Downloaded file
                }
            }
            endChiamata()
        });
    }

    useEffect(() => {
    }, []);

    return (
        <Card
            icon={'fas fa-print'}
            title={"Stampa Movimenti"}
            getBottoniDx={() =>
                <button
                    type={"button"}
                    className="btn btn-card"
                    onClick={() => {
                        history.goBack()
                    }}
                >
                    <i className="fas fa-angle-left"/> Indietro
                </button>
            }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                onSubmit={stampaMovimenti}
                initialValues={{}}>
                <Form>
                    <div className={'row pb-2'}>
                        <h5 className={"mt-2 mb-4"}>Selezionare il periodo da stampare</h5>
                        <div className="col-md-12">
                            <Field
                                as={"select"}
                                name="periodo"
                                className="form-control form-select"
                                value={periodo}
                                onChange={(event) => {
                                    let per = event.target.value;
                                    setPeriodo(per)

                                    let today = new Date();

                                    if (per == 1) {
                                        setDal(getPrimoGiornoMesseCorrente());
                                        setAl(today);
                                    } else if (per == 2) {
                                        setDal(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                                        setAl(new Date(today.getFullYear(), today.getMonth(), 0));
                                    } else if (per == 3) {
                                        setDal(new Date(today.getFullYear(), 0, 1));
                                        setAl(today);
                                    }
                                }}
                            >
                                <option value={'1'}>Mese corrente</option>
                                <option value={'2'}>Mese scorso</option>
                                <option value={'3'}>Anno corrente</option>
                            </Field>
                        </div>
                        <h5 className={"mt-4 mb-4"}>...o impostare le date di inizio/fine del periodo da
                            stampare...</h5>
                        <div className="col-md-6">
                            <label htmlFor="dal" className="form-label">Dal:</label>
                            <NsDatePicker
                                nome={"dal"}
                                dataSelezionata={dal}
                                maxDate={new Date()}
                                setSelectedDate={(date, event) => {
                                    setDal(date)
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="al" className="form-label">al:</label>
                            <NsDatePicker
                                nome={"al"}
                                dataSelezionata={al}
                                maxDate={new Date()}
                                setSelectedDate={(date, event) => {
                                    setAl(date)
                                }}
                            />
                        </div>
                        <h5 className={"mt-4 mb-4"}>...e selezionare il tipo di stampa</h5>
                        <div className="col-md-12 text-center">
                            <button name={"xcausale"}
                                    type={"button"}
                                    className={"btn btn-principal"}
                                    onClick={() => {
                                        stampaMovimenti(TIPO_STAMPA_MOV_CAUSALE);
                                    }}>
                                Riepilogo movimenti per Causale
                            </button>
                            <button name={"xdata"}
                                    type={"button"}
                                    className={"btn btn-principal"}
                                    onClick={() => {
                                        stampaMovimenti(TIPO_STAMPA_MOV_DATA);
                                    }}>
                                Dettaglio movimenti per data
                            </button>
                            <button name={"xconto"}
                                    type={"button"}
                                    className={"btn btn-principal"}
                                    onClick={() => {
                                        stampaMovimenti(TIPO_STAMPA_MOV_CONTO);
                                    }}>
                                Dettaglio movimenti per conto
                            </button>
                            <button name={"xnominativo"}
                                    type={"button"}
                                    className={"btn btn-principal"}
                                    onClick={() => {
                                        stampaMovimenti(TIPO_STAMPA_MOV_NOMINATIVO);
                                    }}>
                                Dettaglio movimenti per Nominativo
                            </button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </Card>
    );
}

export default StampaMovimenti;