import React, {useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {
    CRUSCOTTO_RINNOVI_MESE,
    CRUSCOTTO_SOCI_PER_TIPO_SOCIO,
    CRUSCOTTO_SOCI_QUOTE,
    CRUSCOTTO_SOCI_RINNOVARE,
    CRUSCOTTO_VEICOLI_ANNO,
    CRUSCOTTO_VEICOLI_ANNO_BOLLO, CRUSCOTTO_VEICOLI_MARCA,
    CRUSCOTTO_VEICOLI_SOCI_BOLLO,
    CRUSCOTTO_VEICOLI_SOCI_BOLLO_MESE
} from "../../utility/common";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";

function CruscottoVeicoli({tipo}) {

    const [anno, setAnno] = useState(new Date().getFullYear());
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    useEffect(() => {
    }, [tipo]);

    function stampaCruscotto(tipoStampa) {
        let f = '?tipo=' + tipo;
        if (anno) {
            f += '&anno=' + anno;
        }
        if (tipoStampa && tipoStampa !== '') {
            f += '&tipo_stampa=' + tipoStampa;
        }

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/cruscotto/" + f).then((response) => {
            if (response.data.result === -1) {
                setModalMsg(response.data.description)
                setShow(true)
            } else {
                let resp = response.data;
                if (resp) {
                    var a = document.createElement("a"); //Create <a>
                    a.href = "data:" + resp.content_type + ";base64," + resp.contenuto; //Image Base64 Goes here
                    a.download = resp.nome_file; //File name Here
                    a.click(); //Downloaded file
                }
            }
            endChiamata()
        });
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-chart-bar'}
                title={'Cruscotto associativo ' + (tipo == '1' ? 'RFI' : 'ABARTH')}
                getBottoniDx={() => {
                }}>
                <ModalMsg
                    show={show}
                    handleClose={() => setShow(false)}
                    testo={modalMsg}/>
                <h4>Selezionare il tipo di documento da stampare</h4>
                <div className={"row mt-4"}>
                    <div className={"col-md-6 btn-group-vertical"}>
                        {/* Soci */}
                        <h5>Situazione dei soci</h5>

                        <div className={"mb-4"}>
                            <label htmlFor="anno" className="form-label">Anno sociale:</label>
                            <input
                                type="number"
                                name="anno"
                                id="anno"
                                className="form-control rfi-number-no-arrows"
                                value={anno}
                                onChange={e => setAnno(e.target.value)}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>

                        <button name={"uXannoReg"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_SOCI_PER_TIPO_SOCIO);
                                }}>
                            Numero Soci {(tipo == '1' ? 'RFI' : 'ABARTH')} per tipo socio
                        </button>
                        <button name={"sociQuote"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_SOCI_QUOTE);
                                }}>
                            Soci e quote versate
                        </button>
                        <button name={"sociRinnovoQuote"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_SOCI_RINNOVARE);
                                }}>
                            Soci {(tipo == '1' ? 'RFI' : 'ABARTH')} che devono rinnovare la quota
                        </button>
                        <button name={"rinnoviMese"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_RINNOVI_MESE);
                                }}>
                            Rinnovi {(tipo == '1' ? 'RFI' : 'ABARTH')} per mese
                        </button>
                        <button name={"vSocioBollo"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_VEICOLI_SOCI_BOLLO);
                                }}>
                            Veicoli soci {(tipo == '1' ? 'RFI' : 'ABARTH')} per Tipo di bollo
                        </button>
                        <button name={"vSocioBolloMese"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_VEICOLI_SOCI_BOLLO_MESE);
                                }}>
                            Veicoli soci {(tipo == '1' ? 'RFI' : 'ABARTH')} per Tipo di bollo e mese registrazione
                        </button>
                    </div>
                    <div className={"col-md-6 btn-group-vertical"}>
                        {/* Veicoli */}
                        <h5>Cruscotto Veicoli e dati statistici</h5>

                        <button name={"vXannoReg"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_VEICOLI_ANNO);
                                }}>
                            Veicoli per anno registrazione
                        </button>
                        <button name={"vXannoRegBollo"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_VEICOLI_ANNO_BOLLO);
                                }}>
                            Veicoli per anno registrazione e tipo di bollo
                        </button>
                        <button name={"vXmarca"}
                                type={"button"}
                                className={"btn btn-principal"}
                                onClick={() => {
                                    stampaCruscotto(CRUSCOTTO_VEICOLI_MARCA);
                                }}>
                            Veicoli per marca
                        </button>
                    </div>
                </div>
            </Card>
        </CardContainer>
    );
}

export default CruscottoVeicoli;
