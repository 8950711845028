import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";
import {TranslateContext} from "../../../context/TranslateContext";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";
import NsDatePicker from "../../../components/NsDatePicker";
import {convertiTimestampInDataPerDb} from "../../../utility/common";
import Select from "react-select";
import NSSelectAutocomplete from "../../../components/NSSelectAutocomplete";

function ModificaMovimenti() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [tipiMovimento, setTipiMovimento] = useState([]);
    const [conti, setConti] = useState([]);
    const [causali, setCausali] = useState([]);
    const [causale, setCausale] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getMovimento() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_movimento", val.id_movimento);
                    formikRef.current.setFieldValue("id_tipo_movimento", val.id_tipo_movimento);
                    formikRef.current.setFieldValue("id_conto", val.id_conto);
                    formikRef.current.setFieldValue("id_causale_movimento", val.id_causale_movimento);
                    formikRef.current.setFieldValue("data_movimento", new Date(val.data_movimento));
                    formikRef.current.setFieldValue("importo", val.importo);
                    formikRef.current.setFieldValue("nominativo", val.nominativo);
                    formikRef.current.setFieldValue("rif_fattura", val.rif_fattura);
                    formikRef.current.setFieldValue("note", val.note);
                }
                endChiamata()
            });
    }

    function getTipiMovimento() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/tipo-movimento/")
            .then((response) => {
                setTipiMovimento(response.data.data);
                endChiamata()
            });
    }

    function getConti() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/conto/")
            .then((response) => {
                setConti(response.data.data);
                endChiamata()
            });
    }

    function getCausali() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/causale-movimento/")
            .then((response) => {
                setCausali(response.data.data);
                endChiamata()
            });
    }

    useEffect(() => {
        if (loadingData) {
            getTipiMovimento();
            getConti();
            getCausali();

            if (id && id !== '') {
                getMovimento();
            } else {
                formikRef.current.setFieldValue("data_movimento", new Date())
            }
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        id_tipo_movimento: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio'))
            .nullable(),
        id_conto: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio'))
            .nullable(),
        id_causale_movimento: Yup.string()
            .max(10, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio'))
            .nullable(),
        data_movimento: Yup.string()
            .required(traduci('Questo campo è obbligatorio'))
            .nullable(),
        importo: Yup.string()
            .required(traduci('Questo campo è obbligatorio'))
            .nullable(),
        nominativo: Yup.string()
            .max(100, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        rif_fattura: Yup.string()
            .max(50, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
        note: Yup.string()
            .max(255, traduci('Attenzione! Hai inserito troppi caratteri'))
            .nullable(),
    });

    return (
        <Card
            icon={'far fa-credit-card'}
            title={id ? 'Modifica Movimento' : 'Inserisci Movimento'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
        }
        >
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_tipo_movimento: '',
                    id_conto: '',
                    id_causale_movimento: '',
                    data_movimento: new Date(),
                    importo: '',
                    nominativo: '',
                    rif_fattura: '',
                    note: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let newMov = values;
                    newMov['data_movimento'] = convertiTimestampInDataPerDb(newMov['data_movimento'])

                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/" + id,
                                newMov)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/",
                                newMov)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-12">
                            <label htmlFor="id_tipo_movimento" className="form-label">Tipo di movimento*:</label>
                            <Field
                                as={"select"}
                                name="id_tipo_movimento"
                                className="form-control form-select"
                                value={values['id_tipo_movimento']}
                                disabled={id}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("id_tipo_movimento", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                {
                                    tipiMovimento && tipiMovimento.map(tipo => <option key={tipo.id_tipo_movimento}
                                        value={tipo.id_tipo_movimento}>{tipo.descrizione}</option>)
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_tipo_movimento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className={"col-md-6"}>
                            <label htmlFor="data_movimento" className="form-label">Data pagamento/versamento*:</label>
                            <NsDatePicker
                                nome={"data_movimento"}
                                dataSelezionata={values["data_movimento"]}
                                setSelectedDate={(date, event) => {
                                    formikRef.current.setFieldValue("data_movimento", date)
                                }}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"data_movimento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="importo" className="form-label">Importo (€)*:</label>
                            <Field
                                name="importo"
                                className="form-control rfi-number-no-arrows"
                                type={"number"}
                                step="0.01"
                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                validate={(value) => {
                                    if (parseFloat(value) === 0) {
                                        return 'Non è possibile inserire un movimento con importo di 0,00€';
                                    }
                                }}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"importo"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_conto" className="form-label">Conto*:</label>
                            <Field
                                as={"select"}
                                name="id_conto"
                                className="form-control form-select"
                                value={values['id_conto']}
                                onChange={(event) => {
                                    formikRef.current.setFieldValue("id_conto", event.target.value)
                                }}
                            >
                                <option value={''}></option>
                                {
                                    conti && conti.map(conto => <option key={conto.id_conto}
                                        value={conto.id_conto}>{conto.descrizione}</option>)
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_conto"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_causale_movimento" className="form-label">Causale movimento*:</label>
                            <NSSelectAutocomplete
                                name={"id_causale_movimento"}
                                formikRef={formikRef}
                                options={causali ? causali.map(cau => {
                                    return {
                                        label: cau.descrizione + ' - [' + cau.id_causale_movimento + ']',
                                        value: cau.id_causale_movimento,
                                    };
                                }) : []}
                                startVal={values["id_causale_movimento"]}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_causale_movimento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="nominativo" className="form-label">Nominativo:</label>
                            <Field
                                name="nominativo"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"nominativo"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="rif_fattura" className="form-label">Riferimento fattura:</label>
                            <Field
                                name="rif_fattura"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"rif_fattura"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="note" className="form-label">Note:</label>
                            <Field
                                name="note"
                                className="form-control"
                                as={"textarea"}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"note"}
                                              submitCount={submitCount}/>
                        </div>

                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaMovimenti;