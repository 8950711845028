import React, {Fragment, useContext, useEffect, useState} from "react";
import {
    Link, useLocation
} from "react-router-dom";
import {AuthContext} from "../context/AuthContext";
import {RuoliContext} from "../context/RuoliContext";
import Translate from "./Translate";
import {RichiedentiContext} from "../context/RichiedentiContext";
import {STATO_COMPLETO} from "../utility/common";
import axios from "axios";
import {LoaderContext} from "../context/LoaderContext";
import {CertificatiContext} from "../context/CertificatiContext";
import ModalMsg from "./ModalMsg";

function Menu() {

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [infoPagamento, setInfoPagamento] = useState('');
    const [titoloMsg, setTitoloMsg] = useState('');
    const {user, handleLogout} = useContext(AuthContext);
    const {
        isRuoloAdmin,
        isRuoloSegreteria,
        isRuoloCommissario,
        isRuoloPresidente,
        isRuoloSocio,
        isRuoloClub,
        isRuoloAgenzia
    } = useContext(RuoliContext);
    const {numRichiedenti} = useContext(RichiedentiContext);
    const {numCertificati} = useContext(CertificatiContext);
    const location = useLocation();

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getInfoPagamento() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/api/rfi/utente/${user.id_utente}/info-pagamento/`).then((response) => {
            setInfoPagamento(response.data.pagamentiRichiesti)
            endChiamata()
        });
    }

    useEffect(() => {
        if (user && isRuoloSocio()) {
            getInfoPagamento()
        }
    }, [user, isRuoloSocio()]);

    return (
        <Fragment>
            <ModalMsg
                show={show}
                titolo={titoloMsg}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <div className=" fixed-top">
                <div className="row barratop">
                    <div className="col-md-6">
                        <div className="p-2">
                            <b><i className="fa-solid fa-phone ns-icon-link"/> TEL.</b> 011-5629881 | <b><i
                            className="fa-solid fa-envelope ns-icon-link"/> EMAIL</b> info@registrofiat.it
                        </div>
                    </div>
                    <div className="col-md-6 text-end ns-utente-info">
                        {
                            user ? (
                                <div>
                                    {
                                        isRuoloAdmin() || isRuoloSegreteria() ?
                                            <Fragment>
                                                <Link to={"/list-nuovi-soci"}>
                                                    <i className="fas fa-user-plus pe-4 ns-icon-link"> <span
                                                        className="badge badge-important">{numRichiedenti}</span></i>
                                                </Link>
                                                <Link to={"/list-certificati"}>
                                                    <i className="fas fa-car pe-4 ns-icon-link"> <span
                                                        className="badge badge-important">{numCertificati}</span></i>
                                                </Link>
                                            </Fragment>
                                            : ''
                                    }
                                    <span><i className="fas fa-user-lock"/>
                                        {
                                            ` ${user.nome}`
                                        }
                                    </span>
                                    <Link to="/" className="btn btn-dark" onClick={() => handleLogout()}>
                                        <i className="fa-solid fa-right-from-bracket"/> Esci
                                    </Link>
                                </div>) : <div/>
                        }
                    </div>
                </div>
                <nav className="navbar navbar-expand-md navbar-light">

                    <div className="container-fluid">
                        <img src={"logo.png"} className={"ns-logo-icon"}/>
                        {
                            location.pathname != '/' && location.pathname != '/registrati/1' && location.pathname != '/registrati/2' && location.pathname.indexOf('/reset-password') !== 0 ?
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarCollapse"
                                        aria-controls="navbarCollapse" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"/>
                                </button>
                                : ''
                        }
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            {
                                user ? (
                                    <ul className="navbar-nav menu-centro">
                                        <li className="nav-item">
                                            <Link className="nav-link" aria-current="page" to="/home">
                                                <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                                    <i className="fas fa-home"/>
                                                </div>
                                            </Link>
                                        </li>
                                        {
                                            isRuoloAdmin() || isRuoloSegreteria() ?
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle" href="#" id="utenti-menu"
                                                       role="button"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-cogs"/> <Translate>Configura</Translate>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="utenti-menu">
                                                        <li>
                                                            <Link to="/conf-utenti" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-user-alt"/>
                                                                    <Translate>Utenti</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <hr className="dropdown-divider"/>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-club" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-users"/>
                                                                    <Translate>Club</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-agenzie" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-users"/>
                                                                    <Translate>Agenzie</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-titoli" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-tag"/>
                                                                    <Translate>Titoli</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-tipi-socio" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-user-tag"/>
                                                                    <Translate>Tipi di Socio</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <hr className="dropdown-divider"/>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-registri-veic" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-folder"/>
                                                                    <Translate>Registri di Veicoli</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-tipi-carr" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-car"/>
                                                                    <Translate>Tipi Carrozzeria</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-colori" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-palette"/>
                                                                    <Translate>Colori</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <hr className="dropdown-divider"/>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-modalita-pag"
                                                                  className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-money-check-alt"/>
                                                                    <Translate>Modalità di Pagamento</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-conti" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-hand-holding-usd"/>
                                                                    <Translate>Conti</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-tipi-mov" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-exchange-alt"></i>
                                                                    <Translate>Tipi Movimenti</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-cat-causali"
                                                                  className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-tags"/>
                                                                    <Translate>Categorie Causali</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-causali-mov"
                                                                  className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-stream"/>
                                                                    <Translate>Causali Movimenti</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        {
                                                            isRuoloAdmin() ?
                                                                <Fragment>
                                                                    <li>
                                                                        <hr className="dropdown-divider"/>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/conf-parametri"
                                                                              className="dropdown-item">
                                                                            <div data-bs-toggle="collapse"
                                                                                 data-bs-target=".navbar-collapse.show">
                                                                                <i className="fas fa-sliders"/>
                                                                                <Translate>Impostazioni</Translate>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                </Fragment>
                                                                : ''
                                                        }
                                                    </ul>
                                                </li>
                                                : ''
                                        }
                                        {
                                            isRuoloAdmin() || isRuoloSegreteria() ?
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle" href="#" id="utenti-menu"
                                                       role="button"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-user"/> <Translate>Soci</Translate>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="utenti-menu">
                                                        <li>
                                                            <Link to="/list-persone" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-users"/>
                                                                    <Translate>Archivio Soci</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <hr className="dropdown-divider"/>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-nuovi-soci" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-folder-plus"/>
                                                                    <Translate>Nuove registrazioni</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-quote-temp" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-credit-card"/>
                                                                    <Translate>Quote da approvare</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-quote-asi" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-credit-card"/>
                                                                    <Translate>Quote ASI da versare</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        {
                                                            !isRuoloClub() && !isRuoloAgenzia() &&
                                                            <Fragment>
                                                                <li>
                                                                    <Link to="/stampe-fine-giornata"
                                                                          className="dropdown-item">
                                                                        <div data-bs-toggle="collapse"
                                                                             data-bs-target=".navbar-collapse.show">
                                                                            <i className="fas fa-print"/>
                                                                            <Translate>Stampe di fine
                                                                                giornata</Translate>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <hr className="dropdown-divider"/>
                                                                </li>
                                                                <li>
                                                                    <Link to="/cruscotto-rfi" className="dropdown-item">
                                                                        <div data-bs-toggle="collapse"
                                                                             data-bs-target=".navbar-collapse.show">
                                                                            <i className="fas fa-chart-bar"/>
                                                                            <Translate>Cruscotto RFI</Translate>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/cruscotto-abarth"
                                                                          className="dropdown-item">
                                                                        <div data-bs-toggle="collapse"
                                                                             data-bs-target=".navbar-collapse.show">
                                                                            <i className="fas fa-chart-bar"/>
                                                                            <Translate>Cruscotto ABARTH</Translate>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            </Fragment>
                                                        }
                                                    </ul>
                                                </li> : ''
                                        }
                                        {
                                            (isRuoloClub() || isRuoloAgenzia()) &&
                                            <Fragment>
                                                <li className="nav-item">
                                                    <Link className="nav-link" aria-current="page"
                                                          to="/list-persone">
                                                        <div data-bs-toggle="collapse"
                                                             data-bs-target=".navbar-collapse.show">
                                                            <i className="fas fa-users"/> Soci
                                                        </div>
                                                    </Link>
                                                </li>
                                            </Fragment>
                                        }
                                        {
                                            (!isRuoloSocio() || (user && user.stato == STATO_COMPLETO)) && !isRuoloCommissario() && !isRuoloPresidente() ?
                                                <Fragment>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" aria-current="page"
                                                              to="/list-veicoli">
                                                            <div data-bs-toggle="collapse"
                                                                 data-bs-target=".navbar-collapse.show">
                                                                <i className="fas fa-car"/> Veicoli
                                                            </div>
                                                        </Link>
                                                    </li>
                                                </Fragment>
                                                : (isRuoloSocio() && user && user.stato != STATO_COMPLETO &&
                                                    <Fragment>
                                                        <li className="nav-item">
                                                            <button type={"button"} className="nav-link nav-link-btn"
                                                                    aria-current="page"
                                                                    onClick={() => {
                                                                        setTitoloMsg("Veicoli");
                                                                        setModalMsg("In questa pagina potrai caricare i tuoi veicoli, non appena la tua richiesta di adesione verrà approvata.");
                                                                        setShow(true);
                                                                    }}>
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                <i className="fas fa-car"/> Veicoli
                                                                </div>
                                                            </button>
                                                        </li>
                                                    </Fragment>
                                                )
                                        }
                                        {
                                            !isRuoloSocio() || (user && user.stato == STATO_COMPLETO) ?
                                                <Fragment>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" aria-current="page"
                                                              to="/list-certificati">
                                                            <div data-bs-toggle="collapse"
                                                                 data-bs-target=".navbar-collapse.show">
                                                                <i className="fas fa-file-signature"/> Certificati
                                                            </div>
                                                        </Link>
                                                    </li>
                                                </Fragment>
                                                : (isRuoloSocio() && user && user.stato != STATO_COMPLETO &&
                                                    <Fragment>
                                                        <li className="nav-item">
                                                            <button type={"button"} className="nav-link nav-link-btn"
                                                                    aria-current="page"
                                                                    onClick={() => {
                                                                        setTitoloMsg("Certificati");
                                                                        setModalMsg("In questa pagina potrai richiedere e visualizzare i tuoi certificati, non appena la tua richiesta di adesione verrà approvata.");
                                                                        setShow(true);
                                                                    }}>
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-file-signature"/> Certificati
                                                                </div>
                                                            </button>
                                                        </li>
                                                    </Fragment>
                                                )
                                        }
                                        {
                                            isRuoloAdmin() || isRuoloSegreteria() ?
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle" href="#" id="contabilita"
                                                       role="button"
                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-credit-card"/>
                                                        <Translate>Contabilità</Translate>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="contabilita">
                                                        <li>
                                                            <Link to="/list-movimenti" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="far fa-credit-card"/>
                                                                    <Translate>Movimenti</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/list-transazioni" className="dropdown-item">
                                                                <div data-bs-toggle="collapse"
                                                                     data-bs-target=".navbar-collapse.show">
                                                                    <i className="fas fa-credit-card-alt"/>
                                                                    <Translate>Transazioni</Translate>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                : ''
                                        }
                                        {
                                            isRuoloSocio() && user &&
                                            <Fragment>
                                                {
                                                    user.stato == STATO_COMPLETO ?
                                                        <Fragment>
                                                            <li className="nav-item">
                                                                <Link className="nav-link" aria-current="page"
                                                                      to="/list-quote">
                                                                    <div data-bs-toggle="collapse"
                                                                         data-bs-target=".navbar-collapse.show">
                                                                        <i className="fas fa-shopping-bag"/> Le tue
                                                                        quote
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </Fragment>
                                                        :
                                                        <Fragment>
                                                            {/*
                                                            Utente non valido
                                                            */}
                                                            <li className="nav-item">
                                                                <button type={"button"}
                                                                        className="nav-link nav-link-btn"
                                                                        aria-current="page"
                                                                        onClick={() => {
                                                                            setTitoloMsg("Le tue quote");
                                                                            setModalMsg("In questa pagina potrai consultare lo stato e la scadenza delle tue quote, non appena la tua richiesta di adesione verrà approvata.");
                                                                            setShow(true);
                                                                        }}>
                                                                    <div data-bs-toggle="collapse"
                                                                         data-bs-target=".navbar-collapse.show">
                                                                        <i className="fas fa-shopping-bag"/> Le tue
                                                                        quote
                                                                    </div>
                                                                </button>
                                                            </li>
                                                        </Fragment>
                                                }
                                                {
                                                    user.stato == STATO_COMPLETO ?
                                                        <Fragment>
                                                            {
                                                                infoPagamento && infoPagamento.length > 0 &&
                                                                <li className="nav-item">
                                                                    <Link className="nav-link" aria-current="page"
                                                                          to="/pagamenti">
                                                                        <div data-bs-toggle="collapse"
                                                                             data-bs-target=".navbar-collapse.show">
                                                                            <i className="fas fa-credit-card"/> Pagamenti
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            }
                                                        </Fragment>
                                                        :
                                                        ''
                                                }
                                            </Fragment>
                                        }
                                        {
                                            !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() &&
                                            <li className="nav-item">
                                                <Link className="nav-link" aria-current="page" to="/list-note">
                                                    <div data-bs-toggle="collapse"
                                                         data-bs-target=".navbar-collapse.show">
                                                        <i className="fas fa-sticky-note"/> Note
                                                    </div>
                                                </Link>
                                            </li>
                                        }
                                    </ul>
                                ) : ''
                            }

                        </div>
                    </div>
                </nav>
            </div>
        </Fragment>
    );
}

export default Menu;
