import React, {Fragment, useEffect} from "react";
import LabelErroreCampo from "./LabelErroreCampo";
import LabelWithInfo from "./LabelWithInfo";

export default function FieldContainer({
                                           children,
                                           name,
                                           label,
                                           infoLabel,
                                           errors,
                                           touched,
                                           submitCount,
                                           obbligatorio = false
                                       }) {

    useEffect(() => {
    }, []);

    return (
        <Fragment>
            {
                infoLabel && infoLabel !== '' ?
                    <LabelWithInfo
                        name={name}
                        info={infoLabel}
                    >
                        {obbligatorio === true ? label + '*' : label}
                    </LabelWithInfo>
                    :
                    <label htmlFor={name} className="form-label">{obbligatorio === true ? label + '*' : label}:</label>
            }
            {
                children
            }
            <LabelErroreCampo errors={errors} touched={touched}
                              name={name}
                              submitCount={submitCount}/>
        </Fragment>
    );
}