import React, {Fragment} from "react";

function PagamentoOk() {

    return (
        <Fragment>
            <div className={"text-center mt-4 mb-4 rfi-titolo"}>
                <h1>Pagamento avvenuto con successo</h1>
            </div>
        </Fragment>
    )
}

export default PagamentoOk;
