import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import ModalConfirm from "../../../components/ModalConfirm";
import Pager from "../../../components/Pager";
import NsDataTable from "../../../components/tabelle/NsDataTable";
import {getTimestampFormattato} from "../../../utility/common";

function ConfUtenti() {

    const [filtro, setFiltro] = useState('');
    const [email, setEmail] = useState('');
    const [ruolo, setRuolo] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [utenti, setUtenti] = useState([]);
    const [totUtenti, setTotUtenti] = useState(0);
    const [ruoli, setRuoli] = useState([]);
    const [utenteSel, setUtenteSel] = useState(null);
    const [show, setShow] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();

    let debounce = null;

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getFiltroUtenti() {
        let f = '?pag=' + pagina;
        if (filtro && filtro !== '') {
            f += '&filtro=' + filtro;
        }
        if (email && email !== '') {
            f += '&email=' + email;
        }
        if (ruolo && ruolo !== '') {
            f += '&ruolo=' + ruolo;
        }
        return f;
    }

    function getUtenti() {
        let f = getFiltroUtenti();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + f).then((response) => {
            setUtenti(response.data.data);
            setTotPagine(response.data.pages)
            setTotUtenti(response.data.tot)
            setLoadingData(false);
            endChiamata()
        });
    }

    function getRuoli() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/ruolo/?hideSocio=1").then((response) => {
            setRuoli(response.data.data);
            endChiamata()
        });
    }

    useEffect(() => {
        getUtenti();

        if (loadingData === true) {
            getRuoli()
        }

        return () => {
            if (debounce) {
                clearTimeout(debounce);
            }
        }
    }, [filtro, email, ruolo, pagina]);

    const eliminaUtente = () => {
        let f = getFiltroUtenti();

        setShow(false);
        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + utenteSel.id_utente + f).then((response) => {
            setUtenti(response.data.data);
            setTotPagine(response.data.pages)
            setTotUtenti(response.data.tot)
            endChiamata()
        });
    }

    const cambioPagina = (nuovaPagina) => {
        setPagina(nuovaPagina)
    }

    const handleFiltroChange = (valore, tipo) => {
        clearTimeout(debounce)
        debounce = setTimeout(() => {
            if (tipo === 'N') {
                setFiltro(valore);
            } else if (tipo === 'E') {
                setEmail(valore);
            }
        }, 800)
    }

    const columns = [
        {
            name: 'Cognome',
            selector: row => row.cognome,
            sortable: true,
        },
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Ruolo',
            selector: row => row.ruolo,
            sortable: true,
        },
        {
            name: 'Data registrazione',
            selector: row => getTimestampFormattato(row.registrazione),
            sortable: true,
        },
        {
            name: '',
            selector: row => <Fragment>
                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                    <div className="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" className="btn btn-dark dropdown-toggle"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-bars"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <li><Link to={"/upd-utente/" + row.id_utente} className="dropdown-item"><i
                                className="fas fa-pen"/> Modifica</Link></li>
                            <li>
                                <button type="button" className="dropdown-item" onClick={() => {
                                    setUtenteSel(row)
                                    setShow(true);
                                }}><i className="fas fa-trash"/> Elimina
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fragment>,
            sortable: false,
            right: true,
        }
    ];

    const getComponenteFiltro = () => {
        return <Fragment>
            <input
                type={"text"}
                id={"filtro"}
                placeholder={"Nome/Cognome"}
                className="ns-filtro-data-table"
                onChange={(event) => {
                    handleFiltroChange(event.target.value, 'N')
                }}/>
            <input
                type={"text"}
                id={"email"}
                placeholder={"E-mail"}
                className="ns-filtro-data-table"
                onChange={(event) => {
                    handleFiltroChange(event.target.value, 'E')
                }}/>
            <select id={"ruolo"} className={"ns-filtro-data-table--select"}
                    onChange={(event) => {
                        setRuolo(event.target.value)
                    }}>
                <option value={""}>Tutti i ruoli</option>
                {
                    ruoli && ruoli.map((r) => <option value={r.id_ruolo} key={r.id_ruolo}>{r.nome}</option>)
                }
            </select>
        </Fragment>
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-user'}
                title={'Elenco Utenti'}
                getBottoniDx={() =>
                    <Link to="/upd-utente" className={"btn btn-card"}>
                        <i className="fas fa-plus-circle"/> Aggiungi
                    </Link>
                }>
                <ModalConfirm
                    show={show}
                    handleClose={() => setShow(false)}
                    handleOk={eliminaUtente}
                    testo={"Sicuro di voler eliminare l'utente: " + (utenteSel
                        && (utenteSel.nome + ' ' + utenteSel.cognome)) + "?"}/>
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={utenti}
                            totRighe={totUtenti}
                            columns={columns}
                            addPagination={false}
                            componenteFiltro={getComponenteFiltro}
                        >
                        </NsDataTable>
                    )}
                {
                    totPagine ?
                        (
                            <Pager
                                pagina={pagina}
                                totale={totPagine}
                                handlerCambioPagina={cambioPagina}
                            >
                            </Pager>
                        ) : ''
                }
            </Card>
        </CardContainer>
    );
}

export default ConfUtenti;
