import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {Link, useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";
import {TranslateContext} from "../../../context/TranslateContext";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";

function ModificaImpostazione() {
    const {id} = useParams()

    const [parametro, setParametro] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    useEffect(() => {
        async function getData() {
            startChiamata()
            await axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/parametro/" + id)
                .then((response) => {
                    setLoadingData(false);
                    let param = response.data.data;
                    setParametro(param)

                    if (formikRef.current) {
                        formikRef.current.setFieldValue("id", param.id)
                        formikRef.current.setFieldValue("codice", param.codice)
                        formikRef.current.setFieldValue("descrizione", param.descrizione)
                        formikRef.current.setFieldValue("valore", param.valore)

                        if (param.tipo === 'EM') {
                            let valori = param.valore ? param.valore.split('#|#') : ''
                            formikRef.current.setFieldValue("oggetto", (valori.length > 0 ? valori[0] : ''));
                            formikRef.current.setFieldValue("valore", (valori.length > 1 ? valori[1] : ''));
                        }
                    }
                    endChiamata()
                });
        }

        if (loadingData) {
            if (id && id !== '') {
                getData();
            }
        }
    }, []);

    const closeModal = () => setShow(false);

    const SignupSchema = Yup.object().shape({
        valore: Yup.string()
            .max(2000, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
    });

    const getValore = () => {
        if (parametro.tipo === 'T') {
            return <Field name="valore" className="form-control"/>
        } else if (parametro.tipo === 'N') {
            return <Field name="valore" className="form-control" type={'number'}/>
        } else if (parametro.tipo === 'L') {
            var valori = parametro.valori_previsti.split(';')

            return (
                <Field name="valore" className="form-control form-select" as={'select'}>
                    {
                        valori.map((val, idx) => {
                            var chiaveValore = val.split('=')
                            return <option key={idx} value={chiaveValore[0]}>{chiaveValore[1]}</option>
                        })
                    }
                </Field>
            )
        } else if (parametro.tipo === 'B') {
            return (
                <Field name="valore" className="form-control" as={'select'}>
                    <option value={'1'}>Sì</option>
                    <option value={'0'}>No</option>
                </Field>
            )
        } else if (parametro.tipo === 'TA') {
            return <Field name="valore" className="form-control" as={'textarea'} rows="5"/>
        } else {
            return ''
        }
    }

    return (
        <Card
            icon={'fas fa-sliders'}
            title={'Modifica Impostazione'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id: '',
                    codice: '',
                    descrizione: '',
                    valore: '',
                    canc: '',
                    oggetto: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let newVal = values;
                    if (parametro.tipo === 'EM') {
                        newVal['valore'] = newVal['oggetto'] + '#|#' + newVal['valore'];
                    }

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/parametro/" + id,
                            newVal)
                        .then((response) => {
                            if (response.data.result === 0) {
                                history.push("/conf-parametri");
                            } else {
                                setModalMsg(response.data.description);
                                setShow(true);
                            }
                            endChiamata()
                        });
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="codice" className="form-label">Codice:</label>
                            <Field name="codice" className="form-control" readOnly={true}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="descrizione" className="form-label">Descrizione:</label>
                            <Field name="descrizione" className="form-control" readOnly={true} as={"textarea"}/>
                        </div>
                        {
                            parametro && parametro.tipo !== 'EM' ?
                                <div className="col-md-6">
                                    <label htmlFor="valore"
                                           className="form-label">Valore*:</label>
                                    {
                                        getValore()
                                    }
                                    <LabelErroreCampo errors={errors} touched={touched} name={"valore"}
                                                      submitCount={submitCount}/>
                                </div>
                                : ''
                        }
                        {
                            parametro && parametro.tipo === 'EM' ?
                                <Fragment>
                                    <div className="col-md-6">
                                        <label htmlFor="valore"
                                               className="form-label">Oggetto*:</label>
                                        <Field
                                            name="oggetto"
                                            className="form-control"
                                            validate={(value) => {
                                                if (value === '') {
                                                    return 'Questo campo è obbligatorio'
                                                }
                                            }}
                                        />
                                        <LabelErroreCampo errors={errors} touched={touched} name={"oggetto"}
                                                          submitCount={submitCount}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="valore"
                                               className="form-label">Body*:</label>
                                        <Field name="valore" className="form-control" as={'textarea'} rows="5"/>
                                        <LabelErroreCampo errors={errors} touched={touched} name={"valore"}
                                                          submitCount={submitCount}/>
                                    </div>
                                </Fragment>
                                : ''
                        }
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaImpostazione;