import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../context/LoaderContext";
import {TranslateContext} from "../../context/TranslateContext";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import Translate from "../../components/Translate";
import NsDatePicker from "../../components/NsDatePicker";
import {convertiTimestampInDataPerDb, handleMoneyChange} from "../../utility/common";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";
import NSSelectAutocomplete from "../../components/NSSelectAutocomplete";

export default function ModificaQuotaCertificato() {
    const {idCertificato, idQuota} = useParams()

    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [optionsModalita, setOptionsModalita] = useState([]);
    const [optionsConti, setOptionsConti] = useState([]);
    const [causali, setCausali] = useState([]);
    const [certificato, setCertificato] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const history = useHistory();
    const formikRef = useRef();

    function getModalitaPagamento() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/modalita/")
            .then((response) => {
                endChiamata();

                setOptionsModalita(response.data.data)
            });
    }

    function getConti() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/conto/").then((response) => {
            endChiamata();

            setOptionsConti(response.data.data);
        });
    }

    function getCertificato() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato)
            .then((response) => {
                endChiamata()

                let data = response.data.data;
                if (data) {
                    setCertificato(data);
                    if (data && formikRef.current) {
                        formikRef.current.setFieldValue("targa", data.targa);
                        formikRef.current.setFieldValue("tipo_certificato", data.tipo_certificato);
                    }
                }
            });
    }
    function getQuota() {
        startChiamata();
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/veicolo/" + idQuota)
            .then((response) => {
                endChiamata();

                let data = response.data.data;
                if (data) {
                    if (data && formikRef.current) {
                        formikRef.current.setFieldValue("id_quota", data.id_quota);
                        formikRef.current.setFieldValue("data_pagamento", new Date(data.data_pagamento));
                        formikRef.current.setFieldValue("importo", data.importo);
                        formikRef.current.setFieldValue("id_modalita", data.id_modalita);
                        formikRef.current.setFieldValue("id_tipo_versamento", data.id_tipo_versamento);
                        formikRef.current.setFieldValue("note", data.note);
                        formikRef.current.setFieldValue("canc", data.canc);
                        formikRef.current.setFieldValue("id_causale_movimento", data.id_causale_movimento);
                        formikRef.current.setFieldValue("id_conto", data.id_conto);
                    }
                }
            });
    }

    function getCusali() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/movimento/causale-movimento/?id_categoria_causale=ISCRIZ").then((response) => {
            setCausali(response.data.data);
            endChiamata()
        });
    }

    useEffect(() => {
        getQuota();
        getCertificato();
        getModalitaPagamento();
        getCusali();
        getConti();

        if (certificato && formikRef.current) {
            formikRef.current.setFieldValue("targa", certificato.targa);
            formikRef.current.setFieldValue("tipo_certificato", certificato.tipo_certificato);
        }
    }, [idCertificato, idQuota]);

    const SignupSchema = Yup.object().shape({
        data_pagamento: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        importo: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        id_modalita: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
        id_causale_movimento: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
    });

    return (
        <Card
            icon={'fas fa-credit-card'}
            title={'Inserisci Quota Certificato'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_quota: '',
                    tipo_certificato: certificato ? certificato.tipo_certificato : '',
                    id_certificato: idCertificato,
                    data_pagamento: new Date(),
                    importo: '',
                    id_modalita: '',
                    id_tipo_versamento: '',
                    note: '',
                    lrid: '',
                    canc: '0',
                    id_causale_movimento: '',
                    targa: certificato ? certificato.targa : '',
                    id_conto: ''
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    let newQuota = values;
                    newQuota['data_pagamento'] = convertiTimestampInDataPerDb(newQuota['data_pagamento'])

                    startChiamata()
                    axios
                        .put(process.env.REACT_APP_API_ENDPOINT + '/api/rfi/certificato/' + idCertificato + '/quota/' + idQuota,
                            newQuota)
                        .then((response) => {
                            if (response.data.result === 0) {
                                endChiamata()
                                history.push("/info-certificato/" + idCertificato)
                            } else {
                                setModalMsg(response.data.description);
                                setShow(true);
                                endChiamata()
                            }
                        });
                }}
            >
                {({errors, touched, values, submitCount}) => (
                    <Form className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="tipo_certificato"
                                   className="form-label"><Translate>Certificato</Translate>:</label>
                            <Field name={"tipo_certificato"} className={"form-control"}
                                   readOnly={true}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="targa"
                                   className="form-label"><Translate>Targa</Translate>:</label>
                            <Field name={"targa"} className={"form-control"} readOnly={true}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="data_pagamento" className="form-label"><Translate>Data
                                pagamento*</Translate>:</label>
                            <NsDatePicker
                                nome={"data_pagamento"}
                                dataSelezionata={values["data_pagamento"]}
                                maxDate={new Date()}
                                setSelectedDate={(date, event) => {
                                    formikRef.current.setFieldValue("data_pagamento", date)
                                }}
                            />
                            <LabelErroreCampo errors={errors} touched={touched}
                                              name={"data_pagamento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="importo" className="form-label">Importo*:</label>
                            <Field
                                name="importo"
                                className="form-control rfi-number-no-arrows"
                                type={"number"}
                                onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                step="0.01"
                                onKeyDown={(e) => {
                                    handleMoneyChange(e)
                                }}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"importo"}
                                              submitCount={submitCount}/>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="id_modalita" className="form-label">Modalità di
                                pagamento*:</label>
                            <Field as="select" name="id_modalita" className={'form-select'}>
                                <option value={""}>Selezionare una modalità</option>
                                {
                                    optionsModalita.map((op, index) => {
                                        return <option key={index}
                                                       value={op.id_modalita}>{op.descrizione}</option>
                                    })
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_modalita"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_causale_movimento" className="form-label">Causale
                                pagamento*:</label>
                            <NSSelectAutocomplete
                                name={"id_causale_movimento"}
                                formikRef={formikRef}
                                options={causali ? causali.map(cau => {
                                    return {
                                        label: cau.descrizione + ' - [' + cau.id_causale_movimento + ']',
                                        value: cau.id_causale_movimento,
                                    };
                                }) : []}
                                startVal={values["id_causale_movimento"]}
                            />
                            <LabelErroreCampo errors={errors} touched={touched}
                                              name={"id_causale_movimento"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_conto" className="form-label">Conto:</label>
                            <Field as="select" name="id_conto" className={'form-select'}>
                                <option value={""}>Selezionare un conto</option>
                                {
                                    optionsConti.map((op, index) => {
                                        return <option key={index}
                                                       value={op.id_conto}>{op.descrizione}</option>
                                    })
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_conto"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="note" className="form-label">Note sul pagamento:</label>
                            <Field
                                as="textarea"
                                name="note"
                                className={'form-select'}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"note"}
                                              submitCount={submitCount}/>
                        </div>
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}