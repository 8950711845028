import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../components/Card";
import FormModificaVeicolo from "../veicoli/FormModificaVeicolo";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import ModalMsg from "../../components/ModalMsg";
import {
    convertiTimestampInDataPerDb,
    STATO_CERTIFICATO_BOZZA,
    TIPO_REQ_VEICOLO_CRS, TIPO_REQ_VEICOLO_CRS_REIMM_ALBO,
    TIPO_REQ_VEICOLO_CRS_REIM, TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE
} from "../../utility/common";
import {useLocation} from "react-router-dom";
import ButtonBar from "../../components/ButtonBar";
import {RuoliContext} from "../../context/RuoliContext";

function ModificaVeicolo() {
    const {idUsr, idCertificato} = useParams()

    const location = useLocation();
    var idVeicolo = location.state && location.state.idVeicolo ? location.state.idVeicolo : null;

    const [loadingData, setLoadingData] = useState(true);
    const [loadingTipiCert, setLoadingTipiCert] = useState(true);
    const [veicolo, setVeicolo] = useState(idVeicolo);
    const [idCert, setIdCert] = useState(idCertificato);
    const [tipoRichiesta, setTipoRichiesta] = useState();
    const [certificato, setCertificato] = useState();
    const [note, setNote] = useState('');
    const [veicoli, setVeicoli] = useState([]);
    const [tipiCertificato, setTipiCertificato] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [modalTitolo, setModalTitolo] = useState("ATTENZIONE");
    const [showCampi, setShowCampi] = useState('');
    const [registro, setRegistro] = useState();
    const [numeroRegistrazione, setNumeroRegistrazione] = useState();
    const [suffisso, setSuffisso] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia, isRuoloAdmin, isRuoloSegreteria} = useContext(RuoliContext);

    const history = useHistory();
    const formikRef = useRef();

    function getVeicoli() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/?pag=-1&id_utente=" + idUsr).then((response) => {
            setVeicoli(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getTipiRichiesta(idVeicolo) {
        if (idVeicolo && idVeicolo !== '') {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + idVeicolo + "/tipo-certificato/").then((response) => {
                setTipiCertificato(response.data.data);
                setLoadingTipiCert(false);
                endChiamata()
            });
        } else {
            setTipiCertificato([]);
        }
    }

    function getTipiRichiestaCert() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/tipo-certificato/").then((response) => {
            setTipiCertificato(response.data.data);
            setLoadingTipiCert(false);
            endChiamata()
        });
    }

    function getCertificato() {
        startChiamata();
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCert).then((response) => {
            let certificato = response.data.data;
            if (certificato) {
                setCertificato(certificato);
                setTipoRichiesta(certificato.id_tipo_certificato);
                setVeicolo(certificato.id_veicolo);
                setNumeroRegistrazione(certificato.numero_registrazione);
                setRegistro(certificato.id_registro);
                setSuffisso(certificato.suffisso);
                if (certificato.note) {
                    setNote(certificato.note);
                }
            }
            endChiamata();
        });
    }

    useEffect(() => {
        if (loadingData) {
            getVeicoli()

            if (idVeicolo && idVeicolo !== '') {
                getTipiRichiesta(idVeicolo);
            }
            if (idCert && idCert !== '') {
                getCertificato()
                getTipiRichiestaCert();
            }
        }
    }, [idUsr, idCert]);

    function initCertificato(vei) {
        let cert = certificato;
        if (!cert) {
            cert = {}
        }
        cert.id_tipo_certificato = tipoRichiesta;
        cert.id_veicolo = vei.id_veicolo;
        return cert;
    }

    let salva = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    };

    const onSubmitVeicolo = (veicoloNew) => {
        let cert = initCertificato(veicoloNew);
        cert.veicolo = veicoloNew;
        cert.id_utente = veicoloNew.id_utente;
        cert.note = note;
        cert.id_registro = veicoloNew.id_registro;
        cert.numero_registrazione = veicoloNew.numero_registrazione;

        if (formikRef.current.isSubmitting && formikRef.current.isValid) {
            salvaCertificato(cert, true);
        }
    };

    let salvaBozza = () => {
        if (formikRef.current) {
            let vei = formikRef.current.values;

            vei['data_registrazione'] = vei['data_registrazione'] && vei['data_registrazione'] !== '' ?
                convertiTimestampInDataPerDb(vei['data_registrazione']) : '';
            vei['data_revisione'] = vei['data_revisione'] && vei['data_revisione'] !== '' ?
                convertiTimestampInDataPerDb(vei['data_revisione']) : '';

            let cert = initCertificato(vei);
            cert.veicolo = vei;
            cert.id_utente = vei.id_utente;
            cert.note = note;
            cert.id_registro = vei.id_registro;
            cert.numero_registrazione = vei.numero_registrazione;
            if (!cert.stato || cert.stato == '') {
                cert.stato = STATO_CERTIFICATO_BOZZA;
            }

            startChiamata()
            axios
                .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + idUsr + "/veicolo/" + vei.id_veicolo,
                    vei)
                .then((response) => {
                    if (response.data.result !== 0) {
                        setModalMsg(response.data.description);
                        setModalTitolo("ATTENZIONE");
                        setShow(true);
                    } else {
                        salvaCertificato(cert, false);
                        setModalMsg("Salvataggio avvenuto con successo");
                        setModalTitolo("Salvataggio dati");
                        setShow(true);
                    }
                    endChiamata()
                });
        }
    };

    function procediCert(idCertificato) {
        if (tipoRichiesta && (tipoRichiesta == TIPO_REQ_VEICOLO_CRS
            || tipoRichiesta == TIPO_REQ_VEICOLO_CRS_REIM
            || tipoRichiesta == TIPO_REQ_VEICOLO_CRS_REIMM_ALBO
            || tipoRichiesta == TIPO_CERTIFICATO_ALBO_E_CRS_IN_CIRCOLAZIONE)) {
            history.push('/dich-resp-crs/' + idUsr + '/' + idCertificato);
        } else {
            history.push('/doc-aggiuntivi-cert/' + idUsr + '/' + idCertificato);
        }
    }

    function salvaCertificato(certificato, procedi) {
        if (idCert) {
            startChiamata()
            axios
                .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCert,
                    certificato)
                .then((response) => {
                    endChiamata()

                    if (response.data.result !== 0) {
                        setModalMsg(response.data.description);
                        setModalTitolo("ATTENZIONE");
                        setShow(true);
                    } else if (procedi === true) {
                        procediCert(idCert);
                    }
                });
        } else {
            startChiamata()
            axios
                .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + idUsr + "/certificato/", certificato)
                .then((response) => {
                    endChiamata()
                    if (response.data.result !== 0) {
                        setModalMsg(response.data.description);
                        setModalTitolo("ATTENZIONE");
                        setShow(true);
                    } else if (response.data.data) {
                        setCertificato(response.data.data);
                        setIdCert(response.data.data.id_certificato)

                        if (procedi === true) {
                            procediCert(response.data.data.id_certificato);
                        }
                    }
                });
        }
    }

    return (
        <Card
            icon={'fas fa-file-signature'}
            title={idCert ? 'Modifica Certificato' : 'Inserisci Certificato'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva" className="btn btn-card" onClick={salvaBozza}
                            disabled={!tipoRichiesta || tipoRichiesta === '' || !veicolo || veicolo === ''}>
                        <i className="far fa-save"/> Salva bozza
                    </button>
                    <button type="button" id="salva" name="salva" className="btn btn-card" onClick={salva}
                            disabled={!tipoRichiesta || tipoRichiesta === '' || !veicolo || veicolo === ''}>
                        <i className="fas fa-save"/> Salva e procedi
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}
                titolo={modalTitolo}
            />

            {
                !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() &&
                <div className={"mb-4 row"}>
                    <div className={"col-md-8"}/>
                    <div className={"col-md-4"}>
                        <label htmlFor="campi" className="form-label">Campi da visualizzare:</label>
                        <select
                            name={"campi"}
                            value={showCampi}
                            onChange={e => setShowCampi(e.target.value)}
                            className={"form-control form-select"}
                        >
                            <option value={""}>In base al tipo di certificato</option>
                            <option value={"1"}>Tutti</option>
                        </select>
                    </div>
                </div>
            }
            <div className={"row mb-4"}>
                <div className={"col-md-6"}>
                    <label htmlFor="veicolo" className="form-label">Veicolo per cui richiedere la
                        certificazione*:</label>
                    <select
                        name="veicolo"
                        className={'form-control form-select'}
                        value={veicolo}
                        disabled={idCert}
                        onChange={(ev) => {
                            let idVeicolo = ev.target.value;
                            setVeicolo(idVeicolo);
                            getTipiRichiesta(idVeicolo);
                        }}>
                        <option value={""}></option>
                        {
                            veicoli && veicoli.map((vei) => {
                                return <option value={vei.id_veicolo}
                                               key={vei.id_veicolo}>{vei.marca + ' ' + vei.tipo + ' - ' + vei.anno + ' - ' + vei.targa}</option>
                            })
                        }
                    </select>
                </div>
                <div className={"col-md-6"}>
                    <label htmlFor="tipoRichiesta" className="form-label">Tipo di certificazione*:</label>
                    {
                        tipiCertificato.length === 0 && veicolo && veicolo !== '' && !idCertificato && !loadingTipiCert ?
                            <h5>Non è disponibile nessun certificato per il veicolo selezionato</h5>
                            : <Fragment>
                                <select
                                    name="tipoRichiesta"
                                    className={'form-control form-select'}
                                    value={tipoRichiesta}
                                    disabled={idCert && !isRuoloAdmin() && !isRuoloSegreteria()}
                                    onChange={(ev) => {
                                        setTipoRichiesta(ev.target.value);
                                    }}>
                                    <option value={""}></option>
                                    {
                                        tipiCertificato && tipiCertificato.map((tc) => {
                                            return <option value={tc.id_tipo_certificato}
                                                           key={tc.id_tipo_certificato}>{tc.nome}</option>
                                        })
                                    }
                                </select>
                            </Fragment>
                    }
                </div>
                {
                    !isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() &&
                    <div className={"col-md-12 mt-2"}>
                        <label htmlFor="note" className="form-label">Note certificato:</label>
                        <textarea
                            name={"note"}
                            className={"form-control"}
                            onChange={(e) => {
                                setNote(e.target.value);
                            }}
                            value={note}
                        ></textarea>
                    </div>
                }
            </div>
            {
                (tipoRichiesta && tipoRichiesta !== '' && veicolo && veicolo !== '') &&
                    <FormModificaVeicolo
                        idUsr={idUsr}
                        idVeicolo={veicolo}
                        formikRef={formikRef}
                        tipoRichiesta={tipoRichiesta}
                        isModificaCertificato={true}
                        onSubmitVeicolo={onSubmitVeicolo}
                        showAllCampi={showCampi && showCampi === '1' ? true : false}
                        registro={registro}
                        numeroRegistrazione={numeroRegistrazione}
                        suff={suffisso}
                    />
            }
            <ButtonBar>
                <button
                    type={"button"}
                    className="btn btn-card"
                    onClick={() => {
                        history.goBack()
                    }}
                >
                    <i className="fas fa-angle-left"/> Indietro
                </button>
                <button type="button" id="salva" name="salva" className="btn btn-card" onClick={salvaBozza}
                        disabled={!tipoRichiesta || tipoRichiesta === '' || !veicolo || veicolo === ''}>
                    <i className="far fa-save"/> Salva bozza
                </button>
                <button type="button" id="salva" name="salva" className="btn btn-card" onClick={salva}
                        disabled={!tipoRichiesta || tipoRichiesta === '' || !veicolo || veicolo === ''}>
                    <i className="fas fa-save"/> Salva e procedi
                </button>
            </ButtonBar>
        </Card>
    );
}

export default ModificaVeicolo;