import Translate from './Translate'
import React, {Fragment} from "react";
import {Field} from "formik";
import LabelErroreCampo from "./LabelErroreCampo";

export default function NsField({
                                    label,
                                    name,
                                    className,
                                    formikRef,
                                    values,
                                    errors,
                                    touched,
                                    submitCount,
                                    onBlur = (ev) => {
                                    },
                                    onChange = (ev) => {
                                    },
                                    validate = null,
                                    placeholder = null,
                                    toUpperCase = false
                                }) {

    return (
        <Fragment>
            <label htmlFor={name} className="form-label"><Translate>{label}</Translate>:</label>
            <Field
                id={name}
                name={name}
                className={className}
                placeholder={placeholder}
                onBlur={onBlur}
                value={values[name]}
                onChange={(ev) => {
                    if (toUpperCase === true) {
                        let newVal = ev.target.value && ev.target.value !== '' ? ev.target.value.toUpperCase() : '';
                        formikRef.current.setFieldValue(name, newVal);
                    }

                    onChange(ev);
                }}
                validate={validate}
            />
            <LabelErroreCampo errors={errors} touched={touched} name={name}
                              submitCount={submitCount}/>
        </Fragment>
    );
}