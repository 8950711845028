import {Form, Formik} from "formik";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import ModalMsg from "../../../components/ModalMsg";
import React, {Fragment, useContext, useRef, useState} from "react";
import {getUtenteFromToken} from "../../../utility/common";
import {LoaderContext} from "../../../context/LoaderContext";
import Password from "../../../components/Password";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import {TranslateContext} from "../../../context/TranslateContext";
import * as Yup from "yup";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";

function ResetPassword() {
    const {token} = useParams()

    const [resetEffettuato, setResetEffettuato] = useState(false);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);

    const formikRef = useRef();

    const closeModal = () => setShow(false);

    const SignupSchema = Yup.object().shape({
        password: Yup.string()
            .required(traduci('Password obbligatoria.')),
        password2: Yup.string()
            .required(traduci('La Conferma Password deve essere valorizzata.')),
    });

    return (
        <div>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>

            {
                resetEffettuato === true ?
                    <Fragment>
                        <div className={"text-center mt-4 mb-4 rfi-titolo"}>
                            <h1>Aggiornamento password effettuato con successo</h1>
                            <h5>Torna alla pagina di accesso per entrare nell'Area Soci</h5>
                            <div className={"row mt-4"}>
                                <div className={"col-md-12 text-center"}>
                                    <Link to={"/"} className={"btn btn-dark"}>
                                        <i className="fas fa-sign-in-alt"/> Vai alla pagina di accesso
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    : <Fragment>
                        <div className={"text-center mt-4 mb-4 rfi-titolo"}>
                            <h1>Cambia la tua password</h1>
                            <h5>Inserisci una nuova password ed accedi per utilizzare i nostri servizi</h5>
                        </div>
                        <div className="rfi-login rfi-recupera-pwd">
                            <div>
                                <Formik
                                    initialValues={{
                                        password: '',
                                        password2: ''
                                    }}
                                    innerRef={formikRef}
                                    validationSchema={SignupSchema}
                                    onSubmit={values => {
                                        if (values.password == values.password2) {
                                            let utente = getUtenteFromToken(token);

                                            if (utente && utente.id_utente && utente.id_utente != '') {
                                                startChiamata()
                                                axios
                                                    .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + utente.id_utente + "/password/",
                                                        values, {
                                                            headers: {
                                                                jwt: token
                                                            }
                                                        })
                                                    .then((response) => {
                                                        endChiamata()
                                                        if (response.data.result == '0') {
                                                            setResetEffettuato(true);
                                                        } else {
                                                            setModalMsg(response.data.description);
                                                            setShow(true);
                                                        }
                                                    });
                                            } else {
                                                setModalMsg('Operazione non consentita');
                                                setShow(true);
                                            }
                                        } else {
                                            setModalMsg('Attenzione! Le password non corrispondono');
                                            setShow(true);
                                        }
                                    }}
                                >
                                    {({values, errors, touched, submitCount}) => (
                                        <Form className="row g-3">
                                            <div className="alert alert-info alert-dismissible fade show" role="alert">
                                                La password deve essere composta da:
                                                <ul>
                                                    <li>Lunghezza minima di 8 caratteri</li>
                                                    <li>Contenere almeno una lettera maiuscola, una minuscola e una cifra
                                                        numerica
                                                    </li>
                                                    <li>Deve contenere almeno uno dei seguenti caratteri speciali ! # $ % &
                                                        ' ( ) * + , - . : ; &lt; = &gt; @
                                                    </li>
                                                    <li>Non puo' contenere spazi, lettere accentate o altri caratteri
                                                        diacritici (àéç§ ecc...)
                                                    </li>
                                                </ul>
                                                <button type="button" className="btn-close" data-bs-dismiss="alert"
                                                        aria-label="Close"></button>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="password" className="form-label">Password*</label>
                                                <Password name={"password"} values={values} formikRef={formikRef}
                                                          showControlli={true}/>
                                                <LabelErroreCampo errors={errors} touched={touched} name={"password"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="password2" className="form-label">Conferma password*</label>
                                                <Password name={"password2"} values={values} formikRef={formikRef}/>
                                                <LabelErroreCampo errors={errors} touched={touched} name={"password2"}
                                                                  submitCount={submitCount}/>
                                            </div>
                                            <div className="d-grid gap-2">
                                                <button type="submit" name="conferma" id="conferma"
                                                        className="btn btn-dark"
                                                        disabled={values.password === '' || values.password2 === ''}>
                                                    <i className="fas fa-check"/> Salva
                                                </button>
                                            </div>
                                            <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default ResetPassword;
