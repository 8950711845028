import React from "react";
import CruscottoVeicoli from "./CruscottoVeicoli";

function CruscottoVeicoliAbarth() {

    return (
        <CruscottoVeicoli tipo={'2'}/>
    );
}

export default CruscottoVeicoliAbarth;
