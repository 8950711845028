import React, {useState, useEffect, useContext, Fragment} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {
    convertiTimestampInDataPerDb,
    getDataFormattata,
    getUtenteFromToken, handleMoneyChange, STATO_CERTIFICATO_BOZZA, STATO_CERTIFICATO_IN_APPROVAZIONE,
    TIPO_MODULO_CERTIFICATO_VEICOLO
} from "../../utility/common";
import ModuloPagamentoQuota from "../pagamenti/ModuloPagamentoQuota";
import {Link, useHistory, useParams} from "react-router-dom";
import {RuoliContext} from "../../context/RuoliContext";
import AddQuotaCertificato from "./AddQuotaCertificato";
import Card from "../../components/Card";
import NsDataTable from "../../components/tabelle/NsDataTable";
import ModalMsg from "../../components/ModalMsg";
import {Field, Form, Formik} from "formik";
import Translate from "../../components/Translate";
import NsDatePicker from "../../components/NsDatePicker";
import LabelErroreCampo from "../../components/LabelErroreCampo";
import NSSelectAutocomplete from "../../components/NSSelectAutocomplete";
import NsFormikScrollToError from "../../components/NsFormikScrollToError";

export default function PagamentoQuotaCertificato() {
    const {idCertificato} = useParams()

    const [socio, setSocio] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [quote, setQuote] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [certificato, setCertificato] = useState();

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    const history = useHistory();

    function getQuote() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?quoteVeicoli=1&attiva=1&id_certificato=" + idCertificato).then((response) => {
            endChiamata();

            setQuote(response.data.data);
            setLoadingData(false);
        });
    }

    function getSocio() {
        let token = localStorage.getItem('rfi-login');
        if (token) {
            let user = getUtenteFromToken(token);
            if (user) {
                startChiamata()
                axios
                    .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + user.id_utente)
                    .then((response) => {
                        let val = response.data.data;
                        setLoadingData(false);
                        setSocio(val)
                        endChiamata()
                    });
            }
        }
    }

    function getCertificato() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + idCertificato)
            .then((response) => {
                setCertificato(response.data.data);
                endChiamata();
            });
    }

    useEffect(() => {
        if (loadingData) {
            getQuote();
            getSocio();
            getCertificato();
        }
    }, [idCertificato]);

    return (
        <Fragment>
            <ModalMsg
                show={show}
                handleClose={() => setShow(false)}
                testo={modalMsg}/>
            {
                loadingData === false &&
                <Fragment>
                    {
                        quote && quote.length > 0 ?
                            <Card
                                icon={'fas fa-credit-card'}
                                title={'Quota Certificato'}
                                getBottoniDx={() =>
                                    <Fragment>
                                        <button
                                            type={"button"}
                                            className="btn btn-card"
                                            onClick={() => {
                                                history.goBack()
                                            }}
                                        >
                                            <i className="fas fa-angle-left"/> Indietro
                                        </button>
                                        <button
                                            type={"button"}
                                            className="btn btn-card"
                                            data-toggle="tooltip" title="Fine"
                                            onClick={() => {
                                                if (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) {
                                                    let newCert = certificato;
                                                    newCert.stato = STATO_CERTIFICATO_IN_APPROVAZIONE;

                                                    startChiamata()
                                                    axios
                                                        .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/"
                                                            + idCertificato + '/stato/', newCert)
                                                        .then((response) => {
                                                            endChiamata()

                                                            if (response.data.result !== 0) {
                                                                setModalMsg(response.data.description);
                                                                setShow(true);
                                                            } else {
                                                                history.push("/list-certificati")
                                                            }
                                                        });
                                                } else {
                                                    history.push("/info-certificato/" + idCertificato)
                                                }
                                            }}
                                        >
                                            <i className="fas fa-angle-right"/> Fine
                                        </button>
                                    </Fragment>
                                }>
                                <div className={"row m-4"}>
                                    <div className={"col-md-12 text-center"}>
                                        <h3>Quota già versata</h3>
                                    </div>
                                </div>
                                <NsDataTable
                                    data={quote}
                                    columns={[
                                        {
                                            name: 'Data',
                                            selector: row => getDataFormattata(row.data_pagamento),
                                            sortable: true,
                                        },
                                        {
                                            name: 'Oggetto',
                                            selector: row => row.tipo_versamento,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Importo €',
                                            selector: row => row.importo,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Modalità di pagamento',
                                            selector: row => row.modalita,
                                            sortable: true,
                                        }
                                    ]}
                                    addPagination={false}
                                    addFiltri={false}
                                />
                            </Card>
                            :
                            <Fragment>
                                {
                                    socio &&
                                    <Fragment>
                                        {
                                            (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) ?
                                                <ModuloPagamentoQuota
                                                    usr={socio}
                                                    tipo={TIPO_MODULO_CERTIFICATO_VEICOLO}
                                                    idCertificato={idCertificato} />
                                                : <Fragment>
                                                    <AddQuotaCertificato
                                                        usr={socio}
                                                        idCertificato={idCertificato} />
                                                </Fragment>
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    );

}