import React, {Fragment, useContext, useEffect, useState} from "react";
import axios from "axios";
import {LoaderContext} from "../../context/LoaderContext";
import {
    getDataFormattata,
    TIPO_MODULO_REGISTRAZIONE,
} from "../../utility/common";
import {Link, useHistory} from "react-router-dom";
import ModuloPagamentoQuota from "../pagamenti/ModuloPagamentoQuota";
import NsDataTable from "../../components/tabelle/NsDataTable";
import TabellaQuoteTmpSocio from "./TabellaQuoteTmpSocio";

export default function HomeSocioApprovato({usr}) {

    const [quoteTemp, setQuoteTemp] = useState([]);
    const [loadQuote, setLoadQuote] = useState(true);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    function getQuoteTemp() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/quota/?pag=-1&attiva=0&id_utente=" + usr.id_utente).then((response) => {
            setQuoteTemp(response.data.data);
            setLoadQuote(false);
            endChiamata()
        });
    }

    useEffect(() => {
        getQuoteTemp();
    }, [usr]);

    return (
        <Fragment>
            {
                loadQuote === false && (
                    quoteTemp && quoteTemp.length === 0 ?
                        <ModuloPagamentoQuota usr={usr} tipo={TIPO_MODULO_REGISTRAZIONE}/>
                        : <Fragment>
                            <div className={"text-center mt-4 mb-4 rfi-titolo"}>
                                <h1>Grazie per aver completato la tua richiesta di pagamento</h1>
                                <h3>Allega le tue ricevute e riceverai una E-mail appena la nostra Segreteria avrà
                                    verificato l'avvenuto pagamento.</h3>
                            </div>
                            <div className={"mt-4"}>
                                <TabellaQuoteTmpSocio usr={usr} quote={quoteTemp} updateQuote={() => {
                                    getQuoteTemp()
                                }}/>
                            </div>
                        </Fragment>
                )
            }
        </Fragment>
    )
}
