import React, {useState, useEffect, useRef, useContext, Fragment} from "react";
import axios from "axios";
import {useHistory, useParams} from 'react-router-dom'
import Card from "../../../components/Card";
import {Field, Form, Formik} from "formik"
import ModalMsg from "../../../components/ModalMsg";
import * as Yup from "yup";
import {LoaderContext} from "../../../context/LoaderContext";
import {TranslateContext} from "../../../context/TranslateContext";
import LabelErroreCampo from "../../../components/LabelErroreCampo";
import NsFormikScrollToError from "../../../components/NsFormikScrollToError";
import {RuoliContext} from "../../../context/RuoliContext";
import NSSelectAutocomplete from "../../../components/NSSelectAutocomplete";

function ModificaUtente() {
    const {id} = useParams()

    const [loadingData, setLoadingData] = useState(true);
    const [optionsRuoli, setOptionsRuoli] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [agenzie, setAgenzie] = useState([]);
    const [show, setShow] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);

    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {traduci} = useContext(TranslateContext);
    const {getIdRuoloClub, getIdRuoloAgenzia} = useContext(RuoliContext);

    const history = useHistory();
    const formikRef = useRef();

    function getUtente() {
        startChiamata()
        axios
            .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id)
            .then((response) => {
                let val = response.data.data;
                setLoadingData(false);

                if (formikRef.current) {
                    formikRef.current.setFieldValue("id_utente", val.id_utente);
                    formikRef.current.setFieldValue("nome", val.nome);
                    formikRef.current.setFieldValue("cognome", val.cognome);
                    formikRef.current.setFieldValue("email", val.email);
                    formikRef.current.setFieldValue("id_ruolo", val.id_ruolo);
                    formikRef.current.setFieldValue("id_club", val.id_club);
                    formikRef.current.setFieldValue("id_agenzia", val.id_agenzia);
                }
                endChiamata()
            });
    }

    function getClubs() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/club/").then((response) => {
            setClubs(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getAgenzie() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/agenzie/").then((response) => {
            setAgenzie(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }

    useEffect(() => {
        async function getRuoli() {
            startChiamata()
            await axios
                .get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/ruolo/?hideSocio=1")
                .then((response) => {
                    setOptionsRuoli(response.data.data);
                    endChiamata()
                });
        }

        if (loadingData) {
            getRuoli();
            getClubs();
            getAgenzie();

            if (id && id !== '') {
                getUtente();
            }
        }
    }, [id]);

    const SignupSchema = Yup.object().shape({
        nome: Yup.string()
            .max(150, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        cognome: Yup.string()
            .max(150, traduci('Attenzione! Hai inserito troppi caratteri'))
            .required(traduci('Questo campo è obbligatorio')),
        email: Yup.string()
            .email('Indirizzo E-mail non valido')
            .required(traduci('Questo campo è obbligatorio')),
        id_ruolo: Yup.string()
            .required(traduci('Questo campo è obbligatorio')),
    });

    const closeModal = () => setShow(false);

    return (
        <Card
            icon={'fas fa-user'}
            title={id ? 'Modifica Utente' : 'Inserisci Utente'}
            getBottoniDx={() =>
                <Fragment>
                    <button
                        type={"button"}
                        className="btn btn-card"
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        <i className="fas fa-angle-left"/> Indietro
                    </button>
                    <button type="button" id="salva" name="salva"
                            className="btn btn-card"
                            onClick={() => {
                                if (formikRef.current) {
                                    formikRef.current.handleSubmit()
                                }
                            }}>
                        <i className="fas fa-save"/> Salva
                    </button>
                </Fragment>
            }>
            <ModalMsg
                show={show}
                handleClose={closeModal}
                testo={modalMsg}/>
            <Formik
                initialValues={{
                    id_utente: '',
                    nome: '',
                    cognome: '',
                    email: '',
                    id_ruolo: '',
                    id_club: '',
                    id_agenzia: '',
                    stato: '0' // non necessario per gli utenti NON soci
                }}
                innerRef={formikRef}
                validationSchema={SignupSchema}
                onSubmit={values => {
                    if (id) {
                        startChiamata()
                        axios
                            .put(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/" + id,
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    } else {
                        startChiamata()
                        axios
                            .post(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/",
                                values)
                            .then((response) => {
                                if (response.data.result === 0) {
                                    endChiamata()
                                    history.goBack();
                                } else {
                                    setModalMsg(response.data.description);
                                    setShow(true);
                                    endChiamata()
                                }
                            });
                    }
                }}
            >
                {({errors, touched, submitCount, values}) => (
                    <Form className="row g-3">
                        <div className="col-md-12">
                            <label htmlFor="email" className="form-label">E-mail*:</label>
                            <Field
                                name="email"
                                type="email"
                                className="form-control"
                                readOnly={id}
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"email"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="nome" className="form-label">Nome*:</label>
                            <Field
                                name="nome"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"nome"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="cognome" className="form-label">Cognome*:</label>
                            <Field
                                name="cognome"
                                className="form-control"
                            />
                            <LabelErroreCampo errors={errors} touched={touched} name={"cognome"}
                                              submitCount={submitCount}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id_ruolo" className="form-label">Ruolo*:</label>
                            <Field as="select" name="id_ruolo" className={'form-select'}>
                                <option value={""}>Selezionare un ruolo</option>
                                {
                                    optionsRuoli.map((op, index) => {
                                        return <option key={index} value={op.id_ruolo}>{op.nome}</option>
                                    })
                                }
                            </Field>
                            <LabelErroreCampo errors={errors} touched={touched} name={"id_ruolo"}
                                              submitCount={submitCount}/>
                        </div>
                        {
                            values['id_ruolo'] == getIdRuoloClub() &&
                            <Fragment>
                                <div className="col-md-6">
                                    <label htmlFor="id_club" className="form-label">Club*:</label>
                                    <Field
                                        name={"id_club"}
                                        component={NSSelectAutocomplete}
                                        formikRef={formikRef}
                                        options={() => {
                                            let opts = (clubs ? clubs.map(o => {
                                                return {
                                                    label: o.id_club + (o.descrizione ? (' - ' + o.descrizione) : ''),
                                                    value: o.id_club,
                                                };
                                            }) : []);

                                            opts.unshift({
                                                label: "Selezionare un club",
                                                value: "",
                                            });
                                            return opts;
                                        }}
                                        onChange={(val) => {
                                            formikRef.current.setFieldValue('id_club', val.value)
                                        }}
                                        startVal={values["id_club"]}
                                        validate={(val) => {
                                            if (!val || val === '') {
                                                return 'Questo campo è obbligatorio'
                                            }
                                        }}
                                    />
                                    <LabelErroreCampo errors={errors} touched={touched} name={"id_club"}
                                                      submitCount={submitCount}/>
                                </div>
                            </Fragment>
                        }
                        {
                            values['id_ruolo'] == getIdRuoloAgenzia() &&
                            <Fragment>
                                <div className="col-md-6">
                                    <label htmlFor="id_agenzia" className="form-label">Agenzia*:</label>
                                    <Field
                                        name={"id_agenzia"}
                                        component={NSSelectAutocomplete}
                                        formikRef={formikRef}
                                        options={() => {
                                            let opts = (agenzie ? agenzie.map(o => {
                                                return {
                                                    label: o.descrizione,
                                                    value: o.id_agenzia,
                                                };
                                            }) : []);

                                            opts.unshift({
                                                label: "Selezionare un'agenzia",
                                                value: "",
                                            });
                                            return opts;
                                        }}
                                        onChange={(val) => {
                                            formikRef.current.setFieldValue('id_agenzia', val.value)
                                        }}
                                        startVal={values["id_agenzia"]}
                                        validate={(val) => {
                                            if (!val || val === '') {
                                                return 'Questo campo è obbligatorio'
                                            }
                                        }}
                                    />
                                    <LabelErroreCampo errors={errors} touched={touched} name={"id_agenzia"}
                                                      submitCount={submitCount}/>
                                </div>
                            </Fragment>
                        }
                        <NsFormikScrollToError submitCount={submitCount} errors={errors}/>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default ModificaUtente;