import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../../components/CardContainer";
import Card from "../../../components/Card";
import {LoaderContext} from "../../../context/LoaderContext";
import axios from "axios";
import {Link} from "react-router-dom";
import NsDataTable from "../../../components/tabelle/NsDataTable";

function ConfImpostazioni() {

    const [loadingData, setLoadingData] = useState(true);

    const [data, setData] = useState([]);

    const {startChiamata, endChiamata} = useContext(LoaderContext);

    useEffect(() => {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/parametro/").then((response) => {
            setData(response.data.data);
            setLoadingData(false);
            endChiamata()
        });
    }, []);

    const funzioneFiltro = (item, filtro) => {
        return (item.codice && item.codice.toLowerCase().includes(filtro.toLowerCase()))
            || (item.descrizione && item.descrizione.toLowerCase().includes(filtro.toLowerCase()))
            || (item.area && item.area.toLowerCase().includes(filtro.toLowerCase()))
    }

    const columns = [
        {
            name: 'Area',
            selector: row => row.area,
            sortable: true,
        },
        {
            name: 'Codice',
            selector: row => row.codice,
            sortable: true,
        },
        {
            name: 'Descrizione',
            selector: row => row.descrizione,
            sortable: true,
        },
        {
            name: 'Valore',
            selector: row => {
                var valore = ''
                if (row.tipo === 'L') {
                    var valori = row.valori_previsti.split(';')
                    var valFiltrato = valori.filter((val) => val.split('=')[0] === row.valore)
                    if (valFiltrato) {
                        valore = valFiltrato[0].split('=')[1]
                    }
                } else if (row.tipo === 'B') {
                    valore = row.valore === '1' ? 'Sì' : 'No';
                } else {
                    valore = row.valore.length > 50 ? row.valore.substr(0, 50) + '...' : row.valore
                }
                return valore
            },
            sortable: false,
        },
        {
            name: '',
            selector: row => <Fragment>
                <Link to={"/upd-parametro/" + row.id}
                      className="btn btn-dark rfi-btn-table"
                      data-toggle="tooltip" title="Modifica">
                    <i className="fas fa-pen"/> <span className={'rfi-display-mobile'}>Modifica</span>
                </Link>
            </Fragment>,
            sortable: false,
            right: true,
        }
    ];

    return (
        <CardContainer>
            <Card
                icon={'fas fa-sliders'}
                title={'Impostazioni'}
            >
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : (
                        <NsDataTable
                            data={data}
                            columns={columns}
                            funzioneFiltro={funzioneFiltro}
                        />
                    )
                }
            </Card>
        </CardContainer>
    );
}

export default ConfImpostazioni;
